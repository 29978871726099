export default {
  fieldStyles: {
    width: '100%',
    padding: '12px 8px',
    lineHeight: '16px',
    fontWeight: '500',
    fontSize: '19px',
    border: '1px solid rgba(0,0,0,0.5)',
    borderRadius: '4px',
    outline: 'none',

    '&:hover': {
      border: '1px solid rgba(0,0,0,1)',
    },

    '&:focus': {
      border: '1px solid #3f51b5',
      boxShadow: '0px 0px 0px 1px #3f51b5 inset',
    },
    '&::placeholder': {
      fontWeight: '400',
      fontSize: '15px',
    },
  },
  disabled: {
    pointerEvents: 'none',
    userSelect: 'none',
    opacity: 0.5,
  },
};
