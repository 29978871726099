import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid, InputAdornment } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import SsnOrTinInput from 'now-frontend-shared/components/inputs/SsnOrTinInput';
import styles from './styles';
import { updateCompany } from 'store/actions/companyActions';
import DatePicker from 'now-frontend-shared/components/DatePicker';
import { validateBeneficialOwnershipPercentage } from 'now-shared/validation/beneficial-ownership-percentage';
import { getAllStates } from 'store/actions/statesActions';
import {
  validateBeneficialOwnerDetails,
} from 'now-shared/validation/beneficial-owner-details';
import { formValidationHasErrors } from 'now-shared/validation/validation-rules';
import LockIcon from '@material-ui/icons/LockRounded';
import { navigateToNextPage, navigateToPreviousPage } from 'constants/registrationFlow';

function BeneficialOwnerAccountView({
  classes, states, company,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [touched, setTouched] = React.useState({});

  const errors = validateBeneficialOwnerDetails(company);
  const formHasErrors = formValidationHasErrors(errors);
  const [isSecretVisible, setIsSecretVisible] = React.useState({});

  React.useEffect(() => {
    dispatch(getAllStates());
  }, [dispatch]);

  React.useEffect(() => {
    if (typeof company.BOstateId === 'number' && states?.states?.length) {
      dispatch(updateCompany({ value: states.states.find(state => state.id === company.BOstateId), key: 'BOstateId' }));
    }
  }, [dispatch, states.states, company.BOstateId]);

  const [percentTouched, setPercentTouched] = React.useState(false);

  const setTouchedDelay = 0;
  const setTouchedDelayed = (fieldName, newValue) => {
    setTimeout(() => setTouched(prev => ({
      ...prev,
      [fieldName]: newValue,
    })), setTouchedDelay);
  };

  const setIsSecretVisibleDelayed = (fieldName, newValue) => {
    setTimeout(() => setIsSecretVisible(prev => ({
      ...prev,
      [fieldName]: newValue,
    })), setTouchedDelay);
  };

  const invalidPercentage = percentTouched && !validateBeneficialOwnershipPercentage(company.BOPercentageOwnership);
  const businessFields = [
    { label: 'Title', value: 'BOTitle' },
    { label: 'Address', value: 'BOStreetAddress' },
    { label: 'Address (optional)', value: 'BOStreetAddress2' },
  ];
  const createFields = (list, autoFocus = false) => (
    <>
      {list.map(({ label, value }, i) => (
        <TextField
          value={company[value]}
          key={label}
          variant="outlined"
          onChange={e => {
            dispatch(updateCompany({ value: e.target.value || null, key: value }));
          }}
          label={label}
          placeholder={label}
          fullWidth
          margin="normal"
          autoFocus={autoFocus && i === 0}
        />
      ))}
    </>
  );

  const requiredCompany = {
    BOStreetAddress: company.BOStreetAddress,
    BOTitle: company.BOTitle,
    BOFirstName: company.BOFirstName,
    BOLastName: company.BOLastName,
    BOCity: company.BOCity,
    BOZipCode: company.BOZipCode,
    BOstateId: company.BOstateId,
  };

  const requiredBusinessFields = Object.values(requiredCompany).every(v => {
    if (!v) return false;
    if (typeof v === 'object' || v.length > 0) return true;
    return false;
  }) && company.BOBirthDate && validateBeneficialOwnershipPercentage(company.BOPercentageOwnership) && !formHasErrors;

  const renderInitialContent = () => (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      style={{ margin: 'auto', maxWidth: '642px' }}
    >
      <Typography variant="h1" component="div" className={classes.title}>
        Ultimate Beneficial Owner Detail
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            value={company.BOFirstName}
            key="firstName"
            variant="outlined"
            onChange={e => {
              dispatch(
                updateCompany({ value: e.target.value || null, key: 'BOFirstName' }),
              );
            }}
            label="First Name"
            placeholder="First Name"
            fullWidth
            margin="normal"
            autoFocus
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={company.BOLastName}
            key="lastName"
            variant="outlined"
            onChange={e => {
              dispatch(
                updateCompany({ value: e.target.value || null, key: 'BOLastName' }),
              );
            }}
            label="Last Name"
            placeholder="Last Name"
            fullWidth
            margin="normal"
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            error={invalidPercentage}
            helperText={invalidPercentage ? 'Ownership must be between 25 and 100' : '\u00a0'}
            value={company.BOPercentageOwnership}
            key="Percentage Ownership"
            variant="outlined"
            type="number"
            onFocus={() => setPercentTouched(true)}
            onChange={e => {
              dispatch(
                updateCompany({
                  value: e.target.value || null,
                  key: 'BOPercentageOwnership',
                }),
              );
            }}
            label="Percentage of Ownership"
            placeholder="0"
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={6} className={classes.datePickerStyles}>
          <Typography
            variant="body1"
            component="div"
            className={classes.datePickerText}
          >
            Date of Birth
          </Typography>
          <DatePicker
            meta={{ touched: false, error: false }}
            maxDate={new Date()}
            classes={{ input: classes.datePicker }}
            fullWidth
            input={{
              name: 'BOBirthDate',
              value: company.BOBirthDate,
              onChange: e => dispatch(updateCompany({ value: e, key: 'BOBirthDate' })),
            }}
          />
        </Grid>
      </Grid>
      {(() => {
        const field = 'BOSSNorTin';
        const label = 'SSN/TIN';
        const isSecret = true;
        const error = touched[field] && errors[field];
        const value = company[field];
        return (
          <>
            <SsnOrTinInput
              classes={{ maskStyles: classes.ssnOrTinInput }}
              autoComplete="off"
              input={{
                name: field,
                value: value || '',
                onChange: e => {
                  dispatch(updateCompany({ key: field, value: e.target.value || null }));
                },
                onFocus: () => {
                  setTouchedDelayed(field, false);
                  if (isSecret) {
                    setIsSecretVisibleDelayed(field, true);
                    if (document.getElementById('BOSSNorTin').getAttribute('type') === 'password') {
                      document.getElementById('BOSSNorTin').setAttribute('type', 'text');
                    }
                  }
                },
                onBlur: () => {
                  setTouchedDelayed(field, true);
                  if (isSecret) {
                    document.getElementById('BOSSNorTin').setAttribute('type', 'password');
                    setIsSecretVisibleDelayed(field, false);
                  }
                },
              }}
              renderInput={() => (
                <TextField
                  label={label}
                  id={field}
                  type="text"
                  error={!!error}
                  helperText={error || '\u00a0'}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <LockIcon />
                    ),
                  }}
                />
              )}
            />
            <Typography variant="body" component="div" gutterBottom className={classes.infoText}>
              If the user does not enter a Social Security Number during registration,
              it must be provided prior to closing any transaction on the Platform.
            </Typography>
          </>
        );
      })()}
      {createFields(businessFields)}
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <TextField
            value={company.BOCity}
            key="city"
            variant="outlined"
            onChange={e => {
              dispatch(updateCompany({ value: e.target.value || null, key: 'BOCity' }));
            }}
            label="City"
            placeholder="City"
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            value={company.BOZipCode}
            key="zip"
            variant="outlined"
            onChange={e => {
              dispatch(
                updateCompany({ value: e.target.value || null, key: 'BOZipCode' }),
              );
            }}
            label="Zip"
            placeholder="Zip"
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            margin="normal"
            fullWidth
            value={company.BOstateId}
            onChange={(_, newValue) => {
              dispatch(updateCompany({ value: newValue || null, key: 'BOstateId' }));
            }}
            id="state-select"
            options={states.states}
            style={{
              maxWidth: 629,
              marginTop: '16px',
              marginBottom: '8px',
            }}
            getOptionLabel={option => option.title || ''}
            renderInput={params => (
              <TextField {...params} label="State" variant="outlined" />
            )}
          />
        </Grid>
        <Typography variant="body" component="div" className={classes.infoText}>
          Please gather a copy of Corporate Resolutions or Operating Agreement. You will need to upload this on an upcoming step.
        </Typography>
      </Grid>
      <div className={classes.buttonContainer}>
        <Button
          onClick={() => history.push(navigateToPreviousPage(history.location.pathname))}
          className={classes.backButton}
          variant="text"
        >
          BACK
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          disabled={!requiredBusinessFields}
          onClick={() => history.push(navigateToNextPage(history.location.pathname))}
        >
          NEXT
        </Button>
      </div>
    </Grid>
  );

  return <div className={classes.root}>{renderInitialContent()}</div>;
}

BeneficialOwnerAccountView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  company: PropTypes.objectOf(PropTypes.string).isRequired,
  states: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(
  connect(({ company, states }) => ({
    company,
    states,
  })),
  withStyles(styles),
)(BeneficialOwnerAccountView);
