import { asyncAction, action } from 'now-frontend-shared/utils/actions';

// async actions
export const getPreSignedUrls = asyncAction(
  'accountManagerFiles/GET_PRE_SIGNED_URLS',
);

// sync action
export const reset = action('accountManagerFiles/RESET');
export const setAWSData = action('accountManagerFiles/SET_AWS_DATA');
export const removeAWSDataFile = action('accountManagerFiles/REMOVE_AWS_DATA_FILE');
export const clearAWSData = action('accountManagerFiles/CLEAR_AWS_DATA');
export const setUnloadedFilesExist = action('accountManagerFiles/SET_UNLOADED_FILES_EXIST');
