import { primaryDark } from 'themes/colors';

export default {
  wrapper: {
    padding: '32px 16px',
    margin: '4px 0',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    backgroundColor: '#FBFBFB',

    '@media screen and (max-width: 960px)': {
      padding: '10px',
    },
  },
  bidField: {
    display: 'flex',
    padding: '10px',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'normal',
    color: primaryDark,

    '@media screen and (max-width: 1280px)': {
      flexDirection: 'column',
    },

    '@media screen and (max-width: 960px)': {
      flexDirection: 'row',
      fontSize: '18px',
      lineHeight: '21px',
    },

    '@media screen and (max-width: 600px)': {
      padding: '2px 0',
    },

    '@media screen and (max-width: 320px)': {
      fontSize: '16px',
    },
  },
  bidFieldValue: {
    marginLeft: '8px',
    fontSize: '22px',
    lineHeight: '26px',
    fontWeight: 'bold',

    '@media screen and (max-width: 1280px)': {
      marginLeft: 'unset',
      fontSize: '18px',
      lineHeight: '21px',
    },

    '@media screen and (max-width: 960px)': {
      marginLeft: '8px',
    },
  },
  bidDate: {
    padding: '10px',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'bold',
    color: primaryDark,

    '@media screen and (max-width: 960px)': {
      justifyContent: 'unset',
      fontSize: '18px',
      lineHeight: '21px',
    },

    '@media screen and (max-width: 600px)': {
      padding: '2px 0',
    },

    '@media screen and (max-width: 320px)': {
      fontSize: '16px',
    },
  },
  contacts: {
    flex: 1,
    padding: '10px',
    fontSize: '32px',
    lineHeight: '36px',
    fontWeight: 'normal',
    letterSpacing: '0.003em',
    color: primaryDark,

    '@media screen and (max-width: 1280px)': {
      fontSize: '28px',
      lineHeight: '36px',
    },

    '@media screen and (max-width: 600px)': {
      padding: '2px 0',
      fontSize: '18px',
      lineHeight: '21px',
    },

    '@media screen and (max-width: 320px)': {
      fontSize: '16px',
    },
  },
};
