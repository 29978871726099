import { primaryDark } from '../../../themes/colors';

export default {
  fieldStyles: {
    width: '100%',
    padding: '12px 16px',
    lineHeight: '16px',
    fontWeight: '500',
    fontSize: '14px',
    fontFamily: 'Roboto, sans-serif',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    outline: 'none',
    background: 'none',

    '&:hover': {
      border: '1px solid rgba(0,0,0,1)',
    },

    '&:focus': {
      border: `1px solid ${primaryDark}`,
      boxShadow: `0px 0px 0px 1px ${primaryDark} inset`,
    },
  },
  disabled: {
    pointerEvents: 'none',
    userSelect: 'none',
    opacity: 0.5,
  },
};
