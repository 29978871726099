import {
  all, fork, take, takeLatest, call, put, select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { reset } from 'redux-form';

// actions
import {
  getCurrentProperty,
  editProperty,
  getStates,
  getBasins,
  getCounties,
  getLandingZones,
  getPreSignedUrls,
} from '../actions/editPropertyActions';

// api methods
import Api from 'api/properties';

// storage helpers functions
import * as storage from 'now-frontend-shared/utils/storage';
import queryString from 'query-string';

function* ensureGetStates() {
  try {
    const { data } = yield call(Api.getStates);
    yield put({ type: getStates.success, payload: data });
  } catch (err) {
    yield put({ type: getStates.failure, err });
  }
}

function* watchGetState() {
  yield takeLatest(getStates.type, ensureGetStates);
  yield take(getStates.success);
}

function* ensureGetBasins() {
  const stateId = yield select(({ editProperty }) => editProperty.stateId);
  try {
    const { data } = yield call(Api.getBasins, { url: `/states/${stateId}/basins` });
    yield put({ type: getBasins.success, payload: data });
  } catch (err) {
    yield put({ type: getBasins.failure, err });
  }
}

function* watchGetBasins() {
  yield takeLatest(getBasins.type, ensureGetBasins);
  yield take(getBasins.success);
}

function* ensureGetCounties() {
  const stateId = yield select(({ editProperty }) => editProperty.stateId);
  const basinId = yield select(({ editProperty }) => editProperty.basinId);
  try {
    const { data } = yield call(Api.getCounties, { url: `/states/${stateId}/basins/${basinId}` });
    yield put({ type: getCounties.success, payload: data });
  } catch (err) {
    yield put({ type: getCounties.failure, err });
  }
}

function* watchGetCounties() {
  yield takeLatest(getCounties.type, ensureGetCounties);
  yield take(getCounties.success);
}

function* ensureGetLandingZones() {
  const stateId = yield select(({ editProperty }) => editProperty.stateId);
  const basinId = yield select(({ editProperty }) => editProperty.basinId);
  const countyId = yield select(({ editProperty }) => editProperty.countyId);
  try {
    const { data } = yield call(Api.getLandingZones, {
      url: `/states/${stateId}/basins/${basinId}/counties/${countyId}`,
    });
    yield put({ type: getLandingZones.success, payload: data });
  } catch (err) {
    yield put({ type: getLandingZones.failure, err });
  }
}

function* watchGetLandingZones() {
  yield takeLatest(getLandingZones.type, ensureGetLandingZones);
  yield take(getLandingZones.success);
}

function* ensureGetCurrentProperty({ payload }) {
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    const {
      data: {
        state: { id: stateId },
        basin: { id: basinId },
        county: { id: countyId },
        ...restData
      },
    } = yield call(Api.getCurrentProperty, {
      url: `/properties/${payload}`,
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    const {
      states: { data: statesData },
      basins: { data: basinsData },
      counties: { data: countiesData },
      landingZones: { data: landingZonesData },
    } = yield all({
      states: call(Api.getStates),
      basins: call(Api.getBasins, { url: `/states/${stateId}/basins` }),
      counties: call(Api.getCounties, { url: `/states/${stateId}/basins/${basinId}` }),
      landingZones: call(Api.getLandingZones, {
        url: `/states/${stateId}/basins/${basinId}/counties/${countyId}`,
      }),
    });

    const refactoredData = {
      state: stateId,
      basin: basinId,
      county: countyId,
      ...restData,
    };

    yield put({
      type: getCurrentProperty.success,
      payload: {
        data: refactoredData,
        states: statesData,
        basins: basinsData,
        counties: countiesData,
        landingZones: landingZonesData,
        stateId,
        basinId,
        countyId,
      },
    });
  } catch (err) {
    yield put({ type: getCurrentProperty.failure, err });
  }
}

function* watchGetCurrentProperty() {
  yield takeLatest(getCurrentProperty.type, ensureGetCurrentProperty);
  yield take(getCurrentProperty.success);
}

function* ensureEditCurrentProperty({
  payload: {
    id,
    data,
    formName,
    addDocuments,
  },
}) {
  try {
    if (addDocuments) {
      const { documents } = data;
      yield call(Api.addMorePropertyDocuments(id), {
        data: JSON.stringify({ documents }),
      });
    } else {
      yield call(Api.editProperty(id), {
        data: JSON.stringify(data),
      });
    }
    yield put({ type: editProperty.success });
    yield put(reset(formName));
    yield put(push(`/listings/${id}`));
  } catch (err) {
    yield put({ type: editProperty.failure, err });
  }
}

function* watchEditCurrentProperty() {
  yield takeLatest(editProperty.type, ensureEditCurrentProperty);
  yield take(editProperty.success);
}

function* ensureGetPreSignedUrls({ payload }) {
  const {
    filesName,
    filesMd5,
    filesSize,
    resolve,
    reject,
  } = payload;
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    const params = queryString.stringify({
      filesName,
      filesMd5,
      filesSize,
    }, { arrayFormat: 'bracket' });
    const { data } = yield call(Api.getPreSignedUrls, {
      url: `/get-s3-pre-signed-urls-for-listing-uploads?${params}`,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    yield put({ type: getPreSignedUrls.success, payload: data });
    if (resolve) {
      resolve(data);
    }
  } catch (err) {
    yield put({ type: getPreSignedUrls.failure, err });
    if (reject) {
      reject(err);
    }
  }
}

function* watchGetPreSignedUrls() {
  yield takeLatest(getPreSignedUrls.type, ensureGetPreSignedUrls);
  yield take(getPreSignedUrls.success);
}

export default function* propertyViewSagas() {
  yield all([
    fork(watchGetCurrentProperty),
    fork(watchEditCurrentProperty),
    fork(watchGetState),
    fork(watchGetBasins),
    fork(watchGetCounties),
    fork(watchGetLandingZones),
    fork(watchGetPreSignedUrls),
  ]);
}
