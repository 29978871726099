import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';

import { ReactComponent as PageNotFoundIcon } from 'now-frontend-shared/assets/icons/page_not_found.svg';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

const Page404 = ({ classes }) => (
  <Grid container direction="column" alignItems="center" justify="center" style={{ flex: 1 }}>
    <Grid container alignItems="center" justify="center">
      <PageNotFoundIcon className={classes.icon} />
    </Grid>

    <span className={classes.text}>Page not found</span>

    <Link to="/" className={classes.link}>
      Back to home page
    </Link>
  </Grid>
);

export default compose(withStyles(styles), memo)(Page404);
