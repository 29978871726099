import React from 'react';
import NiceInputPassword from 'react-nice-input-password';
import 'react-nice-input-password/dist/react-nice-input-password.css';
import { withStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import { compose } from 'redux';
import { useDispatch, connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';

import styles from './styles';
import Success from '../../components/Success';
import { signUpCompleted } from 'store/actions/authActions';
import { Link } from 'react-router-dom';

const recaptchaSiteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

function SignUpWizard({ classes }) {
  const [values, setValues] = React.useState({
    email: '',
    password: '',
    confirmPassword: '',
    showPassword: false,
    isValid: false,
    confirmIsValid: false,
  });
  const [captcha, setCaptcha] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [registrationComplete, setRegistrationComplete] = React.useState(false);
  const confirmRef = React.useRef(null);
  const confirmPasswordValueRef = React.useRef();
  confirmPasswordValueRef.current = values.confirmPassword;
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (confirmRef.current) {
      const evt = {
        target: {
          name: 'confirmPassword',
          value: confirmPasswordValueRef.current,
        },
      };

      confirmRef.current.handleChange(evt);
    }
  }, [values.password]);

  const onHandleSubmit = async () => {
    await new Promise((resolve, reject) => dispatch(
      signUpCompleted({
        userData: {
          email: values.email,
          password: values.password,
        },
        resolve,
        reject,
      }),
    ));
    setRegistrationComplete(true);
  };
  const handleCaptchaChange = value => {
    setCaptcha(value);
  };

  const handleChange = prop => event => {
    if (event?.target?.value || event?.target?.value === '') {
      setValues({ ...values, [prop]: event.target.value });
    }
  };

  const handlePasswordChange
    = (prop, validType) => ({ value, isValid }) => {
      setValues({ ...values, [prop]: value, [validType]: isValid });
    };

  const label = { inputProps: { 'aria-label': 'Checkbox' } };
  const readyToRegister
    = values.isValid && values.confirmIsValid && checked && captcha;

  const securityLevels = [
    {
      descriptionLabel: <Typography>1 number</Typography>,
      validator: /.*[0-9].*/,
    },
    {
      descriptionLabel: <Typography>1 lowercase letter</Typography>,
      validator: /.*[a-z].*/,
    },
    {
      descriptionLabel: <Typography>1 uppercase letter</Typography>,
      validator: /.*[A-Z].*/,
    },
    {
      descriptionLabel: <Typography>1 symbol</Typography>,
      validator: /(?=.*[!$%^&*()_+|~\-=`{}[\]:";'<>?,./@#])/,
    },
    {
      descriptionLabel: <Typography>Length at least 8</Typography>,
      validator: /^.{8,}$/,
    },
  ];
  return (
    <div className={classes.root}>
      {registrationComplete ? (
        <Success message={`We are excited to have you. To finish signing up, you will need to confirm your email. Please check your email ${values.email}`} />
      ) : (
        <Grid
          style={{ marginTop: 15, marginBottom: 30 }}
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Typography variant="h1" component="div" className={classes.title}>
            Register Account
          </Typography>
          <Typography variant="body1" component="div" className={classes.text}>
            Enter your details to proceed further
          </Typography>
          <Box
            component="form"
            autoComplete="off"
            sx={{
              width: 415,
            }}
          >
            <TextField
              fullWidth
              onChange={handleChange('email')}
              className={classes.textField}
              value={values.email}
              id="email-basic"
              label="Enter email address"
              variant="outlined"
              autoComplete="off"
              autoFocus
            />
            <NiceInputPassword
              autoComplete="off"
              className={classes.password}
              id="password"
              fullWidth
              variant="outlined"
              name="passwordField"
              value={values.password}
              showSecurityLevelBar={values.password.length !== 0}
              showSecurityLevelDescription={
                values.password.length > 0 && !values.isValid
              }
              onChange={handlePasswordChange('password', 'isValid')}
              InputComponent={TextField}
              InputComponentProps={{
                variant: 'outlined',
                autoComplete: 'off',
                label: 'Enter password',
                className: classes.password,
                fullWidth: true,
                InputProps: {
                  style: { boxSizing: 'content-box' },
                  type: values.showPassword ? 'text' : 'password',
                  endAdornment:
                    values.password.length
                    === 0 ? null : values.showPassword ? (
                      <VisibilityIcon
                        onClick={() => setValues({
                          ...values,
                          showPassword: !values.showPassword,
                        })}
                      />
                      ) : (
                        <VisibilityOffIcon
                          onClick={() => setValues({
                            ...values,
                            showPassword: !values.showPassword,
                          })}
                        />
                      ),
                },
              }}
              securityLevels={securityLevels}
            />
            <NiceInputPassword
              ref={confirmRef}
              className={classes.password}
              id="ConfirmNiceInputPassword"
              fullWidth
              variant="outlined"
              name="passwordField"
              value={values.confirmPassword}
              showSecurityLevelDescription={
                values.confirmPassword.length > 0 && !values.confirmIsValid
              }
              onChange={handlePasswordChange(
                'confirmPassword',
                'confirmIsValid',
              )}
              InputComponent={TextField}
              InputComponentProps={{
                variant: 'outlined',
                autoComplete: 'off',
                label: 'Confirm password',
                className: classes.password,
                fullWidth: true,
                InputProps: {
                  style: { boxSizing: 'content-box' },
                  type: values.showPassword ? 'text' : 'password',
                  endAdornment:
                    values.confirmPassword.length
                    === 0 ? null : values.showPassword ? (
                      <VisibilityIcon
                        onClick={() => setValues({
                          ...values,
                          showPassword: !values.showPassword,
                        })}
                      />
                      ) : (
                        <VisibilityOffIcon
                          onClick={() => setValues({
                            ...values,
                            showPassword: !values.showPassword,
                          })}
                        />
                      ),
                },
              }}
              securityLevels={[
                {
                  descriptionLabel: <Typography>Passwords must match</Typography>,
                  validator: value => value === values.password,
                },
              ]}
            />

            <FormGroup>
              <FormControlLabel
                className={classes.checkboxText}
                control={(
                  <Checkbox
                    {...label}
                    defaultChecked
                    color="default"
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    required
                  />
                )}
                label={(
                  <span>
                    I agree to the NonOpWells
                    {' '}
                    <Link
                      to="/terms-and-conditions"
                      target="_blank"
                      style={{ color: '#2196F3' }}
                    >
                      Terms & Conditions
                    </Link>
                  </span>
                )}
              />
            </FormGroup>
            <ReCAPTCHA
              style={{ display: 'flex', justifyContent: 'center' }}
              sitekey={recaptchaSiteKey}
              onChange={handleCaptchaChange}
            />
            <Button
              variant="contained"
              disabled={!readyToRegister}
              label="Register"
              onClick={onHandleSubmit}
              className={classes.submit}
              style={
                readyToRegister
                  ? {
                    background: '#4B6377',
                    color: '#ffffff',
                    boxShadow:
                        '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
                  }
                  : {}
              }
            >
              Register
            </Button>
          </Box>
        </Grid>
      )}
    </div>
  );
}

SignUpWizard.defaultProps = {
  chosenRole: null,
};

SignUpWizard.propTypes = {
  chosenRole: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  emailSent: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(
  connect(({ auth, form }) => ({
    emailSent: auth.authInfo.emailSent,
    chosenRole: form.signUpWizard?.values?.role,
  })),
  withStyles(styles),
)(SignUpWizard);
