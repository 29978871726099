export default {
  burgerMenu: {
    // empty
  },
  opened: {
    '& $menuIcon > span:nth-child(1)': {
      transform: 'translateY(16px) rotate(45deg)',
    },
    '& $menuIcon > span:nth-child(2)': {
      transform: 'translateY(16px) rotate(-45deg)',
    },
  },
  menuIcon: {
    outline: 'none',
    display: 'block',
    flexShrink: 0,
    width: '32px',
    height: '32px',
    marginLeft: 'auto',
    position: 'relative',
    transform: 'rotate(0deg)',
    transition: '.5s ease-in-out',
    cursor: 'pointer',
    '& span': {
      display: 'block',
      position: 'absolute',
      height: '4px',
      width: '100%',
      backgroundColor: '#100081',
      opacity: 1,
      left: 0,
      transition: 'transform .25s ease-in-out, opacity .25s ease-in-out',
    },
    '& span:nth-child(1)': { transform: 'translateY(12px)' },
    '& span:nth-child(2)': { transform: 'translateY(22px)' },
  },
};
