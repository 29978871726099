import { makeAxiosRequest } from 'utils/apiMethods';

export default {
  getAllCompanies: makeAxiosRequest(
    `${process.env.REACT_APP_API_URL}/companies`,
    {
      method: 'get',
    },
    true,
  ),
  findUsersByCompany: id => makeAxiosRequest(
    `${process.env.REACT_APP_API_URL}/users/company/${id}`,
    {
      method: 'get',
    },
  ),
  addNewCompany: makeAxiosRequest(
    `${process.env.REACT_APP_API_URL}/companies`,
    {
      method: 'post',
    },
  ),
  // TODO: [CONVENTIONS] saveFileUpload: companyId => makeAxiosRequest(
  //   `${process.env.REACT_APP_API_URL}/companies/${companyId}/documents`,
  saveFileUpload: makeAxiosRequest(
    `${process.env.REACT_APP_API_URL}/companies/file-upload`,
    {
      method: 'post',
    },
    true,
  ),
  addNewFinancialCompany: makeAxiosRequest(
    `${process.env.REACT_APP_API_URL}/bank-information/`,
    {
      method: 'post',
    },
  ),
  sendBuyerAgreementForRegistration: companyId => makeAxiosRequest(
    `${process.env.REACT_APP_API_URL}/registration/companies/${companyId}/buyer-agreement`,
    {
      method: 'post',
    },
    true,
  ),
  sendSellerAgreementForRegistration: companyId => makeAxiosRequest(
    `${process.env.REACT_APP_API_URL}/registration/companies/${companyId}/seller-agreement`,
    {
      method: 'post',
    },
    true,
  ),
  updateCompany: companyId => makeAxiosRequest(
    `${process.env.REACT_APP_API_URL}/companies/${companyId}`,
    {
      method: 'put',
    },
    true,
  ),
  getNonOpWellsSignatureDetails: makeAxiosRequest('/nonopwells-signature-details', {
    method: 'get',
  }, true),
};
