import businessStyles from '../../../../styles';

export default {
  item: {
    width: '33%',
  },
  deleteIcon: {
    right: '-30px',
    top: '50%',
    cursor: 'pointer',
    position: 'absolute',
    transform: 'translateY(-50%)',
  },
  ssnOrTinInput: businessStyles.ssnOrTinInput,
  datePickerContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: '0',
  },
  datePickerLabel: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)',
    position: 'absolute',
    top: '-8px',
    left: '8px',
    height: '16px',
    zIndex: '1',
    background: '#ffffff',
    padding: '0 5px',
  },
  datePicker: {
    height: '56px',
  },
};
