import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from '../styles';

const Text = ({ children, classes }) => <span className={classes.text}>{children}</span>;

Text.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
};

export default compose(withStyles(styles), memo)(Text);
