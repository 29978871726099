import {
  all,
  fork,
  take,
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

// actions
import {
  addListingDocumentDownload,
  addListingPageView,
  getListingAnalytics,
} from '../actions/listingAnalyticsActions';

// api methods
import Api from 'api/properties';

function* ensureAddListingPageView({ payload: { listingId } }) {
  try {
    const { data } = yield call(Api.addListingPageView(listingId));
    yield put({ type: addListingPageView.success, payload: data });
  } catch (err) {
    yield put({ type: addListingPageView.failure, err });
  }
}

function* watchAddListingPageView() {
  yield takeLatest(addListingPageView.type, ensureAddListingPageView);
  yield take(addListingPageView.success);
}

function* ensureAddListingDocumentDownload({ payload: { listingId, documentId } }) {
  try {
    const { data } = yield call(Api.addListingDocumentDownload(listingId, documentId));
    yield put({ type: addListingDocumentDownload.success, payload: data });
  } catch (err) {
    yield put({ type: addListingDocumentDownload.failure, err });
  }
}

function* watchAddListingDocumentDownload() {
  yield takeLatest(addListingDocumentDownload.type, ensureAddListingDocumentDownload);
  yield take(addListingDocumentDownload.success);
}

function* ensureGetListingAnalytics({ payload: { listingId } }) {
  try {
    const { data } = yield call(Api.getListingAnalytics(listingId));
    yield put({ type: getListingAnalytics.success, payload: data });
  } catch (err) {
    yield put({ type: getListingAnalytics.failure, err });
  }
}

function* watchGetListingAnalytics() {
  yield takeLatest(getListingAnalytics.type, ensureGetListingAnalytics);
  yield take(getListingAnalytics.success);
}

export default function* listingAnalyticsSagas() {
  yield all([
    fork(watchGetListingAnalytics),
    fork(watchAddListingPageView),
    fork(watchAddListingDocumentDownload),
  ]);
}
