import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { compose } from 'redux';
import cx from 'classnames';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from '../styles';

const StyledLink = ({
  children,
  classes,
  path,
  style,
}) => {
  const location = useLocation();

  return (
    <Link
      to={{ pathname: path, state: { prevPath: location } }}
      className={cx(classes.text, classes.link)}
      style={style}
    >
      {children}
    </Link>
  );
};

StyledLink.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  style: PropTypes.shape({
    fontSize: PropTypes.number,
  }),
};

StyledLink.defaultProps = {
  style: undefined,
};

export default compose(withStyles(styles), memo)(StyledLink);
