import axios from 'axios';

export async function downloadFileAsFilename({
  downloadUrl,
  filename,
  onDownloadProgress,
  cancelToken,
}) {
  // 100GB
  const downloadMaxSize = 1e11;

  // get File
  const response = await axios.get(downloadUrl, {
    headers: {
      Accept: '*/*',
    },
    responseType: 'arraybuffer',
    maxContentLength: downloadMaxSize,
    maxBodyLength: downloadMaxSize,
    onDownloadProgress,
    cancelToken,
  });

  const file = new Blob([response.data]);

  // Create Url
  const url = URL.createObjectURL(file);

  // Create link
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || true;

  // download file
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
