import React, { useEffect } from 'react';
import { Field, reduxForm, reset } from 'redux-form';
import { useDispatch, connect } from 'react-redux';

import PropTypes from 'prop-types';
import { compose } from 'redux';

// layouts
import FormLayout from 'now-frontend-shared/layouts/FormLayout';
import HeadSection from 'now-frontend-shared/layouts/AuthSections/HeadSection';
import MainSection from 'now-frontend-shared/layouts/AuthSections/MainSection';
import BottomSection from 'layouts/AuthSections/BottomSection';
import LabelLayout from 'now-frontend-shared/components/inputs/layouts/LabelLayout';

// modals
import SuccessModal from 'now-frontend-shared/components/modals/SuccessModal';

// components
import Button from 'now-frontend-shared/components/Button';
import Switch from 'now-frontend-shared/components/Switch';
import RequiredDescription from 'now-frontend-shared/components/inputs/RequiredDescription';

// constants

// validations
import { settingsValidate } from 'utils/validation/validations';

// custom hooks
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';
import { warnAboutUnsavedForm } from 'now-frontend-shared/hoc/warnAboutUnsavedForm';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid, Checkbox } from '@material-ui/core';

// styles
import {
  getAllBasins,
  changeUserBasins,
  setNotificationValue,
  updatePassword,
  setPasswordIsUpdated,
} from 'store/actions/settingsActions';
import styles from './styles';

// store
import { settingsFields, notificationsFields } from './constants';
import { primaryDark } from 'themes/colors';

const formName = 'settings';

const Settings = ({
  classes,
  handleSubmit,
  notificationsSwitches,
  defaultBasins,
  notificationsPending,
  basins,
  basinsPending,
  passwordUpdated,
}) => {
  const { setModal } = useModalSetter();
  const dispatch = useDispatch();
  const { newListingsEmails } = notificationsSwitches;
  useEffect(() => {
    if (newListingsEmails) dispatch(getAllBasins());
  }, [newListingsEmails, dispatch]);

  useEffect(() => {
    if (passwordUpdated) {
      setModal(<SuccessModal label="Password updated!" />);
      dispatch(setPasswordIsUpdated(false));
    }
  }, [passwordUpdated, setModal, dispatch]);

  const onHandleSubmit = data => {
    dispatch(updatePassword(data));
    dispatch(reset('settings'));
  };

  const onChangeNotificationsValue = value => {
    dispatch(setNotificationValue(value));
  };

  const onChangeCheckboxValue = (e, title) => {
    const id = parseInt(e.target.id, 10);
    dispatch(changeUserBasins({
      id,
      title,
    }));
  };

  const allValuesSelected = () => basins.length === defaultBasins.length;

  const selectAllBasins = () => {
    defaultBasins.forEach(({ id, title }) => {
      // Only change the ones that are not currently checkmarked
      if (!(basins.find(basin => basin.id === id))) {
        dispatch(changeUserBasins({
          id,
          title,
        }));
      }
    });
  };

  const unSelectAllBasins = () => {
    basins.forEach(basin => {
      dispatch(changeUserBasins({
        id: basin.id,
        title: basin.title,
      }));
    });
  };

  const basinsLoading = () => Object.keys(basinsPending).some(key => basinsPending[key] === true);

  const handleBasinChange = () => {
    if (allValuesSelected()) {
      unSelectAllBasins();
    } else {
      selectAllBasins();
    }
  };

  return (
    <FormLayout onSubmit={handleSubmit(onHandleSubmit)}>
      <HeadSection heading="Settings" path="/company-dashboard" />
      <MainSection heading="Notifications">
        <Grid container className={classes.switchContainer}>
          {notificationsFields.map(({ name, label }) => (
            <Switch
              key={name}
              name={name}
              label={label}
              disabled={notificationsPending[name]}
              checked={notificationsSwitches[name]}
              onChangeNotificationsValue={onChangeNotificationsValue}
            />
          ))}
        </Grid>

        {newListingsEmails && defaultBasins.length > 0 && (
          <Grid container className={classes.checkboxContainer}>
            <div>
              <Checkbox
                disabled={basinsLoading()}
                checked={allValuesSelected()}
                onChange={handleBasinChange}
                className={classes.selectAll}
                size="small"
                color="primary"
                style={{
                  color: primaryDark,
                }}
              />
              {!allValuesSelected() ? 'Select All' : 'Unselect All'}
              <br />
              <hr />
              <br />
            </div>
            <Grid item className={classes.checkboxes}>
              {defaultBasins.map(({ id, title }) => (
                <div>
                  <Checkbox
                    key={id}
                    id={String(id)}
                    disabled={basinsLoading()}
                    checked={Boolean(basins.find(basin => basin.id === id))}
                    onChange={e => onChangeCheckboxValue(e, title)}
                    className={classes.selectAll}
                    size="small"
                    color="primary"
                    label={title}
                    style={{
                      color: primaryDark,
                    }}
                  />
                  <label htmlFor={id} className={classes.label}>
                    {title}
                  </label>
                </div>
              ))}
            </Grid>
          </Grid>
        )}
        <p className={classes.text}>Notification preference updates save automatically.</p>
      </MainSection>

      <MainSection heading="Personal Details">
        {settingsFields.map(({ name, layoutProps, inputProps }) => (
          <LabelLayout key={name} name={name} {...layoutProps}>
            <Field name={name} {...inputProps} />
          </LabelLayout>
        ))}
        <RequiredDescription />
      </MainSection>

      <BottomSection>
        <Grid container alignItems="center" className={classes.buttonContainer}>
          <Grid item className={classes.button}>
            <Button type="submit" label="Update Password" buttonColor="green" fullWidth />
          </Grid>
        </Grid>
      </BottomSection>
    </FormLayout>
  );
};

Settings.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func,
  notificationsSwitches: PropTypes.shape({
    confirmingBidsEmails: PropTypes.bool,
    closesAuctionEmails: PropTypes.bool,
    newListingsEmails: PropTypes.bool,
    outBidsEmails: PropTypes.bool,
  }),
  defaultBasins: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number.isRequired, title: PropTypes.string.isRequired }),
  ),
  basins: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number.isRequired, title: PropTypes.string.isRequired })),
  basinsPending: PropTypes.objectOf(PropTypes.bool.isRequired),
  passwordUpdated: PropTypes.bool,
};

export default compose(
  connect(({ settings }) => ({
    notificationsSwitches: settings.notificationsSwitches,
    defaultBasins: settings.defaultBasins,
    notificationsPending: settings.notificationsPending,
    basins: settings.basins,
    basinsPending: settings.basinsPending,
    passwordUpdated: settings.passwordUpdated,
  })),
  reduxForm({
    form: formName,
    validate: settingsValidate,
  }),
  warnAboutUnsavedForm,
  withStyles(styles),
)(Settings);
