import React, { memo, useCallback, forwardRef } from 'react';
import { compose } from 'redux';

// components
import Button from 'now-frontend-shared/components/Button';

// custom hooks
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';

// styles and components from material-ui
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

const SubmitConfirmationModal = ({
  classes, confirmLabel, heading, handleSubmit, message, submitButtonOnly,
}, ref) => {
  const { setModal } = useModalSetter();

  const handleCloseModal = useCallback(() => {
    setModal(null);
  }, [setModal]);

  const onHandleSubmit = useCallback(() => {
    handleSubmit();
    setModal(null);
  }, [setModal, handleSubmit]);

  return (
    <Grid container direction="column" tabIndex={-1} className={classes.wrapper} ref={ref}>
      <Grid container direction="column" alignItems="center" className={classes.container}>
        <Grid container justify="center" className={classes.heading}>
          <span>{heading}</span>
        </Grid>

        {message && (
          <Grid container justify="center" className={classes.message}>
            <Typography variant="h6" align="center">{message}</Typography>
          </Grid>
        )}

        <Grid container justify="space-around">
          {!submitButtonOnly && <Button label="Cancel" buttonColor="red" onClick={handleCloseModal} />}
          <Button label={confirmLabel || 'Confirm'} buttonColor="green" onClick={onHandleSubmit} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default compose(withStyles(styles), memo, forwardRef)(SubmitConfirmationModal);
