// components
import BaseInput from 'now-frontend-shared/components/inputs/BaseInput';
import PhoneInput from 'now-frontend-shared/components/inputs/PhoneInput';
import SelectForIdValue from 'now-frontend-shared/components/Select/SelectForIdValue';
import { ContactUsInquiryTypes, ContactUsInquiryTypeTitles } from '../../now-shared/enums/contact-us';

export const contactUsFields = [
  {
    name: 'inquiryTypeName',
    layoutProps: {
      label: 'Reason for Inquiry',
      isRequired: true,
    },
    inputProps: {
      component: SelectForIdValue,
      props: {
        options: Object.values(ContactUsInquiryTypes).map(name => ({
          id: name,
          title: ContactUsInquiryTypeTitles[name],
        })),
      },
      autoFocus: true,
    },
  },
  {
    name: 'name',
    layoutProps: {
      label: 'Name',
      isRequired: true,
    },
    inputProps: {
      component: BaseInput,
    },
  },
  {
    name: 'email',
    layoutProps: {
      label: 'E-mail',
      isRequired: true,
    },
    inputProps: {
      component: BaseInput,
    },
  },
  {
    name: 'phoneNumber',
    layoutProps: {
      label: 'Phone Number',
      isRequired: true,
    },
    inputProps: {
      component: PhoneInput,
    },
  },
  {
    name: 'subject',
    layoutProps: {
      label: 'Subject',
      isRequired: true,
    },
    inputProps: {
      component: BaseInput,
    },
  },
  {
    name: 'text',
    layoutProps: {
      label: 'Notes or Comments',
      isRequired: true,
    },
    inputProps: {
      component: BaseInput,
      props: {
        isTextArea: true,
      },
    },
  },
];
