import { createBrowserHistory } from 'history';
import {
  applyMiddleware, compose, createStore, combineReducers,
} from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import createSagaMiddleware, { END } from 'redux-saga';

import { reducer as formReducer } from 'redux-form';
import * as Sentry from '@sentry/react';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const history = createBrowserHistory();

const configureStore = () => {
  const composeEnhancers
    = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    combineReducers({
      router: connectRouter(history),
      form: formReducer,
      // TODO: [REFACTOR][CONVENTIONS] change these to imports
      ...require('./reducers/businessFilesReducer').reducer,
      ...require('./reducers/beneficialOwnerFilesReducer').reducer,
      ...require('./reducers/accountManagerFilesReducer').reducer,
      ...require('./reducers/officerFilesReducer').reducer,
      ...require('./reducers/authReducer').reducer,
      ...require('./reducers/userReducer').reducer,
      ...require('./reducers/companyReducer').reducer,
      ...require('./reducers/settingsReducer').reducer,
      ...require('./reducers/propertiesReducer').reducer,
      ...require('./reducers/myPropertiesReducer').reducer,
      ...require('./reducers/createPropertyReducer').reducer,
      ...require('./reducers/propertyViewReducer').reducer,
      ...require('./reducers/bidedPropertiesReducer').reducer,
      ...require('./reducers/propertyBidsListReducer').reducer,
      ...require('./reducers/editPropertyReducer').reducer,
      ...require('./reducers/mapReducer').reducer,
      ...require('./reducers/myBidAllowanceReducer').reducer,
      ...require('./reducers/companiesReducer').reducer,
      ...require('./reducers/statesReducer').reducer,
      ...require('./reducers/listingAnalyticsReducer').reducer,
    }),
    composeEnhancers(
      applyMiddleware(routerMiddleware(history), sagaMiddleware),
      sentryReduxEnhancer,
    ),
  );

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  return store;
};

export const store = configureStore();
