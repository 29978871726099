import createTheme from '@material-ui/core/styles/createTheme';
import {
  lightGrey, white, green, blue, cyan, grey, red, yellow,
} from './colors';

const theme = createTheme({
  colors: {
    lightGrey,
    white,
    green,
    blue,
    cyan,
    grey,
    red,
    yellow,
  },
});

export default theme;
