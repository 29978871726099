import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// components
import StatusBadge from 'now-frontend-shared/components/StatusBadge';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

// colors
import { green, primaryDark, red } from 'now-frontend-shared/themes/colors';

const ProfileSection = ({
  children, classes, heading, isSubmitted, status,
}) => {
  const renderStatusBadge = useCallback(() => {
    if (isSubmitted) return <StatusBadge small status="submitted" />;
    if (status === 'approved') return <StatusBadge small status="approved" />;
    if (status === 'pending') return <StatusBadge small status="pending" />;
    if (status === 'rejected') return <StatusBadge small status="rejected" />;
    if (status === 'canceled') return <StatusBadge small status="canceled" />;
  }, [status, isSubmitted]);

  return (
    <Grid container direction="column" className={classes.wrapper}>
      <Grid container alignItems="center" className={classes.headingContainer}>
        <span
          style={{
            color: isSubmitted || status === 'approved'
              ? green
              : ['rejected', 'canceled'].includes(status)
                ? red
                : primaryDark,
          }}
          className={classes.heading}
        >
          {heading}
        </span>
        {renderStatusBadge()}
      </Grid>

      {children}
    </Grid>
  );
};

ProfileSection.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  heading: PropTypes.string.isRequired,
  isSubmitted: PropTypes.bool,
  status: PropTypes.string,
};

export default compose(withStyles(styles), memo)(ProfileSection);
