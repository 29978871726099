export default {
  root: {
    padding: '86px 0',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '34px',
    textAlign: 'center',
    letterSpacing: '0.25px',
    color: '#171721',
  },
  text: {
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: '#4B6377',
    margin: '11px 0 25px 0',
  },
  textField: {
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.6)',
    order: 0,
  },
  password: {
    '& input': {
      boxSizing: 'content-box',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '0px',
    },
    '& svg': {
      marginRight: '8px',
    },
    '& .input-password__marker': {
      marginLeft: '5px',
      marginTop: '5px',
    },
    width: '100%',
    marginTop: 4,
    boxSizing: 'content-box',
  },
  checkboxText: {
    fontWeight: 'normal',
    fontSize: 16,
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.6)',
    marginBottom: '10px',
  },
  submit: {
    background: 'rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    fontWeight: 500,
    fontSize: 15,
    letterSpacing: '0.46px',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.26)',
    width: '100%',
    height: '42px',
    marginTop: 26,
  },
};
