// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { InputLabel } from '@material-ui/core';

const Label = withStyles({
  root: {
    transform: 'translate(14px, 18px) scale(1)',
  },
  shrink: {
    transform: 'translate(6px, -15px) scale(0.75) !important',
  },
})(InputLabel);

export default Label;
