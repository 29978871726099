import React from 'react';
import PdfSignatureWrapper from '../components/PdfSignatureWrapper';
import pdfStyles from '../components/PdfSignatureWrapper/styles';
import PdfNameAndTitleWrapper from '../components/PdfNameAndTitleWrapper';

export const getAgreementSignatureAttachments = ({
  pageNumber,
  offsetLeft,
  offsetTop,
  signer1Name,
  signer1Title,
  signer2Name,
  signer2Title,
  signer2TitleOffsets = {
    top: 26,
    left: 252,
  },
  wrapperStyle = {},
  /**
   * Default scale of 1 is used for main site pdf viewers
   */
  scale = 1,
  /**
   * The result of wrapper height / font size
   */
  wrapperHeightFontFactor = 1.5,
  /**
   * Or, if you know what the wrapper height is, you can set it explicitly.
   */
  wrapperHeight,
}) => {
  const wrapperStyleSantized = {
    ...wrapperStyle,
    width: wrapperStyle.width ?? pdfStyles.container.width,
    fontSize: wrapperStyle.fontSize ?? pdfStyles.container.fontSize,
  };

  const wrapperSize = {
    width: wrapperStyleSantized.width,
    height: wrapperHeight ?? wrapperStyleSantized.fontSize * wrapperHeightFontFactor,
  };
  wrapperSize.halfWidth = wrapperSize.width / 2;
  wrapperSize.halfHeight = wrapperSize.height / 2;
  wrapperStyleSantized.height = wrapperStyleSantized.height ?? wrapperSize.height;

  const centered = {
    signer1Signature: {
      left: offsetLeft + wrapperSize.halfWidth,
      top: offsetTop + wrapperSize.halfHeight,
    },
    signer2Title: {
      left: offsetLeft + signer2TitleOffsets.left + wrapperSize.halfWidth,
      top: offsetTop + signer2TitleOffsets.top + wrapperSize.halfHeight,
    },
  };

  const scaled = {
    centered: {
      signer1Signature: {
        left: centered.signer1Signature.left * scale,
        top: centered.signer1Signature.top * scale,
      },
      signer2Title: {
        left: centered.signer2Title.left * scale,
        top: centered.signer2Title.top * scale,
      },
    },
    wrapperSize: {
      width: wrapperSize.width * scale,
      height: wrapperSize.height * scale,
    },
    wrapperStyle: {
      ...wrapperStyleSantized,
      width: wrapperStyleSantized.width * scale,
      fontSize: wrapperStyleSantized.fontSize * scale,
      height: wrapperStyleSantized.height * scale,
    },
  };
  scaled.wrapperSize.halfWidth = scaled.wrapperSize.width / 2;
  scaled.wrapperSize.halfHeight = scaled.wrapperSize.height / 2;

  const adjustX = x => x - scaled.wrapperSize.halfWidth;
  const adjustY = y => y - scaled.wrapperSize.halfHeight;

  return [
    {
      pageNumber,
      attachment: (
        <PdfSignatureWrapper
          left={adjustX(scaled.centered.signer1Signature.left)}
          top={adjustY(scaled.centered.signer1Signature.top)}
          style={scaled.wrapperStyle}
        >
          {signer1Name}
        </PdfSignatureWrapper>
      ),
    },
    {
      pageNumber,
      attachment: (
        <PdfNameAndTitleWrapper
          left={adjustX(scaled.centered.signer1Signature.left)}
          top={adjustY(scaled.centered.signer2Title.top)}
          style={scaled.wrapperStyle}
        >
          {signer1Title}
        </PdfNameAndTitleWrapper>
      ),
    },
    {
      pageNumber,
      attachment: (
        <PdfSignatureWrapper
          top={adjustY(scaled.centered.signer1Signature.top)}
          left={adjustX(scaled.centered.signer2Title.left)}
          style={scaled.wrapperStyle}
        >
          {signer2Name}
        </PdfSignatureWrapper>
      ),
    },
    {
      pageNumber,
      attachment: (
        <PdfNameAndTitleWrapper
          top={adjustY(scaled.centered.signer2Title.top)}
          left={adjustX(scaled.centered.signer2Title.left)}
          style={scaled.wrapperStyle}
        >
          {signer2Title}
        </PdfNameAndTitleWrapper>
      ),
    },
  ];
};
