export default theme => ({
  // Dashboard
  wrapper: {
    // empty
  },
  noDocumentsText: {
    marginTop: theme.spacing(2),
  },
  // BuyerDashboard

});
