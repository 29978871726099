import {
  fork, all,
} from 'redux-saga/effects';

// sagas
import { checkAuth } from './auth';
import { watchLogOut } from './logout';
import watchNavigate from './navigator';
import watchNotifications from './notifications';

export default function* rootSaga() {
  yield all([
    fork(watchNavigate),
    fork(checkAuth),
    fork(watchLogOut),
    fork(watchNotifications),
  ]);
}
