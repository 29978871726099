import GA4React from 'ga-4-react';
import {
  all,
  fork,
  takeLatest,
  take,
  call,
  put,
} from 'redux-saga/effects';

import Api from 'api/auth';
import { signUpCompleted } from 'store/actions/authActions';

function reportSignUp() {
  if (GA4React.isInitialized()) {
    const ga = GA4React.getGA4React();
    ga.gtag('event', 'sign_up', {
      method: 'email',
    });
  }
}

function* ensureSignUpCompleted({ payload: { userData, resolve, reject } }) {
  try {
    const { data } = yield call(Api.signUp, { data: JSON.stringify(userData) });
    yield call(reportSignUp);
    yield put({
      type: signUpCompleted.success,
      payload: {
        ...data,
        role: userData.role,
        companyId: userData.companyId,
      },
    });
    if (resolve) {
      resolve();
    }
  } catch (err) {
    yield put({
      type: signUpCompleted.failure,
      err,
    });
    if (reject) {
      reject();
    }
  }
}

function* watchCompletedRegistration() {
  yield takeLatest(signUpCompleted.type, ensureSignUpCompleted);
  yield take(signUpCompleted.success);
}

export default function* signUpSagas() {
  yield all([
    fork(watchCompletedRegistration),
  ]);
}
