import {
  all, fork, take, takeLatest, call, put, select,
} from 'redux-saga/effects';

// actions
import {
  getProperties,
  getStatuses,
  getStates,
  getBasins,
  getCounties,
  getLandingZones,
} from '../actions/propertiesActions';

// api methods
import Api from 'api/properties';

// storage helpers functions
import * as storage from 'now-frontend-shared/utils/storage';

import { SortDirection } from 'now-shared/enums/sort-direction';

function* ensureGetStates() {
  try {
    const { data } = yield call(Api.getStates);
    yield put({ type: getStates.success, payload: data });
  } catch (err) {
    yield put({ type: getStates.failure, err });
  }
}

function* watchGetState() {
  yield takeLatest(getStates.type, ensureGetStates);
  yield take(getStates.success);
}

function* ensureGetBasins() {
  const stateId = yield select(({ properties }) => properties.stateId);
  try {
    const { data } = yield call(Api.getBasins, { url: `/states/${stateId}/basins` });
    yield put({ type: getBasins.success, payload: data });
  } catch (err) {
    yield put({ type: getBasins.failure, err });
  }
}

function* watchGetBasins() {
  yield takeLatest(getBasins.type, ensureGetBasins);
  yield take(getBasins.success);
}

function* ensureGetCounties() {
  const stateId = yield select(({ properties }) => properties.stateId);
  const basinId = yield select(({ properties }) => properties.basinId);
  try {
    const { data } = yield call(Api.getCounties, { url: `/states/${stateId}/basins/${basinId}` });
    yield put({ type: getCounties.success, payload: data });
  } catch (err) {
    yield put({ type: getCounties.failure, err });
  }
}

function* watchGetCounties() {
  yield takeLatest(getCounties.type, ensureGetCounties);
  yield take(getCounties.success);
}

function* ensureGetLandingZones() {
  const stateId = yield select(({ properties }) => properties.stateId);
  const basinId = yield select(({ properties }) => properties.basinId);
  const countyId = yield select(({ properties }) => properties.countyId);
  try {
    const { data } = yield call(Api.getLandingZones, {
      url: `/states/${stateId}/basins/${basinId}/counties/${countyId}`,
    });
    yield put({ type: getLandingZones.success, payload: data });
  } catch (err) {
    yield put({ type: getLandingZones.failure, err });
  }
}

function* watchGetLandingZones() {
  yield takeLatest(getLandingZones.type, ensureGetLandingZones);
  yield take(getLandingZones.success);
}

function* ensureGetProperties({ payload: query }) {
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    const { data } = yield call(Api.getProperties, {
      // TODO: improve query parameter handling so that if only "limit" is included, this still functions
      url: query.includes('order')
        ? `/properties${query}`
        : `/properties${query}&limit=10&order=endTime&sort=${SortDirection.Descending}`,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    yield put({ type: getProperties.success, payload: data });
  } catch (err) {
    yield put({ type: getProperties.failure, err });
  }
}

function* watchGetProperties() {
  yield takeLatest(getProperties.type, ensureGetProperties);
  yield take(getProperties.success);
}

function* ensureGetStatuses() {
  try {
    const { data } = yield call(Api.getStatuses);
    yield put({ type: getStatuses.success, payload: data });
  } catch (err) {
    yield put({ type: getStatuses.failure, err });
  }
}

function* watchGetStatuses() {
  yield takeLatest(getStatuses.type, ensureGetStatuses);
  yield take(getStatuses.success);
}

export default function* propertiesSagas() {
  yield all([
    fork(watchGetProperties),
    fork(watchGetStatuses),
    fork(watchGetState),
    fork(watchGetBasins),
    fork(watchGetCounties),
    fork(watchGetLandingZones),
  ]);
}
