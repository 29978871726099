/**
 * This matches the enum as defined in the database for company entities.
 *
 * NOTE: changes to enum values here must be accompanied by a database migration.
 *
 * @readonly
 * @enum {'corporate_llc_or_less_than_50_mil'|'InstitutionalInvestor'}
 */
export const OptionBestSuitsEntity1 = {
  /**
   * @readonly
   */
  CorporateOrLLCOrLessThan50Mil: 'corporate_llc_or_less_than_50_mil',
  /**
   * @readonly
   */
  InstitutionalInvestor: 'InstitutionalInvestor',
};

export const OptionBestSuitsEntity1Label = {
  [OptionBestSuitsEntity1.CorporateOrLLCOrLessThan50Mil]: 'Corporation, LLC Partnership, trust or other entity with total assets of at least $5 million but less than $50 million',
};

/**
 * This matches the enum as defined in the database for company entities.
 *
 * NOTE: changes to enum values here must be accompanied by a database migration.
 *
 * @readonly
 * @enum {'bank_association_investment_company'|'investment_advisor'|'corporation_partnership_assets_over_50_mil'}
 */
export const OptionBestSuitsEntity2 = {
  /**
   * Bank or saving or loan association or registered investment company
   * @readonly
   */
  BankAssociationInvestmentCompany: 'bank_association_investment_company',
  /**
   * @readonly
   */
  InvestmentAdvisor: 'investment_advisor',
  /**
   * @readonly
   */
  OtherPerson: 'corporation_partnership_assets_over_50_mil',
};

export const OptionBestSuitsEntity2Label = {
  [OptionBestSuitsEntity2.BankAssociationInvestmentCompany]: 'A bank, savings, loan association, insurance company, or registered investment company',
  [OptionBestSuitsEntity2.InvestmentAdvisor]: 'An investment adviser registered with the SEC under Section 203 of the Investment Advisers Act',
  [OptionBestSuitsEntity2.OtherPerson]: 'Corporation, LLC Partnership, trust or other entity with total assets exceeding $50 million',
};
