import { signatureStyle } from '../../styles/signature-styles';

export default {
  container: {
    ...signatureStyle,
    alignItems: 'center',
    display: 'flex',
    fontSize: 12,
    justifyContent: 'center',
    position: 'absolute',
    width: 205,
  },
};
