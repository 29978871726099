import theme from 'themes/theme';

export default {
  root: {
    padding: '86px 0',
  },
  title: {
    marginTop: '24px',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '36px',
    textAlign: 'center',
    // TODO: [DRY][REFACTOR] use equivalent theme palette color instead?
    color: '#171721',
  },
  text: {
    fontSize: '14px',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: theme.palette.secondary.text,
    marginTop: '8px',
    maxWidth: '371px',
  },
  homeButton: {
    // TODO: [DRY][REFACTOR] use equivalent theme palette color instead (this is similar to secondary/main but with more transparency)
    border: '1px solid rgba(178, 157, 121, 0.5)',
    color: '#B29D79',
    marginTop: '24px',
  },
};
