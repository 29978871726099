export const defaultLandingZoneValue = {
  landingZone: null,
  surfaceLatitude: null,
  surfaceLongitude: null,
  bottomLatitude: null,
  bottomLongitude: null,
  depthConfirmedWithOperator: false,
  wellTotalVerticalDepth: null,
  wellNetAFE: null,
  wellGrossAfe: null,
  wellName: null,
};
