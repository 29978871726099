import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const HtmlTooltip = compose(
  withStyles(theme => ({
    tooltip: {
      backgroundColor: 'rgba(255, 255, 255, .95)',
      color: theme.palette.text.secondary,
      boxShadow: '3px 3px 4px 0px rgba(0, 0, 255, .2)',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '90vw',
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 500,
      },
      fontSize: theme.typography.pxToRem(16),
      border: '1px solid #dadde9',
    },
  })),
)(Tooltip);

export default function HelpTooltip(props) {
  const {
    content,
    iconSize,
    title,
  } = props;

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipToggle = () => {
    setOpen(open => !open);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <HtmlTooltip
        title={(
          <>
            {title && (
              <Typography color="inherit" variant="h6">{title}</Typography>
            )}
            {content}
          </>
        )}
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <IconButton
          size="small"
          onClick={handleTooltipToggle}
        >
          <HelpOutlineIcon fontSize={iconSize || 'default'} />
        </IconButton>
      </HtmlTooltip>
    </ClickAwayListener>
  );
}
