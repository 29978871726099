import {
  all, fork, takeLatest, take, put, select, call,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { updateAccount } from 'store/actions/authActions';
import Api from 'api/auth';
import * as storage from 'now-frontend-shared/utils/storage';
import { checkAuth } from './auth';

function* ensureUpdateAccount({ payload }) {
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    yield call(Api.updateAccount, {
      data: payload,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    yield call(checkAuth);
    yield put({ type: updateAccount.success });
  } catch (err) {
    yield put({ type: updateAccount.failure, err });
  }
}

function* watchUpdateAccount() {
  yield takeLatest(updateAccount.type, ensureUpdateAccount);
  yield take(updateAccount.success);
  const isAuthorized = yield select(({ auth }) => auth.authInfo.isAuthorized);
  yield put(push(isAuthorized ? '/company-dashboard' : '/'));
}

export default function* updateAccountSagas() {
  yield all([fork(watchUpdateAccount)]);
}
