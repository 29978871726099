// actions
import * as A from '../actions/myBidAllowanceActions';

const initialState = {
  allowanceDetails: undefined,
  allowanceDetailsLoading: false,
  allowanceRequest: undefined,
  allowanceRequestLoading: false,
};

export const reducer = {
  myBidAllowance(state = initialState, action) {
    switch (action.type) {
      case A.getMyBidAllowanceDetails.type:
        return { ...state, allowanceDetailsLoading: true };

      case A.getMyBidAllowanceDetails.success:
        return { ...state, allowanceDetails: action.payload, allowanceDetailsLoading: false };

      case A.getMyBidAllowanceDetails.failure:
        return { ...state, allowanceDetails: undefined, allowanceDetailsLoading: false };

      case A.getMyBidAllowanceRequest.type:
        return { ...state, allowanceRequestLoading: true };

      case A.getMyBidAllowanceRequest.success:
        return { ...state, allowanceRequest: action.payload, allowanceRequestLoading: false };

      case A.getMyBidAllowanceRequest.failure:
        return { ...state, allowanceRequest: undefined, allowanceRequestLoading: false };

      case A.clearState.type:
        return initialState;

      default:
        return state;
    }
  },
};
