export default {
  wrapper: {
    position: 'relative',
    maxWidth: '520px',

    '@media screen and (max-width: 600px)': {
      alignItems: 'center',
      width: '90%',
    },
  },
  container: {
    padding: '40px 100px 25px 100px',
    background: '#fff',
    borderRadius: '4px',

    '@media screen and (max-width: 600px)': {
      padding: '14px',
    },
  },
  heading: {
    marginBottom: '16px',
    fontSize: '32px',
    lineHeight: '37px',
    fontWeight: 'bold',
    textAlign: 'center',

    '@media screen and (max-width: 600px)': {
      marginBottom: '16px',
      fontSize: '24px',
      lineHeight: '28px',
    },
  },
  buttonContainer: {
    maxWidth: '70%',
    marginTop: '40px',

    '@media screen and (max-width: 600px)': {
      maxWidth: '90%',
      marginTop: '20px',
    },
  },
  inputContainer: {
    padding: '8px 0',
  },
};
