import { makeAxiosRequest } from 'utils/apiMethods';

export default {
  cancelBidAllowanceRequest: makeAxiosRequest(
    '/my-bid-allowance-request/cancel',
    { method: 'put' },
  ),
  getBidAllowanceDetails: makeAxiosRequest('/my-bid-allowance-details', {
    method: 'get',
  }),
  getBidAllowanceRequest: makeAxiosRequest('/my-bid-allowance-request', {
    method: 'get',
  }),
  sendSellerAgreement: makeAxiosRequest('/profile/seller-agreement', {
    method: 'post',
  }),
  sendBuyerAgreement: companyId => makeAxiosRequest(
    `/companies/${companyId}/buyer-agreement`,
    {
      method: 'post',
    },
    true,
  ),
  sendBankInformation: userId => makeAxiosRequest(
    `${process.env.REACT_APP_API_URL}/users/${userId}/bank-information`,
    {
      method: 'post',
    },
  ),
  setBidAllowanceRequest: makeAxiosRequest('/my-bid-allowance-request', {
    method: 'put',
  }),
  updateSellerAgreement: makeAxiosRequest('/profile/seller-agreement', {
    method: 'put',
  }),
  getBuyerAgreementTemplate: makeAxiosRequest(
    '/buyer-agreement-template',
    {
      method: 'get',
    },
    true,
  ),
  updateBuyerAgreement: makeAxiosRequest('/profile/buyer-agreement', {
    method: 'put',
  }),
  updateBankInformation: makeAxiosRequest('/profile/bank-information', {
    method: 'put',
  }),
  updateUserInformation: makeAxiosRequest('/users', { method: 'put' }),
};
