export default {
  wrapper: {
    position: 'relative',
    padding: '12px 10px',
  },
  simpleWrapper: {
    padding: '8px 10px',
  },
  fileUpload: {
    width: '100%',
  },
  titleContainer: {
    marginTop: '10px',
  },
  simpleTitleContainer: {
    marginTop: 0,
  },
  title: {
    width: '100%',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 'normal',
    color: '#333333',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  simpleTitle: {
    fontSize: '14px',
  },
  cornerCloseButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '16px',
    height: '16px',
    transform: 'translateX(8px) translateY(-8px)',
    cursor: 'pointer',
  },
  inlineIconButton: {
    width: '14px',
    height: '14px',
    opacity: 0.5,
    cursor: 'pointer',

    '&:hover': {
      opacity: 1,
    },
  },
  imageContainer: {
    position: 'relative',
  },
  progressContainer: {
    position: 'absolute',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  icon: {
    width: '18px',
    height: '18px',
    cursor: 'pointer',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
