import PhoneInputStyles from 'now-frontend-shared/components/inputs/PhoneInput/styles';

export default {
  root: {
    padding: '86px 0',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '34px',
    textAlign: 'center',
    letterSpacing: '0.25px',
    color: '#171721',
    marginBottom: '30px',
  },
  buttonContainer: {
    marginTop: '20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonContainerSingle: {
    marginTop: '20px',
    width: '100%',
    textAlign: 'right',
  },
  text: {
    margin: '10px 0',
  },
  phoneInput: PhoneInputStyles.phoneInput,
  amountInput: {
    height: '56px',
    fontSize: '16px',
    fontWeight: 'normal',
    marginTop: '6px',
  },
  button: {
    backgroundColor: '#7890A6',
    color: '#ffffff',
  },
  backButton: {
    color: '#7890A6',
  },
  homeButton: {
    border: '1px solid rgba(178, 157, 121, 0.5)',
    color: '#B29D79',
    marginTop: '24px',
  },
  label: {
    fontWeight: 'normal',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: '20px',
  },
};
