export default {
  container: {
    '@media screen and (max-width: 600px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  heading: {
    fontSize: '32px',
    lineHeight: '37px',
    fontWeight: 'bold',
    color: '#282424',

    '@media screen and (max-width: 600px)': {
      fontSize: '24px',
      lineHeight: '28px',
    },
  },
  icon: {
    padding: '0 25px 0 0',

    '& svg': {
      cursor: 'pointer',
    },

    '@media screen and (max-width: 600px)': {
      padding: '0 0 25px',
    },
  },
  headingWithoutIcon: {
    paddingLeft: '35px',
  },
};
