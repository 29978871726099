import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import React, { useState, useMemo } from 'react';

import PropTypes from 'prop-types';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import useIsMobile from '../../hooks/useIsMobile';

export function pdfViewerDefaultWidth(isMobile) {
  const pageWidth = isMobile ? 450 : 650;
  return pageWidth;
}

export const pdfViewerDefaultScale = 1;

export const renderAttachment = (attachments, pageNumber) => {
  const pageAttachments = attachments.filter(att => att?.pageNumber === pageNumber);

  if (pageAttachments.length) {
    return pageAttachments.map((pageAtt, index) => (
      <div key={`page-${pageNumber}-attachment-${index}`}>
        {pageAtt.attachment}
      </div>
    ));
  }

  return null;
};

const PdfViewer = ({ file, attachments }) => {
  const [numPages, setNumPages] = useState(null);
  const isMobile = useIsMobile();

  const pageWidth = useMemo(() => pdfViewerDefaultWidth(isMobile), [isMobile]);

  const onDocumentLoadSuccess = ({ numPages: pages }) => {
    setNumPages(pages);
  };

  return (
    <Document
      file={file}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {Array.from(
        new Array(numPages),
        (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={pageWidth}
            scale={pdfViewerDefaultScale}
          >
            {!!attachments?.length && (
              <>
                {renderAttachment(attachments, index)}
              </>
            )}
          </Page>
        ),
      )}
    </Document>
  );
};

PdfViewer.propTypes = {
  file: PropTypes.object.isRequired,
  // TODO: [TYPE] define more specific type
  attachments: PropTypes.object,
};

PdfViewer.defaultProps = {
  attachments: undefined,
};

export default PdfViewer;
