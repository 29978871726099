/**
 * @typedef ComputeWellsAverageWorkingInterestWell
 * @property {number|string} workingInterestPercentage
 */

/**
 * @arg {ComputeWellsAverageWorkingInterestWell[]} wells
 * @returns {number} Wells average working interest percentage
 */
export function computeWellsAverageWorkingInterestPercentage(wells) {
  let result = 0;
  if (wells.length) {
    result = wells.reduce((prev, current) => prev + +current.workingInterestPercentage, 0) / wells.length;
  }
  return result;
}

/**
 * @typedef ComputeWellsNetAfeWell
 * @property {number|null|undefined} wellNetAFE
 */

/**
 * @param {ComputeWellsNetAfeWell[]|undefined} wells
 * @returns {number|undefined}
 */
export function computeWellsNetAfe(wells) {
  return wells?.reduce((acc, well) => {
    if (well.wellNetAFE === null || well.wellNetAFE === undefined) {
      return acc;
    }
    if (acc === undefined) {
      return Number(well.wellNetAFE);
    }
    return acc + Number(well.wellNetAFE);
  }, undefined);
}

/**
 * @typedef ComputeWellsGrossAfeWell
 * @property {number|null|undefined} wellGrossAfe
 */

/**
 * @param {ComputeWellsGrossAfeWell[]|undefined} wells
 * @returns {number|undefined}
 */
export function computeWellsGrossAfe(wells) {
  return wells?.reduce((acc, well) => {
    if (well.wellGrossAfe === null || well.wellGrossAfe === undefined) {
      return acc;
    }
    if (acc === undefined) {
      return Number(well.wellGrossAfe);
    }
    return acc + Number(well.wellGrossAfe);
  }, undefined);
}
