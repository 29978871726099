import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styled error field
import ErrorField from '../ErrorField';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { StyledTextField } from '../StyledTextField/styledTextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';

// styles
import styles from './styles';

const BaseInput = ({
  classes,
  meta,
  input,
  type,
  placeholder,
  isTextArea,
  inputToggleButton,
  description,
  ...rest
}) => {
  const isError = meta.touched && meta.error;

  return (
    <Grid container direction="column">
      <StyledTextField
        className={classes.fieldStyles}
        placeholder={placeholder}
        type={type || 'input'}
        id={input.name}
        multiline={isTextArea}
        rows={isTextArea && 5}
        endAdornment={inputToggleButton && <InputAdornment position="end">{inputToggleButton}</InputAdornment>}
        {...input}
        {...rest}
        variant="outlined"
      />

      {description || <ErrorField error={isError ? meta.error : null} />}
    </Grid>
  );
};

BaseInput.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string.isRequired,
  inputIcon: PropTypes.node,
  type: PropTypes.string,
  isTextArea: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default compose(withStyles(styles), memo)(BaseInput);
