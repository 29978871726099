export default {
  wrapper: {
    position: 'absolute',
    top: 0,
    left: '50%',
    height: '26px',
    width: '26px',
    background: 'rgba(0, 0, 0, 0)',
    borderRadius: '50%',
    border: '2px solid #fff',
    transform: 'translateX(-50%) translateY(-40px)',
    zIndex: 1,
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '26px',
    width: '26px',
    background: '#fff',
    borderRadius: '50%',
    outline: 'none',
    border: '4px double rgba(0, 0, 0, 0)',
    transform: 'translateX(-2px) translateY(-2px)',

    userSelect: 'none',
    cursor: 'pointer',
    transition: 'transform 0.25s ease-in-out, background 0.25s ease-in-out',
    backgroundClip: 'padding-box',
    zIndex: 2,

    '&:hover': {
      transform: 'scale(1.05) translateX(-2px) translateY(-2px)',

      '& span': {
        background: '#233E55',
      },
    },

    '& span': {
      display: 'block',
      height: '3px',
      width: '15px',
      background: '#C4C4C4',
      transition: 'background 0.25s ease-in-out',
    },

    '& span:first-child': {
      transform: 'rotate(45deg) translateX(2px) translateY(0px)',
    },

    '& span:last-child': {
      transform: 'rotate(135deg) translateX(-2px) translateY(0px)',
    },

    '@media screen and (max-width: 600px)': {
      top: 'unset',
      bottom: '-35px',
    },
  },
};
