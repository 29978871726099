import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskedInput from 'react-text-mask';
import cx from 'classnames';

// styled error field
import ErrorField from '../ErrorField';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

const maskOptions = {
  prefix: '',
  suffix: '',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 999,
  allowNegative: true,
  allowLeadingZeroes: false,
  includeThousandsSeparator: false,
};

const NumberInput = ({
  classes, meta, input, placeholder, disabled,
}) => {
  const currencyMask = createNumberMask(maskOptions);
  const isError = meta && meta.touched && meta.error;

  return (
    <Grid container direction="column">
      <MaskedInput
        placeholder={placeholder}
        mask={currencyMask}
        showMask={!!input.value}
        {...input}
        className={cx(classes.fieldStyles, { [classes.disabled]: disabled })}
      />

      <ErrorField error={isError ? meta.error : null} />
    </Grid>
  );
};

NumberInput.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default compose(withStyles(styles), memo)(NumberInput);
