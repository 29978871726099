import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// components
import ControlledSelectInput from './components/ControlledSelectInput';
import SelectWrapper from './components/SelectWrapper';
import Label from './components/Label';

// select arrow icons
import { ReactComponent as SelectArrow } from 'now-frontend-shared/assets/icons/select_arrow.svg';

// styles and components from material-ui
import { Grid, FormControl, MenuItem } from '@material-ui/core';

const ControlledSelect = ({
  options, placeholder, name, disabled, setQueryValue, onHandleChange, defaultOption,
}) => {
  const [value, setValue] = useState('');
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get(name);
    if (searchQuery) {
      setValue(searchQuery);
    } else {
      setValue('');
    }
  }, [location.search, name]);

  const handleChange = ({ target: { value } }) => {
    setValue(value);
    setQueryValue(name, value);
    if (onHandleChange) onHandleChange(value);
  };

  return (
    <Grid container direction="column">
      <FormControl variant="outlined">
        <Label id={name}>{placeholder}</Label>

        <SelectWrapper
          labelId={name}
          value={value}
          label={placeholder}
          input={<ControlledSelectInput />}
          IconComponent={SelectArrow}
          disabled={disabled}
          style={{ height: '48px' }}
          onChange={handleChange}
        >
          {defaultOption && (
            <MenuItem value="">
              <span style={{ textTransform: 'capitalize' }}>{defaultOption}</span>
            </MenuItem>
          )}
          {options?.map(({ title, id }) => (
            <MenuItem key={id} value={id}>
              <span style={{ textTransform: 'capitalize' }}>{title}</span>
            </MenuItem>
          ))}
        </SelectWrapper>
      </FormControl>
    </Grid>
  );
};

ControlledSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      title: PropTypes.string,
    }),
  ),
  disabled: PropTypes.bool,
  defaultOption: PropTypes.string,
};

export default ControlledSelect;
