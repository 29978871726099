export default {
  fieldStyles: {
    '& input': {
      lineHeight: '16px',
      fontWeight: '500',
      fontSize: '14px',
      padding: '12px 16px',
    },

    /* webkit browsers scrollbar */
    '& textarea::-webkit-scrollbar': {
      width: '3px',
      backgroundColor: '#fff',
    },
    '& textarea::-webkit-scrollbar-thumb': {
      backgroundColor: '#8F8F8F',
      borderRadius: '4px',
    },

    '& textarea': {
      /* firefox scrollbar */
      scrollbarWidth: 'thin',
      scrollbarColor: '#8F8F8F #fff',
    },
  },
};
