export default {
  errorText: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '18px',
    padding: '3px 14px 0',
    color: '#EB5757',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'left',
  },
};
