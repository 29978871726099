// actions
import * as A from '../actions/propertyBidsListActions';

const initialState = {
  bidsList: null,
  commonInfo: null,
  pending: false,
};

export const reducer = {
  propertyBidsList(state = initialState, action) {
    switch (action.type) {
      case A.clearState.type:
        return initialState;

      case A.getPropertyBids.type:
        return { ...state, pending: true };

      case A.getPropertyBids.failure:
        return { ...state, pending: false };

      case A.getPropertyBids.success:
        return {
          ...state, bidsList: action.payload.bidsList, commonInfo: action.payload.commonInfo, pending: false,
        };

      case A.setListingWinner.type:
        return { ...state, pending: true };

      case A.setListingWinner.failure:
        return { ...state, pending: false };

      case A.setListingWinner.success:
        return { ...state, pending: false };

      default:
        return state;
    }
  },
};
