import {
  createFormValidator,
  hasValue,
  minCharCount,
  maxCharCount,
  required,
  socialSecurityNumber,
  trimmed,
  GOVERNMENT_ISSUED_ID_MIN_LENGTH,
  GOVERNMENT_ISSUED_ID_MAX_LENGTH,
  GOVERNMENT_ISSUED_ID_PLACE_OF_ISSUANCE_MIN_LENGTH,
  GOVERNMENT_ISSUED_ID_PLACE_OF_ISSUANCE_MAX_LENGTH,
  JOB_TITLE_MAX_LENGTH,
  JOB_TITLE_MIN_LENGTH,
  PERSON_FULL_NAME_MAX_LENGTH,
  PERSON_FULL_NAME_MIN_LENGTH,
  validateIsDateOnly,
} from './validation-rules';

export const COMPANY_OFFICER_NAME_MIN_LENGTH = PERSON_FULL_NAME_MIN_LENGTH;

/**
 * NOTE: updates to this must be accompanied by a database migration
 */
export const COMPANY_OFFICER_NAME_MAX_LENGTH = PERSON_FULL_NAME_MAX_LENGTH;

export const COMPANY_OFFICER_TITLE_MIN_LENGTH = JOB_TITLE_MIN_LENGTH;

/**
 * NOTE: updates to this must be accompanied by a database migration
 */
export const COMPANY_OFFICER_TITLE_MAX_LENGTH = JOB_TITLE_MAX_LENGTH;

/**
 * @type {import('./validation-rules').FieldValidators}
 */
export const companyOfficerDetailsFieldValidators = {
  name: () => [
    required,
    trimmed,
    minCharCount(COMPANY_OFFICER_NAME_MIN_LENGTH),
    maxCharCount(COMPANY_OFFICER_NAME_MAX_LENGTH),
  ],
  title: () => [
    required,
    trimmed,
    minCharCount(COMPANY_OFFICER_TITLE_MIN_LENGTH),
    maxCharCount(COMPANY_OFFICER_TITLE_MAX_LENGTH),
  ],
  dateOfBirth: () => [
    required,
    trimmed,
    validateIsDateOnly,
  ],
  socialSecurityNumber: values => [
    ...hasValue(values.socialSecurityNumber) ? [
      trimmed,
      socialSecurityNumber,
    ] : [],
  ],
  governmentIssuedId: values => [
    ...hasValue(values.governmentIssuedId) ? [
      trimmed,
      minCharCount(GOVERNMENT_ISSUED_ID_MIN_LENGTH),
      maxCharCount(GOVERNMENT_ISSUED_ID_MAX_LENGTH),
    ] : [],
  ],
  placeOfIssuance: values => [
    ...hasValue(values.governmentIssuedId) ? [
      required,
    ] : [],
    ...hasValue(values.BOPlaceOfIssuance) ? [
      trimmed,
      minCharCount(GOVERNMENT_ISSUED_ID_PLACE_OF_ISSUANCE_MIN_LENGTH),
      maxCharCount(GOVERNMENT_ISSUED_ID_PLACE_OF_ISSUANCE_MAX_LENGTH),
    ] : [],
  ],
};

export const validateCompanyOfficerDetails = createFormValidator(companyOfficerDetailsFieldValidators);
