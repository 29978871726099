import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import LockIcon from '@material-ui/icons/LockRounded';

import SsnOrTinInput from 'now-frontend-shared/components/inputs/SsnOrTinInput';
import {
  validateNewEntityInformation,
} from 'now-shared/validation/new-entity-information';
import {
  formValidationHasErrors,
} from 'now-shared/validation/validation-rules';

import styles from './styles';
import { updateCompany } from 'store/actions/companyActions';
import { signatureStyle } from '../styles';
import { StyledTextField } from 'now-frontend-shared/components/inputs/StyledTextField/styledTextField';

import {
  validateSignatureForm,
} from 'now-shared/validation/signature-validation';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import { navigateToNextPage, navigateToPreviousPage } from 'constants/registrationFlow';
import { getAllCompanies } from 'store/actions/companiesActions';

const options = [
  { name: 'Sole Proprietorship', value: 'sole_proprietorship' },
  { name: 'Corporation', value: 'corporation' },
  { name: 'General Partnership', value: 'general_partnership' },
  { name: 'Limited Liability Company', value: 'limited_liability_company' },
  {
    name: 'Limited Partnership or Limited Liability Partnership',
    value: 'limited_partnership_or_limited_liability_partnership',
  },
];

function NewAccountView({
  classes, company, companies, user,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [touched, setTouched] = React.useState({});
  const [isSecretVisible, setIsSecretVisible] = React.useState({});
  const isCompanyNameSaved = user.company?.fullLegalCompanyName === company.fullLegalCompanyName;

  // TODO: [EDGECASE][BUG] if companies hasn't finished loading yet, this validation will pass
  // even if the company name is in the list that eventually loads.
  const errors = validateNewEntityInformation({ ...company, companies: isCompanyNameSaved ? [] : companies });

  // TODO: [REFACTOR] add this valiadtion to a shared input component called SignatureInput
  // instead of adding errors to the individual parent components
  const signatureErrors = validateSignatureForm({ signature: company.accountManagerSignature, printedName: getUserFullName(user) || '' });
  const signatureError = touched.signature && signatureErrors.signature;
  const areBusinessFieldsValid = (
    !formValidationHasErrors(errors)
    && !formValidationHasErrors(signatureErrors)
  );

  const setTouchedDelay = 0;
  const setTouchedDelayed = (fieldName, newValue) => {
    setTimeout(() => setTouched(prev => ({
      ...prev,
      [fieldName]: newValue,
    })), setTouchedDelay);
  };

  const setIsSecretVisibleDelayed = (fieldName, newValue) => {
    setTimeout(() => setIsSecretVisible(prev => ({
      ...prev,
      [fieldName]: newValue,
    })), setTouchedDelay);
  };

  const businessFields = [
    {
      label: 'Full Legal Name of Company',
      value: 'fullLegalCompanyName',
    },
    {
      label: 'Operating Name (subsidiary/DBA) optional',
      value: 'operatingName',
    },
  ];

  useEffect(() => {
    /* TODO: [SECURITY]
      Remove this call and create a call specific call for checking a company's name against existing companies.
    */
    dispatch(getAllCompanies());
    if (typeof company.role === 'string') {
      dispatch(updateCompany({ key: 'role', value: options.find(role => role.value === company.role) }));
    }
  }, [dispatch]);

  const createFields = (list, autoFocus = false) => (
    <>
      {list.map(({ label, value, isSecret }, i) => {
        const error = touched[value] && errors[value];
        return (
          <StyledTextField
            value={company[value] || ''}
            key={value}
            type={(!isSecret || isSecretVisible[value]) ? 'text' : 'password'}
            variant="outlined"
            onChange={e => {
              dispatch(updateCompany({ key: value, value: e.target.value || null }));
            }}
            onFocus={() => {
              setTouchedDelayed(value, false);
              if (isSecret) {
                setIsSecretVisibleDelayed(value, true);
              }
            }}
            onBlur={() => {
              setTouchedDelayed(value, true);
              if (isSecret) {
                setIsSecretVisibleDelayed(value, false);
              }
            }}
            label={label}
            error={!!error}
            helperText={error || '\u00a0'}
            placeholder={label}
            fullWidth
            margin="normal"
            {...isSecret && {
              InputProps: {
                endAdornment: (
                  <LockIcon />
                ),
              },
            }}
            autoFocus={autoFocus && i === 0}
          />
        );
      })}
    </>
  );

  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ margin: 'auto', maxWidth: '642px' }}
      >
        <Typography variant="h1" component="div" className={classes.title}>
          New Account Information
        </Typography>
        <Typography variant="body1" component="div" className={classes.text}>
          Enter your details to proceed further
        </Typography>
        {createFields(businessFields, true)}
        {(() => {
          const field = 'einOrTin';
          const label = 'EIN/TIN';
          const isSecret = true;
          const error = touched[field] && errors[field];
          const value = company[field];
          return (
            <SsnOrTinInput
              classes={{ maskStyles: classes.ssnOrTinInput }}
              autoComplete="off"
              input={{
                name: field,
                value: value || '',
                onChange: e => {
                  dispatch(updateCompany({ key: field, value: e.target.value || null }));
                },
                onFocus: () => {
                  setTouchedDelayed(field, false);
                  if (isSecret) {
                    setIsSecretVisibleDelayed(field, true);
                    if (document.getElementById('einOrTin').getAttribute('type') === 'password') {
                      document.getElementById('einOrTin').setAttribute('type', 'text');
                    }
                  }
                },
                onBlur: () => {
                  setTouchedDelayed(field, true);
                  if (isSecret) {
                    document.getElementById('einOrTin').setAttribute('type', 'password');
                    setIsSecretVisibleDelayed(field, false);
                  }
                },
              }}
              renderInput={() => (
                <StyledTextField
                  label={label}
                  id={field}
                  type="text"
                  error={!!error}
                  helperText={error || '\u00a0'}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <LockIcon />
                    ),
                  }}
                />
              )}
            />
          );
        })()}
        <Autocomplete
          margin="normal"
          fullWidth
          value={company.role}
          onChange={(_, newValue) => {
            dispatch(updateCompany({ value: newValue, key: 'role' }));
          }}
          onFocus={() => setTouchedDelayed('role', false)}
          onBlur={() => setTouchedDelayed('role', true)}
          id="entity-select"
          options={options}
          style={{
            marginTop: '16px',
            marginBottom: '8px',
          }}
          getOptionLabel={option => option.name || ''}
          renderInput={params => {
            const error = touched.role && errors.role;
            return (
              <StyledTextField
                {...params}
                label="Entity Type"
                variant="outlined"
                error={!!error}
                helperText={error || '\u00a0'}
              />
            );
          }}
        />
        {(() => (
          <StyledTextField
            value={company.accountManagerSignature}
            InputProps={{ style: signatureStyle }}
            variant="outlined"
            onChange={e => {
              dispatch(updateCompany({ value: e.target.value, key: 'accountManagerSignature' }));
            }}
            onFocus={() => setTouchedDelayed('signature', false)}
            onBlur={() => setTouchedDelayed('signature', true)}
            label="Your signature"
            error={!!signatureError}
            helperText={signatureError || '\u00a0'}
            placeholder="Your signature"
            fullWidth
            margin="normal"
          />
        ))()}
        <div className={classes.buttonContainer}>
          <Button
            onClick={() => history.push(navigateToPreviousPage(history.location.pathname))}
            className={classes.backButton}
            variant="text"
          >
            BACK
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            disabled={!areBusinessFieldsValid}
            onClick={() => history.push(navigateToNextPage(history.location.pathname))}
          >
            NEXT
          </Button>
        </div>
      </Grid>
    </div>
  );
}

NewAccountView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  company: PropTypes.objectOf(PropTypes.any).isRequired,
  companies: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    company: PropTypes.shape({
      fullLegalCompanyName: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default compose(
  connect(({ company, companies, auth }) => ({
    company,
    companies: companies.companies,
    user: auth.user,
  })),
  withStyles(styles),
)(NewAccountView);
