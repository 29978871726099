export default {
  menuContainer: {
    padding: '0 30px',

    '@media screen and (max-width: 1080px)': {
      padding: '0 10px',
    },

    '@media screen and (max-width: 980px)': {
      display: 'none',
    },
  },
  headerButton: {
    textTransform: 'none',
    margin: '0 30px',
    textAlign: 'center',

    '& span': {
      fontSize: '15px',
      lineHeight: '22px',
      fontWeight: 'normal',
      color: '#282424',
    },

    '@media screen and (max-width: 980px)': {
      margin: '0 10px',
    },
  },
};
