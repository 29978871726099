export const ClosingMethod = {
  EscrowDotCom: 'escrowDotCom',
  AlternativeEscrowAgent: 'alternativeEscrowAgent',
  NoEscrow: 'noEscrow',
};

export const ClosingMethodTitle = {
  [ClosingMethod.EscrowDotCom]: 'Escrow.com',
  [ClosingMethod.AlternativeEscrowAgent]: 'Alternative Escrow Agent',
  [ClosingMethod.NoEscrow]: 'No Escrow',
};
