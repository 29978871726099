import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';

// styles
import styles from './styles';

const PlusButton = ({ classes, error, onClick }) => (
  <Grid container direction="column" className={classes.buttonContainer}>
    <Button variant="contained" startIcon={<span>+</span>} onClick={onClick} className={classes.button}>
      Add one more well
    </Button>

    <span className={classes.errorText}>
      {error && 'Please, fill in all fields of landing zones'}
      {' '}
    </span>
  </Grid>
);

PlusButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  error: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), memo)(PlusButton);
