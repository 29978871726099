import {
  all, fork, take, takeLatest, call, put,
} from 'redux-saga/effects';

// actions
import { getBidedProperties, getBidsStatuses } from '../actions/bidedPropertiesActions';

// api methods
import Api from 'api/bids';

// storage helpers functions
import * as storage from 'now-frontend-shared/utils/storage';

import { SortDirection } from 'now-shared/enums/sort-direction';

function* ensureGetBidedProperties({ payload: query }) {
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    const { data } = yield call(Api.getBidedProperties, {
      url: query.includes('order')
        ? `/bids/auth-user-bids${query}`
        : `/bids/auth-user-bids${query}&limit=10&order=createdAt&sort=${SortDirection.Descending}`,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    yield put({ type: getBidedProperties.success, payload: data });
  } catch (err) {
    yield put({ type: getBidedProperties.failure, err });
  }
}

function* watchGetBidedProperties() {
  yield takeLatest(getBidedProperties.type, ensureGetBidedProperties);
  yield take(getBidedProperties.success);
}

function* ensureGetBidsStatuses() {
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    const { data } = yield call(Api.getBidsStatuses, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    yield put({ type: getBidsStatuses.success, payload: data });
  } catch (err) {
    yield put({ type: getBidsStatuses.failure, err });
  }
}

function* watchGetBidsStatuses() {
  yield takeLatest(getBidsStatuses.type, ensureGetBidsStatuses);
  yield take(getBidsStatuses.success);
}

export default function* bidedPropertiesSagas() {
  yield all([fork(watchGetBidedProperties), fork(watchGetBidsStatuses)]);
}
