import {
  createFormValidator,
  hasValue,
  maxCharCount,
  minCharCount,
  required,
  einOrTinNumber,
  trimmed,
  companyNameExists,
} from './validation-rules';

/**
 * @type {import('./validation-rules').FieldValidators}
 */
export const newEntityInformationFieldValidators = {
  einOrTin: () => [
    required,
    einOrTinNumber,
  ],
  fullLegalCompanyName: values => [
    required,
    trimmed,
    companyNameExists(values.companies),
    // TODO: [REFACTOR][DRY] define this as a constant and use that throughout the code base
    minCharCount(2),
    // TODO: [REFACTOR][DRY] define this as a constant and use it to determine db column size
    maxCharCount(200),
  ],
  operatingName: values => [
    ...hasValue(values.operatingName) ? [
      trimmed,
      // TODO: [REFACTOR][DRY] define this as a constant and use that throughout the code base
      minCharCount(2),
      // TODO: [REFACTOR][DRY] define this as a constant and use it to determine db column size
      maxCharCount(200),
    ] : [],
  ],
  role: () => [
    required,
  ],
};

export const validateNewEntityInformation = createFormValidator(newEntityInformationFieldValidators);
