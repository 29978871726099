import theme from 'now-frontend-shared/themes/theme';

export default {
  modalWrapper: {
    position: 'relative',
    maxWidth: '900px',
  },
  contentWrapper: {
    maxHeight: '700px',
    overflowY: 'scroll',

    '@media screen and (max-height: 1000px)': {
      maxHeight: '650px',
    },
    '@media screen and (max-height: 700px)': {
      maxHeight: '550px',
    },
    '@media screen and (max-height: 600px)': {
      maxHeight: '450px',
    },
    '@media screen and (max-height: 500px)': {
      maxHeight: '350px',
    },
    '@media screen and (max-height: 400px)': {
      maxHeight: '250px',
    },
    '@media screen and (max-height: 300px)': {
      maxHeight: '150px',
    },
  },
  container: {
    padding: '15px',
    background: theme.palette.background.default,
    borderRadius: '4px',
  },
  heading: {
    padding: '25px',
    fontSize: '32px',
    lineHeight: '37px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
};
