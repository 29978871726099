import * as isEmailValidator from 'validator/lib/isEmail';
import * as moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { unicodeCharCount } from '../helpers/text-helpers';
import { phoneNumberParseOptions } from '../helpers/phone-number-helpers';

export const dateOnlyDtoFormat = 'YYYY-MM-DD';

export const REQUIRED = 'Required';
export const MUST_NOT_EXIST = 'Must not exist';
export const STRING = 'Must be a string';
export const INVALID_EMAIL = 'This email not correct, please try again';
export const MUST_BE_THE_SAME = 'Must be the same';
export const INVALID_PHONE_NUMBER = 'This phone number is not correct, please try again';
export const INVALID_DATE_FORMAT = 'This date not correct, please try again';
export const INVALID_POSITIVE_NUMBER = 'Value must be a positive number';
export const INVALID_NEGATIVE_NUMBER = 'Value must be a negative number';
export const INVALID_INTEGER = 'Value must be an integer';
export const INVALID_CHECK_ONE = 'Select at least one of the checkboxes';
export const COMPANY_NAME_UNAVAILABLE = 'This entity name is not available, please try another';
export const INVALID_DATE_ONLY = `Must be a valid date (required format: ${dateOnlyDtoFormat})`;

/**
 * NOTE: SSN has the same format as TIN or EIN (taxpayer/employer identification number)
 *
 * 1 represents [1-9]
 * 0 represents [0-9]
 */
export const SOCIAL_SECURITY_NUMBER_TEMPLATE = '100-00-0000';
export const SOCIAL_SECURITY_NUMBER_LENGTH = SOCIAL_SECURITY_NUMBER_TEMPLATE.length;
export const INVALID_SSN = 'This social security number not correct, please try again';
export const INVALID_TIN_EIN = 'This EIN/TIN number not correct, please try again';

/**
 * NOTE: updates to this must be accompanied by a database migration
 */
export const GOVERNMENT_ISSUED_ID_MAX_LENGTH = 20;
export const GOVERNMENT_ISSUED_ID_MIN_LENGTH = 1;
/**
 * NOTE: updates to this must be accompanied by a database migration
 */
export const GOVERNMENT_ISSUED_ID_TYPE_MAX_LENGTH = 200;
export const GOVERNMENT_ISSUED_ID_TYPE_MIN_LENGTH = 1;

/**
 * NOTE: updates to this must be accompanied by a database migration
 */
export const STOCK_TICKER_SYMBOL_MAX_LENGTH = 5;
export const STOCK_TICKER_SYMBOL_MIN_LENGTH = 1;

/**
 * NOTE: updates to this must be accompanied by a database migration
 */
export const STOCK_EXCHANGE_MIC_MAX_LENGTH = 6;
export const STOCK_EXCHANGE_MIC_MIN_LENGTH = 3;

/**
 * NOTE: updates to this must be accompanied by a database migration
 */
export const GOVERNMENT_ISSUED_ID_PLACE_OF_ISSUANCE_MAX_LENGTH = 100;
export const GOVERNMENT_ISSUED_ID_PLACE_OF_ISSUANCE_MIN_LENGTH = 1;

/**
 * NOTE: updates to this must be accompanied by a database migration
 */
export const PERSON_FIRST_NAME_MAX_LENGTH = 50;
/**
 * NOTE: updates to this must be accompanied by a database migration
 */
export const PERSON_LAST_NAME_MAX_LENGTH = 50;
/**
 * NOTE: updates to this must be accompanied by a database migration
 */
export const PERSON_MIDDLE_NAMES_MAX_LENGTH = 100;
/**
 * NOTE: updates to this must be accompanied by a database migration
 */
export const PERSON_FULL_NAME_MAX_LENGTH
  = PERSON_FIRST_NAME_MAX_LENGTH
  + PERSON_MIDDLE_NAMES_MAX_LENGTH
  + PERSON_LAST_NAME_MAX_LENGTH;
export const PERSON_FULL_NAME_MIN_LENGTH = 1;
/**
 * NOTE: updates to this must be accompanied by a database migration
 */
export const SIGNATURE_MIN_LENGTH = PERSON_FULL_NAME_MIN_LENGTH;
/**
 * NOTE: updates to this must be accompanied by a database migration
 */
export const SIGNATURE_MAX_LENGTH = PERSON_FULL_NAME_MAX_LENGTH;

export const JOB_TITLE_MIN_LENGTH = 2;
/**
 * NOTE: updates to this must be accompanied by a database migration
 */
export const JOB_TITLE_MAX_LENGTH = 200;

const SIGN_IN_INVALID_PASSWORD = 'This password not correct, please try again';
const SIGN_UP_INVALID_PASSWORD = 'At least 8 digits and at least, 1 capital letter, 1 lowercase, 1 number and 1 symbol';

export const addError = (fieldName, err) => errors => {
  // TODO: support multiple errors on the same field ?
  if (errors[fieldName]) return errors;

  if (err !== undefined) {
    return { ...errors, [fieldName]: err };
  }

  return errors;
};

/**
 * If the validate result is an object with a message, use the message as the error.
 *
 * @returns {string|undefined}
 */
export const getValidatorErrorMessage = validateResult => {
  let message;
  if (typeof validateResult === 'string') {
    message = validateResult;
  } else if (validateResult?.message) {
    message = validateResult.message;
  }
  return message;
};

/**
 * If the validate result is an object with a message, use the message as the error.
 *
 * @return {string|undefined}
 */
export const adaptValidatorResult = validate => (...args) => getValidatorErrorMessage(validate(...args));

/**
 * @callback ValueValidator
 * @param {any} value
 * @param {object=} values
 * @param {string=} fieldName
 * @returns {string|undefined}
 */

/**
 * @typedef {ValueValidator[]} ValueValidatorArray
 */

/**
 * @callback ValueValidatorArrayGetter
 * @param {object} values
 * @param {any=} value
 * @param {string=} fieldName
 * @returns {ValueValidatorArray}
 */

/**
 * @typedef {Object.<string, ValueValidatorArrayGetter>} FieldValidators
 */

/**
 * @typedef {Object} CreateFieldValidatorArgs
 * @property {string} fieldName
 * @property {object} values
 * @property {FieldValidators=} fieldValidators
 * @property {ValueValidatorArrayGetter=} getValidators
 */

/**
 * @param {CreateFieldValidatorArgs} args
 * @returns {string|undefined}
 */
export const validateField = args => {
  const {
    fieldName,
    values,
  } = args;
  const getValidators = args.getValidators || args.fieldValidators[fieldName];
  const value = values[fieldName];
  const validators = getValidators(values, value, fieldName);
  let error;
  validators.some(validate => {
    error = validate(value, values, fieldName);
    if (error !== undefined) {
      return true;
    }
    return false;
  });
  return error;
};

/**
 * @typedef {Object} ValidationErrorObject
 * @property {string} message
 * @property {number=} code
 */

/**
 * @typedef {string|ValidationErrorObject} ValidationError
 */

/**
 * @typedef {Object.<string, ValidationError>} FormValidationErrors
 */

/**
 * @callback FormValidator
 * @param {object} values
 * @returns {FormValidationErrors}
 */

/**
 * Create a form validator from a fieldValidators definition
 *
 * @param {FieldValidators} fieldValidators
 * @returns {FormValidator}
 */
export const createFormValidator = fieldValidators => (
  values => {
    const errors = {};
    Object.entries(fieldValidators).forEach(([fieldName, getValidators]) => {
      const error = validateField({
        fieldName,
        values,
        getValidators,
      });
      if (error !== undefined) {
        errors[fieldName] = error;
      }
    });
    return errors;
  }
);

/**
 * @param {FormValidationErrors} errors
 * @returns {boolean}
 */
export const formValidationHasErrors = errors => !!Object.values(errors).filter(value => value !== undefined).length;

/**
 * @param {any} value
 * @returns {boolean}
 */
export const hasValue = value => typeof value === 'number' || typeof value === 'boolean' || !!value;

/**
 * @param {any} value
 * @returns {string|undefined}
 */
export const required = value => (hasValue(value) ? undefined : REQUIRED);

/**
 * @param {any} value
 * @returns {string|undefined}
 */
export const validateMustNotExist = value => (
  (value === undefined || value === null) ? undefined : MUST_NOT_EXIST
);

/**
 * @param {any} value
 * @returns {string|undefined}
 */
export const string = value => (typeof value === 'string' ? undefined : STRING);

export const phoneNumber = value => {
  try {
    if (!hasValue(value)) {
      return REQUIRED;
    }
    const phoneNum = parsePhoneNumberFromString(value, phoneNumberParseOptions);
    return phoneNum?.isValid() ? undefined : INVALID_PHONE_NUMBER;
  } catch (e) {
    return e.message;
  }
};

/**
 * @callback ToString
 * @param {any} value
 * @returns {string}
 */

/**
 * @typedef {Object} CompanyNameExistsCompany
 * @property {string} fullLegalCompanyName
 */

/**
 * @param {CompanyNameExistsCompany[]} listOfCompanies
 * @returns {ValueValidator}
 *
 * TODO: [REFACTOR][ORGANIZATION] I would consider this less of a common/general rule and more
 * of a rule specific to company registration. As such, I'd put this in a separate file
 * at `shared/validation/companies.js`.
 *
 * TODO: [REQUIREMENTS][FEATURE] do we need to treat company names as case insensitive
 * when checking for uniqueness? (Is "My Company" considered the same as "MY COMPANY"?)
 */
export const companyNameExists = listOfCompanies => (
  value => (
    (
      listOfCompanies.find(company => company.fullLegalCompanyName === value)
        ? COMPANY_NAME_UNAVAILABLE
        : undefined
    )
  )
);

/**
 * @param {any} value
 * @returns {string|undefined}
 */
export const trimmed = value => (
  (typeof value === 'string' && unicodeCharCount(value) === unicodeCharCount(value.trim()))
    ? undefined
    : 'Must not begin or end with whitespace'
);

/**
 * @param {any} value
 * @returns {string|undefined}
 */
export const validateNotEmpty = value => {
  let result;
  if (typeof value === 'string') {
    if (value.trim().length === 0) {
      result = true;
    }
  } else if (Array.isArray(value)) {
    if (value.length === 0) {
      result = true;
    }
  } else if (typeof value === 'object') {
    if (value === null || Object.keys(value).length === 0) {
      result = true;
    }
  } else {
    result = true;
  }

  if (result === true) {
    result = 'Must not be empty';
  }
  return result;
};

/**
 * @param {number} count
 * @param {ToString=} toString
 * @returns {ValueValidator}
 */
export const minCharCount = (count, toString) => (
  value => {
    const actualValue = toString ? toString(value) : value;
    return (typeof actualValue === 'string' && unicodeCharCount(actualValue) >= count)
      ? undefined
      : `Minimum ${count} characters`;
  }
);

/**
 * @param {number} count
 * @param {ToString=} toString
 * @returns {ValueValidator}
 */
export const maxCharCount = (count, toString) => (
  value => {
    const actualValue = toString ? toString(value) : value;
    return (typeof actualValue === 'string' && unicodeCharCount(actualValue) <= count)
      ? undefined
      : `Maximum ${count} characters`;
  }
);

/**
 * SSN/TIN/EIN all share this same format
 *
 * @param {any} ssn
 * @returns {string|undefined}
 */
export const socialSecurityNumber = ssn => (
  (
    typeof ssn === 'string'
    && ssn.length === SOCIAL_SECURITY_NUMBER_LENGTH
    && /^[1-9]\d{2}-\d{2}-\d{4}$/.test(ssn)
  ) ? undefined : INVALID_SSN
);

export const einOrTinNumber = ssn => (
  (
    typeof ssn === 'string'
    && ssn.length === SOCIAL_SECURITY_NUMBER_LENGTH
    && /^[1-9]\d{2}-\d{2}-\d{4}$/.test(ssn)
  ) ? undefined : INVALID_TIN_EIN
);

export const validateIsDateOnly = value => (
  (
    typeof value === 'string'
    && /^[1-9]\d*-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/.test(value)
    && moment(value, dateOnlyDtoFormat).isValid()
  )
    ? undefined
    : INVALID_DATE_ONLY
);

/**
 * Max character count for a valid email address.
 *
 * NOTE: If this constant is ever used to define a database column length, please add a note here
 * that changing this value must be accompanied with a database migration.
 */
export const validEmailMaxCharCount = 320;

/**
 * @param {any} value
 * @returns {string|undefined}
 */
export const validateIsEmail = (
  value => (
    typeof value === 'string' && isEmailValidator(value)
      ? undefined
      : INVALID_EMAIL
  )
);

export const generalRules = {
  required,
  email: validateIsEmail,
  socialSecurityNumber,
  same: (value, ...rest) => rest.reduce((currentValue, sameValue) => {
    if (typeof currentValue === 'undefined') {
      if (sameValue !== value) return MUST_BE_THE_SAME;
      return undefined;
    }
    return currentValue;
  }, undefined),
  string,
  trimmed,
  minCharCount,
  maxCharCount,
  minLength: minCharCount(2, value => (value || '').trim()),
  shortFieldMaxLenght: maxCharCount(50, value => (value || '').toString()),
  longFieldMaxLenght: maxCharCount(200, value => (value || '').toString()),
  phoneNumber,
  phoneNumberOptional: value => value && phoneNumber(value),
  dateString: dateString => (/^(((0)[0-9])|((1)[0-2]))(\/)([0-2][0-9]|(3)[0-1])(\/)\d{4}$/.test(dateString) ? undefined : INVALID_DATE_FORMAT),
  isPositiveNumber: value => (Number(value) && Number(value) > 0 ? undefined : INVALID_POSITIVE_NUMBER),
  isNegativeNumber: value => (Number(value) && Number(value) < 0 ? undefined : INVALID_NEGATIVE_NUMBER),
  notFutureDate: date => (date < new Date() ? undefined : INVALID_DATE_FORMAT),
  isInteger: value => (Number.isInteger(value) ? undefined : INVALID_INTEGER),
  atLeastOneIsTrue: value => (
    value && Object.values(value).some(v => v === true)
      ? undefined
      : INVALID_CHECK_ONE
  ),
};

export const userPasswordRegex
  = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!$%^&*()_+|~\-=`{}[\]:";'<>?,./@#])[A-Za-z\d!$%^&*()_+|~\-=`{}[\]:";'<>?,./@#]{8,}$/;

export const signInRules = {
  password: password => (
    userPasswordRegex.test(password)
      ? undefined
      : SIGN_IN_INVALID_PASSWORD
  ),
};

export const signUpRules = {
  password: password => (
    userPasswordRegex.test(password)
      ? undefined
      : SIGN_UP_INVALID_PASSWORD
  ),
};
