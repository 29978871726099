import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { primaryDark } from '../../themes/colors';

// google map components
import {
  GoogleApiWrapper, Map, Polyline, Marker,
} from 'google-maps-react';

// marker icon
import markerIcon from 'now-frontend-shared/assets/icons/oil_derrick.svg';
// import markerIcon from 'now-frontend-shared/assets/icons/marker.svg';

const GoogleMap = ({ google, wells: { polylinesCoordinates, markersCoordinates }, containerStyle }) => {
  const [map, setMap] = useState();

  const getMap = useCallback((_, map) => setMap(map), [setMap]);

  const initialCenter = { lat: 40.134701, lng: -102.452763 };

  const renderPolylines = useCallback(() => {
    if (map && polylinesCoordinates.length > 0) {
      const bounds = new google.maps.LatLngBounds();

      polylinesCoordinates.forEach(polyline => {
        polyline.forEach(marker => {
          bounds.extend(marker);
        });
      });

      map.fitBounds(bounds);
    }

    return polylinesCoordinates.map((path, index) => (
      <Polyline key={index} path={path} strokeColor={primaryDark} strokeOpacity={1.0} strokeWeight={2} />
    ));
  }, [google.maps.LatLngBounds, map, polylinesCoordinates]);

  const renderMarkers = useCallback(
    () => markersCoordinates.map((coordinates, index) => <Marker key={index} position={coordinates} icon={markerIcon} />),
    [markersCoordinates],
  );

  const polylines = useMemo(() => renderPolylines(), [renderPolylines]);
  const markers = useMemo(() => renderMarkers(), [renderMarkers]);

  return (
    <Map google={google} containerStyle={containerStyle} initialCenter={initialCenter} zoom={3} onReady={getMap} mapTypeControl={false}>
      {polylines}
      {markers}
    </Map>
  );
};

GoogleMap.propTypes = {
  wells: PropTypes.objectOf(PropTypes.array),
};

export default compose(
  GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    language: 'us',
  }),
)(GoogleMap);
