import { textPrimary } from 'themes/colors';

export default {
  heading: {
    padding: '40px',
    fontSize: '32px',
    lineHeight: '37px',
    fontWeight: 'bold',
    color: textPrimary,
    '@media screen and (max-width: 600px)': {
      justifyContent: 'left',
      padding: '40px 20px',
      fontSize: '24px',
      lineHeight: '28px',
    },
  },

  // TODO: [DRY][REFACTOR] consolidate and move the following styles to a reusable style object
  // constant in `frontend-shared`, which can be used for rendering any document like this one (such as
  // FAQ, Privacy Policy, etc.)

  sectionHeading: {
    marginBottom: '48px',
    fontSize: '28px',
    lineHeight: '24px',
    fontWeight: 'bold',
    color: textPrimary,

    '@media screen and (max-width: 960px)': {
      marginBottom: '40px',
    },

    '@media screen and (max-width: 600px)': {
      fontSize: '16px',
      lineHeight: '19px',
    },
  },
  textContainer: {
    '@media screen and (max-width: 600px)': {
      padding: '0 20px',
    },
  },
  text: {
    marginBottom: '70px',
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 'normal',
    color: textPrimary,

    '@media screen and (max-width: 960px)': {
      marginBottom: '40px',
    },

    '@media screen and (max-width: 600px)': {
      fontSize: '16px',
      lineHeight: '19px',
    },
  },
  list: {
    marginBottom: '24px',
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 'normal',
    color: textPrimary,

    '@media screen and (max-width: 960px)': {
      marginBottom: '40px',
    },
  },
};
