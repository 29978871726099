/**
 * WARNING: changing these existing values will likely require a database migration, as these types
 * are stored in the database and application logic depends on these exact value strings. Changing
 * these values means the existing documents stored with the old version of the value will no longer
 * be considered to have the same type.
 *
 * @readonly
 * @enum {'newListingClosingDocument'|'newListingOtherDocument'|'additionalListingClosingDocument'|'additionalListingOtherDocument'}
 */
export const ListingDocumentType = {
  /**
   * @readonly
   * @type {'newListingClosingDocument'}
   */
  NEW_LISTING_CLOSING_DOCUMENT: 'newListingClosingDocument',
  /**
   * @readonly
   * @type {'newListingOtherDocument'}
   */
  NEW_LISTING_OTHER_DOCUMENT: 'newListingOtherDocument',
  /**
   * @readonly
   * @type {'additionalListingClosingDocument'}
   */
  ADDITIONAL_LISTING_CLOSING_DOCUMENT: 'additionalListingClosingDocument',
  /**
   * @readonly
   * @type {'additionalListingOtherDocument'}
   */
  ADDITIONAL_LISTING_OTHER_DOCUMENT: 'additionalListingOtherDocument',
};

export const ListingDocumentTypeTitle = {
  [ListingDocumentType.NEW_LISTING_CLOSING_DOCUMENT]:
    'Closing Document',
  [ListingDocumentType.NEW_LISTING_OTHER_DOCUMENT]:
    'Other Document',
  [ListingDocumentType.ADDITIONAL_LISTING_CLOSING_DOCUMENT]:
    'Additional Closing Document',
  [ListingDocumentType.ADDITIONAL_LISTING_OTHER_DOCUMENT]:
    'Additional Other Document',
};
