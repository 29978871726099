import { LOCATION_CHANGE } from 'connected-react-router';
import * as A from '../actions/businessFilesActions';

const initialState = {
  preSignedUrls: undefined,
  preSignedUrlsIsLoading: false,
  AWSData: [],
  AWSDataIsSet: false,
  unloadedFilesExist: false,
};

export const reducer = {
  businessFiles(state = initialState, action) {
    switch (action.type) {
      case LOCATION_CHANGE:
      case A.reset.type:
        return initialState;

      case A.getPreSignedUrls.type:
        return { ...state, preSignedUrlsIsLoading: true };

      case A.getPreSignedUrls.success:
        return {
          ...state,
          preSignedUrls: action.payload,
          preSignedUrlsIsLoading: false,
        };

      case A.getPreSignedUrls.failure:
        return {
          ...state,
          preSignedUrls: initialState.preSignedUrls,
          preSignedUrlsIsLoading: false,
        };

      case A.setAWSData.type:
        return {
          ...state,
          AWSData: [
            ...state.AWSData.filter(doc => doc.key !== action.payload.key),
            action.payload,
          ],
        };

      case A.removeAWSDataFile.type:
        return {
          ...state,
          AWSData: [
            ...state.AWSData.filter(file => file.key !== action.payload),
          ],
        };

      case A.clearAWSData.type:
        return {
          ...state,
          AWSData: initialState.AWSData,
          AWSDataIsSet: initialState.AWSDataIsSet,
        };

      case A.setUnloadedFilesExist.type:
        return { ...state, unloadedFilesExist: action.payload };

      default:
        return state;
    }
  },
};
