export default {
  container: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 12,
    justifyContent: 'center',
    position: 'absolute',
    width: 205,
  },
};
