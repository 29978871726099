import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';

const ErrorField = ({ classes, error }) => <span className={classes.errorText}>{error}</span>;

ErrorField.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  error: PropTypes.string,
};

export default compose(withStyles(styles), memo)(ErrorField);
