import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

const Faq = ({ classes }) => (
  <Grid container justify="center">
    <Grid container alignItems="center" justify="center" className={classes.heading}>
      <span>Frequently Asked Questions</span>
    </Grid>

    <Grid container item xs={12} sm={12} md={8} lg={8} justify="left" className={classes.textContainer}>
      <span className={classes.sectionHeading}><b>General</b></span>
      <p className={classes.text}>
        <b>What is NOW?</b>
        <br />
        <br />
        NonOpWells (NOW) is a platform to buy and sell non-operated working interests in oil and gas wellbores. We specialize in non-op AFE sales which E&P owners intend to sell instead of the costly alternative, non-consenting.
      </p>

      <p className={classes.text}>
        <b>Are we a broker-dealer?</b>
        <br />
        <br />
        We’ve partnered with Pickering Energy Partners (PEP Advisory, LLC) to ensure transactions on our platform are compliant with FINRA and the SEC. All activity on the platform is supervised by PEP Advisory and its registered representatives.
      </p>

      <span className={classes.sectionHeading}><b>Onboarding & Registration</b></span>
      <p className={classes.text}>
        <b>What is the registration process?</b>
        <br />
        <br />
        Each company registering for NOW will need to appoint an account manager to onboard your entity. The account manager must be a duly authorized signer permitted to sign and transact on behalf of your company. Our onboarding wizard will guide you through each step, but please be prepared to enter verification and regulatory details for your business, officers, beneficial owners, and authorized signers. Once your company is approved, additional users can register using an expedited process.
      </p>

      <p className={classes.text}>
        <b>Who is eligible to participate?</b>
        <br />
        <br />
        Our platform is limited to US-domiciled institutions and legal entities. No retail investors are permitted. Most participants will be E&P companies, energy investment funds, energy private equity, or other legal entities which currently hold or have the ability to acquire working interests. Generally speaking, it is critical that your company and any authorized users be sophisticated, knowledgeable, and actively participating or investing in the exploration and production of oil & gas interests.
      </p>

      <p className={classes.text}>
        <b>Why aren’t individual and/or accredited investors allowed to participate on the platform?</b>
        <br />
        <br />
        The risks inherent in buying working interests in oil & gas wellbores are complex. Because most of our listings will only allow for short windows of diligence, NOW has determined that individuals are less suitable for investments of this type. We take great pride in protecting the interests of potential sellers and buyers.
      </p>

      <span className={classes.sectionHeading}><b>Process & Format</b></span>
      <p className={classes.text}>
        <b>What is the sales format?</b>
        <br />
        <br />
        All listings are sealed-bid auction format. Each seller will choose a bid window for which the listing will remain open and a minimum bid which a bidder will need to bid at or above in order to participate in the auction. For AFE sales, the bid window will generally be less than thirty days. The minimum bid is paid as a premium on top of any capital commitment the buyer would be responsible for via AFE. All bids are binding once entered.
      </p>

      <p className={classes.text}>
        <b>How long will it take for a listing to go-live on the platform?</b>
        <br />
        <br />
        If the seller has all relevant property details, a listing should take between 5-10 minutes to create. NOW and PEP Advisory will review the listing for completeness (but not accuracy), and approval will come in less than 24 hours. Under certain circumstances when time is of the essence, a seller can contact us to request an expedited/priority approval.
      </p>

      <p className={classes.text}>
        <b>Are bids binding?</b>
        <br />
        <br />
        Yes, all bids are binding and once submitted cannot be rescinded.
      </p>

      <p className={classes.text}>
        <b>What diligence information will be available for review?</b>
        <br />
        <br />
        {/* TODO: include info here regarding minimum bid carry ? */}
        A seller must provide basic information about each wellbore including location, operator, landing zones/tvd, working interest, net revenue interest, net AFE amount (for AFE sales) and minimum bid. Additionally, the seller must provide a draft copy of the closing documents which the winning bidder will be executing upon close. It is up to the seller’s discretion what, if any, additional diligence documents will be available for bidders to review prior to making a bid.
      </p>

      <p className={classes.text}>
        <b>Will title information be available to review prior to bidding?</b>
        <br />
        <br />
        Sellers are encouraged to provide as much relevant information as possible for buyers to review in each listing. However, each seller has full discretion regarding what diligence documents will be available. Each buyer will have varying degrees of comfortability, but we encourage all buyers to perform title diligence prior to submitting a binding bid.
      </p>

      <p className={classes.text}>
        <b>What is the closing process?</b>
        <br />
        <br />
        Transactions on NOW are all sign-and-close
        <br />
        <br />
        <li className={classes.list}>The seller has full discretion to choose any conforming bid as the winning bid, regardless of highest bid.</li>
        <li className={classes.list}>Once a seller has chosen the winning bid, that buyer will be notified to initiate the closing process via escrow.</li>
        <li className={classes.list}>We have integrated escrow.com into the platform for an efficient and semi-automated closing process, but ultimately the seller and buyer can elect to use any escrow provider they choose. If mutually agreed to by both parties, seller and buyer can choose any alternative closing format.</li>
        <li className={classes.list}>Users will be directed to escrow.com via the platform and will need to create an account prior to completing first transaction.</li>
        <li className={classes.list}>Seller will provide digital copy of the closing document for buyer to execute.</li>
        {/* TODO: include info here regarding bid carry ? */}
        <li className={classes.list}>Buyer will send winning bid amount plus closing fees to escrow.com (or escrow provider of seller and buyer’s choice).</li>
        <li className={classes.list}>Seller will mail original executed documents to buyer.</li>
        <li className={classes.list}>Buyer reviews executed documents and confirms receipt, upon which the funds in escrow will be released to seller and PEP Advisory.</li>
        <li className={classes.list}>Seller and broker confirm receipt of funds and buyer is then responsible for recording title transfer with appropriate local courthouse.</li>
        <li className={classes.list}>Should seller and buyer elect to use outside escrow agent, parties must allow PEP Advisory to maintain contact with escrow agent as funds will flow in the same order as escrow.com process.</li>
      </p>

      <p className={classes.text}>
        <b>Who is escrow.com?</b>
        <br />
        <br />
        Escrow.com is prominent provider of online escrow services and is fully licensed in 48 states. For more information visit escrow.com.
      </p>

      <p className={classes.text}>
        <b>How do you ensure buyers are able to fund transactions?</b>
        <br />
        <br />
        Each buyer will submit relevant funding source details and must allow NOW to contact its bank or backer to confirm the requested bid allowance and supporting documentation. The NOW platform tracks a buyer’s outstanding bids and potential liabilities to ensure no buyer can bid more than available liquidity. Buyers are always free to request a bid limit increase.
      </p>

      <span className={classes.sectionHeading}><b>Fees & Commissions</b></span>
      <p className={classes.text}>
        <b>What is the fee structure?</b>
        <br />
        <br />
        There are no fees or commissions charged to the seller. The winning bidder on a listing will pay a 3% commission on the net AFE value. This commission is subject to change.
      </p>

      <p className={classes.text}>
        <b>Are there any additional fees aside from the commission?</b>
        <br />
        <br />
        If seller and buyer agree to use escrow.com for closing a transaction, that cost is included in the commission/closing fee. However, should the seller and buyer of a transaction elect to use their own preferred escrow agents, seller and buyer would be responsible for any outside closing fees.
      </p>

      <span className={classes.sectionHeading}><b>Unanswered Questions</b></span>
      {/* TODO: [DRY][MAINTAINABILITY] get the email and phone number dynamically from the server
      // so they are automatically maintained if/when these constants change or should be different between
      // different environments. */}
      <p className={classes.text}>
        If you have additional questions that we haven’t answered here, please reach out to us by emailing us at contact@nonopwells.com, calling 832-900-3055, or through our
        {' '}
        <a href="/contact-us">contact us</a>
        {' '}
        portal.
      </p>
    </Grid>
  </Grid>
);

Faq.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), memo)(Faq);
