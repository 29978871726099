import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, connect } from 'react-redux';

import PropTypes from 'prop-types';
import { compose } from 'redux';

// components
import Pagination from 'now-frontend-shared/components/Pagination';
import Search from 'now-frontend-shared/components/inputs/Search';
import ControlledSelect from 'now-frontend-shared/components/Select/ControlledSelect';
// import Button from 'now-frontend-shared/components/Button';
import Spinner from 'now-frontend-shared/components/Spinner';

// layouts
import BidLayout from 'layouts/BidLayout';

// helpers
import { createQuery, DEFAULT_QUERY, removeQueryParam } from 'now-frontend-shared/utils/helpers';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  Chip,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// styles
import { getBidedProperties, getBidsStatuses, clearState } from 'store/actions/bidedPropertiesActions';
import styles from './styles';

import { BooleanString, BooleanStringTitle } from 'now-shared/enums/boolean-string';

// store

const archivedOptions = [
  {
    id: '',
    title: '-',
  },
  {
    id: BooleanString.True,
    title: BooleanStringTitle[BooleanString.True],
  },
  {
    id: BooleanString.False,
    title: BooleanStringTitle[BooleanString.False],
  },
];

/**
 * This is for the buyer: My Bids
 */
const BidedProperties = ({
  classes, history, bidsList, bidsMeta, statuses, pending,
}) => {
  const dispatch = useDispatch();
  const sideNavContentRef = useRef();
  const listingsFilterLabelRef = useRef();
  const listingsRef = useRef();
  const filterButtonRef = useRef();
  const topLevelContainerRef = useRef();
  const sideNavRef = useRef();
  const navOpen = useRef(false);
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const selectedStatus = useRef();
  const selectedArchiveOption = useRef();

  useEffect(() => {
    dispatch(getBidsStatuses());

    return () => dispatch(clearState());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch({ type: getBidedProperties.type, payload: history.location.search || DEFAULT_QUERY });
  }, [dispatch, history, history.location.search]);

  const setQueryValue = (queryName, queryValue) => {
    if (queryName && queryValue) {
      const search = createQuery(history, queryName, queryValue);
      history.push(search);
    } else if (queryName && !queryValue) {
      const search = removeQueryParam(history, queryName);
      history.push(search);
    } else {
      history.push(DEFAULT_QUERY);
    }
  };

  const openNav = () => {
    const sideNav = sideNavRef?.current;
    const sideNavContent = sideNavContentRef?.current;
    const filterButton = filterButtonRef?.current;
    const listingsFilterLabel = listingsFilterLabelRef?.current;
    if (!navOpen.current) {
      listingsFilterLabel.style.setProperty('margin', '0px 0px 0px 400px');
      sideNav.style.setProperty('width', '400px');
      sideNavContent.style.setProperty('visibility', 'visible');
      sideNavContent.style.setProperty('opacity', 1);
      sideNavContent.style.setProperty('z-index', 3);
      filterButton.style.setProperty('opacity', 0);
      navOpen.current = !navOpen.current;
    }
  };

  const closeNav = () => {
    const sideNav = sideNavRef?.current;
    const sideNavContent = sideNavContentRef?.current;
    const filterButton = filterButtonRef?.current;
    const listingsFilterLabel = listingsFilterLabelRef?.current;
    if (navOpen.current) {
      listingsFilterLabel.style.setProperty('margin', '0px 0px 0px 0px');
      sideNav.style.setProperty('width', '0px');
      sideNavContent.style.setProperty('opacity', 0);
      setTimeout(() => {
        sideNavContent.style.setProperty('visibility', 'hidden');
      }, 500);
      sideNavContent.style.setProperty('z-index', 1);
      filterButton.style.setProperty('opacity', 1);
      navOpen.current = !navOpen.current;
    }
  };

  const handleAccordionChange = panel => (event, isExpanded) => {
    setAccordionExpanded(isExpanded ? panel : false);
  };

  if (!bidsList) return <Spinner wrapped />;

  return (
    <>
      <Box sx={{ display: 'grid', margin: '0px 0px 0px 0px', maxWidth: '100%' }}>
        {/* side nav content */}
        <Box
          ref={sideNavContentRef}
          className={classes.sideNavContent}
        >
          <Box sx={{
            justifySelf: 'end',
          }}
          >
            <CloseIcon style={{ cursor: 'hand', marginRight: '10px' }} onClick={closeNav} />
          </Box>
          <Box sx={{ paddingBottom: '14px' }}>Filters:</Box>
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              Property Name/ID
            </AccordionSummary>
            <AccordionDetails>
              <Search setQueryValue={setQueryValue} placeholder="" />
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} expanded={accordionExpanded === 'status'} onChange={handleAccordionChange('status')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              Status
            </AccordionSummary>
            <AccordionDetails>
              <Box className={classes.statusFilter}>
                <FormControlLabel
                  label="All"
                  checked={!selectedStatus.current}
                  control={(
                    <Checkbox
                      onChange={() => { selectedStatus.current = undefined; setQueryValue('status'); }}
                      className={classes.checkbox}
                    />
                  )}
                />
                {statuses.map((status, index) => (
                  <FormControlLabel
                    key={index}
                    label={status.title}
                    checked={status.id === selectedStatus.current}
                    control={(
                      <Checkbox
                        onChange={() => { selectedStatus.current = status.id; setQueryValue('status', status.id); }}
                        className={classes.checkbox}
                      />
                    )}
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} expanded={accordionExpanded === 'archived'} onChange={handleAccordionChange('archived')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              Archived
            </AccordionSummary>
            <AccordionDetails>
              <Box className={classes.statusFilter}>
                {archivedOptions.map((archiveOption, index) => (
                  <FormControlLabel
                    key={index}
                    label={archiveOption.title}
                    checked={archiveOption.id === selectedArchiveOption.current}
                    control={(
                      <Checkbox
                        onChange={() => { selectedArchiveOption.current = archiveOption.id; setQueryValue('archived', archiveOption.id); }}
                        className={classes.checkbox}
                      />
                    )}
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        {/* listings label and filter chips */}
        <Box
          ref={listingsFilterLabelRef}
          className={classes.labelAndChips}
        >
          <div>
            <span style={{ fontSize: '24px', padding: '0px 10px 0px 0px' }}>My Bids</span>
            <Box sx={{ paddingTop: '5px' }}>
              {selectedStatus.current
              && (
                <Chip
                  label={
                    statuses.filter(status => status.id === selectedStatus.current)[0]?.title
                  }
                  variant="outlined"
                  onDelete={
                    () => {
                      selectedStatus.current = undefined;
                      setQueryValue('status');
                    }
}
                  style={{ margin: '0px 5px' }}
                />
              )}
              {selectedArchiveOption.current
              && (
                <Chip
                  label={
                    archivedOptions.filter(archive => archive.id === selectedArchiveOption.current)[0]?.title
                  }
                  variant="outlined"
                  onDelete={
                    () => {
                      selectedArchiveOption.current = undefined;
                      setQueryValue('archived');
                    }
}
                  style={{ margin: '0px 5px' }}
                />
              )}
            </Box>
          </div>
        </Box>

        {/* listings and map */}
        <Box
          ref={topLevelContainerRef}
          className={classes.listingsAndMap}
        >
          <Box ref={sideNavRef} className={classes.sidenav} />
          <Box ref={listingsRef} sx={{ gridColumnStart: 2, gridColumnEnd: 3 }}>
            {bidsList?.length === 0 ? (
              <Box sx={{ display: 'grid', padding: '0px 10px', width: '100%' }}>
                <span className={classes.message}>You don’t have any bids.</span>
                <Box
                  ref={filterButtonRef}
                  className={classes.filterButton}
                >
                  <Button
                    style={{
                      opacity: 0,
                    }}
                    variant="contained"
                  >
                    Filter
                  </Button>
                </Box>
              </Box>
            ) : (
              <>
                <Box sx={{
                  display: 'grid', gridAutoFlow: 'column', padding: '0px 15px', width: '100%',
                }}
                >
                  <Box
                    ref={filterButtonRef}
                    className={classes.filterButton}
                  >
                    <Button
                      className={classes.filterButtonStyle}
                      variant="contained"
                      onClick={openNav}
                    >
                      Filter
                    </Button>
                  </Box>
                  <Box sx={{ width: '175px', justifySelf: 'end' }}>
                    <ControlledSelect
                      options={[
                        { title: 'Bid Date', id: 'createdAt' },
                        { title: 'Bid Amount, USD', id: 'amount' },
                        { title: 'Bid Amount, Carry', id: 'carryPercentage' },
                      ]}
                      placeholder="Sort by"
                      name="order"
                      setQueryValue={setQueryValue}
                    />
                  </Box>
                </Box>
                <Box className={classes.listings}>
                  {bidsList?.map(bid => (
                    <BidLayout key={bid.id} bid={bid} />
                  ))}
                </Box>
              </>
            )}
          </Box>
        </Box>

      </Box>
      {bidsMeta?.totalItems > 10 && (
        <Grid container justify="center">
          <Pagination count={bidsMeta.totalPages} itemsPerPage={10} />
        </Grid>
      )}
    </>
  );
};

BidedProperties.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  bidsList: PropTypes.array,
  bidsMeta: PropTypes.object,
  statuses: PropTypes.array,
};

export default compose(
  connect(({ bidedProperties }) => ({
    bidsList: bidedProperties.bidsList?.items,
    bidsMeta: bidedProperties.bidsList?.meta,
    statuses: bidedProperties.statuses || [],
    pending: bidedProperties.pending,
  })),
  withStyles(styles),
)(BidedProperties);
