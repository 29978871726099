import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

// styles
import styles from './styles';

const PlaceBidButton = ({ classes, disabled }) => (
  <>
    {disabled ? (
      <div className={cx(classes.button, classes.disabled)}>Place Bid</div>
    ) : (
      <Button type="submit" variant="contained" className={classes.button}>
        Place Bid
      </Button>
    )}
  </>
);

PlaceBidButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
};

export default compose(withStyles(styles), memo)(PlaceBidButton);
