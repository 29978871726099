import { asyncAction, action } from 'now-frontend-shared/utils/actions';

// async actions
export const sendResetPasswordLink = asyncAction('auth/SEND_RESET_PASSWORD_LINK');
export const sendBankInformation = asyncAction('auth/SEND_BANK_INFORMATION');
export const sendSellerAgreement = asyncAction('auth/SEND_SELLER_AGREEMENT');
export const getBuyerAgreementTemplate = asyncAction('auth/GET_BUYER_AGREEMENT_TEMPLATE');
export const sendBuyerAgreement = asyncAction('auth/SEND_BUYER_AGREEMENT');
export const signUpCompleted = asyncAction('auth/SIGN_UP_COMPLETED');
export const changePassword = asyncAction('auth/CHANGE_PASSWORD');
export const updateAccount = asyncAction('auth/UPDATE_ACCOUNT');
export const confirmEmail = asyncAction('auth/CONFIRM_EMAIL');
export const signIn = asyncAction('auth/SIGN_IN');
export const sendContactUs = asyncAction('contactUs/SEND_CONTACT_US');
export const updateBuyerAgreement = asyncAction('company/UPDATE_BUYER_AGREEMENT');
export const updateSellerAgreement = asyncAction('company/UPDATE_SELLER_AGREEMENT');
export const updateBankInformation = asyncAction('company/UPDATE_BANK_INFORMATION');

// sync actions
export const setResetPasswordToken = action('auth/SET_RESET_PASSWORD_TOKEN');
export const clearModalFlags = action('auth/CLEAR_MODAL_FLAGS');
export const setIsAuthorized = action('auth/SET_IS_AUTHORIZED');
export const removeUserData = action('auth/REMOVE_USER_DATA');
export const setUserDataRemoved = action('auth/SET_USER_DATA_REMOVED');
export const changeAuthInfo = action('auth/CHANGE_AUTH_INFO');
export const setUserData = action('auth/SET_USER_DATA');
