import newTheme from 'themes/theme';

export const desktopContentMaxWidthOld = '1400px';
export const desktopContentMaxWidth = '1500px';
export const desktopContentMinWidthExclusiveInPixels = 1200;
export const desktopContentMinWidthExclusive = `${desktopContentMinWidthExclusiveInPixels}px`;

export const mainPageContentBackgroundColor = newTheme.palette.primary.contrastText;

export const headerNewHeight = 74;
export const headerOldHeight = 90;

export const footerNewHeight = 72;
export const footerOldHeight = 70;
