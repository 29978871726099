import {
  fork, takeEvery, cancel, select, all,
} from 'redux-saga/effects';
import { matchPath } from 'react-router-dom';
import { LOCATION_CHANGE } from 'connected-react-router';
import queryString from 'query-string';

import mainPageSagas from './mainPageSagas';
import signUpSagas from './signUpSagas';
import buyerAgreementSagas from './buyerAgreementSagas';
import sellerAgreementSagas from './sellerAgreementSagas';
import bankInformationSagas from './bankInformationSagas';
import updateAccountSagas from './updateAccountSagas';
import contactUsSagas from './contactUsSagas';
import settingsSagas from './settingsSagas';
import propertiesSagas from './propertiesSagas';
import myPropertiesSagas from './myPropertiesSagas';
import createPropertySagas from './createPropertySagas';
import propertyViewSagas from './propertyViewSagas';
import listingAnalyticsSagas from './listingAnalyticsSagas';
import bidedPropertiesSagas from './bidedPropertiesSagas';
import propertyBidsListSagas from './propertyBidsListSagas';
import editPropertySagas from './editPropertySagas';
import mapSagas from './mapSagas';
import myBidAllowanceSagas from './myBidAllowanceSagas';
import companiesSagas from './companiesSagas';
import statesSagas from './statesSagas';
import userSagas from './userSagas';
import businessFilesSagas from './businessFilesSagas';
import officerFilesSagas from './officerFilesSagas';
import beneficialOwnerFilesSagas from './beneficialOwnerFilesSagas';
import accountManagerFilesSagas from './accountManagerFilesSagas';
import { BUSINESS_ADDRESS_ROUTE } from 'constants/registrationFlow';

const routes = [
  { path: '/', fns: [mainPageSagas] },
  { path: '/sign-up', fns: [signUpSagas, companiesSagas, statesSagas] },
  { path: '/sign-up-old', fns: [signUpSagas, companiesSagas] },
  { path: '/buyer-agreement', fns: [buyerAgreementSagas, companiesSagas] },
  { path: '/seller-agreement', fns: [sellerAgreementSagas, companiesSagas] },
  { path: '/bank-information', fns: [bankInformationSagas] },
  { path: '/update-account', fns: [updateAccountSagas] },
  { path: '/contact-us', fns: [contactUsSagas] },
  { path: '/settings', fns: [settingsSagas] },
  { path: '/create-listing', fns: [companiesSagas, createPropertySagas] },
  { path: '/listings', fns: [propertiesSagas] },
  { path: '/my-listings', fns: [myPropertiesSagas] },
  { path: '/listings/:id', fns: [propertyViewSagas, listingAnalyticsSagas] },
  { path: '/listings/:id/analytics', fns: [listingAnalyticsSagas] },
  { path: '/listings/:id/make-a-bid', fns: [propertyViewSagas] },
  { path: '/my-bids', fns: [bidedPropertiesSagas] },
  { path: '/listings/:id/bids', fns: [propertyBidsListSagas] },
  { path: '/listings/:id/edit', fns: [companiesSagas, editPropertySagas] },
  { path: '/map', fns: [mapSagas] },
  { path: '/buyer-dashboard', fns: [myBidAllowanceSagas, propertiesSagas] },
  { path: '/increase-bid-allowance', fns: [myBidAllowanceSagas] },
  { path: '/seller-dashboard', fns: [myPropertiesSagas] },
  { path: '/company-dashboard', fns: [companiesSagas] },
  {
    path: '/registration',
    fns: [companiesSagas, userSagas],
  },
  {
    path: BUSINESS_ADDRESS_ROUTE,
    fns: [statesSagas, companiesSagas, userSagas, businessFilesSagas, officerFilesSagas, beneficialOwnerFilesSagas, accountManagerFilesSagas],
  },
];

let task;

function* routeMatcher({ payload: { location } }) {
  for (const route of routes) {
    const match = matchPath(location.pathname, {
      exact: 'exact' in route ? route.exact : true,
      strict: false,
      ...route,
    });
    if (task) yield cancel(task);
    const query = yield select(({ router }) => queryString.parse(router.location.search, { arrayFormat: 'bracket' }));
    if (match !== null) {
      task = yield all(
        route.fns.map(fn => fork(fn, { ...location, query }, match)),
      );
      break;
    }
  }
}

export default function* navigator() {
  /**
   * We set this to false because of a synchronization issue
   * that makes it so some initial requests made when a route first loads
   * do not get handled by the appropriate saga because the saga is not
   * running yet.
   *
   * By starting and running all the sagas all the time, we don't run into this
   * issue when the route location changes, because all the sagas are always
   * running.
   *
   * TODO: [PERFORMANCE] is there a significant performance or other reason to
   * run the sagas separately depending on the route instead of
   * running all of them all the time?
   * - 03/12/2022 Ross: Tyler suggested to me that it's typcial to run all sagas all the time
   *   and it doesn't impact performance in a significant way.
   */
  const isSwitchingSagasOnRouteLocationChange = false;

  if (isSwitchingSagasOnRouteLocationChange) {
    yield takeEvery(LOCATION_CHANGE, routeMatcher);
  } else {
    // extract a distinct list of all the sagas from the route-sagas map above
    const allSagas = new Set();
    routes.forEach(route => {
      route.fns.forEach(saga => {
        allSagas.add(saga);
      });
    });
    yield all(Array.from(allSagas).map(saga => fork(saga)));
  }
}
