import { textSecondary } from '../../../../themes/colors';

export default {
  wrapper: {
    margin: '12px 0',
  },
  labelContainer: {
    padding: '10px 0',

    lineHeight: '19px',
    fontWeight: 'bold',
    fontSize: '16px',
    color: textSecondary,
  },
};
