import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';
import Success from '../../components/Success';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

function ApprovalStatus({ user, classes }) {
  return (
    user?.approvalStatus?.title !== 'rejected' ? (
      <div>
        <Success
          message="Your application is currently being reviewed. Be on the lookout for an email within the next 2 business days."
        >
          <Button className={classes.homeButton} href="/" variant="outlined">
            TAKE ME HOME
          </Button>
        </Success>
      </div>
    ) : (
      <div className={classes.root}>
        <Grid
          style={{ padding: '100px 0' }}
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Typography variant="h2" className={classes.title}>
            Application Update
          </Typography>
          <Typography variant="body1" className={classes.text}>
            Unfortunately your application has been rejected. Please contact us if you have any questions or concerns.
          </Typography>
          <Button className={classes.homeButton} href="/contact-us" variant="outlined">
            CONTACT US
          </Button>
        </Grid>
      </div>
    )
  );
}

ApprovalStatus.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default compose(
  connect(({ auth }) => ({
    user: auth.user,
  })),
  withStyles(styles),
)(ApprovalStatus);
