import GA4React from 'ga-4-react';
import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
// configure store function and history
import { store, history } from './store';
// modals context provider
import { ModalProvider } from 'now-frontend-shared/hooks/useModal';
// service worker
import * as serviceWorker from './serviceWorker';
// theme
import theme from 'now-frontend-shared/themes/theme';
// root saga
import rootSaga from './store/sagas';
// app component
import App from './pages';
// styles
import 'now-frontend-shared/styles/index.css';

import { ScrollToTopController } from 'now-frontend-shared/components/ScrollToTopController';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV_NAME,
});

async function init() {
  const gaCode = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
  if (gaCode) {
    try {
      const ga4React = new GA4React(gaCode);
      await ga4React.initialize();
    } catch (e) {
      console.error(e);
      // TODO: [UX] notify user that they can't use the site unless they allow GA tracking
    }
  }
  store.runSaga(rootSaga);

  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <ModalProvider>
            <ScrollToTopController />
            <App />
          </ModalProvider>
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root'),
  );

  serviceWorker.unregister();
}

init();
