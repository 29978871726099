import { action, asyncAction } from 'now-frontend-shared/utils/actions';

// async actions
export const setNotificationValue = asyncAction('notifications/SET_NOTIFICATION_VALUE');
export const changeUserBasins = asyncAction('notifications/CHANGE_USER_BASINS');
export const updatePassword = asyncAction('settings/UPDATE_PASSWORD');

// sync actions
export const getAllBasins = action('notifications/GET_ALL_BASINS');
export const setDefaultBasins = action('notifications/SET_DEFAULT_BASINS');
export const setUserNotificationsData = action('notifications/SET_USER_NOTIFICATIONS_DATA');
export const addUserBasin = action('notifications/ADD_USER_BASIN');
export const removeUserBasin = action('notifications/REMOVE_USER_BASIN');
export const setBasinPendingValue = action('notifications/SET_BASIN_PENDING_VALUE');
export const removeSettingsData = action('notifications/REMOVE_SETTINGS_DATA');
export const setPasswordIsUpdated = action('settings/SET_PASSWORD_IS_UPDATED');
