import { primaryDark } from '../../themes/colors';

export default {
  input: {
    '& .react-date-picker__wrapper': {
      flexDirection: 'row-reverse',
      flexGrow: 'unset',
      padding: '2px 6px',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '16px',
      borderRadius: '4px',
      borderColor: 'rgba(0, 0, 0, 0.2)',
      color: 'rgba(0, 0, 0, 0.87)',

      '& .react-date-picker__inputGroup': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 0,
      },

      '& .react-date-picker__clear-button': {
        marginLeft: 'auto',

        '& svg': {
          stroke: primaryDark,
          transition: 'stroke 0.15s ease-in-out',
        },

        '&:hover': {
          '& svg': {
            stroke: '#EB5757',
          },
        },
      },
    },
  },
};
