import React, {
  memo,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// icons
import IntegratedLogo from 'now-frontend-shared/assets/icons/2022-06-01_high_res_logo.png';

// components
import BurgerMenu from 'components/BurgerMenu';
import HeaderMenu from '../HeaderMenu';
import Button from 'now-frontend-shared/components/Button';

// modals
import SignInModal from 'components/Modals/SignInModal';

// custom hooks
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

const Header = ({
  classes,
  isAuthorized,
}) => {
  const { setModal } = useModalSetter();
  const history = useHistory();

  const handleModalOpen = useCallback(() => {
    setModal(<SignInModal />);
  }, [setModal]);

  const handleOpenSignUpPage = () => {
    history.push({ pathname: '/sign-up' });
  };

  return (
    <Grid
      component="header"
      container
      justify="center"
      className={classes.header}
    >
      <Grid
        container
        alignItems="center"
        justify="space-between"
        className={classes.container}
      >
        <Grid item className={classes.burgerMenu}>
          <BurgerMenu />
        </Grid>

        <Grid item className={classes.logoContainer}>
          <img src={IntegratedLogo} alt="NOW-PEP Logo" className={classes.imgLogo} />
        </Grid>

        <Grid item className={classes.buttonContainer}>
          {isAuthorized ? (
            <HeaderMenu />
          ) : (
            <>
              <Grid item className={classes.button}>
                <Button
                  label="Login"
                  buttonColor="white"
                  onClick={handleModalOpen}
                  className={classes.button}
                />
              </Grid>
              <Grid item className={classes.button}>
                <Button
                  label="Register"
                  buttonColor="primaryMain"
                  onClick={handleOpenSignUpPage}
                  className={classes.button}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isAuthorized: PropTypes.bool,
};

Header.defaultProps = {
  isAuthorized: undefined,
};

export default compose(
  connect(({ auth }) => ({
    isAuthorized: auth.authInfo.isAuthorized,
  })),
  withStyles(styles),
  memo,
)(Header);
