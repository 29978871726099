import { desktopContentMinWidthExclusive } from 'layouts/styles';
import newTheme from 'themes/theme';

export const newHeaderPaddingLeft = '30px';
export const newHeaderPaddingRight = '41px';

const mediumDeviceHeaderPadding = '85px';
const tinyDeviceHeaderPadding = '20px';

export default {
  header: {
    background: '#F8F8F8',
  },
  newWrapper: {
    height: '100%',
    margin: '0 auto',
  },
  newLogoContainer: {
    marginRight: '86px',
    marginLeft: '-10px',
    marginTop: '8px',

    [`@media screen and (max-width: ${desktopContentMinWidthExclusive})`]: {
      position: 'fixed',
      padding: `0 ${newHeaderPaddingLeft}`,
      right: 0,
    },

    '@media screen and (max-width: 600px)': {
      padding: `0 ${tinyDeviceHeaderPadding}`,
    },
  },
  burgerMenu: {
    display: 'none',

    '@media screen and (max-width: 980px)': {
      display: 'block',
    },
  },
  newBurgerMenu: {
    display: 'none',

    [`@media screen and (max-width: ${desktopContentMinWidthExclusive})`]: {
      display: 'block',
      position: 'fixed',
      left: 0,
      padding: `0 ${mediumDeviceHeaderPadding}`,
    },

    '@media screen and (max-width: 600px)': {
      padding: `0 ${tinyDeviceHeaderPadding}`,
    },
  },
  newLinkContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',

    [`@media screen and (max-width: ${desktopContentMinWidthExclusive})`]: {
      display: 'none',
    },
  },
  newLink: {
    color: newTheme.palette.text.primary,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    textDecoration: 'none',
    textTransform: 'none',
    letterSpacing: '0.15px',
    margin: '0 8px',
  },
  newActiveLink: {
    fontWeight: '700',
    textDecoration: 'underline',
  },
  imgLogo: {
    height: '64px',
  },
};
