import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from '../styles';

const EmptyIcon = ({ classes }) => <span className={classes.icon} />;

EmptyIcon.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), memo)(EmptyIcon);
