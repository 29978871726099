import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from '../../styles';

const LabelLayout = ({
  classes, children, label, description, isRequired, name, space, withoutSlash, multiple,
}) => {
  const renderLabel = () => {
    if (description && withoutSlash) {
      return (
        <>
          <label htmlFor={name} className={classes.label}>
            {label}
            {' '}
            {isRequired && <span>*</span>}
          </label>
          <label htmlFor={name} className={classes.description}>
            {description}
          </label>
        </>
      );
    }

    if (description) {
      return (
        <>
          <label htmlFor={name} className={classes.label}>
            {label}
            {' '}
            /
          </label>
          <label htmlFor={name} className={classes.description}>
            {description}
            {' '}
            {isRequired && <span>*</span>}
          </label>
        </>
      );
    }

    return (
      <label htmlFor={name} className={classes.label}>
        {label}
        {' '}
        {isRequired && <span>*</span>}
      </label>
    );
  };

  return (
    <Grid container alignItems="flex-start" className={classes.wrapper}>
      <Grid
        container
        item
        xs={12}
        sm={10}
        md={space === 'medium' ? 6 : space === 'small' ? 4 : 5}
        lg={space === 'medium' ? 6 : space === 'small' ? 4 : 5}
        direction="column"
        justify="center"
        className={multiple ? classes.multiple : classes.labelContainer}
      >
        {renderLabel()}
      </Grid>

      <Grid
        item
        xs={12}
        sm={10}
        md={space === 'medium' ? 6 : space === 'small' ? 8 : 7}
        lg={space === 'medium' ? 6 : space === 'small' ? 8 : 7}
        style={{ width: '100%' }}
      >
        {children}
      </Grid>
    </Grid>
  );
};

LabelLayout.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  isRequired: PropTypes.bool,
  name: PropTypes.string,
  space: PropTypes.oneOf(['small', 'medium']),
  withoutSlash: PropTypes.bool,
};

export default compose(withStyles(styles), memo)(LabelLayout);
