import { asyncAction, action } from 'now-frontend-shared/utils/actions';

// async actions
export const getPreSignedUrls = asyncAction(
  'businessFiles/GET_PRE_SIGNED_URLS',
);

// sync action
export const reset = action('businessFiles/RESET');
export const setAWSData = action('businessFiles/SET_AWS_DATA');
export const removeAWSDataFile = action('businessFiles/REMOVE_AWS_DATA_FILE');
export const clearAWSData = action('businessFiles/CLEAR_AWS_DATA');
export const setUnloadedFilesExist = action('businessFiles/SET_UNLOADED_FILES_EXIST');
