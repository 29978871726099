import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import styles from './styles';
import { updateCompany } from 'store/actions/companyActions';
import { StyledTextField } from 'components/inputs/styledTextField';
import { validateStockExchangeMic, validateStockTicker } from 'now-frontend-shared/validation/stock-validation';
import { trimmed } from 'now-shared/validation/validation-rules';
import { navigateToNextPage, navigateToPreviousPage } from 'constants/registrationFlow';

function PublicCompanyView({ classes, company }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [touched, setTouched] = React.useState({
    stockTicker: false,
    stockExchange: false,
  });
  const setTouchedDelay = 0;
  const setTouchedDelayed = (fieldName, newValue) => {
    setTimeout(() => setTouched(prev => ({
      ...prev,
      [fieldName]: newValue,
    })), setTouchedDelay);
  };

  const stockTickerValidation = validateStockTicker(company.stockTicker) || trimmed(company.stockTicker);
  const stockExchangeValidation = validateStockExchangeMic(company.stockExchange) || trimmed(company.stockExchange);
  const verifyStockValues = !!company.stockTicker && !!company.stockExchange && !stockExchangeValidation && !stockTickerValidation;

  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ margin: 'auto', maxWidth: '627px' }}
      >
        <Typography variant="h1" component="div" className={classes.title}>
          Company Detail
        </Typography>
        <Typography variant="h5" component="div" className={classes.subTitle}>
          Are you a publicly traded company?
        </Typography>
        {company.isPublicCompany && (
          <div className={classes.inputContainer}>
            <Typography
              variant="body1"
              component="div"
              className={classes.textTitle}
            >
              Please add the following information:
            </Typography>
            <StyledTextField
              fullWidth
              id="standard-basic"
              className={classes.item}
              label="Stock Symbol"
              required
              variant="outlined"
              onFocus={() => setTouchedDelayed('stockTicker', false)}
              onBlur={() => setTouchedDelayed('stockTicker', true)}
              error={!!(touched.stockTicker && stockTickerValidation)}
              helperText={(touched.stockTicker && stockTickerValidation) || '\u00a0'}
              value={company.stockTicker}
              onChange={e => dispatch(
                updateCompany({
                  value: e.target.value,
                  key: 'stockTicker',
                }),
              )}
            />
            <StyledTextField
              fullWidth
              id="standard-basic"
              className={classes.item}
              label="Listed Exchange"
              required
              value={company.stockExchange}
              variant="outlined"
              onFocus={() => setTouchedDelayed('stockExchange', false)}
              onBlur={() => setTouchedDelayed('stockExchange', true)}
              error={!!(touched.stockExchange && stockExchangeValidation)}
              helperText={(touched.stockExchange && stockExchangeValidation) || '\u00a0'}
              onChange={e => dispatch(
                updateCompany({
                  value: e.target.value,
                  key: 'stockExchange',
                }),
              )}
            />
          </div>
        )}
        <div className={classes.optionContainer}>
          <Button
            className={classes.button}
            variant="outlined"
            onClick={() => {
              // TODO: [BUG] await a new Promise() for this async redux action to resolve successfully, so we don't
              // move on from this view if this request fails for connectivity reasons or other reasons.
              const newValue = false;
              dispatch(
                updateCompany({
                  value: newValue,
                  key: 'isPublicCompany',
                }),
              );
              history.push(navigateToNextPage(history.location.pathname, newValue));
            }}
          >
            No
          </Button>
          <Button
            className={classes.button}
            variant={company.isPublicCompany ? 'contained' : 'outlined'}
            disabled={company.isPublicCompany ? !verifyStockValues : false}
            onClick={() => {
              if (!company.isPublicCompany) {
                dispatch(
                  updateCompany({
                    value: true,
                    key: 'isPublicCompany',
                  }),
                );
              } else {
                dispatch(
                  updateCompany({
                    value: false,
                    key: 'BO25PercentOfCompanyOrMore',
                  }),
                );
                history.push(navigateToNextPage(history.location.pathname, company.isPublicCompany));
              }
            }}
          >
            {company.isPublicCompany ? 'Next' : 'Yes'}
          </Button>
        </div>
        <div className={classes.optionContainer} style={{ marginTop: '50px' }}>
          <Button
            className={classes.backButton}
            variant="text"
            onClick={() => {
              history.push(navigateToPreviousPage(history.location.pathname));
              dispatch(
                updateCompany({
                  value: false,
                  key: 'isPublicCompany',
                }),
              );
            }}
          >
            Back
          </Button>
        </div>
      </Grid>
    </div>
  );
}

PublicCompanyView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(
  connect(({ company }) => ({
    company,
  })),
  withStyles(styles),
)(PublicCompanyView);
