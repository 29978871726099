import { signatureDoesNotMatch } from './signature-validation';
import {
  createFormValidator,
  maxCharCount,
  minCharCount,
  PERSON_FULL_NAME_MAX_LENGTH,
  PERSON_FULL_NAME_MIN_LENGTH,
  required,
  SIGNATURE_MAX_LENGTH,
  SIGNATURE_MIN_LENGTH,
  trimmed,
} from './validation-rules';

export const COMPANY_CERTIFICATION_CERTIFIER_FULL_NAME_MIN_LENGTH = PERSON_FULL_NAME_MIN_LENGTH;

/**
 * NOTE: updates to this must be accompanied by a database migration
 */
export const COMPANY_CERTIFICATION_CERTIFIER_FULL_NAME_MAX_LENGTH = PERSON_FULL_NAME_MAX_LENGTH;

export const COMPANY_CERTIFICATION_SIGNATURE_MIN_LENGTH = SIGNATURE_MIN_LENGTH;

/**
 * NOTE: updates to this must be accompanied by a database migration
 */
export const COMPANY_CERTIFICATION_SIGNATURE_MAX_LENGTH = SIGNATURE_MAX_LENGTH;

/**
 * @type {import('./validation-rules').FieldValidators}
 */
export const certificationFieldValidators = {
  signature: values => [
    required,
    trimmed,
    minCharCount(COMPANY_CERTIFICATION_SIGNATURE_MIN_LENGTH),
    maxCharCount(COMPANY_CERTIFICATION_SIGNATURE_MAX_LENGTH),
    signatureDoesNotMatch(values.printedName),
  ],
};

export const validateCertification = createFormValidator(certificationFieldValidators);
