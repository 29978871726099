import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Checkbox from '@material-ui/icons/CheckBox';
import styles from './styles';
import DropZone, { dropZoneTypes } from 'now-frontend-shared/components/DropZone';
import Spinner from 'now-frontend-shared/components/Spinner';
import {
  getPreSignedUrls,
  removeAWSDataFile,
  setAWSData,
  setUnloadedFilesExist,
} from 'store/actions/officerFilesActions';

import { acceptFileFormats } from 'now-shared/validation/listing-validation';
import Officers from './components/Officers';
import { addOfficer, persistCompanyUpdates, saveFileUpload } from 'store/actions/companyActions';
import { documentTypes } from 'now-shared/helpers/document-types';
import {
  validateCompanyOfficerDetails,
} from 'now-shared/validation/company-officer';
import { formValidationHasErrors } from 'now-shared/validation/validation-rules';
import { navigateToNextPage, navigateToPreviousPage } from 'constants/registrationFlow';

function ListOfficersView({
  classes,
  AWSData,
  getPreSignedUrls,
  preSignedUrls,
  removeAWSDataFile,
  setAWSData,
  setUnloadedFilesExist,
  company,
  companyIsUpdating,
  authUser,
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [dropZoneIsProcessingFile, setDropZoneIsProcessingFile] = useState(false);
  const [dropZoneHasFileNotUploaded, setDropZoneHasFileNotUploaded] = useState(false);

  const uploadIsPending = dropZoneIsProcessingFile || dropZoneHasFileNotUploaded;

  useEffect(() => {
    dispatch(setUnloadedFilesExist(uploadIsPending));
  }, [dispatch, setUnloadedFilesExist, uploadIsPending]);

  const companyId = authUser.company?.id;

  const officersErrors = company.officers.map(officer => validateCompanyOfficerDetails(officer));

  const officerAgreement = authUser.company?.documents?.find(
    v => v.type === documentTypes.OFFICER_CORPORATE_AGREEMENT,
  );

  const hasRequiredData
    = company.officers.length > 0
    && officersErrors.every(errors => !formValidationHasErrors(errors));

  return (
    <div className={classes.root}>
      {companyIsUpdating && <Spinner backdrop />}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ margin: 'auto', maxWidth: '950px' }}
      >
        <Typography variant="h1" component="div" className={classes.title}>
          Identity of Account Manager or Corporate Officer and Authorization Letter
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className={classes.subTitle}
        >
          Please add the following information for your company’s Account Manager and/or Corporate Officer.
          The following information is required for one individual with significant responsibility for managing
          the legal entity. Includes an executive officer or senior manager (e.g., Chief Executive Officer,
          Chief Financial Officer, Chief Operating Officer, Managing Member, General Partner, President,
          Vice President, Treasurer); or any other individual who regularly performs similar functions.
        </Typography>
        <Grid container direction="column" style={{ margin: '20px 0px' }}>
          <Typography
            variant="body1"
            component="div"
            className={classes.officerTitle}
          >
            Add Officer information here:
          </Typography>
          <Officers
            autoFocus
            officersInformation={company.officers}
            officersErrors={officersErrors}
            officerAgreement={officerAgreement}
          />
        </Grid>
        {!officerAgreement && (
          <Button
            className={classes.addMoreButton}
            variant="outlined"
            onClick={() => dispatch(addOfficer())}
          >
            Add another officer
          </Button>
        )}
        <div className={classes.dropZoneContainer}>
          <Typography
            variant="body1"
            component="div"
            className={classes.officerTitle}
            style={{ marginBottom: '20px' }}
          >
            Corporate Resolution or Letter of Authorization which confirms the
            above individual(s) is authorized to operate on behalf of the entity
          </Typography>

          {officerAgreement
            ? (
              <Typography
                variant="body2"
                component="div"
                className={classes.complete}
              >
                Complete
                <Checkbox />
              </Typography>
            )
            : (
              <DropZone
                AWSData={AWSData}
                uploadText="The required documents listed above"
                preSignedUrls={preSignedUrls}
                setAWSData={setAWSData}
                getPreSignedUrls={getPreSignedUrls}
                removeAWSDataFile={removeAWSDataFile}
                documentType={documentTypes.OFFICER_CORPORATE_AGREEMENT}
                onSetIsProcessingFiles={setDropZoneIsProcessingFile}
                onSetIsSomeFileNotUploaded={setDropZoneHasFileNotUploaded}
                accept={acceptFileFormats}
              />
            )}
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant="text"
            className={classes.backButton}
            onClick={() => history.push(navigateToPreviousPage(history.location.pathname))}
          >
            BACK
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={async () => {
              try {
                // Remove if check when officers endpoint can allow additional officers to be saved
                if (authUser.company.officers?.length === 0) {
                  await new Promise((resolve, reject) => dispatch(persistCompanyUpdates({
                    companyId,
                    updates: {
                      officers: company.officers,
                    },
                    resolve,
                    reject,
                  })));
                }
                await new Promise((resolve, reject) => dispatch(saveFileUpload({
                  AWSData,
                  resolve,
                  reject,
                })));
              } catch (error) {
                console.error(error);
              }
              history.push(navigateToNextPage(history.location.pathname, company.BO25PercentOfCompanyOrMore));
            }}
            disabled={
              !officerAgreement
              && (
                !AWSData.length
                || uploadIsPending
                || !hasRequiredData
                || companyIsUpdating
              )
            }
          >
            NEXT
          </Button>
        </div>
      </Grid>
    </div>
  );
}

ListOfficersView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  AWSData: dropZoneTypes.AWSData.isRequired,
  getPreSignedUrls: dropZoneTypes.getPreSignedUrls.isRequired,
  preSignedUrls: dropZoneTypes.preSignedUrls,
  removeAWSDataFile: dropZoneTypes.removeAWSDataFile.isRequired,
  setAWSData: dropZoneTypes.setAWSData.isRequired,
  setUnloadedFilesExist: PropTypes.func.isRequired,
  authUser: PropTypes.objectOf(PropTypes.any),
};

ListOfficersView.defaultProps = {
  authUser: undefined,
  preSignedUrls: undefined,
};

const mapStateToProps = ({ auth, officerFiles, company }) => ({
  AWSData: officerFiles.AWSData,
  AWSDataIsSet: officerFiles.AWSDataIsSet,
  currentListing: officerFiles.currentProperty,
  getPreSignedUrls,
  preSignedUrls: officerFiles.preSignedUrls,
  removeAWSDataFile,
  setAWSData,
  setUnloadedFilesExist,
  company,
  authUser: auth.user,
  companyIsUpdating: company.companyIsUpdating,
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(ListOfficersView);
