/**
 * @typedef PotentialListingEditor
 * @property {boolean=} isAuthorizedSigner
 * @property {boolean=} isViewOnlyListingEditor
 */

/**
 * @param {PotentialListingEditor} user
 * @returns {boolean}
 */
export function userHasPermissionToEditListing({
  isAuthorizedSigner,
  isViewOnlyListingEditor,
}) {
  return !!isAuthorizedSigner || !!isViewOnlyListingEditor;
}
