import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';

// arrow icon
import { ReactComponent as ArrowIcon } from 'now-frontend-shared/assets/icons/pagination_arrow.svg';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

import { SortDirection } from 'now-shared/enums/sort-direction';

const SwitchInput = ({
  classes, placeholder, name, setQueryValue,
}) => {
  const [value, setValue] = useState('');
  const location = useLocation();
  const isActive = name === value.order;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const order = searchParams.get('order');
    const sort = searchParams.get('sort');

    if (order && sort) {
      setValue({ order, sort });
    } else {
      setValue('');
    }
  }, [location.search, name]);

  const onHandleClick = () => {
    setQueryValue('order', name);
    setQueryValue(
      'sort',
      (
        !isActive
        || !value
        || value.sort === SortDirection.Descending
      )
        ? SortDirection.Ascending
        : SortDirection.Descending,
    );
  };

  return (
    <Grid container alignItems="center" className={classes.wrapper} onClick={onHandleClick}>
      <Grid item className={classes.placeholder}>
        {placeholder}
      </Grid>
      <Grid item className={classes.iconsContainer}>
        <ArrowIcon
          width={8}
          height={16}
          className={cx(
            classes.topIcon,
            {
              [classes.disabled]: value.sort !== SortDirection.Ascending || !isActive,
            },
          )}
        />
        <ArrowIcon
          width={8}
          height={16}
          className={cx(
            classes.bottomIcon,
            {
              [classes.disabled]: value.sort !== SortDirection.Descending || !isActive,
            },
          )}
        />
      </Grid>
    </Grid>
  );
};

SwitchInput.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string.isRequired,
  setQueryValue: PropTypes.func.isRequired,
};

export default compose(withStyles(styles))(SwitchInput);
