import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import moment from 'moment';

// helpers
import { formatNumberToCurrency } from 'now-frontend-shared/utils/helpers';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import { getCompanyName } from 'now-shared/helpers/company-helpers';

// components
import Button from 'now-frontend-shared/components/Button';
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';
import SelectWinningBidModal from 'components/Modals/SelectWinningBidModal';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

import { setListingWinner } from 'store/actions/propertyBidsListActions';

const BidLayout = ({
  canSelectWinner,
  hasPermissionToSelectWinner,
  classes,
  bid,
  property,
  query,
}) => {
  const dispatch = useDispatch();
  const { setModal } = useModalSetter();

  const formatDate = useCallback(date => moment(date).format('h:mm A, MMMM D, YYYY'), []);

  const bidAmount = useMemo(() => formatNumberToCurrency(bid.amount), [bid.amount]);
  const bidCarryFormatted = useMemo(() => `${bid.carryPercentage || 0}%`, [bid.carryPercentage]);
  const bidDate = useMemo(() => formatDate(bid.createdAt), [formatDate, bid.createdAt]);

  const bidId = bid.id;
  const listingId = bid.property.id;
  const bidStatus = bid.status.title;

  const onHandleSetWinner = useCallback(() => {
    const selectWinner = async () => {
      await new Promise((resolve, reject) => dispatch(setListingWinner({
        listingId,
        selectedWinnerBidId: bidId,
        query,
        resolve,
        reject,
      })));
      setModal(null);
    };

    setModal(
      <SelectWinningBidModal
        bid={bid}
        onSuccess={selectWinner}
      />,
    );
  }, [dispatch, listingId, bid, bidId, setModal, query]);

  return (
    <>
      {bid && (
        <Grid container alignItems="center" justify="flex-start" className={classes.wrapper}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.contacts}>
            <span>
              {`${getUserFullName(bid.user)}, ${getCompanyName(bid.user.company) || '<missing company>'}`}
            </span>
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className={classes.bidField}>
            <span>Seller:</span>
            <span className={classes.bidFieldValue}>
              {property.isAnonymous
                ? 'Anonymous'
                : getCompanyName(property.user.company) || '<missing company>'}
            </span>
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className={classes.bidField}>
            <span>Bid Amount:</span>
            <span className={classes.bidFieldValue}>{bidAmount}</span>
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className={classes.bidField}>
            <span>Bid Carry:</span>
            <span className={classes.bidFieldValue}>{bidCarryFormatted}</span>
          </Grid>

          <Grid container item sx={12} sm={12} md={3} lg={3} xl={3} className={classes.bidDate}>
            <span>{`Bid Date ${bidDate}`}</span>
          </Grid>

          {['closed', 'winner', 'winner selected', 'sold'].includes(bidStatus) && (
            <Grid container item sx={12} sm={12} md={3} lg={3} xl={3}>
              {canSelectWinner && bidStatus === 'closed' ? (
                <Button
                  label="Select Winner"
                  buttonColor="red"
                  onClick={onHandleSetWinner}
                  disabled={!hasPermissionToSelectWinner}
                />
              ) : ['winner', 'winner selected'].includes(bidStatus) && (
                <Button
                  label={
                    bidStatus === 'winner'
                      ? 'Winner'
                      : bidStatus === 'winner selected'
                        ? 'Selected Winner'
                        : 'Not Selected'
                  }
                  buttonColor="redFilled"
                  disabled
                />
              )}
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

BidLayout.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  canSelectWinner: PropTypes.bool,
  hasPermissionToSelectWinner: PropTypes.bool,
  setCurrentWells: PropTypes.func,
};

BidLayout.defaultProps = {
  hasPermissionToSelectWinner: true,
};

export default compose(withStyles(styles), memo)(BidLayout);
