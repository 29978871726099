/* eslint-disable no-console */
import React, { memo, useEffect } from 'react';
import {
  Switch, Route, useLocation, Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useGA4React } from 'ga-4-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// layouts
import WrappedRoute from 'layouts/WrappedRoute';

// pages
import Spinner from 'now-frontend-shared/components/Spinner';
import PropTypes from 'prop-types';
import Page404 from './Page404';
import MainPage from './MainPage';
import ApprovalStatus from './ApprovalStatus';
import Dashboard from './Dashboard';
import BuyerAgreement from './Agreements/BuyerAgreement';
import SellerAgreement from './Agreements/SellerAgreement';
import BankInformation from './BankInformation';
import UpdateAccount from './UpdateAccount';
import Settings from './Settings';
import AboutUs from './AboutUs';
import TermsAndConditions from './TermsAndConditions';
import ContactUs from './ContactUs';
import CreateProperty from './CreateProperty';
import Properties from './Properties';
import MyProperties from './MyProperties';
import PropertyView from './PropertyView';
import PropertyBid from './PropertyBid';
import BidedProperties from './BidedProperties';
import PropertyBidsList from './PropertyBidsList';
import EditProperty from './EditProperty';
import Map from './Map';
import SignUpWizard from './SignUpWizard';
import IncreaseBidAllowance from './IncreaseBidAllowance';
import Faq from './Faq';
import PrivacyPolicy from './PrivacyPolicy';
import Registration from './Registration';
import Business from './Business';
import BusinessContinuityDisclosure from './BusinessContinuityDisclosure';
import ListingAnalyticsPage from './ListingAnalyticsPage';

import { isAdminOrCompliancePerson } from 'now-shared/validation/admin-upsert-user';
import PermissionsView from './Business/components/PermissionsView';
import DescriptionView from './Business/components/DescriptionView';
import NewAccountView from './Business/components/NewAccountView';
import BeneficialOwnerQuestionnaireView from './Business/components/BeneficialOwnerQuestionnaireView';
import PublicCompanyView from './Business/components/PublicCompanyView';
import BeneficialOwnerAccountView from './Business/components/BeneficialOwnerAccountView';
import UploadDocumentsView from './Business/components/UploadDocumentsView';
import BuyersCertificateView from './Business/components/BuyersCertificateView';
import SellerCertificateView from './Business/components/SellerCertificateView';
import FinancingInformationView from './Business/components/FinancingInformationView';
import ListOfficersView from './Business/components/ListOfficersView';
import UploadBeneficialOwnerView from './Business/components/UploadBeneficialOwnerView';
import CertifyInfoView from './Business/components/CertifyInfoView';
import RegistrationRoute from 'layouts/RegistrationRoute';
import {
  APPROVAL_STATUS_ROUTE,
  BENEFICIAL_OWNER_ACCOUNT_INFORMATION_ROUTE,
  BENEFICIAL_OWNER_QUESTIONNAIRE_ROUTE,
  BENEFICIAL_OWNER_UPLOADS,
  BUSINESS_ADDRESS_ROUTE,
  BUYER_AGREEMENT_ROUTE,
  CERTIFY_INFO_ROUTE,
  ENTITY_DESCRIPTION_ROUTE,
  FINANCING_INFORMATION_ROUTE,
  LIST_OFFICERS_ROUTE,
  NEW_BUSINESS_ACCOUNT_ROUTE,
  PERMISSION_ROUTE,
  PUBLIC_COMPANY_QUESTIONNAIRE_ROUTE,
  REGISTRATION_ROUTE,
  SELLER_AGREEMENT_ROUTE,
  UPLOAD_COMPANY_FILES_ROUTE,
  registrationFlow,
} from 'constants/registrationFlow';

const debug = false;

const App = ({
  isAuthorized,
  user,
}) => {
  const location = useLocation();
  const ga = useGA4React();

  const {
    isAccountManager,
    isAuthorizedSigner,
    isViewOnlyListingEditor,
  } = user;

  const isAdminOrComplianceUser = isAdminOrCompliancePerson(user);
  const userIsApproved = user?.approvalStatus?.title === 'approved';
  const companyIsApprovedBuyer = (
    user.company?.buyerAgreement?.status.title === 'approved'
    && !!user.company?.approved
    && !!user.company?.active
  );
  const companyIsApprovedSeller = (
    user.company?.sellerAgreement?.status.title === 'approved'
    && !!user.company?.approved
    && !!user.company?.active
  );

  if (debug) {
    console.debug('isAuthorized', isAuthorized);
    console.debug('isAdminOrComplianceUser', isAdminOrComplianceUser);
    console.debug('isAccountManager', isAccountManager);
    console.debug('isAuthorizedSigner', isAuthorizedSigner);
    console.debug('isViewOnlyListingEditor', isViewOnlyListingEditor);
    console.debug('isApproved', userIsApproved);
    console.debug('isCompanyApprovedBuyer', companyIsApprovedBuyer);
    console.debug('isCompanyApprovedSeller', companyIsApprovedSeller);
  }

  // TODO: [REQUIREMENTS] only mark page viewed if user is authorized to view page?
  useEffect(() => {
    if (ga) {
      ga.pageview(location.pathname, location, location.pathname);
    }
  }, [ga, location]);

  // show a loading animation until authorization info is loaded
  if (isAuthorized === null) return <Spinner />;

  return (
    <>
      <ToastContainer />
      <Switch>
        {(() => {
          const routes = [];

          const makeRouteProps = (path, options) => ({
            path,
            // defaults to true
            exact: options?.exact ?? true,
            key: options?.key ?? path,
          });

          routes.push(
            <Route
              {...makeRouteProps('/')}
              component={MainPage}
            />,
          );
          routes.push(
            <WrappedRoute
              {...makeRouteProps('/contact-us')}
              component={ContactUs}
            />,
          );
          routes.push(
            <WrappedRoute
              {...makeRouteProps('/faq')}
              component={Faq}
            />,
          );
          routes.push(
            <WrappedRoute
              {...makeRouteProps('/privacy-policy')}
              component={PrivacyPolicy}
            />,
          );
          routes.push(
            <WrappedRoute
              {...makeRouteProps('/business-continuity-disclosure')}
              component={BusinessContinuityDisclosure}
            />,
          );
          routes.push(
            <WrappedRoute
              {...makeRouteProps('/terms-and-conditions')}
              component={TermsAndConditions}
            />,
          );
          if (isAuthorized) {
            if (!userIsApproved && !isAdminOrComplianceUser) {
              // allow the following routes even when isRegistered is true so that when a user just finishes
              // registration they won't be immediately kicked off the last page/step ("You did it!")
              routes.push(
                <RegistrationRoute
                  {...makeRouteProps(REGISTRATION_ROUTE)}
                  component={Registration}
                />,
              );
              routes.push(
                <RegistrationRoute
                  {...makeRouteProps(BUSINESS_ADDRESS_ROUTE)}
                  component={Business}
                />,
              );
              routes.push(
                <RegistrationRoute
                  exact
                  {...makeRouteProps(PERMISSION_ROUTE)}
                  component={PermissionsView}
                />,
              );
              routes.push(
                <RegistrationRoute
                  exact
                  {...makeRouteProps(ENTITY_DESCRIPTION_ROUTE)}
                  component={DescriptionView}
                />,
              );

              routes.push(
                <RegistrationRoute
                  {...makeRouteProps(NEW_BUSINESS_ACCOUNT_ROUTE)}
                  component={NewAccountView}
                />,
              );
              routes.push(
                <RegistrationRoute
                  {...makeRouteProps(BENEFICIAL_OWNER_QUESTIONNAIRE_ROUTE)}
                  component={BeneficialOwnerQuestionnaireView}
                />,
              );
              routes.push(
                <RegistrationRoute
                  {...makeRouteProps(PUBLIC_COMPANY_QUESTIONNAIRE_ROUTE)}
                  component={PublicCompanyView}
                />,
              );
              routes.push(
                <RegistrationRoute
                  {...makeRouteProps(BENEFICIAL_OWNER_ACCOUNT_INFORMATION_ROUTE)}
                  component={BeneficialOwnerAccountView}
                />,
              );
              routes.push(
                <RegistrationRoute
                  {...makeRouteProps(UPLOAD_COMPANY_FILES_ROUTE)}
                  component={UploadDocumentsView}
                />,
              );
              routes.push(
                <RegistrationRoute
                  {...makeRouteProps(BUYER_AGREEMENT_ROUTE)}
                  component={BuyersCertificateView}
                />,
              );
              routes.push(
                <RegistrationRoute
                  {...makeRouteProps(SELLER_AGREEMENT_ROUTE)}
                  component={SellerCertificateView}
                />,
              );
              routes.push(
                <RegistrationRoute
                  {...makeRouteProps(FINANCING_INFORMATION_ROUTE)}
                  component={FinancingInformationView}
                />,
              );
              routes.push(
                <RegistrationRoute
                  {...makeRouteProps(LIST_OFFICERS_ROUTE)}
                  component={ListOfficersView}
                />,
              );
              routes.push(
                <RegistrationRoute
                  {...makeRouteProps(BENEFICIAL_OWNER_UPLOADS)}
                  component={UploadBeneficialOwnerView}
                />,
              );
              routes.push(
                <RegistrationRoute
                  {...makeRouteProps(CERTIFY_INFO_ROUTE)}
                  component={CertifyInfoView}
                />,
              );
              routes.push(
                <WrappedRoute
                  {...makeRouteProps(APPROVAL_STATUS_ROUTE)}
                  component={ApprovalStatus}
                />,
              );
            } else {
              // user is an Admin or their registration is approved

              if (userIsApproved) {
                registrationFlow.map(step => step.route).forEach(path => {
                  routes.push(
                    <Route
                      {...makeRouteProps(path)}
                    >
                      <Redirect to="/dashboard" />
                    </Route>,
                  );
                });
              }

              if (isAccountManager) {
                routes.push(
                  <WrappedRoute
                    {...makeRouteProps('/seller-agreement')}
                    component={SellerAgreement}
                  />,
                );
                routes.push(
                  <WrappedRoute
                    {...makeRouteProps('/bank-information')}
                    component={BankInformation}
                  />,
                );
              }

              if (isAuthorizedSigner || isViewOnlyListingEditor) {
                routes.push(
                  <WrappedRoute
                    {...makeRouteProps('/create-listing')}
                    component={CreateProperty}
                  />,
                );
                routes.push(
                  <WrappedRoute
                    {...makeRouteProps('/listings/:id/edit')}
                    component={EditProperty}
                  />,
                );
              }

              if (isAuthorizedSigner) {
                routes.push(
                  <Route
                    {...makeRouteProps('/listings/:id/make-a-bid')}
                    component={PropertyBid}
                  />,
                );
              }

              if (companyIsApprovedBuyer) {
                routes.push(
                  <WrappedRoute
                    {...makeRouteProps('/my-bids')}
                    component={BidedProperties}
                  />,
                );
                routes.push(
                  <WrappedRoute
                    {...makeRouteProps('/increase-bid-allowance')}
                    component={IncreaseBidAllowance}
                  />,
                );
              }

              if (companyIsApprovedSeller || isAdminOrComplianceUser) {
                routes.push(
                  <WrappedRoute
                    {...makeRouteProps('/listings/:id/bids')}
                    component={PropertyBidsList}
                  />,
                );
                if (companyIsApprovedSeller) {
                  routes.push(
                    <WrappedRoute
                      {...makeRouteProps('/my-listings')}
                      component={MyProperties}
                    />,
                  );
                }
                routes.push(
                  <WrappedRoute
                    {...makeRouteProps('/listings/:id/analytics')}
                    component={ListingAnalyticsPage}
                  />,
                );
              }

              [
                '/dashboard',
                '/company-dashboard',
                '/buyer-dashboard',
                '/seller-dashboard',
              ].forEach(path => {
                routes.push(
                  <WrappedRoute
                    {...makeRouteProps(path)}
                    component={Dashboard}
                  />,
                );
              });

              routes.push(
                <WrappedRoute
                  {...makeRouteProps('/listings')}
                  component={Properties}
                />,
              );
              routes.push(
                <Route
                  {...makeRouteProps('/listings/:id')}
                  component={PropertyView}
                />,
              );
              routes.push(
                <Route
                  {...makeRouteProps('/map')}
                  component={Map}
                />,
              );
              routes.push(
                <WrappedRoute
                  {...makeRouteProps('/update-account')}
                  component={UpdateAccount}
                />,
              );
              routes.push(
                <WrappedRoute
                  {...makeRouteProps('/settings')}
                  component={Settings}
                />,
              );
              routes.push(
                <WrappedRoute
                  component={AboutUs}
                  {...makeRouteProps('/about-us')}
                />,
              );
            }

            if (isAccountManager || user.isAdmin) {
              // NOTE: this route is typically only allowed for account managers, after they have
              // completed registration. We make it available to Admins and potential account managers
              // here in order to support Buyer Agreement PDF generation using Puppeteer.
              routes.push(
                <Route
                  {...makeRouteProps('/buyer-agreement')}
                  component={BuyerAgreement}
                />,
              );
            }

            routes.push(
              // catch-all
              <WrappedRoute
                key="pageNotFound"
                component={Page404}
              />,
            );
          } else /* !isAuthorized */ {
            routes.push(
              <WrappedRoute
                {...makeRouteProps('/sign-up')}
                component={SignUpWizard}
              />,
            );
            routes.push(
              // catch-all
              <Redirect
                key="redirectHomeWithNext"
                to={{
                  pathname: '/',
                  search: `?next=${encodeURIComponent(
                    location.pathname + location.search,
                  )}`,
                }}
              />,
            );
          }

          if (debug) {
            console.debug('routes', routes);
          }

          return routes;
        })()}
      </Switch>
    </>
  );
};

App.propTypes = {
  isAuthorized: PropTypes.bool,
  user: PropTypes.shape({
    isAdmin: PropTypes.bool,
    isAccountManager: PropTypes.bool,
    isAuthorizedSigner: PropTypes.bool,
    isViewOnlyListingEditor: PropTypes.bool,
    isComplianceSupervisor: PropTypes.bool,
    isRegisteredRepresentative: PropTypes.bool,
    // TODO: [TYPE] specify missing types
  }),
};

App.defaultProps = {
  isAuthorized: false,
  user: undefined,
};

export default compose(
  connect(({ auth }) => ({
    isAuthorized: auth.authInfo.isAuthorized,
    user: auth.user,
  })),
  memo,
)(App);
