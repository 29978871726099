import { asyncAction, action } from 'now-frontend-shared/utils/actions';

// async actions
export const getPreSignedUrls = asyncAction(
  'beneficialOwnerFiles/GET_PRE_SIGNED_URLS',
);

// sync action
export const reset = action('beneficialOwnerFiles/RESET');
export const setAWSData = action('beneficialOwnerFiles/SET_AWS_DATA');
export const removeAWSDataFile = action('beneficialOwnerFiles/REMOVE_AWS_DATA_FILE');
export const clearAWSData = action('beneficialOwnerFiles/CLEAR_AWS_DATA');
export const setUnloadedFilesExist = action('beneficialOwnerFiles/SET_UNLOADED_FILES_EXIST');
