export function getAccessToken() {
  return (localStorage.getItem('accessToken') || '').replaceAll(/"/g, '');
}

export function getAuthorizationHeader() {
  return `Bearer ${getAccessToken()}`;
}

export function getUserData() {
  return JSON.parse(localStorage.getItem('userData')) || undefined;
}
