// actions
import * as A from '../actions/userActions';
import { createNewCompany } from 'store/actions/companyActions';
import { setUserData } from 'store/actions/authActions';

const initialState = {
  firstName: null,
  lastName: null,
  companyId: null,
  department: null,
  jobTitle: null,
  phoneNumber: null,
  isAccountManager: null,
};

export const reducer = {
  user(state = initialState, action) {
    switch (action.type) {
      case A.clearState:
        return initialState;
      case setUserData.type:
        return {
          ...state,
          isAccountManager: action.payload.isAccountManager,
        };
      case A.updateUser.type:
        return { ...state, [action.payload.key]: action.payload.value };
      case createNewCompany.success:
        return { ...state, companyId: action.payload.data.id };
      default:
        return state;
    }
  },
};
