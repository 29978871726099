import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';

// components
import LinkButton from 'now-frontend-shared/components/LinkButton';
import CloseInfoWindowButton from '../CloseInfoWindowButton';

// custom hooks
import useIsMobile from 'now-frontend-shared/hooks/useIsMobile';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

const InfoWindow = ({
  classes,
  isCluster,
  pointCount,
  infoWindowData,
  handleToggleScrollwheel,
  handleCloseInfoWindow,
}) => {
  const isMobile = useIsMobile();

  const transform = useMemo(() => {
    if (isCluster) {
      return `${isMobile ? 'translateX(-50%)' : 'translateX(-8px)'} translateY(-${10 + pointCount * 2}px)`;
    }
  }, [isCluster, isMobile, pointCount]);

  return (
    <div
      className={classes.infoWindow}
      style={{ transform }}
      onMouseEnter={handleToggleScrollwheel}
      onMouseLeave={handleToggleScrollwheel}
      onTouchStartCapture={handleToggleScrollwheel}
    >
      <CloseInfoWindowButton handleClick={handleCloseInfoWindow} />

      <Grid container>
        <span className={classes.infoHeading}>
          {isCluster ? 'Multiple listings in this location/county...' : 'One listing in this location/county...'}
        </span>
      </Grid>

      <Grid container className={cx(classes.infoContainer, { [classes.infoContainerOverflow]: pointCount > 4 })}>
        {infoWindowData.properties.map(propertyId => (
          <Grid container justify="space-between" alignItems="center" className={classes.propertyInfo} key={propertyId}>
            <Grid item xs={5}>
              <span className={classes.propertyId}>{`LISTING-${propertyId}`}</span>
            </Grid>

            <Grid item xs={6}>
              <LinkButton
                fullWidth
                buttonColor="green"
                label="Property info"
                path={`/listings/${propertyId}`}
                customStyles={{
                  height: '26px', margin: 'unset', borderRadius: '4px', fontWeight: 'normal',
                }}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>

      {isCluster && (
        <Grid container direction="column">
          <span className={classes.infoCount}>
            There are
            {' '}
            <span className={classes.infoCountBold}>{`${pointCount} additional `}</span>
            listings at this location
          </span>

          <span className={classes.infoMessage}>Zoom in to see additional listings.</span>
        </Grid>
      )}
    </div>
  );
};

InfoWindow.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isCluster: PropTypes.bool.isRequired,
  pointCount: PropTypes.number,
  infoWindowData: PropTypes.shape({
    id: PropTypes.number,
    propertyId: PropTypes.number,
    properties: PropTypes.arrayOf(PropTypes.number).isRequired,
  }),
  handleToggleScrollwheel: PropTypes.func.isRequired,
  handleCloseInfoWindow: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), memo)(InfoWindow);
