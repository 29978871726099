/**
 * @readonly
 * @enum {'ASC'|'DESC'}
 */
export const SortDirection = {
  /**
   * @readonly
   * @type {'ASC'}
   */
  Ascending: 'ASC',
  /**
   * @readonly
   * @type {'DESC'}
   */
  Descending: 'DESC',
};

/**
 * @readonly
 */
export const SortDirectionTitle = {
  /**
   * @readonly
   */
  [SortDirection.Ascending]: 'Ascending',
  /**
   * @readonly
   */
  [SortDirection.Descending]: 'Descending',
};
