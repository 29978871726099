import React, { memo, useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';
import {
  put, select,
} from 'redux-saga/effects';
import { push, goBack } from 'connected-react-router';

// arrow image
import { ReactComponent as ArrowIcon } from 'now-frontend-shared/assets/icons/arrow.svg';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

export function canNavigateBackEffectively(history) {
  return (history.length || 0) > 2;
}

export function returnToPreviousPage(location, history, fallbackRoute) {
  const prevPath = location.state?.prevPath;
  if (prevPath) {
    history.push(`${prevPath.pathname}${prevPath.search}`);
  } else if (canNavigateBackEffectively(history)) {
    history.goBack();
  } else if (fallbackRoute) {
    history.push(fallbackRoute);
  }
}

export function* returnToPreviousPageInSaga(fallbackRoute) {
  const location = yield select(({ router }) => router.location);
  const history = yield select(({ router }) => router.history);

  const prevPath = location.state?.prevPath;
  if (prevPath) {
    yield put(push(`${prevPath.pathname}${prevPath.search}`));
  } else if (canNavigateBackEffectively(history)) {
    yield put(goBack());
  } else if (fallbackRoute) {
    yield put(push(fallbackRoute));
  }
}

const HeadSection = ({
  children, classes, goBack, heading, ignorePrevPath, path, noIndent, onReturn,
}) => {
  const history = useHistory();
  const location = useLocation();

  const previousPagePath = useMemo(() => {
    if (!ignorePrevPath) {
      const prevPath = location.state?.prevPath;
      if (prevPath) {
        return `${prevPath.pathname}${prevPath.search}`;
      }
    }

    if (goBack && canNavigateBackEffectively(history)) {
      return true;
    }

    if (typeof path === 'string' && path) {
      return path;
    }
  }, [goBack, history, ignorePrevPath, location, path]);

  return (
    <Grid container direction="row" alignItems="center" className={classes.container}>
      {(onReturn || previousPagePath) && (
        <Grid item className={classes.icon}>
          <Link
            {...onReturn && { onClick: () => onReturn() }}
            {...!onReturn && previousPagePath && (
              previousPagePath === true
                ? { onClick: () => history.goBack() }
                : { to: previousPagePath }
            )}
          >
            <ArrowIcon />
          </Link>
        </Grid>
      )}

      <Grid item xs={!noIndent} className={cx(classes.heading, !path && classes.headingWithoutIcon)}>
        <span>{heading}</span>
      </Grid>

      {children}
    </Grid>
  );
};

HeadSection.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  goBack: PropTypes.bool,
  ignorePrevPath: PropTypes.bool,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  noIndent: PropTypes.bool,
  onReturn: PropTypes.func,
};

export default compose(withStyles(styles), memo)(HeadSection);
