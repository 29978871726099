import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Switch as MUISwitch, Grid } from '@material-ui/core';

// styles
import { primaryDark } from '../../themes/colors';
import styles from './styles';

const StyledSwitch = withStyles({
  root: {
    display: 'flex',
    width: '32px',
    height: '16px',
    padding: 0,
  },
  switchBase: {
    padding: '2px',
    color: '#BDBDBD',
    '&$checked': {
      transform: 'translateX(16px)',
      color: '#FFFFFF',
      '& + $track': {
        opacity: 1,
        backgroundColor: primaryDark,
        borderColor: primaryDark,
      },
      '&$disabled + $track': {
        opacity: 0.5,
        backgroundColor: '#000000',
      },
    },
    '&$disabled + $track': {
      opacity: 0.5,
      backgroundColor: '#000000',
    },
  },
  thumb: {
    width: '12px',
    height: '12px',
    boxShadow: 'none',
  },
  track: {
    opacity: 1,
    backgroundColor: '#FFFFFF',
    border: '1px solid #E9E9E9',
    borderRadius: '16px',
  },
  checked: {},
  disabled: {},
})(MUISwitch);

const Switch = ({
  classes, name, label, checked, disabled, onChangeNotificationsValue,
}) => {
  const [isChecked, setChecked] = useState(false);

  useEffect(() => {
    if (checked) setChecked(true);
  }, [checked]);

  const onHandleChanhe = ({ target: { checked } }) => {
    setChecked(checked);
    onChangeNotificationsValue({ emailType: name, value: checked });
  };

  return (
    <Grid container alignItems="center" className={classes.wrapper}>
      <StyledSwitch disabled={disabled} checked={isChecked} onChange={onHandleChanhe} name={name} id={name} />
      <label htmlFor={name} className={classes.label}>
        {label}
      </label>
    </Grid>
  );
};

Switch.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChangeNotificationsValue: PropTypes.func.isRequired,
};

export default compose(withStyles(styles))(Switch);
