import { DateTime, Interval } from 'luxon';
import moment from 'moment';
import {
  minEndTimeForListing,
  minStartTimeForListing,
} from 'now-shared/validation/listing-validation';
import { toAuctionEventTime } from 'now-shared/helpers/time-helpers';

export const DEFAULT_QUERY = '?page=1';

export const createQuery = (history, queryName, queryValue) => {
  const searchParams = new URLSearchParams(history.location.search || DEFAULT_QUERY);

  if (queryName && queryValue) {
    const id = typeof queryValue === 'string' && +queryValue.toLowerCase().replace(/listing-/g, '');
    if (id) {
      searchParams.set(queryName, id);
    } else {
      searchParams.set(queryName, queryValue);
    }
  }

  if (queryName === 'state') {
    searchParams.delete('basin');
    searchParams.delete('county');
  }

  if (queryName === 'basin') {
    searchParams.delete('county');
  }

  if (queryName !== 'page') {
    searchParams.set('page', 1);
  }

  return `?${searchParams.toString()}`;
};

export const createCustomQuery = (history, queryName, queryValue) => {
  const searchParams = new URLSearchParams(history.location.search || DEFAULT_QUERY);

  if (queryName && queryValue) {
    const id = typeof queryValue === 'string' && +queryValue.toLowerCase().replace(/listing-/g, '');
    if (id) {
      searchParams.set(queryName, id);
    } else {
      searchParams.set(queryName, queryValue);
    }
  }

  if (queryName !== 'page') {
    searchParams.set('page', 1);
  }

  return `?${searchParams.toString()}`;
};

export const removeQueryParam = (history, queryName) => {
  const searchParams = new URLSearchParams(history.location.search || DEFAULT_QUERY);

  if (queryName) {
    searchParams.delete(queryName);
  }

  if (!searchParams.page) {
    searchParams.set('page', 1);
  }

  return `?${searchParams.toString()}`;
};

/**
 * @param {string|number|null|undefined} maskedValue
 * @returns {number|undefined}
 */
export const maskedAmountToNumber = maskedValue => {
  let result;
  if (typeof maskedValue === 'number') {
    result = maskedValue;
  } else if (typeof maskedValue === 'string') {
    const numberString = maskedValue.replace(/[^0-9.-]+/g, '');
    if (numberString) {
      result = +numberString;
    }
  }
  return result;
};

/**
 * TODO: [CLARIFY] convert the second arg `noSpace` into an arg called `options`, and make
 * `noSpace` an optional property of that arg. This will help people reading the code where this
 * function is called to quickly understand what this boolean is for (they'll see `noSpace: true` instead
 * of just `true`, which will be a lot more descriptive).
 */
export const formatNumberToCurrency = (number, noSpace) => {
  const formatted = `$ ${Number(number || 0).toLocaleString('en-US')}`;
  if (noSpace) {
    return formatted.replace(/\s+/g, '');
  }
  return formatted;
};

/**
 * @param {string} formatted
 * @returns {string}
 */
export const formattedCurrencyToNumberString = formatted => `${maskedAmountToNumber(formatted)}`;

export const formattedPhoneToNumberString = formatted => formatted.replace(/[^+\d]/g, '');

export const existingPropertyDateRangeGenerator = (startDate, endDate) => {
  const minStartDate = minStartTimeForListing();
  const minEndDate = minEndTimeForListing(minStartDate);

  const currentStartDate = toAuctionEventTime(new Date(startDate), 'start')
    .toJSDate();

  const currentEndDate = toAuctionEventTime(new Date(endDate), 'end')
    .toJSDate();

  const startTime = moment(currentStartDate).isBefore(minStartDate) ? minStartDate : currentStartDate;
  const endTime = moment(currentEndDate).isBefore(minEndDate) ? minEndDate : currentEndDate;

  return {
    startTime,
    endTime,
  };
};

export const checkWellsFields = ({
  landingZone, surfaceLatitude, surfaceLongitude, bottomLatitude, workingInterestPercentage, netRevenueInterestNumber, bottomLongitude,
}) => Boolean(
  landingZone
      && surfaceLatitude
      && surfaceLatitude !== '-_'
      && surfaceLongitude
      && surfaceLongitude !== '-_'
      && bottomLatitude
      && bottomLatitude !== '-_'
      && workingInterestPercentage
      && netRevenueInterestNumber
      && bottomLongitude
      && bottomLongitude !== '-_',
);

export const checkWellsCoordinates = ({
  surfaceLatitude, surfaceLongitude, bottomLatitude, bottomLongitude,
}) => Boolean(
  surfaceLatitude
      && surfaceLatitude !== '-_'
      && surfaceLongitude
      && surfaceLongitude !== '-_'
      && bottomLatitude
      && bottomLatitude !== '-_'
      && bottomLongitude
      && bottomLongitude !== '-_',
);

export const coordinatesGenerator = wells => {
  const polylinesCoordinates = [];
  const markersCoordinates = [];
  if (!wells) return { polylinesCoordinates, markersCoordinates };

  wells.forEach(zone => {
    const allFieldsFilled = checkWellsCoordinates(zone);
    if (allFieldsFilled) {
      polylinesCoordinates.push([
        { lat: +zone.surfaceLatitude, lng: +zone.surfaceLongitude },
        { lat: +zone.bottomLatitude, lng: +zone.bottomLongitude },
      ]);

      markersCoordinates.push(
        { lat: +zone.surfaceLatitude, lng: +zone.surfaceLongitude },
        // { lat: +zone.bottomLatitude, lng: +zone.bottomLongitude },
      );
    }
  });

  return { polylinesCoordinates, markersCoordinates };
};

export const formatStringToFirstCapitalLetter = string => {
  const newString = string.toLowerCase();
  return newString.charAt(0).toUpperCase() + newString.slice(1);
};

export const formatStringToTitleCase = string => {
  const newString = string.split(' ').map(word => formatStringToFirstCapitalLetter(word)).join(' ');
  return newString;
};

export const getCountdownValue = endTime => {
  const startDate = DateTime.local();
  let endDate = DateTime.fromISO(endTime);
  let timeIsUp = false;
  if (endDate <= startDate) {
    endDate = startDate;
    timeIsUp = true;
  }
  return {
    text: Interval.fromDateTimes(startDate, endDate).toDuration().toFormat("dd'D' hh'H' mm'M' ss'S'"),
    timeIsUp,
  };
};

export function getDocumentExtensionFromS3Key(key) {
  return key?.match(/\.[^.]+$/)?.[0];
}

export function decimalCount(num) {
  // Convert to String
  const numStr = String(num);
  // String Contains Decimal
  if (numStr.includes('.')) {
    return numStr.split('.')[1].length;
  }
  // String Does Not Contain Decimal
  return 0;
}

export function isObjectEmpty(obj) {
  return obj && Object.keys(obj).length === 0;
}
