import { primaryDark } from '../themes/colors';

export default {
  container: {
    padding: '8px',
    marginTop: '10px',

    '@media screen and (max-width: 600px)': {
      padding: 0,
    },
  },
  headingContainer: {
    padding: '40px 45px 30px 45px',

    '@media screen and (max-width: 600px)': {
      padding: '20px 0',
    },
  },
  heading: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 'bold',
    color: primaryDark,

    '@media screen and (max-width: 600px)': {
      fontSize: '20px',
      lineHeight: '23px',
    },
  },
  description: {
    marginTop: '5px',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 'normal',
    letterSpacing: '0.003em',
    color: '#000000',
  },
};
