export default {
  wrapper: {
    position: 'relative',
    maxWidth: '640px',

    '@media screen and (max-width: 600px)': {
      alignItems: 'center',
      width: '90%',
    },
  },
  container: {
    padding: '40px 80px 25px 80px',
    background: '#fff',
    borderRadius: '4px',

    '@media screen and (max-width: 600px)': {
      padding: '14px',
    },
  },
  heading: {
    marginBottom: '16px',
    fontSize: '30px',
    lineHeight: '36px',
    fontWeight: 'bold',
    textAlign: 'center',

    '@media screen and (max-width: 600px)': {
      marginBottom: '16px',
      fontSize: '21px',
      lineHeight: '26px',
    },
  },
  buttonContainer: {
    maxWidth: '70%',
    marginTop: '40px',

    '@media screen and (max-width: 600px)': {
      maxWidth: '90%',
      marginTop: '20px',
    },
  },
  inputContainer: {
    padding: '8px 0',
  },
};
