import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';

const CloseModalButton = ({ classes, handleCloseModal }) => (
  <div className={classes.wrapper}>
    <button className={classes.closeButton} onClick={handleCloseModal}>
      <span />
      <span />
    </button>
  </div>
);

CloseModalButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), memo)(CloseModalButton);
