import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// search icon
import SearchIcon from '@material-ui/icons/Search';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { IconButton, InputAdornment } from '@material-ui/core';
import { StyledTextField } from 'components/inputs/styledTextField';

// styles
import styles from '../styles';

const Search = ({ classes, placeholder, setQueryValue }) => {
  const [value, setValue] = useState('');
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get('search');
    if (searchQuery) {
      setValue(searchQuery);
    } else {
      setValue('');
    }
  }, [location.search]);

  const handleButtonMouseDown = event => {
    event.preventDefault();
  };

  const handleChange = ({ target: { value } }) => {
    setValue(value);
  };

  const setSearchQuery = useCallback(() => {
    setQueryValue('search', value);
  }, [setQueryValue, value]);

  const handleOnKeyDown = ({ keyCode }) => {
    if (keyCode === 13) {
      setSearchQuery();
    }
  };

  return (
    <>
      <StyledTextField
        className={classes.search}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onKeyDown={handleOnKeyDown}
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                className={classes.iconButton}
                aria-label="toggle password visibility"
                onMouseDown={handleButtonMouseDown}
                onClick={setSearchQuery}
              >
                <SearchIcon style={{ color: 'black' }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
    </>
  );
};

Search.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string.isRequired,
  setQueryValue: PropTypes.func.isRequired,
};

export default compose(withStyles(styles))(Search);
