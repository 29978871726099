import { primaryDark, red } from 'themes/colors';

export default {
  wrapper: {
    padding: '16px 16px 8px',
    margin: '12px 0',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    backgroundColor: '#FBFBFB',
  },
  // TODO: [CLEANUP] remove this if/when it is no longer being used
  status_old: {
    padding: '0 0 8px',
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#333333',
  },
  id: {
    marginRight: '14px',
    fontWeight: 'normal',
    color: '#BDBDBD',
  },
  wells: {
    padding: '8px 0 6px',
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#333333',
  },
  location: {
    padding: '6px 0',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'bold',
    color: '#000000',
  },
  locationText: {
    margin: '0 16px 0 12px',
    fontWeight: 'normal',
  },
  percents: {
    padding: '10px 0 0',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'bold',
    color: '#828282',
  },
  percentValue: {
    margin: '0 16px',
    fontWeight: 'normal',

    '@media screen and (max-width: 600px)': {
      margin: '0 0 0 10px',
    },
  },
  bidAmount: {
    padding: '22px 0 0',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'normal',
    color: '#333333',
  },
  bidAmountValue: {
    marginLeft: '8px',
    fontSize: '22px',
    lineHeight: '26px',
    fontWeight: 'bold',
  },
  bidDate: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'bold',
    color: primaryDark,

    '@media screen and (max-width: 960px)': {
      paddingBottom: '8px',
    },
  },
  sellerInfo: {
    padding: '12px 0',
    fontSize: '20px',
    lineHeight: '22px',
    fontWeight: 'normal',
    color: '#2F80ED',
  },
  sellerInfoHeader: {
    marginRight: '16px',
    fontWeight: 'bold',
  },
  anonymous: {
    fontWeight: 'bold',
    color: red,
  },
  closedBid: {
    paddingTop: '8px',
    fontSize: '20px',
    lineHeight: '22px',
    fontWeight: 'bold',
    color: `${red} !important`,
    textAlign: 'right',

    '@media screen and (max-width: 600px)': {
      textAlign: 'center',
    },
  },
  disabled: {
    '& span': {
      color: '#E0E0E0',
    },
  },
  button: {
    '@media screen and (max-width: 600px)': {
      '& a': {
        width: '100%',
      },
    },
  },
  noWinner: {
    '@media screen and (max-width: 1140px)': {
      fontSize: '16px',
      lineHeight: '20px',
    },

    '@media screen and (max-width: 960px)': {
      fontSize: '20px',
      lineHeight: '22px',
    },
  },

  card: {
    display: 'grid',
    columnGap: '20px',
    placeItems: 'center',
    height: '96px',
    borderRadius: '4px 4px 4px 4px',
    // TODO: [DRY] use color palette constants instead of string literals here.
    // See `frontend-shared/theme/colors.js`
    boxShadow: `0px 2px 4px -1px rgba(0, 0, 0, 0.2), 
    0px 4px 5px rgba(0, 0, 0, 0.14), 
    0px 1px 10px rgba(0, 0, 0, 0.12)`,
    margin: '10px 0px 10px 0px',
    transition: 'all 0.05s linear',

    '&:hover': {
      borderRadius: '4px 4px 4px 4px',
      // TODO: [DRY] use a color palette constant instead of string literal here.
      // See `frontend-shared/theme/colors.js`
      boxShadow: `0px 4px 4px 4px rgba(0, 0, 0, 0.1), 
      0px 8px 5px 4px rgba(0, 0, 0, 0.07), 
      0px 2px 10px 4px rgba(0, 0, 0, 0.06)`,
      margin: '10px 0px 10px 0px',
      transition: 'all 0.05s linear',
      transform: 'scale(1.007, 1.007)',
    },
  },

  status: {
    textTransform: 'uppercase',
    // TODO: [DRY] use a color palette constant instead of string literal here.
    // See `frontend-shared/theme/colors.js`
    color: '#ffffff',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    letterSpacing: '0.4px',
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    transform: 'rotate3d(0, 0, 1, 180deg)',
    overflowWrap: 'anywhere',
    width: '61px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
  },

  active: {
    // TODO: [DRY] use a color palette constant instead of string literal here.
    // See `frontend-shared/theme/colors.js`
    background: '#4B6377',
  },

  closed: {
    // TODO: [DRY] use a color palette constant instead of string literal here.
    // See `frontend-shared/theme/colors.js`
    background: '#E0E5EB',
    // TODO: [DRY] use a color palette constant instead of string literal here.
    // See `frontend-shared/theme/colors.js`
    color: '#000000',
  },

  topRow: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '0.15px',
  },

  bottomRow: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    // TODO: [DRY] use a color palette constant instead of string literal here.
    // See `frontend-shared/theme/colors.js`
    color: 'rgba(0, 0, 0, 0.6)',
  },

  titleLink: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '0.15px',
    textDecoration: 'none',
    // TODO: [DRY] use a color palette constant instead of string literal here.
    // See `frontend-shared/theme/colors.js`
    color: 'black',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationThickness: '1px',
    },
  },
};
