import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

const MainSection = ({
  children, classes, heading, fullWidth,
}) => (
  <Grid container>
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={fullWidth ? 12 : 6}
      lg={fullWidth ? 12 : 6}
      direction="column"
      className={classes.container}
    >
      {heading && <span className={classes.heading}>{heading}</span>}

      {children}
    </Grid>
  </Grid>
);

MainSection.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  heading: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default compose(withStyles(styles), memo)(MainSection);
