import {
  all, fork, take, takeLatest, call, put,
} from 'redux-saga/effects';

// actions
import {
  cancelMyBidAllowanceRequest,
  getMyBidAllowanceDetails,
  getMyBidAllowanceRequest,
  setMyBidAllowanceRequest,
} from '../actions/myBidAllowanceActions';

// api methods
import Api from 'api/user';

// helpers
import { computeCommission, computeTotalListingCost } from 'now-shared/helpers/bid-helpers';

// storage helpers functions
import * as storage from 'now-frontend-shared/utils/storage';

function* ensureGetMyBidAllowanceDetails() {
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    const { data: allowanceDetails } = yield call(Api.getBidAllowanceDetails, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // enrich the bid data
    allowanceDetails.bids = allowanceDetails.bids.map(bid => {
      // TODO: [FEATURE] set bidAmount to `undefined` for carry bids (when carryPercentage is present)?
      const bidAmount = bid.amount ? +bid.amount : 0;
      const netAfe = +bid.property.netAfe;
      const closingFees = computeCommission({
        bidAmount,
        netAfe,
      });
      const total = computeTotalListingCost({
        bidAmount,
        closingFees,
      });
      const result = {
        ...bid,
        bidAmount,
        netAfe,
        closingFees,
        total,
      };
      return result;
    });
    yield put({ type: getMyBidAllowanceDetails.success, payload: allowanceDetails });
  } catch (err) {
    yield put({ type: getMyBidAllowanceDetails.failure, err });
  }
}

function* ensureGetMyBidAllowanceRequest() {
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    const { data: allowanceRequest } = yield call(Api.getBidAllowanceRequest, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    yield put({ type: getMyBidAllowanceRequest.success, payload: allowanceRequest });
  } catch (err) {
    yield put({ type: getMyBidAllowanceRequest.failure, err });
  }
}

function* ensureSetMyBidAllowanceRequest({ payload }) {
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    yield call(Api.setBidAllowanceRequest, {
      data: JSON.stringify(payload),
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    yield put({ type: setMyBidAllowanceRequest.success });
    yield put(getMyBidAllowanceRequest());
  } catch (err) {
    yield put({ type: setMyBidAllowanceRequest.failure, err });
  }
}

function* ensureCancelMyBidAllowanceRequest({ payload }) {
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    yield call(Api.cancelBidAllowanceRequest, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    yield put({ type: cancelMyBidAllowanceRequest.success });
    yield put(getMyBidAllowanceRequest());
  } catch (err) {
    yield put({ type: cancelMyBidAllowanceRequest.failure, err });
  }
}

function* watchGetMyBidAllowanceDetails() {
  yield takeLatest(getMyBidAllowanceDetails.type, ensureGetMyBidAllowanceDetails);
  yield take(getMyBidAllowanceDetails.success);
}

function* watchGetMyBidAllowanceRequest() {
  yield takeLatest(getMyBidAllowanceRequest.type, ensureGetMyBidAllowanceRequest);
  yield take(getMyBidAllowanceRequest.success);
}

function* watchSetMyBidAllowanceRequest() {
  yield takeLatest(setMyBidAllowanceRequest.type, ensureSetMyBidAllowanceRequest);
  yield take(setMyBidAllowanceRequest.success);
}

function* watchCancelMyBidAllowanceRequest() {
  yield takeLatest(cancelMyBidAllowanceRequest.type, ensureCancelMyBidAllowanceRequest);
  yield take(cancelMyBidAllowanceRequest.success);
}

export default function* myBidAllowanceSagas() {
  yield all([
    fork(watchGetMyBidAllowanceDetails),
    fork(watchGetMyBidAllowanceRequest),
    fork(watchSetMyBidAllowanceRequest),
    fork(watchCancelMyBidAllowanceRequest),
  ]);
}
