import { primaryDark } from '../../themes/colors';

export default {
  wrapper: {
    margin: '10px 0 0',

    '@media screen and (max-width: 960px)': {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  fieldStyles: {
    lineHeight: '16px',
    fontWeight: '500',
    fontSize: '14px',

    '& input': {
      padding: '12px 16px',
    },
  },
  labelContainer: {
    padding: '10px 0',
  },
  label: {
    paddingRight: '5px',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 'bold',
    color: '#333333',
    userSelect: 'none',
  },
  iconButton: {
    padding: 0,
  },
  description: {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 'normal',
    color: '#333333',
  },
  maskStyles: {
    fontFamily: 'Roboto, sans-serif',
    lineHeight: '16px',
    fontWeight: '500',
    fontSize: '14px',

    height: '40px',
    width: '100%',
    cursor: 'text',
    color: 'rgba(0, 0, 0, 0.87)',
    background: 'none',

    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    padding: '9px 16px',
    margin: 0,

    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 1)',
    },

    '&:focus': {
      border: `2px solid ${primaryDark}`,
    },
  },
  passwordDescription: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '18px',
    padding: '2px 0 0 6px',
    fontSize: '9px',
    lineHeight: '11px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  search: {
    width: '100%',
    height: '48px',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 'normal',
  },
  multiple: {
    '@media screen and (max-width: 960px)': {
      padding: '10px 0',
    },
  },
};
