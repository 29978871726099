import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const phoneNumberParseOptions = {
  defaultCountry: 'US',
  extract: false,
};

/**
 * @param {string} value
 * @returns {string}
 */
export const standardizePhoneNumber = value => {
  const phoneNum = parsePhoneNumberFromString(value, phoneNumberParseOptions);
  // E.164 format
  return phoneNum.number;
};

/**
 * @param {string} value
 * @returns {string}
 */
export const humanReadablePhoneNumber = value => {
  const phoneNumber = parsePhoneNumberFromString(value, phoneNumberParseOptions);
  return `+1 ${phoneNumber.formatNational()}`;
};
