import {
  all, fork, take, takeLatest, call, put, select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { reset } from 'redux-form';

// actions
import {
  getStates,
  getBasins,
  getCounties,
  getLandingZones,
  createProperty,
  getPreSignedUrls,
} from '../actions/createPropertyActions';

// api methods
import Api from 'api/properties';

// storage helpers functions
import * as storage from 'now-frontend-shared/utils/storage';
import queryString from 'query-string';

function* ensureGetStates() {
  try {
    const { data } = yield call(Api.getStates);
    yield put({ type: getStates.success, payload: data });
  } catch (err) {
    yield put({ type: getStates.failure, err });
  }
}

function* watchGetState() {
  yield takeLatest(getStates.type, ensureGetStates);
  yield take(getStates.success);
}

function* ensureGetBasins() {
  const stateId = yield select(({ createProperty }) => createProperty.stateId);
  try {
    const { data } = yield call(Api.getBasins, { url: `/states/${stateId}/basins` });
    yield put({ type: getBasins.success, payload: data });
  } catch (err) {
    yield put({ type: getBasins.failure, err });
  }
}

function* watchGetBasins() {
  yield takeLatest(getBasins.type, ensureGetBasins);
  yield take(getBasins.success);
}

function* ensureGetCounties() {
  const stateId = yield select(({ createProperty }) => createProperty.stateId);
  const basinId = yield select(({ createProperty }) => createProperty.basinId);
  try {
    const { data } = yield call(Api.getCounties, { url: `/states/${stateId}/basins/${basinId}` });
    yield put({ type: getCounties.success, payload: data });
  } catch (err) {
    yield put({ type: getCounties.failure, err });
  }
}

function* watchGetCounties() {
  yield takeLatest(getCounties.type, ensureGetCounties);
  yield take(getCounties.success);
}

function* ensureGetLandingZones() {
  const stateId = yield select(({ createProperty }) => createProperty.stateId);
  const basinId = yield select(({ createProperty }) => createProperty.basinId);
  const countyId = yield select(({ createProperty }) => createProperty.countyId);
  try {
    const { data } = yield call(Api.getLandingZones, {
      url: `/states/${stateId}/basins/${basinId}/counties/${countyId}`,
    });
    yield put({ type: getLandingZones.success, payload: data });
  } catch (err) {
    yield put({ type: getLandingZones.failure, err });
  }
}

function* watchGetLandingZones() {
  yield takeLatest(getLandingZones.type, ensureGetLandingZones);
  yield take(getLandingZones.success);
}

function* ensureCreateProperty({ payload: { formName, ...formData } }) {
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    const {
      data: { id },
    } = yield call(Api.sendPropertyData, {
      data: JSON.stringify(formData),
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    yield put({ type: createProperty.success });
    yield put(reset(formName));
    yield put(push(`/listings/${id}`));
  } catch (err) {
    yield put({ type: createProperty.failure, err });
  }
}

function* watchCreateProperty() {
  yield takeLatest(createProperty.type, ensureCreateProperty);
  yield take(createProperty.success);
}

function* ensureGetPreSignedUrls({ payload }) {
  const {
    filesName,
    filesMd5,
    filesSize,
    resolve,
    reject,
  } = payload;
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    const params = queryString.stringify({
      filesName,
      filesMd5,
      filesSize,
    }, { arrayFormat: 'bracket' });
    const { data } = yield call(Api.getPreSignedUrls, {
      url: `/get-s3-pre-signed-urls-for-listing-uploads?${params}`,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    yield put({ type: getPreSignedUrls.success, payload: data });
    if (resolve) {
      resolve(data);
    }
  } catch (err) {
    yield put({ type: getPreSignedUrls.failure, err });
    if (reject) {
      reject(err);
    }
  }
}

function* watchGetPreSignedUrls() {
  yield takeLatest(getPreSignedUrls.type, ensureGetPreSignedUrls);
  yield take(getPreSignedUrls.success);
}

export default function* createPropertySagas() {
  yield all([
    fork(watchGetState),
    fork(watchGetBasins),
    fork(watchGetCounties),
    fork(watchGetLandingZones),
    fork(watchCreateProperty),
    fork(watchGetPreSignedUrls),
  ]);
}
