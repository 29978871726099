import { useLayoutEffect, useState } from 'react';

function useWindowScrollY() {
  const [windowScrollY, setWindowScrollY] = useState(0);

  useLayoutEffect(() => {
    const onScroll = () => {
      setWindowScrollY(window.scrollY);
    };
    onScroll();
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return windowScrollY;
}

export default useWindowScrollY;
