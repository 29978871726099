import React, {
  memo, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// components
import HelpTooltip from 'now-frontend-shared/components/HelpTooltip';

// helpers
import { formatNumberToCurrency } from 'now-frontend-shared/utils/helpers';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';

const ImpliedClosingFees = ({
  classes,
  closingFees,
}) => {
  const closingFeesFormatted = useMemo(() => (
    closingFees === undefined ? '-' : formatNumberToCurrency(closingFees)
  ), [closingFees]);

  return (
    <>
      <span>
        Implied closing fees:
        <HelpTooltip
          title="Implied closing fees"
          iconSize="inherit"
          content="Calculated based on net AFE"
        />
      </span>
      <span
        className={classes.description}
      >
        {closingFeesFormatted}
      </span>
    </>
  );
};

ImpliedClosingFees.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closingFees: PropTypes.number,
};

ImpliedClosingFees.defaultProps = {
  closingFees: undefined,
};

export default compose(
  memo,
  withStyles(styles),
)(ImpliedClosingFees);
