/**
 * Return the supplied string if a condition is satisfied, otherwise
 * return an empty string or the orElse parameter.
 *
 * @param {(string|() => string)} str the string to return if ifCondition is true
 * @param {boolean} ifCondition
 * @param {(string|() => string)=} orElse the string to return if ifCondition is not true
 * @returns {string}
 */
export function strIf(
  str,
  ifCondition,
  orElse,
) {
  const positive = typeof str === 'string' ? () => str : str;
  const negative = (orElse === undefined || typeof orElse === 'string') ? () => (orElse || '') : orElse;

  return ifCondition ? positive() : negative();
}

/**
 * Pluralize noun depending on how many there are
 *
 * @param {(string|() => string)} plural
 * @param {(number|undefined)} count
 * @param {(string|() => string)=} singular
 * @returns {string}
 */
export function pluralize(
  plural,
  count,
  singular,
) {
  const countConst = count || 0;

  return strIf(plural, countConst !== 1, singular);
}

/**
 * @param {(string|undefined)} text
 * @returns
 */
export function toFirstLetterUppercase(text) {
  if (!text) {
    return text;
  }
  return text[0].toUpperCase() + text.slice(1);
}

/**
 * String.length is not unicode-aware. Use this function to get the correct
 * number of unicode characters in a string.
 *
 * @param {string} text
 */
export function unicodeCharCount(text) {
  return [...text].length;
}
