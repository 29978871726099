import { white, primaryMain, secondaryMain } from 'now-frontend-shared/themes/colors';

export default {
  icon: {
    height: '312px',
    width: '400px',

    '@media screen and (max-width: 600px)': {
      width: '200px',
      height: '160px',
    },
  },
  text: {
    fontSize: '40px',
    padding: '20px',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px',
    width: '160px',
    margin: '10px 0',
    borderRadius: '8px',
    border: 'unset',
    outline: 'none',
    color: white,
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'none',
    textDecoration: 'none',
    backgroundColor: primaryMain,
    boxShadow: 'unset',
    transition: 'all 0.5s ease-in-out',

    '&:hover': {
      backgroundColor: secondaryMain,
    },
  },
};
