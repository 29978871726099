import React, { useState } from 'react';
import PropTypes from 'prop-types';

// select arrow icons
import { ReactComponent as MenuArrow } from 'now-frontend-shared/assets/icons/menu_arrow_white.svg';

// styles and components from material-ui
import {
  styled,
  withStyles,
} from '@material-ui/core/styles';
import {
  Button,
  MenuList,
  MenuItem,
  Popper,
  Paper,
  ClickAwayListener,
} from '@material-ui/core';

import styles from './styles';

import { downloadListingDocuments } from 'now-frontend-shared/components/ListingForm/components/GeneralInformation';

const DocumentsMenu = ({
  classes,
  documents,
  listingId,
  onDownload,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filesPreparing, setFilesPreparing] = useState([]);

  const handleClick = event => {
    event.currentTarget.scrollIntoView({
      block: 'center',
      inline: 'center',
    });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadFile = async ({
    documentId,
    downloadUrl,
    filename,
    key,
    downloadAll,
  }) => downloadListingDocuments({
    documentId,
    documents,
    downloadUrl,
    filename,
    key,
    downloadAll,
    listingId,
    filesPreparing,
    setFilesPreparing,
    onDownload,
  });

  if (documents.length === 0) return <StyledMenuButton className={classes.button}>No documents</StyledMenuButton>;

  const downloadAllFilePreparing = filesPreparing.find(file => file.downloadAll);

  return (
    <div>
      <StyledMenuButton
        className={classes.button}
        aria-controls="documents-popper"
        aria-haspopup="true"
        variant="contained"
        toggleRotate={Boolean(anchorEl)}
        onClick={handleClick}
      >
        <MenuArrow className={classes.menuArrow} />
        <span>
          {(
            filesPreparing.length
              ? `Preparing downloads ${
                Math.round(
                  (
                    filesPreparing.reduce((prev, curr) => prev + (curr.loaded ?? 0), 0)
                    / (filesPreparing.reduce((prev, curr) => prev + (curr.total ?? 0), 0) || 1)
                  )
                  * 100,
                )
              }%`
              : 'Download files'
          )}
        </span>
      </StyledMenuButton>

      <Popper
        id="documents-popper"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
      >
        <StyledPaper>
          <ClickAwayListener onClickAway={handleClose}>
            <StyledMenuList
              id="documents-menu-list"
              autoFocusItem={Boolean(anchorEl)}
            >
              {!!documents.length && (
                <StyledMenuItem
                  component="a"
                  onClick={() => downloadFile({ downloadAll: true })}
                >
                  <span
                    title="DOWNLOAD ALL (AS ZIP)"
                    style={{ fontWeight: 700 }}
                    className={classes.filename}
                  >
                    {downloadAllFilePreparing && (
                      <b>
                        {Math.round(((downloadAllFilePreparing.loaded ?? 0) / (downloadAllFilePreparing.total ?? 1)) * 100)}
                        %
                        {' '}
                      </b>
                    )}
                    DOWNLOAD ALL (AS ZIP)
                  </span>
                </StyledMenuItem>
              )}
            </StyledMenuList>
          </ClickAwayListener>
        </StyledPaper>
      </Popper>
    </div>
  );
};

const StyledPaper = withStyles({
  root: {
    maxHeight: '200px',
    marginTop: '20px',
    padding: '30px 0',
    borderRadius: '8px',
    background: '#271E8E',
    position: 'relative',

    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 0,
      height: 0,
      border: '16px solid transparent',
      borderBottomColor: '#271E8E',
      borderTop: 0,
      borderLeft: 0,
      transform: 'translate3d(190px, -6px, 0) rotate(135deg)',
    },
  },
})(Paper);

const StyledMenuList = withStyles({
  root: {
    width: '220px',
    maxHeight: '100px',
    overflow: 'auto',
    padding: 'unset',

    /* firefox scrollbar */
    scrollbarWidth: 'thin',
    scrollbarColor: '#8F8F8F #271E8E',

    /* webkit browsers scrollbar */
    '&::-webkit-scrollbar': {
      width: '3px',
      backgroundColor: '#271E8E',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#8F8F8F',
      borderRadius: '4px',
    },
  },
})(MenuList);

const StyledMenuItem = withStyles({
  root: {
    justifyContent: 'flex-end',
    padding: '4px 8px',
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 'normal',
    whiteSpace: 'normal',
    textAlign: 'right',
    color: '#FFFFFF',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
})(MenuItem);

const StyledMenuButton = styled(({ toggleRotate, children, ...other }) => (
  <Button {...other}>{children}</Button>
))({
  '& svg': {
    transform: props => props.toggleRotate && 'rotateZ(-180deg)',
    transition: 'transform 0.25s ease-in-out',
  },
});

DocumentsMenu.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      filename: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      downloadUrl: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  listingId: PropTypes.number.isRequired,
  onDownload: PropTypes.func.isRequired,
};

export default withStyles(styles)(DocumentsMenu);
