import React from 'react';
import { useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import styles from './styles';
import { updateUser } from 'store/actions/userActions';
import { navigateToNextPage, navigateToPreviousPage } from 'constants/registrationFlow';

function PermissionsView({ classes }) {
  const [permission, setPermission] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ margin: 'auto', maxWidth: '642px' }}
      >
        <Typography variant="h1" component="div" className={classes.title}>
          Onboard Your Entity
        </Typography>
        <Typography variant="h5" component="div" className={classes.subTitle}>
          Will you be the account manager responsible for your entity?
        </Typography>
        <div className={classes.optionContainer}>
          <Button
            className={classes.button}
            variant="outlined"
            onClick={() => setPermission(true)}
          >
            No
          </Button>
          <Button
            className={classes.button}
            variant="outlined"
            onClick={() => {
              dispatch(updateUser({ value: true, key: 'isAccountManager' }));
              history.push(navigateToNextPage(history.location.pathname));
            }}
          >
            Yes
          </Button>
        </div>
        {permission && (
          <div className={classes.errorContainer}>
            <Typography
              variant="h5"
              component="div"
              className={classes.subTitle}
            >
              Looks like you are not authorized
            </Typography>
            <Typography
              variant="body1"
              component="div"
              className={classes.subText}
            >
              Please coordinate with your team as only authorized signers are
              allowed to complete the initial registration of your entity.
            </Typography>
            <Button
              className={classes.button}
              variant="contained"
              href="/contact-us"
            >
              Contact Us
            </Button>
          </div>
        )}
        <div className={classes.optionContainer} style={{ marginTop: '50px' }}>
          <Button
            className={classes.backButton}
            variant="text"
            onClick={() => history.push(navigateToPreviousPage(history.location.pathname))}
          >
            Back
          </Button>
        </div>
      </Grid>
    </div>
  );
}

PermissionsView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles))(PermissionsView);
