import {
  all, fork, takeLatest, take, put, call, select,
} from 'redux-saga/effects';

// auth actions and sagas
import { sendBankInformation, updateBankInformation } from 'store/actions/authActions';
import { getUserDataFromAccessToken } from 'store/sagas/auth';
import { returnToPreviousPageInSaga } from 'now-frontend-shared/layouts/AuthSections/HeadSection';

// api
import Api from 'api/user';

// storage helpers functions
import * as storage from 'now-frontend-shared/utils/storage';

function* ensureSendBankInformation({ payload }) {
  const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
  const isAuthorized = yield select(({ auth }) => auth.authInfo.isAuthorized);
  const userId = yield select(({ auth }) => auth.user.id);
  try {
    yield call(Api.sendBankInformation(userId), { data: JSON.stringify(payload) });
    if (isAuthorized) yield call(getUserDataFromAccessToken, { accessToken });
    yield put({ type: sendBankInformation.success });
  } catch (err) {
    yield put({
      type: sendBankInformation.failure,
      err,
    });
  }
}

function* watchSendingBankInformation() {
  yield takeLatest(sendBankInformation.type, ensureSendBankInformation);
  yield take(sendBankInformation.success);
  yield returnToPreviousPageInSaga('/company-dashboard');
}

function* ensureUpdateBankInformation({ payload }) {
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    yield call(Api.updateBankInformation, {
      data: payload,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    yield call(getUserDataFromAccessToken, { accessToken });
    yield put({ type: updateBankInformation.success });
  } catch (err) {
    yield put({ type: updateBankInformation.failure, err });
  }
}

function* watchUpdateBankInformation() {
  yield takeLatest(updateBankInformation.type, ensureUpdateBankInformation);
  yield take(updateBankInformation.success);
  yield returnToPreviousPageInSaga('/company-dashboard');
}

export default function* bankInformationSagas() {
  yield all([fork(watchSendingBankInformation), fork(watchUpdateBankInformation)]);
}
