import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styled error field
import ErrorField from 'now-frontend-shared/components/inputs/ErrorField';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import { StyledTextField } from 'components/inputs/styledTextField';

// styles
import styles from './styles';

const ForgotPasswordForm = ({
  classes, input, meta, label, placeholder,
}) => {
  const isError = meta.touched && meta.error;

  return (
    <Grid container className={classes.wrapper}>
      <Grid container justify="flex-start" className={classes.labelContainer}>
        <label htmlFor={input.name}>{label}</label>
      </Grid>

      <Grid container direction="row">
        <Grid item xs={12} sm={7} md={7} lg={7}>
          <StyledTextField className={classes.fieldStyles} placeholder={placeholder} id={input.name} {...input} variant="outlined" />
        </Grid>

        <Grid container item xs alignItems="center" justify="center">
          <Button type="submit" variant="contained" color="primary" className={classes.sendButton}>
            Send Me Reset Link
          </Button>
        </Grid>
      </Grid>

      <Grid container item xs={7} justify="center" alignItems="center">
        {isError && <ErrorField error={meta.error} />}
      </Grid>
    </Grid>
  );
};

ForgotPasswordForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default compose(withStyles(styles), memo)(ForgotPasswordForm);
