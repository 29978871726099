import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';

// arrow image
import { ReactComponent as ArrowIcon } from 'now-frontend-shared/assets/icons/pagination_arrow.svg';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';

const SingleArrowButton = ({
  classes, isReversed, disabled, onClick,
}) => (
  <div
    className={cx(classes.button, { [classes.reverse]: isReversed })}
    style={{ cursor: disabled && 'unset' }}
    onClick={disabled ? null : onClick}
  >
    <ArrowIcon className={cx({ [classes.disabled]: disabled })} />
  </div>
);

SingleArrowButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isReversed: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default compose(withStyles(styles), memo)(SingleArrowButton);
