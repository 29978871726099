import { TextField } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { primaryDark } from 'themes/colors';

/**
 * @deprecated use the duplicate version of this component in `frontend-shared` instead. Any changes to that
 * file also need to be made to this file as well. Eventually, this file here should be removed.
 *
 * TODO: [DRY][REFACTOR] Delete this file when all components that use StyledTextField
 * are refactored to import from frontend-shared (NOW-938)
 */
export const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: primaryDark,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: primaryDark,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.2)',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: primaryDark,
    },
  },
});
