// components
import PasswordInput from 'now-frontend-shared/components/inputs/PasswordInput';

export const settingsFields = [
  {
    name: 'oldPassword',
    layoutProps: {
      label: 'Current password',
      isRequired: true,
    },
    inputProps: {
      component: PasswordInput,
      props: {
        placeholder: '••••••••',
      },
    },
  },
  {
    name: 'password',
    layoutProps: {
      label: 'New password',
      isRequired: true,
    },
    inputProps: {
      component: PasswordInput,
      props: {
        placeholder: '••••••••',
      },
    },
  },
  {
    name: 'confirmPassword',
    layoutProps: {
      label: 'Verify password',
      isRequired: true,
    },
    inputProps: {
      component: PasswordInput,
      props: {
        placeholder: '••••••••',
      },
    },
  },
];

export const notificationsFields = [
  {
    name: 'confirmingBidsEmails',
    label: 'Receive an email confirming bids you make',
  },
  // {
  //   name: 'outBidsEmails',
  //   label: 'Receive email when you are outbid.',
  // },
  {
    name: 'closesAuctionEmails',
    label: 'Receive an email when an auction you have bid on closes',
  },
  {
    name: 'newListingsEmails',
    label: 'Notify me by e-mail of new listings',
  },
];
