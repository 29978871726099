import { makeAxiosRequest } from 'utils/apiMethods';

export default {
  sendResetPasswordLink: makeAxiosRequest(
    `${process.env.REACT_APP_API_URL}/auth/send-reset-password-link`,
    {
      method: 'post',
    },
  ),
  loginByRefreshToken: makeAxiosRequest(
    `${process.env.REACT_APP_API_URL}/auth/login-by-refresh-token`,
    {
      method: 'post',
    },
  ),
  saveNewPassword: makeAxiosRequest(
    `${process.env.REACT_APP_API_URL}/auth/save-new-password`,
    {
      method: 'post',
    },
  ),
  confirmEmail: makeAxiosRequest(
    `${process.env.REACT_APP_API_URL}/auth/confirm-email`,
    {
      method: 'post',
    },
  ),
  signUp: makeAxiosRequest(`${process.env.REACT_APP_API_URL}/auth/register`, {
    method: 'post',
  }),
  contactUs: makeAxiosRequest(`${process.env.REACT_APP_API_URL}/contact-us`, {
    method: 'post',
  }),
  signIn: makeAxiosRequest(`${process.env.REACT_APP_API_URL}/auth/login`, {
    method: 'post',
  }),
  getUserData: makeAxiosRequest(`${process.env.REACT_APP_API_URL}/auth/me`, {
    method: 'get',
  }),
  updateAccount: makeAxiosRequest(`${process.env.REACT_APP_API_URL}/auth/me`, {
    method: 'put',
  }),
};
