// actions
import { setUserData } from 'store/actions/authActions';
import * as A from '../actions/companyActions';

const officerInformation = {
  name: null,
  title: null,
  governmentIssuedId: null,
  socialSecurityNumber: null,
  placeOfIssuance: null,
  dateOfBirth: null,
};

const initialState = {
  companyId: null,
  businessStreetAddress: '',
  businessStreetAddress2: '',
  city: '',
  zipCode: '',
  stateId: '',
  optionBestSuitsEntity1: null,
  optionBestSuitsEntity2: null,
  fullLegalCompanyName: null,
  einOrTin: null,
  operatingName: null,
  role: null,
  BO25PercentOfCompanyOrMore: null,
  BOFirstName: null,
  BOLastName: null,
  BOStreetAddress: null,
  BOStreetAddress2: null,
  BOCity: null,
  BOstateId: null,
  BOZipCode: null,
  BOSSNorTin: null,
  BOBirthDate: null,
  BOPercentageOwnership: null,
  BOTitle: null,
  signedMasterCertificate: false,
  signedInvestmentAdvisorCertification: false,
  officers: [officerInformation],
  stockTicker: null,
  stockExchange: null,
  isPublicCompany: false,
  companyIsUpdating: false,
  accountManagerSignature: null,
};

export const reducer = {
  company(state = initialState, action) {
    switch (action.type) {
      case A.updateCompany.type:
        if (action.payload.key === 'BO25PercentOfCompanyOrMore') {
          if (!action.payload.value) {
            return {
              ...state,
              [action.payload.key]: action.payload.value,
              BOFirstName: null,
              BOLastName: null,
              BOStreetAddress: null,
              BOStreetAddress2: null,
              BOCity: null,
              BOstateId: null,
              BOZipCode: null,
              BOSSNorTin: null,
              BOBirthDate: null,
              BOPercentageOwnership: null,
              BOTitle: null,
            };
          }
          return {
            ...state,
            [action.payload.key]: action.payload.value,
            isPublicCompany: false,
            stockTicker: null,
            stockExchange: null,
          };
        }
        return { ...state, [action.payload.key]: action.payload.value };
      case setUserData.type:
        if (action.payload.company) {
          return {
            ...state,
            fullLegalCompanyName: action.payload.company.fullLegalCompanyName,
            operatingName: action.payload.company.operatingName,
            einOrTin: action.payload.company.einOrTin,
            role: action.payload.company.role,
            businessStreetAddress: action.payload.company.businessStreetAddress,
            businessStreetAddress2: action.payload.company.businessStreetAddress2,
            city: action.payload.company.city,
            zipCode: action.payload.company.zipCode,
            stateId: action.payload.company.stateId,
            optionBestSuitsEntity1: action.payload.company.optionBestSuitsEntity1,
            optionBestSuitsEntity2: action.payload.company.optionBestSuitsEntity2,
            BOFirstName: action.payload.company.BOFirstName,
            BOLastName: action.payload.company.BOLastName,
            BOPercentageOwnership: action.payload.company.BOPercentageOwnership,
            BOTitle: action.payload.company.BOTitle,
            BOBirthDate: action.payload.company.BOBirthDate,
            BOSSNorTin: action.payload.company.BOSSNorTin,
            BOStreetAddress: action.payload.company.BOStreetAddress,
            BOStreetAddress2: action.payload.company.BOStreetAddress2,
            BOCity: action.payload.company.BOCity,
            BOstateId: action.payload.company.BOstateId,
            BOZipCode: action.payload.company.BOZipCode,
            officers: action.payload.company.officers?.length ? action.payload.company.officers : [officerInformation],
            BO25PercentOfCompanyOrMore: action.payload.company.BO25PercentOfCompanyOrMore,
            bankInformation: action.payload.company.bankInformation,
            stockExchange: action.payload.company.stockExchange,
            stockTicker: action.payload.company.stockTicker,
            isPublicCompany: action.payload.company.isPublicCompany,
          };
        }
        return state;

      case A.createNewCompany.success:
        return { ...state, companyId: action.payload.data.id };
      case A.clearState.type:
        return initialState;
      case A.updateOfficer.type:
        return {
          ...state,
          officers: state.officers.map((officer, index) => {
            if (index === action.payload.index) {
              return {
                ...officer,
                [action.payload.key]: action.payload.value,
              };
            }
            return officer;
          }),
        };
      case A.addOfficer.type:
        return {
          ...state,
          officers: [...state.officers, { ...officerInformation }],
        };
      case A.deleteOfficer.type:
        return {
          ...state,
          officers: state.officers.filter((officer, index) => index !== action.payload.index),
        };
      case A.persistCompanyUpdates.type:
        return {
          ...state,
          companyIsUpdating: true,
        };
      case A.persistCompanyUpdates.success:
        return {
          ...state,
          companyIsUpdating: false,
        };
      case A.persistCompanyUpdates.failure:
        return {
          ...state,
          companyIsUpdating: false,
        };
      default:
        return state;
    }
  },
};
