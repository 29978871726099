import {
  STOCK_TICKER_SYMBOL_MAX_LENGTH,
  STOCK_TICKER_SYMBOL_MIN_LENGTH,
  STOCK_EXCHANGE_MIC_MAX_LENGTH,
  STOCK_EXCHANGE_MIC_MIN_LENGTH,
  REQUIRED,
} from 'now-shared/validation/validation-rules';

export const validateStockTicker = stockTicker => {
  if (stockTicker) {
    if (stockTicker.length < STOCK_TICKER_SYMBOL_MIN_LENGTH) {
      return `Must be at least ${STOCK_TICKER_SYMBOL_MIN_LENGTH} characters`;
    }
    if (stockTicker.length > STOCK_TICKER_SYMBOL_MAX_LENGTH) {
      return `Must be less than ${STOCK_TICKER_SYMBOL_MAX_LENGTH} characters`;
    }
  } else {
    return REQUIRED;
  }
};

export const validateStockExchangeMic = stockExchangeMic => {
  if (stockExchangeMic) {
    if (stockExchangeMic.length < STOCK_EXCHANGE_MIC_MIN_LENGTH) {
      return `Must be at least ${STOCK_EXCHANGE_MIC_MIN_LENGTH} characters`;
    }
    if (stockExchangeMic.length > STOCK_EXCHANGE_MIC_MAX_LENGTH) {
      return `Must be less than ${STOCK_EXCHANGE_MIC_MAX_LENGTH} characters`;
    }
  } else {
    return REQUIRED;
  }
};
