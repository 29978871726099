/* eslint-disable no-console */
import React, {
  memo, useEffect, useCallback, useMemo,
} from 'react';
import ReactDatePicker from 'react-date-picker';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import moment from 'moment';

// calendar icon
import { ReactComponent as CalendarIcon } from 'now-frontend-shared/assets/icons/calendar.svg';

// styled error field
import ErrorField from '../inputs/ErrorField';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// helpers
import { setLocalZone, setOtherZone } from 'now-shared/helpers/time-helpers';

// styles
import styles from './styles';

const debug = false;

const DatePicker = ({
  classes,
  input,
  meta,
  minDate,
  maxDate,
  required,
  timeZone,
  disabled,
}) => {
  const isError = meta.touched && meta.error;

  const {
    value,
    onBlur,
    onChange,
    ...inputRest
  } = input;

  const translatedValue = useMemo(() => {
    let result;
    if (input.value && input.value instanceof Date && !Number.isNaN(input.value.getTime())) {
      result = timeZone ? setLocalZone(input.value, timeZone) : input.value;
    }
    return result;
  }, [input.value, timeZone]);

  const translatedMinDate = useMemo(() => (
    minDate && (
      timeZone ? setLocalZone(minDate, timeZone) : minDate
    )
  ), [minDate, timeZone]);

  const translatedMaxDate = useMemo(() => (
    maxDate && (
      timeZone ? setLocalZone(maxDate, timeZone) : maxDate
    )
  ), [maxDate, timeZone]);

  if (debug) {
    console.debug('timeZone', timeZone);
    console.debug('minDate', minDate);
    console.debug('translatedMinDate', translatedMinDate);
    console.debug('maxDate', maxDate);
    console.debug('translatedMaxDate', translatedMaxDate);
    console.debug('input.value', typeof input.value, input.value);
    console.debug('input', input);
    console.debug('translatedValue', translatedValue);
    console.debug('disabled', disabled);
    console.debug('required', required);
  }

  const changeDate = useCallback(newDate => (
    input.onChange(timeZone ? setOtherZone(newDate, timeZone) : newDate)
  ), [input, timeZone]);

  useEffect(() => {
    // TODO: [UX][NOTIFICATIONS] Should we change the value at all here? Should we at least notify the user of
    // the change somehow?
    if (translatedValue) {
      if (translatedMinDate && translatedValue < translatedMinDate) {
        changeDate(translatedMinDate);
      } else if (translatedMaxDate && translatedValue > translatedMaxDate) {
        changeDate(translatedMaxDate);
      }
    }
  }, [changeDate, translatedMinDate, translatedMaxDate, translatedValue]);

  const handleChange = date => {
    if (debug) {
      console.debug('ReactDatePicker.onChange', typeof date, date);
    }
    const transformedDate = moment(date, 'MM.DD.YYYY').hours(0).minutes(0).seconds(0)
      .milliseconds(0)
      .toDate();
    changeDate(transformedDate);
  };

  return (
    <Grid container direction="column">
      <ReactDatePicker
        locale="en-US"
        showLeadingZeros
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="yyyy"
        minDate={translatedMinDate}
        maxDate={translatedMaxDate}
        calendarIcon={<CalendarIcon />}
        clearIcon={null}
        value={translatedValue}
        onChange={handleChange}
        onBlur={() => onBlur?.()}
        disabled={disabled}
        className={classes.input}
        {...inputRest}
      />

      <ErrorField error={isError ? meta.error : null} />
    </Grid>
  );
};

DatePicker.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
  }).isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  required: PropTypes.bool,
  timeZone: PropTypes.string,
  disabled: PropTypes.bool,
};

DatePicker.defaultProps = {
  minDate: undefined,
  maxDate: undefined,
  required: false,
  timeZone: undefined,
  disabled: false,
};

export default compose(withStyles(styles), memo)(DatePicker);
