import { createTheme } from '@material-ui/core';
import {
  lightGrey,
  white,
  green,
  blue,
  cyan,
  grey,
  red,
  yellow,
  primaryLight,
  textPrimary,
  textSecondary,
  primaryDark,
  primaryMain,
  primaryContrast,
  secondaryMain,
  secondaryLight,
  secondaryDark,
  secondaryContrast,
} from './colors';

const theme = createTheme({
  colors: {
    lightGrey,
    white,
    green,
    blue,
    cyan,
    grey,
    red,
    yellow,
  },
  palette: {
    background: {
      default: primaryLight,
    },
    primary: {
      main: primaryMain,
      light: primaryLight,
      dark: primaryDark,
      contrastText: primaryContrast,
    },
    secondary: {
      main: secondaryMain,
      light: secondaryLight,
      dark: secondaryDark,
      contrastText: secondaryContrast,
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
    },
  },
});

export default theme;
