import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from '../styles';

const CheckIcon = ({ classes }) => <span className={cx(classes.icon, classes.checkIcon)} />;

CheckIcon.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), memo)(CheckIcon);
