import React, { useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { StyledTextField } from 'components/inputs/styledTextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import styles from './styles';
import { updateCompany } from 'store/actions/companyActions';
import { getAllStates } from 'store/actions/statesActions';
import { navigateToNextPage, navigateToPreviousPage } from 'constants/registrationFlow';

function Business({
  classes,
  company,
  states,
  authUser,
  user,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  React.useEffect(() => {
    dispatch(getAllStates());
  }, [dispatch]);

  React.useEffect(() => {
    if (typeof company.stateId === 'number' && states?.states?.length) {
      dispatch(updateCompany({ value: states.states.find(state => state.id === company.stateId), key: 'stateId' }));
    }
  }, [dispatch, company.stateId, states.states]);

  const companyRef = useRef();
  companyRef.current = company;

  const userRef = useRef();
  userRef.current = user;

  const authUserRef = useRef();
  authUserRef.current = authUser;

  const businessFields = [
    { label: 'Address', value: 'businessStreetAddress' },
    { label: 'Address (optional)', value: 'businessStreetAddress2' },
  ];
  const createFields = (list, autoFocus) => (
    <>
      {list.map(({ label, value }, i) => (
        <StyledTextField
          value={company[value]}
          key={label}
          variant="outlined"
          onChange={e => {
            dispatch(updateCompany({ value: e.target.value, key: value }));
          }}
          id="standard-full-width"
          label={label}
          placeholder={label}
          fullWidth
          margin="normal"
          autoFocus={!!autoFocus && i === 0}
        />
      ))}
    </>
  );

  const requiredCompany = {
    businessStreetAddress: company.businessStreetAddress,
    city: company.city,
    zipCode: company.zipCode,
    stateId: company.stateId,
  };

  const areBusinessFieldsValid
    = Object.values(requiredCompany).every(v => {
      if (!v) return false;
      if (typeof v === 'object' || v.length > 0) return true;
      return false;
    });

  const renderContent = () => (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      style={{ margin: 'auto', maxWidth: '642px' }}
    >
      <Typography variant="h1" component="div" className={classes.title}>
        Business Address
      </Typography>
      <Typography variant="body1" component="div" className={classes.text}>
        Input business address of the entity that will transact
      </Typography>
      {createFields(businessFields)}
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <StyledTextField
            value={company.city}
            key="city"
            variant="outlined"
            onChange={e => {
              dispatch(updateCompany({ value: e.target.value, key: 'city' }));
            }}
            id="standard-full-width"
            label="City"
            placeholder="City"
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={3}>
          <StyledTextField
            value={company.zipCode}
            key="zip"
            variant="outlined"
            onChange={e => {
              dispatch(
                updateCompany({ value: e.target.value, key: 'zipCode' }),
              );
            }}
            id="standard-full-width"
            label="Zip"
            placeholder="Zip"
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            margin="normal"
            fullWidth
            value={company.stateId}
            onChange={(_, newValue) => {
              dispatch(updateCompany({ value: newValue, key: 'stateId' }));
            }}
            id="state-select"
            options={states.states}
            style={{
              maxWidth: 629,
              marginTop: '16px',
              marginBottom: '8px',
            }}
            getOptionLabel={option => option.title || ''}
            renderInput={params => (
              <StyledTextField {...params} label="State" variant="outlined" />
            )}
          />
        </Grid>
      </Grid>
      <div className={classes.buttonContainer}>
        <Button
          onClick={() => history.push(navigateToPreviousPage(history.location.pathname))}
          className={classes.backButton}
          variant="text"
        >
          BACK
        </Button>
        <Button
          className={classes.button}
          disabled={!areBusinessFieldsValid}
          onClick={() => history.push(navigateToNextPage(history.location.pathname))}
          variant="contained"
        >
          NEXT
        </Button>
      </div>
    </Grid>
  );

  return <div className={classes.root}>{renderContent()}</div>;
}

Business.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  company: PropTypes.objectOf(PropTypes.any).isRequired,
  states: PropTypes.objectOf(PropTypes.any).isRequired,
  authUser: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default compose(
  connect(({
    company, states, auth, user,
  }) => ({
    authUser: auth.user,
    company,
    states,
    user,
  })),
  withStyles(styles),
)(Business);
