import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

// styles and components from material-ui
import {
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button,
} from '@material-ui/core';

const TitleEditDialog = ({
  open, title, handleClose, handleSetNewTitle,
}) => {
  const [newTitle, setNewTitle] = useState(title);

  const handleChange = useCallback(
    ({ target: { value } }) => {
      setNewTitle(value);
    },
    [setNewTitle],
  );

  const submitEditTitle = useCallback(() => {
    handleSetNewTitle({ newTitle });
    handleClose();
  }, [handleClose, handleSetNewTitle, newTitle]);

  const onHandleClose = useCallback(() => {
    handleClose();
    setNewTitle(title);
  }, [handleClose, setNewTitle, title]);

  return (
    <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit file name</DialogTitle>
      <DialogContent>
        <TextField
          label="New file name"
          margin="dense"
          fullWidth
          autoFocus
          id="newTitle"
          value={newTitle}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onHandleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={submitEditTitle} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TitleEditDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSetNewTitle: PropTypes.func.isRequired,
};

export default TitleEditDialog;
