import {
  all, fork, takeLatest, take, put, select, call,
} from 'redux-saga/effects';

// auth actions and sagas
import {
  getBuyerAgreementTemplate,
  sendBuyerAgreement,
  updateBuyerAgreement,
} from 'store/actions/authActions';
import { getUserDataFromAccessToken } from 'store/sagas/auth';

// api
import Api from 'api/user';

// storage helpers functions
import * as storage from 'now-frontend-shared/utils/storage';

function* ensureGetBuyerAgreementTemplate({ payload }) {
  let resolve;
  let reject;
  if (payload) {
    ({ resolve, reject } = payload);
  }
  try {
    const { data } = yield call(Api.getBuyerAgreementTemplate);
    yield put({
      type: getBuyerAgreementTemplate.success,
      payload: {
        document: data.content,
        version: data.updatedAt,
      },
    });
    if (resolve) {
      resolve(data);
    }
  } catch (err) {
    yield put({
      type: getBuyerAgreementTemplate.failure,
      err,
    });
    if (reject) {
      reject(err);
    }
  }
}

function* watchGetBuyerAgreementTemplate() {
  yield takeLatest(getBuyerAgreementTemplate.type, ensureGetBuyerAgreementTemplate);
  yield take(getBuyerAgreementTemplate.success);
}

function* ensureSendBuyerAgreement({ payload }) {
  const companyId = yield select(({ auth }) => auth.user.companyId);
  const {
    resolve,
    reject,
    ...payloadRest
  } = payload;
  try {
    if (!companyId) {
      const e = new Error();
      e.response = {
        data: {
          message: "You don't have a company",
        },
      };
      throw e;
    }
    yield call(Api.sendBuyerAgreement(companyId), { data: JSON.stringify(payloadRest) });
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    const isAuthorized = yield select(({ auth }) => auth.authInfo.isAuthorized);
    if (isAuthorized) yield call(getUserDataFromAccessToken, { accessToken });
    yield put({ type: sendBuyerAgreement.success });
    if (resolve) {
      resolve();
    }
  } catch (err) {
    yield put({ type: sendBuyerAgreement.failure, err });
    if (reject) {
      reject(err);
    }
  }
}

function* watchSendingBuyerAgreement() {
  yield takeLatest(sendBuyerAgreement.type, ensureSendBuyerAgreement);
  yield take(sendBuyerAgreement.success);
}

function* ensureUpdateBuyerAgreement({ payload }) {
  const {
    resolve,
    reject,
    ...payloadRest
  } = payload;
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    yield call(Api.updateBuyerAgreement, {
      data: payloadRest,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    yield call(getUserDataFromAccessToken, { accessToken });
    yield put({ type: updateBuyerAgreement.success });
    if (resolve) {
      resolve();
    }
  } catch (err) {
    yield put({ type: updateBuyerAgreement.failure, err });
    if (reject) {
      reject(err);
    }
  }
}

function* watchUpdateBuyerAgreement() {
  yield takeLatest(updateBuyerAgreement.type, ensureUpdateBuyerAgreement);
  yield take(updateBuyerAgreement.success);
}

export default function* buyerAgreementSagas() {
  yield all([
    fork(watchSendingBuyerAgreement),
    fork(watchGetBuyerAgreementTemplate),
    fork(watchUpdateBuyerAgreement),
  ]);
}
