import {
  all, fork, takeLatest, take, put, select, call,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import queryString from 'query-string';

// actions
import {
  setResetPasswordToken,
  sendResetPasswordLink,
  changePassword,
  confirmEmail,
  signIn,
} from 'store/actions/authActions';

import { isUserRegistrationComplete } from 'now-shared/helpers/auth-helpers';
import * as storage from 'now-frontend-shared/utils/storage';
import Api from 'api/auth';
import GA4React from 'ga-4-react';
import { getUserDataFromAccessToken } from 'store/sagas/auth';
import { isAdminOrCompliancePerson } from 'now-shared/validation/admin-upsert-user';
import {
  hasCompanyInfoSaved, hasCompanyInfoToSubmit, hasCompletedPersonalInfoSection, hasPersonalInfoSaved,
} from 'layouts/RegistrationRoute';
import {
  APPROVAL_STATUS_ROUTE,
  BENEFICIAL_OWNER_UPLOADS,
  BUSINESS_ADDRESS_ROUTE,
  BUYER_AGREEMENT_ROUTE,
  CERTIFY_INFO_ROUTE,
  FINANCING_INFORMATION_ROUTE,
  LIST_OFFICERS_ROUTE,
  PERMISSION_ROUTE,
  REGISTRATION_ROUTE,
  SELLER_AGREEMENT_ROUTE,
  UPLOAD_COMPANY_FILES_ROUTE,
} from 'constants/registrationFlow';
import { documentTypes } from 'now-shared/helpers/document-types';

function* watchQuery() {
  const query = yield select(({ router }) => queryString.parse(router.location.search, { arrayFormat: 'bracket' }));
  const confirmEmailQuery = query.code;
  const resetPasswordQuery = query.resetToken;

  if (confirmEmailQuery) {
    yield put(confirmEmail(confirmEmailQuery));
  }

  if (resetPasswordQuery) {
    yield put(setResetPasswordToken(resetPasswordQuery));
  }
}

function* ensureConfirmEmail({ payload }) {
  try {
    const { data } = yield call(Api.confirmEmail, {
      data: JSON.stringify({ emailVerificationCode: payload }),
    });
    yield put({ type: confirmEmail.success, payload: data });
  } catch (err) {
    yield put({
      type: confirmEmail.failure,
      err,
    });
  }
}

function* watchConfirmedEmail() {
  yield takeLatest(confirmEmail.type, ensureConfirmEmail);
  yield take(confirmEmail.success);
  yield put(push('/'));
}

function reportSignIn() {
  if (GA4React.isInitialized()) {
    const ga = GA4React.getGA4React();
    ga.gtag('event', 'login', {
      method: 'email',
    });
  }
}

// storage helpers functions

export function persistToken({ accessToken, refreshToken }) {
  storage.setStorageType(storage.LOCAL_STORAGE);
  if (accessToken) {
    storage.setStorageItem('accessToken', JSON.stringify(accessToken));
  }
  if (refreshToken) {
    storage.setStorageItem('refreshToken', JSON.stringify(refreshToken));
  }
}

export function* ensureSendResetPasswordLink({ payload }) {
  try {
    yield call(Api.sendResetPasswordLink, { data: JSON.stringify(payload) });
    yield put({ type: sendResetPasswordLink.success });
  } catch (err) {
    yield put({
      type: sendResetPasswordLink.failure,
      err,
    });
  }
}

export function* watchSendingResetPasswordLink() {
  yield takeLatest(sendResetPasswordLink.type, ensureSendResetPasswordLink);
  yield take(sendResetPasswordLink.success);
}

export function* ensureChangePassword({ payload }) {
  try {
    yield call(Api.saveNewPassword, { data: JSON.stringify(payload) });
    yield put({ type: changePassword.success });
  } catch (err) {
    yield put({
      type: changePassword.failure,
      err,
    });
  }
}

function* watchPasswordChanges() {
  yield takeLatest(changePassword.type, ensureChangePassword);
  yield take(changePassword.success);
  yield put(push('/'));
}

export function* getRedirectPath() {
  const query = yield select(({ router }) => queryString.parse(router.location.search, { arrayFormat: 'bracket' }));
  return query?.next;
}

export function getRedirectPathAfterSignIn(user, redirectPath) {
  let path;
  const incorporationFile = user?.company?.documents?.find(
    v => v.type === documentTypes.CERTIFIED_ARTICLES_OF_INCORPORATION,
  );
  const formationFile = user?.company?.documents?.find(
    v => v.type === documentTypes.FORM_W9,
  );
  const officerAgreement = user?.company?.documents?.find(
    v => v.type === documentTypes.OFFICER_CORPORATE_AGREEMENT,
  );
  const beneficialOwnerAgreement = user?.company?.documents?.find(
    v => v.type === documentTypes.BENEFICIAL_OWNER_FILES,
  );

  const hasRequiredUploadsDocuments = incorporationFile && formationFile;

  if (redirectPath) {
    path = decodeURIComponent(redirectPath);
  } else if (!isUserRegistrationComplete(user) && !isAdminOrCompliancePerson(user)) {
    if (!hasCompanyInfoSaved(user) && (!hasPersonalInfoSaved(user, 'all') || !user.isAccountManager)) {
      path = PERMISSION_ROUTE;
    } else if (!hasCompletedPersonalInfoSection(user)) {
      path = REGISTRATION_ROUTE;
    } else if (!hasCompanyInfoToSubmit(user?.company)) {
      path = BUSINESS_ADDRESS_ROUTE;
    } else if (!hasRequiredUploadsDocuments) {
      path = UPLOAD_COMPANY_FILES_ROUTE;
    } else if (!officerAgreement) {
      path = LIST_OFFICERS_ROUTE;
    } else if (user.company?.BO25PercentOfCompanyOrMore && !beneficialOwnerAgreement) {
      path = BENEFICIAL_OWNER_UPLOADS;
    } else if (!user?.company?.buyerAgreement) {
      path = BUYER_AGREEMENT_ROUTE;
    } else if (user?.company?.buyerAgreement && !user?.company?.bankInformation) {
      path = FINANCING_INFORMATION_ROUTE;
    } else if (!user?.company?.sellerAgreement) {
      path = SELLER_AGREEMENT_ROUTE;
    } else if (!user?.company?.certification) {
      path = CERTIFY_INFO_ROUTE;
    }
  } else if (user.approvalStatus?.title !== 'approved' && !isAdminOrCompliancePerson(user)) {
    path = APPROVAL_STATUS_ROUTE;
  } else if (
    user.company?.hasBuyerAgreement
    && user.company?.approved
    && user.company?.active
  ) {
    path = '/listings';
  } else {
    path = '/dashboard';
  }
  return path;
}

export function* redirectAfterSignIn(redirectPath) {
  const user = yield select(state => state.auth.user);
  const path = getRedirectPathAfterSignIn(user, redirectPath);
  yield put(push(path));
}

export function* ensureSignIn({ payload }) {
  try {
    const redirectPath = yield getRedirectPath();
    const {
      data: { accessToken, refreshToken },
    } = yield call(Api.signIn, { data: JSON.stringify(payload) });
    yield call(reportSignIn);
    yield call(persistToken, { accessToken, refreshToken });
    yield call(getUserDataFromAccessToken, { accessToken });
    yield call(redirectAfterSignIn, redirectPath);
    yield put({ type: signIn.success });
  } catch (err) {
    yield put({
      type: signIn.failure,
      err,
    });
  }
}

function* watchSignIn() {
  yield takeLatest(signIn.type, ensureSignIn);
  yield take(signIn.success);
}

export default function* mainPageSagas() {
  yield all([
    fork(watchSendingResetPasswordLink),
    fork(watchPasswordChanges),
    fork(watchConfirmedEmail),
    fork(watchSignIn),
    fork(watchQuery),
  ]);
}
