import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';

// modals
import SignInModal from 'components/Modals/SignInModal';

// custom hooks
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';

// styles and components from Material-UI
import { withStyles } from '@material-ui/core/styles';
import { Menu, MenuItem } from '@material-ui/core';

// helpers
import { isAdminOrCompliancePerson } from 'now-shared/validation/admin-upsert-user';
import { getRedirectPathAfterSignIn } from 'store/sagas/mainPageSagas';

// styles
import styles from './styles';

// store
import { removeUserData } from 'store/actions/authActions';

const BurgerMenu = ({
  classes,
  hasBuyerAgreement,
  hasSellerAgreement,
  isAdminOrComplianceUser,
  isAuthorized,
  user,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { setModal } = useModalSetter();
  const dispatch = useDispatch();
  const history = useHistory();

  const userIsApproved = user.approvalStatus?.title === 'approved';
  const companyIsApprovedAndActive = !!user.company?.approved && !!user.company?.active;

  const handleModalOpen = () => {
    setModal(<SignInModal />);
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    history.push('/');
    dispatch(removeUserData());
    setAnchorEl(null);
  };

  const handleClick = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className={cx(classes.burgerMenu, {
        [classes.opened]: Boolean(anchorEl),
      })}
    >
      <div className={classes.menuIcon} onClick={handleClick}>
        <span />
        <span />
      </div>

      <StyledMenu
        style={{ top: '10px' }}
        id="menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {(() => {
          const items = [];
          if (isAuthorized) {
            if (userIsApproved || isAdminOrComplianceUser) {
              items.push(
                <StyledMenuItem
                  component={Link}
                  key="dashboard"
                  to="/dashboard"
                  onClick={handleClose}
                >
                  Dashboard
                </StyledMenuItem>,
              );

              if (
                isAdminOrComplianceUser
                || (
                  companyIsApprovedAndActive
                  && (
                    hasBuyerAgreement
                    || hasSellerAgreement
                  )
                )
              ) {
                items.push(
                  <StyledMenuItem
                    component={Link}
                    key="listings"
                    to="/listings"
                    onClick={handleClose}
                  >
                    Listings
                  </StyledMenuItem>,
                );
              }

              if (hasSellerAgreement && companyIsApprovedAndActive) {
                items.push(
                  <StyledMenuItem
                    component={Link}
                    key="my-listings"
                    to="/my-listings"
                    onClick={handleClose}
                  >
                    My Listings
                  </StyledMenuItem>,
                );
              }

              if (hasBuyerAgreement && companyIsApprovedAndActive) {
                items.push(
                  <StyledMenuItem
                    component={Link}
                    key="my-bids"
                    to="/my-bids"
                    onClick={handleClose}
                  >
                    My Bids
                  </StyledMenuItem>,
                );
              }

              if (
                isAdminOrComplianceUser
                || (
                  companyIsApprovedAndActive
                  && (
                    hasBuyerAgreement
                    || hasSellerAgreement
                  )
                )
              ) {
                items.push(
                  <StyledMenuItem component={Link} key="map" to="/map" onClick={handleClose}>
                    Map
                  </StyledMenuItem>,
                );
              }
            } else {
              items.push(
                <StyledMenuItem
                  component={Link}
                  key="registration"
                  to={getRedirectPathAfterSignIn(user)}
                  onClick={handleClose}
                >
                  Registration
                </StyledMenuItem>,
              );
            }

            items.push(
              <StyledMenuItem key="logOut" onClick={handleLogOut}>Log Out</StyledMenuItem>,
            );
          } else {
            items.push(
              <StyledMenuItem key="login" onClick={handleModalOpen}>Login</StyledMenuItem>,
            );

            items.push(
              <StyledMenuItem key="sign-up" component={Link} to="/sign-up" onClick={handleClose}>
                Register
              </StyledMenuItem>,
            );
          }
          return items;
        })()}
      </StyledMenu>
    </div>
  );
};

const StyledMenu = withStyles({
  list: {
    padding: 0,
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '100vw',
    width: '100vw',
    left: '0 !important',
    border: 0,
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles({
  root: {
    background: '#F2F2F2',
    marginBottom: '2px',
    width: '100vw',
    justifyContent: 'center',
    fontSize: '18px',
  },
  gutters: {
    paddingLeft: 'unset',
    paddingRight: 'unset',
  },
})(MenuItem);

BurgerMenu.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isAuthorized: PropTypes.bool,
  hasBuyerAgreement: PropTypes.bool,
  hasSellerAgreement: PropTypes.bool,
  isAdminOrComplianceUser: PropTypes.bool,
  user: PropTypes.shape({
    // TODO: [TYPE] specify fields
  }),
};

BurgerMenu.defaultProps = {
  isAuthorized: undefined,
  hasBuyerAgreement: undefined,
  hasSellerAgreement: undefined,
  isAdminOrComplianceUser: undefined,
  user: undefined,
};

export default compose(
  connect(({ auth }) => ({
    hasBuyerAgreement: auth.user.company?.hasBuyerAgreement,
    hasSellerAgreement: auth.user.company?.hasSellerAgreement,
    isAdminOrComplianceUser: isAdminOrCompliancePerson(auth.user),
    isAuthorized: auth.authInfo.isAuthorized,
    user: auth.user,
  })),
  withStyles(styles),
)(BurgerMenu);
