export default {
  root: {
    padding: '86px 0',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '34px',
    textAlign: 'center',
    letterSpacing: '0.25px',
    color: '#171721',
    marginBottom: '30px',
  },
  buttonContainer: {
    marginTop: '20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonContainerSingle: {
    marginTop: '20px',
    width: '100%',
    textAlign: 'right',
  },
  button: {
    backgroundColor: '#7890A6',
    color: '#ffffff',
  },
  backButton: {
    color: '#7890A6',
  },
  homeButton: {
    border: '1px solid rgba(178, 157, 121, 0.5)',
    color: '#B29D79',
    marginTop: '24px',
  },
  datePicker: {
    height: '42px',
  },
  datePickerStyles: {
    marginTop: '11px',
    marginBottom: '-6px',
    display: 'flex',
    flexDirection: 'column',
  },
  datePickerText: {
    fontSize: '12px',
  },
  infoText: {
    fontSize: '14px',
    padding: '0px 6px',
  },
};
