import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import styles from './styles';
import Checkbox from '@material-ui/icons/CheckBox';
import DropZone, { dropZoneTypes } from 'now-frontend-shared/components/DropZone';
import {
  getPreSignedUrls,
  removeAWSDataFile,
  setAWSData,
  setUnloadedFilesExist,
} from 'store/actions/beneficialOwnerFilesActions';

import { acceptFileFormats } from 'now-shared/validation/listing-validation';
import { documentTypes } from 'now-shared/helpers/document-types';
import { saveFileUpload } from 'store/actions/companyActions';
import { navigateToNextPage, navigateToPreviousPage } from 'constants/registrationFlow';

function UploadBeneficialOwnerView({
  classes,
  AWSData,
  getPreSignedUrls,
  preSignedUrls,
  removeAWSDataFile,
  setAWSData,
  setUnloadedFilesExist,
  authUser,
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [dropZoneIsProcessingFile, setDropZoneIsProcessingFile] = useState(false);
  const [dropZoneHasFileNotUploaded, setDropZoneHasFileNotUploaded] = useState(false);

  const uploadIsPending = dropZoneIsProcessingFile || dropZoneHasFileNotUploaded;

  useEffect(() => {
    dispatch(setUnloadedFilesExist(uploadIsPending));
  }, [dispatch, setUnloadedFilesExist, uploadIsPending]);

  const documents = authUser.company?.documents;
  const beneficialOwnerAgreement = documents?.find(
    v => v.type === documentTypes.BENEFICIAL_OWNER_FILES,
  );

  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ margin: 'auto', maxWidth: '710px' }}
      >
        <Typography variant="h1" component="div" className={classes.title}>
          Upload Beneficial Owner Documents
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className={classes.subTitle}
        >
          Please upload the corporate resolution or operating agreement document that lists the beneficial owner.
        </Typography>
        <div className={classes.dropZoneContainer}>
          {beneficialOwnerAgreement
            ? (
              <Typography
                variant="body2"
                component="div"
                className={classes.complete}
              >
                Complete
                <Checkbox />
              </Typography>
            )
            : (
              <DropZone
                AWSData={AWSData}
                uploadText="The required documents listed above"
                preSignedUrls={preSignedUrls}
                setAWSData={setAWSData}
                getPreSignedUrls={getPreSignedUrls}
                removeAWSDataFile={removeAWSDataFile}
                documentType={documentTypes.BENEFICIAL_OWNER_FILES}
                onSetIsProcessingFiles={setDropZoneIsProcessingFile}
                onSetIsSomeFileNotUploaded={setDropZoneHasFileNotUploaded}
                accept={acceptFileFormats}
                placeholderPrompt="Please upload"
                placeholder="the documents listed above"
              />
            )}
        </div>
        <div className={classes.buttonContainer}>
          <Button
            onClick={async () => {
              await new Promise((resolve, reject) => dispatch(saveFileUpload({
                AWSData,
                resolve,
                reject,
              })));
              history.push(navigateToPreviousPage(history.location.pathname));
            }}
            className={classes.backButton}
            variant="text"
          >
            BACK
          </Button>
          <Button
            disabled={
              !beneficialOwnerAgreement
              && (
                !AWSData.length
                || uploadIsPending
              )
            }
            className={classes.button}
            variant="contained"
            onClick={async () => {
              await new Promise((resolve, reject) => dispatch(saveFileUpload({
                AWSData,
                resolve,
                reject,
              })));
              history.push(navigateToNextPage(history.location.pathname));
            }}
          >
            NEXT
          </Button>
        </div>
      </Grid>
    </div>
  );
}

UploadBeneficialOwnerView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  authUser: PropTypes.objectOf(PropTypes.string).isRequired,
  AWSData: dropZoneTypes.AWSData.isRequired,
  getPreSignedUrls: dropZoneTypes.getPreSignedUrls.isRequired,
  preSignedUrls: dropZoneTypes.preSignedUrls,
  removeAWSDataFile: dropZoneTypes.removeAWSDataFile.isRequired,
  setAWSData: dropZoneTypes.setAWSData.isRequired,
  setUnloadedFilesExist: PropTypes.func.isRequired,
};

UploadBeneficialOwnerView.defaultProps = {
  preSignedUrls: undefined,
};

const mapStateToProps = ({ beneficialOwnerFiles, company, auth }) => ({
  authUser: auth.user,
  AWSData: beneficialOwnerFiles.AWSData,
  AWSDataIsSet: beneficialOwnerFiles.AWSDataIsSet,
  currentListing: beneficialOwnerFiles.currentProperty,
  getPreSignedUrls,
  preSignedUrls: beneficialOwnerFiles.preSignedUrls,
  removeAWSDataFile,
  setAWSData,
  setUnloadedFilesExist,
  company,
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(UploadBeneficialOwnerView);
