import React, {
  useRef, useState, useEffect, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SparkMD5 from 'spark-md5';
import parsePath from 'path-parse';

// helpers
import { acceptFileFormats } from 'now-shared/validation/listing-validation';
import { checkFileSize } from 'now-shared/helpers/upload-helpers';

// components
import DropZoneFile, { isDocumentUploaded } from './components/File';

// icons
import { ReactComponent as CloudIcon } from 'now-frontend-shared/assets/icons/cloud.svg';
import checkIcon from 'now-frontend-shared/assets/icons/check_green_icon.svg';

// styles and components from material-ui
import { withStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

function calculateMd5Hash({ file, bufferSize, onProgress }) {
  const fileSize = file.size;

  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    const fileSlicer = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
    const hashAlgorithm = new SparkMD5();
    let offset = 0;

    const processNextPart = () => {
      const start = offset;
      const end = Math.min(start + bufferSize, fileSize);
      fileReader.readAsBinaryString(fileSlicer.call(file, start, end));
    };

    fileReader.onload = e => {
      if (e.target.error) {
        reject(e.target.error || {});
        return;
      }

      hashAlgorithm.appendBinary(e.target.result);

      offset += e.target.result.length;

      if (onProgress) {
        onProgress({
          current: offset,
          total: fileSize,
        });
      }

      if (offset < fileSize) {
        processNextPart();
      } else {
        resolve(hashAlgorithm.end(true));
      }
    };

    fileReader.onerror = e => {
      reject(e);
    };

    processNextPart();
  });
}

const DropZone = ({
  classes,
  getPreSignedUrls,
  getPreSignedUrlsCustomData,
  setAWSData: addUploadedDocument,
  removeAWSDataFile: removeUploadedDocument,
  preSignedUrls,
  savedDocuments,
  setUploading: setIsUploading,
  onSetIsSomeFileNotUploaded,
  accept: allowedFileTypes,
  placeholder,
  placeholderPrompt,
  documentType,
  canRemoveSavedFiles,
  isSimpleView,
  onSetIsProcessingFiles,
  disabled,
  hideSaved,
  maxFiles,
}) => {
  const [newlyAddedFiles, setNewlyAddedFiles] = useState([]);
  const [filesContainingS3Key, setFilesContainingS3Key] = useState([]);
  const [startUpload, setStartUpload] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [processingProgress, setProcessingProgress] = useState(undefined);
  const [hover, setHover] = useState(false);
  const [allFilesUploadProgress, setAllFilesUploadProgress] = useState([]);
  const [showProgress, setShowProgress] = useState(false);
  const fileInputRef = useRef();
  const dispatch = useDispatch();

  const shouldBeDisabled = disabled || !!newlyAddedFiles.length;

  const filesNotHiddenCount = hideSaved
    ? filesContainingS3Key.filter(doc => !doc.id).length
    : filesContainingS3Key.length;

  const savedDocumentIds = useMemo(() => (
    new Set(savedDocuments.map(doc => doc.id))
  ), [savedDocuments]);

  const savedDocumentIdsString = useMemo(() => (
    Array.from(savedDocumentIds).sort().join(',')
  ), [savedDocumentIds]);

  const allowedFileTypesString = useMemo(() => (
    allowedFileTypes.join(',')
  ), [allowedFileTypes]);

  const isSomeFileNotUploaded = useMemo(() => (
    filesContainingS3Key.some(doc => !isDocumentUploaded(doc))
  ), [filesContainingS3Key]);

  const handleRemoveFile = useCallback(
    key => {
      const fileInfo = filesContainingS3Key.find(file => file.key === key);
      if (!fileInfo) {
        toast.error(`File info for key ${key} was not found`);
        return;
      }
      setFilesContainingS3Key(files => files.filter(file => file.key !== key));
      setAllFilesUploadProgress(prevProgress => prevProgress.filter(file => file.key !== key));
      if (isDocumentUploaded(fileInfo)) {
        // WARNING: [BUG] if multiple DropZones on the same page are using the same state for AWSData,
        // and the same saved document is associated with multiple DropZones, removing one instance of the document
        // from one DropZone will remove all instances of the document from AWSData, even though to the user
        // it will appear as though the document has only been removed from one DropZone.
        dispatch(removeUploadedDocument(key));
      }
    },
    [filesContainingS3Key, setFilesContainingS3Key, removeUploadedDocument, dispatch],
  );

  useEffect(() => {
    // TODO: [BUG] if savedDocuments is updated while the DropZone has other files that have not yet been
    // uploaded or saved, this will remove those other files.
    setFilesContainingS3Key(savedDocuments);
    const uploadedDocumentsToRemove = filesContainingS3Key.filter(doc => !savedDocumentIds.has(doc.id));
    uploadedDocumentsToRemove.forEach(doc => handleRemoveFile(doc.key));
    // TODO: [CONVENTIONS][BUG] fix the types of references used here so that the dependency list below is complete
    // and passes without warning when removing eslint-disable-next-line
    // eslint-disable-next-line
  }, [savedDocumentIdsString, /* savedDocumentIds, savedDocuments */]);

  const onSetIsSomeFileNotUploadedRef = useRef();
  onSetIsSomeFileNotUploadedRef.current = onSetIsSomeFileNotUploaded;

  useEffect(() => {
    if (onSetIsSomeFileNotUploadedRef.current) {
      onSetIsSomeFileNotUploadedRef.current(isSomeFileNotUploaded);
    }
  }, [isSomeFileNotUploaded]);

  useEffect(() => {
    // if this DropZone shares preSignedUrls state with other DropZones, only the DropZone with newly added
    // files will handle the preSignedUrls. All other DropZones will ignore them.
    if (newlyAddedFiles.length && preSignedUrls?.length) {
      if (newlyAddedFiles.length === preSignedUrls.length) {
        const newlyPreparedFiles = newlyAddedFiles.map((file, index) => ({
          ...file,
          ...preSignedUrls[index],
        }));
        setFilesContainingS3Key(prevFiles => [...prevFiles, ...newlyPreparedFiles]);
      } else {
        console.warn('Number of newly added files does not match number of pre signed URLs');
        toast.error('Error adding files. Please try again.');
      }
      // NOTE: this clears the pre signed urls
      // TODO: [SEPARATION_OF_CONCERNS] use a separate action, `clearPreSignedUrls` instead
      dispatch({ type: getPreSignedUrls.success, payload: undefined });
      setNewlyAddedFiles([]);
    }
  }, [newlyAddedFiles, preSignedUrls, getPreSignedUrls.success, dispatch]);

  const stopEvent = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onHandleGetFiles = useCallback(async files => {
    if (disabled || newlyAddedFiles.length) {
      toast.error('Please wait for files to finish processing');
      return;
    }
    setNewlyAddedFiles(files);
    if (onSetIsProcessingFiles) {
      onSetIsProcessingFiles(true);
    }

    let didGetPresignedUrls = false;

    try {
      if (maxFiles > 0 && (filesContainingS3Key.length + files.length) > maxFiles) {
        toast.error(`Unable to attach more than ${maxFiles} files.`);
        return;
      }

      const validFiles = [];

      const fileProgressMap = new Map();
      const updateTotalProgress = () => {
        const allProgresses = [...fileProgressMap.values()];
        const totalProgress = allProgresses.reduce(
          (prev, curr) => ({
            current: prev.current + curr.current,
            total: prev.total + curr.total,
          }),
          {
            current: 0,
            total: 0,
          },
        );
        const progressResult = {
          fileProgressMap,
          totalProgress,
          totalPercent: totalProgress.current / (totalProgress.total || 1),
        };
        setProcessingProgress(progressResult);
      };

      await Promise.all([...files].map(async file => {
        const {
          ext: extension,
        } = parsePath(file.name);

        if (!extension) {
          toast.error(`${file.name} is missing a file extension`);
          return;
        }

        const fileTypeIsWrong = !allowedFileTypes.includes(file.type);
        const filenameLowerCase = file.name.toLowerCase();
        const isDuplicate = filesContainingS3Key.some(fileContainingS3Key => (
          fileContainingS3Key.filename.toLowerCase() === filenameLowerCase
        ));

        if (fileTypeIsWrong) {
          toast.error(
            // TODO: [BUG][NOTIFICATIONS][SEPARATION_OF_CONCERNS] specify the allowed file types based on a
            // DropZone prop instead of hard code here. These types may not be accurate depending on what is
            // passed in to `accept` prop.
            `${file.name} cannot be uploaded. Sorry, this file type is not permitted. Please use PDF, excel, word, tiff or jpeg`,
            {
              position: 'bottom-center',
            },
          );
          return;
        }

        if (isDuplicate) {
          toast.error(`Unable to upload the same file, ${file.name} already exists`, { position: 'bottom-center' });
          return;
        }
        const fileSizeError = checkFileSize({
          fileMime: file.type,
          fileSize: file.size,
        });
        if (fileSizeError) {
          toast.error(`${file.name}: ${fileSizeError.message}`);
          return;
        }

        // calculate MD5 hash of file

        // 4MB buffer size
        const bufferSize = 4 * 1024 * 1024;

        const onProgress = progress => {
          fileProgressMap.set(file, progress);
          updateTotalProgress();
        };

        const useIncrementalHashTechnique = true;

        let md5Base64;
        try {
          let md5HashRaw;
          if (useIncrementalHashTechnique) {
            md5HashRaw = await calculateMd5Hash({ file, bufferSize, onProgress });
          } else {
            // NOTE: this doesn't work on files bigger than ~2GB
            const fileBuffer = await file.arrayBuffer();
            md5HashRaw = SparkMD5.ArrayBuffer.hash(fileBuffer, true);
          }
          md5Base64 = Buffer.from(md5HashRaw, 'binary').toString('base64');
        } catch (e) {
          fileProgressMap.delete(file);
          updateTotalProgress();
          throw e;
        }

        // TODO: [UX] check for duplicate MD5 hash to warn about uploading another file with different name but
        // duplicate content?

        validFiles.push({
          file,
          md5Base64,
          filename: file.name,
        });
      }));

      if (!validFiles.length) {
        return;
      }

      if (showProgress) {
        toast.error('Please wait for the current upload(s) to finish before adding more files');
        return;
      }

      setNewlyAddedFiles(validFiles);

      await new Promise((resolve, reject) => (
        dispatch(getPreSignedUrls({
          filesName: validFiles.map(({ file }) => file.name),
          filesMd5: validFiles.map(({ md5Base64 }) => md5Base64),
          filesSize: validFiles.map(({ file }) => file.size),
          ...getPreSignedUrlsCustomData,
          resolve,
          reject,
        }))
      ));

      didGetPresignedUrls = true;
    } finally {
      setProcessingProgress(undefined);
      if (!didGetPresignedUrls) {
        setNewlyAddedFiles([]);
      }
      if (onSetIsProcessingFiles) {
        onSetIsProcessingFiles(false);
      }
    }
  }, [
    disabled,
    newlyAddedFiles,
    allowedFileTypes,
    dispatch,
    filesContainingS3Key,
    getPreSignedUrls,
    getPreSignedUrlsCustomData,
    showProgress,
    onSetIsProcessingFiles,
    maxFiles,
  ]);

  const onHandleDrop = event => {
    stopEvent(event);

    const { files } = event.dataTransfer;
    onHandleGetFiles(files);

    setHover(false);
  };

  const onDragOver = event => {
    stopEvent(event);
    setHover(true);
  };

  const onDragLeave = event => {
    stopEvent(event);
    setHover(false);
  };

  const openFileDialog = () => {
    if (disabled || newlyAddedFiles.length) {
      toast.error('Please wait for files to finish processing');
      return;
    }
    fileInputRef.current.click();
  };

  const onFilesAdded = ({ target: { files } }) => {
    onHandleGetFiles(files);
    setShowProgress(false);
  };

  const uploadFiles = () => {
    if (isSomeFileNotUploaded) {
      setStartUpload(true);
      if (setIsUploading) {
        setIsUploading(true);
      }
      setUploadingFiles(filesContainingS3Key.filter(doc => !isDocumentUploaded(doc)));
      setShowProgress(true);
    }
  };

  const handleStopUploadFiles = useCallback(() => {
    setStartUpload(false);
  }, [setStartUpload]);

  const finishUpload = fileInfo => {
    if (!fileInfo.key) {
      throw new Error('Uploaded file is missing key');
    }
    const fileKey = fileInfo.key;
    const existingFileInfo = filesContainingS3Key.find(file => file.key === fileKey);
    if (!existingFileInfo) {
      toast.error(`File info for key ${fileKey} was not found`);
      return;
    }
    const stripExtraFields = data => {
      const result = {};
      Object.entries(data).forEach(([propertyName, value]) => {
        if ([
          'id',
          'key',
          'filename',
          'downloadUrl',
          'type',
          'comment',
          'createdAt',
        ].includes(propertyName)) {
          result[propertyName] = value;
        }
      });
      return result;
    };
    const newFileInfo = stripExtraFields({
      type: documentType,
      ...existingFileInfo,
      ...fileInfo,
    });
    setFilesContainingS3Key(docs => docs.map(
      doc => (
        doc.key === fileInfo.key ? newFileInfo : doc
      ),
    ));
    dispatch(addUploadedDocument(newFileInfo));
  };

  const handleEditTitle = ({ editedFile }) => {
    const fileInfo = filesContainingS3Key.find(file => file.key === editedFile.key);
    if (!fileInfo) {
      toast.error(`File info for key ${editedFile.key} was not found`);
      return;
    }

    if (isDocumentUploaded(fileInfo)) {
      toast.error(
        `You can't change the filename after the file has been uploaded: ${fileInfo.filename}`,
      );
      return;
    }

    const {
      ext: fileExtension,
    } = parsePath(fileInfo.filename);

    const {
      ext: newFileExtension,
    } = parsePath(editedFile.filename);

    if (fileExtension.toLowerCase() !== newFileExtension.toLowerCase()) {
      toast.error(
        `You can't change the file extension from the original file: ${fileInfo.filename}`,
      );
      return;
    }

    const editedFilenameLowerCase = editedFile.filename.toLowerCase();
    const isDuplicated = filesContainingS3Key
      .filter(file => file.key !== editedFile.key)
      .some(file => file.filename.toLowerCase() === editedFilenameLowerCase);
    if (isDuplicated) {
      toast.error(`You can't use the same name as another file with the same extension: ${
        editedFile.filename
      }`, {
        position: 'bottom-center',
      });
      return;
    }

    const updatedFile = {
      ...fileInfo,
      filename: editedFile.filename,
    };

    setFilesContainingS3Key(docs => docs.map(
      doc => (
        doc.key === fileInfo.key ? updatedFile : doc
      ),
    ));
    // TODO: [FEATURE] notify parent that filename has changed if we allowing
    // filename changes _after_ file has been uploaded.
  };

  const watchAllFilesUploadProgress = (separateFileProgress, key) => {
    const fileProgress = {
      key,
      progress: separateFileProgress,
    };
    setAllFilesUploadProgress(prevProgress => {
      const progressIndex = prevProgress.findIndex(file => file.key === key);
      const result = [...prevProgress];
      if (progressIndex !== -1) {
        result.splice(progressIndex, 1, fileProgress);
      } else {
        result.push(fileProgress);
      }
      return result;
    });
  };

  const overallProgress = useMemo(() => {
    let result = 0;
    // if true, will show percentage based on total files pending upload at the time the upload last started.
    const isOutOfUploading = true;
    allFilesUploadProgress.forEach(({ key, progress }) => {
      if (progress === undefined) {
        return;
      }
      if (!isOutOfUploading || uploadingFiles.some(doc => doc.key === key)) {
        result += progress;
      }
    });
    const total = isOutOfUploading
      ? uploadingFiles.length
      : filesNotHiddenCount;
    if (total) {
      result /= total;
    }
    return result;
  }, [allFilesUploadProgress, uploadingFiles, filesNotHiddenCount]);

  const setIsUploadingRef = useRef();
  setIsUploadingRef.current = setIsUploading;

  useEffect(() => {
    if (overallProgress === 100 || overallProgress === 0 || !isSomeFileNotUploaded) {
      setStartUpload(false);
      if (setIsUploadingRef.current) {
        setIsUploadingRef.current(false);
      }
      setUploadingFiles([]);
      setShowProgress(false);
    }
  }, [overallProgress, isSomeFileNotUploaded]);

  return (
    <Grid
      container
      direction="column"
      onDrop={onHandleDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      className={cx(classes.container, {
        [classes.hover]: hover,
        [classes.expand]: !isSimpleView && filesNotHiddenCount > 2,
        [classes.disabled]: shouldBeDisabled,
      })}
    >
      <input
        id="dropzone"
        type="file"
        accept={allowedFileTypesString}
        ref={fileInputRef}
        multiple
        value=""
        onChange={onFilesAdded}
        className={classes.input}
        disabled={shouldBeDisabled}
      />
      {(filesNotHiddenCount === 0 || newlyAddedFiles.length) ? (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          onClick={openFileDialog}
          className={classes.inputContainer}
        >
          <Grid container justifyContent="center" alignItems="center" className={classes.icon}>
            <CloudIcon />
          </Grid>

          {(
            newlyAddedFiles.length
              ? (
                <span className={classes.label}>
                  Processing files
                  {(
                    processingProgress
                      ? ` ${Math.round(processingProgress.totalPercent * 100)} %`
                      : '...'
                  )}
                </span>
              ) : (
                <>
                  <span className={classes.label}>Drag and drop files here or click here to upload</span>

                  <span className={classes.description}>
                    <span className={classes.boldText}>{placeholderPrompt}</span>
                    {' '}
                    {placeholder}
                  </span>
                </>
              )
          )}
        </Grid>
      ) : (
        <Grid container direction="column" className={classes.uploadContainer}>
          <Grid container justifyContent="space-between" alignItems="center" className={classes.headerButtonsContainer}>
            <span className={classes.filesCount}>{`${filesNotHiddenCount} files selected`}</span>

            <span
              {...!showProgress && {
                onClick: openFileDialog,
              }}
              className={cx(classes.controlButton, {
                [classes.disabled]: showProgress,
              })}
            >
              + Add more
            </span>
          </Grid>

          <Grid container justifyContent="space-between" className={cx(classes.filesContainer, isSimpleView && classes.filesContainerAsBlock)}>
            {filesContainingS3Key.map(document => (
              <DropZoneFile
                key={document.key}
                document={document}
                startUpload={startUpload}
                watchAllFilesUploadProgress={watchAllFilesUploadProgress}
                handleStopUploadFiles={handleStopUploadFiles}
                handleRemoveFile={handleRemoveFile}
                handleEditTitle={handleEditTitle}
                finishUpload={finishUpload}
                canRemoveFile={!document.id || canRemoveSavedFiles}
                isSimpleView={isSimpleView}
                hideSaved={hideSaved}
              />
            ))}
          </Grid>

          {filesNotHiddenCount > 0 && (
            <Grid container justifyContent="space-between" alignItems="center" className={classes.uploadButtonContainer}>
              {showProgress && (
                <span className={classes.uploadInfo}>
                  {' '}
                  {`Uploading: ${Math.round(overallProgress)}%`}
                </span>
              )}

              {(
                !showProgress
                && overallProgress !== 100
                && isSomeFileNotUploaded
                && (
                  <button type="button" onClick={uploadFiles} className={classes.uploadButton}>
                    Upload
                  </button>
                )
              )}

              {!showProgress && (overallProgress === 100 || !isSomeFileNotUploaded) && (
                <Grid container alignItems="center">
                  <img src={checkIcon} alt="check icon" className={classes.checkIcon} />
                  <span className={classes.completed}>Completed</span>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export const dropZoneTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  preSignedUrls: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    }),
  ),
  /**
   * TODO: [CLARIFY][REFACTOR] rename to `uploadedDocuments`
   *
   * This data should typically only be set and manipulated by the DropZone component instance,
   * via setAWSData and removeAWSDataFile.
   */
  AWSData: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    /**
     * Includes file extension
     */
    filename: PropTypes.string.isRequired,
    id: PropTypes.number,
    downloadUrl: PropTypes.string,
    type: PropTypes.string,
    comment: PropTypes.string,
    createdAt: PropTypes.string,
  })),
  placeholder: PropTypes.string,
  /**
   * This is a redux action with the following payload signature:
   * {
   *   filesName: string[]
   *   filesMd5: string[]
   *   filesSize: number[]
   *   ...getPreSignedUrlsCustomData?: object
   *   resolve?: function
   *   reject?: function
   * }
   */
  getPreSignedUrls: PropTypes.func,
  /**
   * Custom data appended to dispatch calls to getPreSignedUrls redux action
   */
  getPreSignedUrlsCustomData: PropTypes.object,
  /**
   * This is a redux action with the following payload signature:
   * {
   *   key: string
   *   filename: string
   *   id?: number
   *   downloadUrl?: string
   *   type?: string
   *   comment?: string
   *   createdAt?: string
   * }
   *
   * If the file was previously saved, this method will be called on it as soon as it is loaded,
   * and `id` will be defined. If the file has not yet been saved, `id` will be undefined.
   *
   * TODO: [CLARIFY][REFACTOR] rename to `addUploadedDocument`
   */
  setAWSData: PropTypes.func,
  /**
   * Notify caller that a previously uploaded file has been removed from the DropZone.
   *
   * This is a redux action with the following payload signature:
   * (s3Key: string)
   *
   * TODO: [CLARIFY][REFACTOR] rename to `removeUploadedDocument`
   */
  removeAWSDataFile: PropTypes.func,
  /**
   * Documents that were previously saved (different than uploaded) in association with this DropZone
   *
   * A document is saved when it has been stored in the database, after having been uploaded. A saved
   * document is associated with an S3 upload key.
   */
  savedDocuments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      filename: PropTypes.string.isRequired,
      downloadUrl: PropTypes.string.isRequired,
      type: PropTypes.string,
      comment: PropTypes.string,
      createdAt: PropTypes.string,
    }),
  ),
  onSetIsSomeFileNotUploaded: PropTypes.func,
  setUploading: PropTypes.func,
  /**
   * TODO: [CLARIFY][REFACTOR] rename to `allowedFileTypes`
   *
   * The format of each entry is either a MIME type ('application/pdf') or a file extension
   * ('.pdf')
   */
  accept: PropTypes.arrayOf(PropTypes.string),
  /**
   * A valid document type enum string (e.g., 'certifiedArticlesOfIncorporation')
   */
  documentType: PropTypes.string,
  canRemoveSavedFiles: PropTypes.bool,
  isSimpleView: PropTypes.bool,
  onSetIsProcessingFiles: PropTypes.func,
  /**
   * NOTE: if DropZone is sharing preSignedUrls state with other DropZones, `disabled`
   * should be `true` if any of the other shared state DropZones is currently processing
   * files or loading pre signed urls, since allowing multiple shared DropZones to receive
   * new files around the same time can result in a mixup of files between DropZone instances.
   */
  disabled: PropTypes.bool,
  hideSaved: PropTypes.bool,
  /**
   * Maximum number of files that can be attached in this DropZone, including
   * existing uploads that are already saved.
   *
   * A value of zero or less indicates no limit.
   */
  maxFiles: PropTypes.number,
};

DropZone.propTypes = {
  classes: dropZoneTypes.classes.isRequired,
  preSignedUrls: dropZoneTypes.preSignedUrls,
  /**
   * TODO: [CLEANUP][REFACTOR] remove this prop as it is no longer used by DropZone
   */
  AWSData: dropZoneTypes.AWSData.isRequired,
  placeholder: dropZoneTypes.placeholder,
  placeholderPrompt: dropZoneTypes.placeholderPrompt,
  getPreSignedUrls: dropZoneTypes.getPreSignedUrls.isRequired,
  getPreSignedUrlsCustomData: dropZoneTypes.getPreSignedUrls,
  setAWSData: dropZoneTypes.setAWSData.isRequired,
  removeAWSDataFile: dropZoneTypes.removeAWSDataFile.isRequired,
  savedDocuments: dropZoneTypes.savedDocuments,
  onSetIsSomeFileNotUploaded: dropZoneTypes.onSetIsSomeFileNotUploaded,
  setUploading: dropZoneTypes.setUploading,
  accept: dropZoneTypes.accept,
  documentType: dropZoneTypes.documentType,
  canRemoveSavedFiles: dropZoneTypes.canRemoveSavedFiles,
  isSimpleView: dropZoneTypes.isSimpleView,
  onSetIsProcessingFiles: dropZoneTypes.onSetIsProcessingFiles,
  disabled: dropZoneTypes.disabled,
  hideSaved: dropZoneTypes.hideSaved,
  maxFiles: dropZoneTypes.maxFiles,
};

DropZone.defaultProps = {
  accept: acceptFileFormats,
  placeholder: '',
  placeholderPrompt: '',
  getPreSignedUrlsCustomData: undefined,
  preSignedUrls: undefined,
  savedDocuments: [],
  documentType: undefined,
  canRemoveSavedFiles: true,
  isSimpleView: false,
  onSetIsProcessingFiles: undefined,
  disabled: false,
  setUploading: undefined,
  onSetIsSomeFileNotUploaded: undefined,
  hideSaved: false,
  maxFiles: 100,
};

export default compose(withStyles(styles))(DropZone);
