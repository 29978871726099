import React, { useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import Checkbox from '@material-ui/icons/CheckBox';
import styles from './styles';
import DropZone, { dropZoneTypes } from 'now-frontend-shared/components/DropZone';
import { createNewCompany, saveFileUpload } from 'store/actions/companyActions';
import {
  getPreSignedUrls,
  removeAWSDataFile,
  setAWSData,
  setUnloadedFilesExist,
} from 'store/actions/businessFilesActions';

import { acceptFileFormats } from 'now-shared/validation/listing-validation';
import { documentTypes } from 'now-shared/helpers/document-types';
import { hasCompanyInfoSaved, hasCompanyInfoToSubmit } from 'layouts/RegistrationRoute';
import { navigateToNextPage, navigateToPreviousPage } from 'constants/registrationFlow';

function UploadDocumentsView({
  classes,
  AWSData,
  getPreSignedUrls,
  preSignedUrls,
  removeAWSDataFile,
  setAWSData,
  setUnloadedFilesExist,
  company,
  authUser,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [submitted, setSubmitted] = React.useState(false);
  const [dropZonesProcessingFiles, setDropZonesProcessingFiles] = React.useState([]);
  const [dropZonesWithFileNotUploaded, setDropZonesWithFileNotUploaded] = React.useState([]);

  const doesExistFileProcessing = !!dropZonesProcessingFiles.length;
  const doesExistFileNotUploaded = !!dropZonesWithFileNotUploaded.length;
  const doesExistPendingUpload = doesExistFileProcessing || doesExistFileNotUploaded;

  useEffect(
    () => {
      dispatch(setUnloadedFilesExist(doesExistPendingUpload));
    },
    [dispatch, setUnloadedFilesExist, doesExistPendingUpload],
  );

  const refs = {
    [documentTypes.CERTIFIED_ARTICLES_OF_INCORPORATION]: React.useRef(),
    [documentTypes.FORM_W9]: React.useRef(),
  };

  const attachmentOptions = [
    {
      label: (
        <>
          <Box component="span" sx={{ fontWeight: 'bold' }}>
            One of the following:
          </Box>
          {' '}
          Certified Articles of Incorporation / Certificate of Formation / Corporate Resolution or Operating Agreement / Articles of Association / Articles of Organization or Memorandum of Association
        </>
      ),
      dropzonePlaceholderPrompt: null,
      dropzonePlaceholder: (
        <>
          Please upload
          {' '}
          <Box component="span" sx={{ fontWeight: 'bold' }}>
            one of
          </Box>
          {' '}
          the documents listed above
        </>
      ),
      documentType: documentTypes.CERTIFIED_ARTICLES_OF_INCORPORATION,
    },
    {
      label: 'Form W-9',
      dropzonePlaceholderPrompt: 'Please upload',
      dropzonePlaceholder: 'the documents listed above',
      documentType: documentTypes.FORM_W9,
    },
  ];

  const dropZoneDocTypes = attachmentOptions.map(option => option.documentType);

  const documents = authUser.company?.documents;
  const hasAlreadyUploadedDocuments = dropZoneDocTypes.every(docType => !!documents?.find(v => v.type === docType));

  const errorRequiredMessage = 'The file is required. Please complete uploading by clicking the "Upload" button after dropping files.';
  const errorNotUploadedMessage = 'One or more files are pending upload.';

  const errors = {};

  dropZoneDocTypes.forEach(docType => {
    if (!AWSData.some(v => v.type === docType)) {
      errors[docType] = errorRequiredMessage;
    } else if (
      dropZonesProcessingFiles.includes(docType)
      || dropZonesWithFileNotUploaded.includes(docType)
    ) {
      errors[docType] = errorNotUploadedMessage;
    }
  });

  const createAttachments = list => (
    <ol className={classes.itemContainer}>
      {list.map(({
        label,
        dropzonePlaceholderPrompt,
        dropzonePlaceholder,
        documentType,
      }) => (
        <div key={documentType} ref={refs[documentType] || null}>
          <li className={classes.itemContainer_li}>
            <Typography
              variant="body1"
              component="div"
              className={classes.liText}
            >
              {label}
            </Typography>
          </li>
          <div className={classes.dropZoneContainer}>
            {hasAlreadyUploadedDocuments
              ? (
                <Typography
                  variant="body2"
                  component="div"
                  className={classes.complete}
                >
                  Complete
                  <Checkbox />
                </Typography>
              )
              : (
                <DropZone
                  AWSData={AWSData}
                  uploadText="The required document listed above"
                  preSignedUrls={preSignedUrls}
                  setAWSData={setAWSData}
                  documentType={documentType}
                  getPreSignedUrls={getPreSignedUrls}
                  removeAWSDataFile={removeAWSDataFile}
                  onSetIsSomeFileNotUploaded={flag => {
                    if (flag) {
                      setDropZonesWithFileNotUploaded(dropZones => [...dropZones, documentType]);
                    } else {
                      setDropZonesWithFileNotUploaded(dropZones => dropZones.filter(dropZone => dropZone !== documentType));
                    }
                  }}
                  accept={acceptFileFormats}
                  onSetIsProcessingFiles={flag => {
                    if (flag) {
                      setDropZonesProcessingFiles(dropZones => [...dropZones, documentType]);
                    } else {
                      setDropZonesProcessingFiles(dropZones => dropZones.filter(dropZone => dropZone !== documentType));
                    }
                  }}
                  placeholderPrompt={dropzonePlaceholderPrompt}
                  placeholder={dropzonePlaceholder}
                  disabled={doesExistFileProcessing}
                />
              )}
          </div>

          {submitted && errors[documentType] && <Typography variant="body2" color="error" style={{ marginTop: 4 }}>{errors[documentType]}</Typography>}
        </div>
      ))}
    </ol>
  );

  const scrollTo = top => window.scrollTo({
    top,
    left: 0,
    behavior: 'smooth',
  });

  const onNext = async () => {
    if (hasAlreadyUploadedDocuments) {
      history.push(navigateToNextPage(history.location.pathname));
      return;
    }

    setSubmitted(true);

    const docTypeWithError = dropZoneDocTypes.find(docType => !!errors[docType]);

    if (docTypeWithError) {
      scrollTo(refs[docTypeWithError].current.offsetTop);
      return;
    }

    await new Promise((resolve, reject) => dispatch(saveFileUpload({
      AWSData,
      resolve,
      reject,
    })));

    setSubmitted(false);

    history.push(navigateToNextPage(history.location.pathname));
  };

  const authUserRef = useRef();
  authUserRef.current = authUser;

  const companyRef = useRef();
  companyRef.current = company;

  React.useEffect(() => {
    if (!hasCompanyInfoSaved(authUserRef.current) && hasCompanyInfoToSubmit(companyRef.current)) {
      dispatch(createNewCompany());
    }
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ margin: 'auto', maxWidth: '710px' }}
      >
        <Typography variant="h1" component="div" className={classes.title}>
          Upload Documents
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className={classes.subTitle}
        >
          Please upload the following documents.
        </Typography>
        <Typography variant="body1" component="div" className={classes.subText}>
          Required:
        </Typography>
        {createAttachments(attachmentOptions)}
        <div className={classes.buttonContainer}>
          <Button
            className={classes.backButton}
            variant="text"
            onClick={() => history.push(navigateToPreviousPage(history.location.pathname, company))}
          >
            BACK
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={onNext}
          >
            NEXT
          </Button>
        </div>
      </Grid>
    </div>
  );
}

UploadDocumentsView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  AWSData: dropZoneTypes.AWSData.isRequired,
  getPreSignedUrls: dropZoneTypes.getPreSignedUrls.isRequired,
  preSignedUrls: dropZoneTypes.preSignedUrls,
  removeAWSDataFile: dropZoneTypes.removeAWSDataFile.isRequired,
  setAWSData: dropZoneTypes.setAWSData.isRequired,
  setUnloadedFilesExist: PropTypes.func.isRequired,
  authUser: PropTypes.objectOf(PropTypes.any),
};

UploadDocumentsView.defaultProps = {
  authUser: undefined,
  preSignedUrls: undefined,
};

const mapStateToProps = ({ auth, businessFiles, company }) => ({
  AWSData: businessFiles.AWSData,
  AWSDataIsSet: businessFiles.AWSDataIsSet,
  currentListing: businessFiles.currentProperty,
  getPreSignedUrls,
  preSignedUrls: businessFiles.preSignedUrls,
  removeAWSDataFile,
  setAWSData,
  setUnloadedFilesExist,
  company,
  authUser: auth.user,
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(UploadDocumentsView);
