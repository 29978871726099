export default {
  container: {
    height: '300px',
    border: '2px dashed #87A6BC',
    borderRadius: '8px',
    backgroundColor: '#F3F6F8',
  },
  inputContainer: {
    height: '100%',
    cursor: 'pointer',
  },
  label: {
    fontSize: '16px',
    lineHeight: '21px',
    fontWeight: 'normal',
    color: '#2E4453',
    textAlign: 'center',

    '@media screen and (max-width: 320px)': {
      fontSize: '14px',
    },
  },
  description: {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 'normal',
    color: '#333333',
    textAlign: 'center',
    paddingTop: '38px',
  },
  boldText: {
    fontWeight: 'bold',
  },
  input: {
    display: 'none',
  },
  hover: {
    transform: 'scale(0.95)',
  },
  filesContainer: {
    flex: 1,
    padding: '12px',
    overflow: 'auto',

    '@media screen and (max-width: 320px)': {
      padding: '10px',
    },
  },
  filesContainerAsBlock: {
    display: 'block',
    maxHeight: 210,
    overFlowY: 'scroll',
  },
  headerButtonsContainer: {
    height: '40px',
    borderBottom: '1px solid #87A6BC',
  },
  controlButton: {
    padding: '0 10px',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: '500',
    color: '#2275d7',
  },
  uploadContainer: {
    height: '100%',
  },
  uploadButtonContainer: {
    height: '46px',
    borderTop: '1px solid #87A6BC',
  },
  uploadButton: {
    padding: '6px 10px',
    margin: '0 24px',
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: '500',
    color: '#fff',
    backgroundColor: '#1bb240',
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
    borderRadius: '4px',
  },
  uploadInfo: {
    padding: '0 24px',
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 'normal',
  },
  expand: {
    height: '460px',

    '@media screen and (max-width: 1280px)': {
      height: '356px',
    },

    '@media screen and (max-width: 960px)': {
      height: '336px',
    },

    '@media screen and (max-width: 600px)': {
      height: '440px',
    },

    '@media screen and (max-width: 320px)': {
      height: '360px',
    },
  },
  checkIcon: {
    width: '20px',
    height: '20px',
    margin: '0 10px 0 24px',
  },
  completed: {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 'bold',
    color: '#54c147',
  },
  filesCount: {
    padding: '0 10px',
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: '500',
    color: '#2275d7',
  },
  disabled: {
    opacity: 0.3,
    cursor: 'unset',
    pointerEvents: 'none',
  },
};
