import { LOCATION_CHANGE } from 'connected-react-router';

// actions
import * as A from '../actions/propertyViewActions';

const initialState = {
  currentProperty: null,
};

export const reducer = {
  propertyView(state = initialState, action) {
    switch (action.type) {
      case LOCATION_CHANGE:
      case A.clearState.type:
        return initialState;

      case A.getCurrentProperty.success:
        return { ...state, currentProperty: action.payload };

      default:
        return state;
    }
  },
};
