import React, { memo, forwardRef } from 'react';
import { compose } from 'redux';

// components
import CloseModalButton from 'now-frontend-shared/components/CloseModalButton';

// custom hooks
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from '../styles';

const TextModal = ({ classes, heading, description }, ref) => {
  const { setModal } = useModalSetter();

  const handleCloseModal = () => {
    setModal(null);
  };

  return (
    <Grid container direction="column" tabIndex={-1} className={classes.wrapper} ref={ref}>
      <Grid container direction="column" alignItems="center" className={classes.container}>
        <Grid container justify="center" className={classes.heading}>
          <span>{heading}</span>
        </Grid>

        <Grid container justify="center" className={classes.description}>
          <span>{description}</span>
        </Grid>

        <CloseModalButton handleCloseModal={handleCloseModal} />
      </Grid>
    </Grid>
  );
};

export default compose(withStyles(styles), memo, forwardRef)(TextModal);
