// NOTE: this file is duplicated in `frontend-main`, so any changes made to this file should also be made in
// that file as well.
//
// TODO: [DRY][REFACTOR] remove the duplicate file in `frontend-main` and refactor references to that file
// to refer to this file instead (NOW-938)
export const lightGrey = '#A4A4A4';
export const white = '#FFFFFF';
export const green = '#4CAF50';
export const blue = '#2196F3';
export const grey = '#4F4F4F';
export const cyan = '#56CCF2';
export const red = '#F44336';
export const lightGreen = '#6FCF97';
export const navyBlue = '#2D9CDB';
export const yellow = '#F2C94C';

export const primaryMain = 'rgba(120, 144, 166, 1)';
export const primaryLight = 'rgba(224, 229, 235, 1)';
export const primaryDark = 'rgba(75, 99, 119, 1)';
export const primaryContrast = 'rgba(255, 255, 255, 1)';

export const secondaryMain = 'rgba(178, 157, 121, 1)';
export const secondaryLight = 'rgba(229, 206, 168, 1)';
export const secondaryDark = 'rgba(130, 111, 77, 1)';
export const secondaryContrast = 'rgba(255, 255, 255, 1)';

export const textPrimary = 'rgba(0, 0, 0, 0.87)';
export const textSecondary = 'rgba(0, 0, 0, 0.6)';
export const textDisabled = 'rgba(0, 0, 0, 0.38)';
