import { green } from 'now-frontend-shared/themes/colors';

export default {
  button: {
    height: '50px',
    width: '220px',
    margin: '10px 0',
    borderRadius: '8px',
    border: `2px solid ${green}`,
    outline: 'none',
    color: green,
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'none',
    backgroundColor: 'inherit',
    boxShadow: 'unset',

    '& .MuiButton-startIcon': {
      '& span': {
        fontSize: '40px',
        lineHeight: '46px',
        fontWeight: 'normal',
        color: green,

        '&:hover': {
          color: '#FFFFFF',
        },
      },
    },

    '&:hover': {
      backgroundColor: green,
      color: '#FFFFFF',

      '& .MuiButton-startIcon': {
        '& span': {
          color: '#FFFFFF',
        },
      },
    },
  },
  errorText: {
    height: '18px',
    color: '#EB5757',
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'left',
  },
  buttonContainer: {
    '@media screen and (max-width: 1280px)': {
      marginBottom: '20px',
    },

    '@media screen and (max-width: 960px)': {
      alignItems: 'center',
    },
  },
};
