import axios from 'axios';

// storage helpers functions
import * as storage from 'now-frontend-shared/utils/storage';
import { getAuthorizationHeader } from 'auth/auth-helpers';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'content-type': 'application/json' },
});

export const refreshTokens = async () => {
  const refreshTokenFromStorage = JSON.parse(
    storage.getStorageItem('refreshToken', '{}'),
  );

  const {
    data: {
      accessToken,
      refreshToken,
    },
  } = await httpClient.post(
    `${process.env.REACT_APP_API_URL}/auth/login-by-refresh-token`,
    {
      refreshToken: refreshTokenFromStorage,
    },
    {
      _loginByRefreshToken: true,
    },
  );
  storage.setStorageItem('accessToken', JSON.stringify(accessToken));
  storage.setStorageItem('refreshToken', JSON.stringify(refreshToken));
  return {
    accessToken,
    refreshToken,
  };
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = ({ error, accessToken }) => {
  failedQueue.forEach(promise => {
    if (error) {
      false
        && console.log('rejecting request that failed while refreshing', error);
      promise.reject(error);
    } else {
      false
        && console.log(
          're-running request that failed while refreshing with new access token',
          accessToken,
        );
      promise.resolve(accessToken);
    }
  });

  failedQueue = [];
};

httpClient.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (
      !originalRequest._retry
      && !originalRequest._loginByRefreshToken
      && (error.response?.status === 401 || error.response?.status === 500)
    ) {
      false
        && console.log(
          'request failed with 401 or 500 (not retried and not login-by-refresh-token',
        );

      if (isRefreshing) {
        false && console.log('another request failed while isRefreshing');
        const token = await new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        });
        originalRequest.headers.Authorization = `Bearer ${token}`;
        return await axios(originalRequest);
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const {
          accessToken,
        } = await refreshTokens();
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        processQueue({ accessToken });
        return await axios(originalRequest);
      } catch (error) {
        false && console.log('error from login-by-refresh-token');
        processQueue({ error });
        throw error;
      } finally {
        false && console.log('isRefreshing = false');
        isRefreshing = false;
      }
    }
    throw error;
  },
);

/*
 * Function helper returned function that return promise
 * @param method {string} api method (get, put, post, etc...)
 * @param url {string|function} url to api method
 *
 */

export const makeAxiosRequest = (url, method, auth = false) => ({ headers, ...argsRest } = { headers: undefined }) => httpClient({
  url,
  ...method,
  ...(headers || auth) && {
    headers: {
      ...(auth && {
        Authorization: getAuthorizationHeader(),
      }),
      ...headers,
    },
  },
  ...argsRest,
});
