export function isTransactionCanceled({ isCanceled }) {
  return isCanceled;
}

export function isTransactionNotCanceled({ isCanceled }) {
  return !isCanceled;
}

export function isTransactionComplete({ completedDate, isCanceled }) {
  return !isCanceled && !!completedDate;
}

export function isTransactionPaymentInitiated({ isPaymentInitiated, isCanceled }) {
  return !isCanceled && isPaymentInitiated;
}

export function findCanceledTransaction(transactions) {
  return transactions.find(isTransactionCanceled);
}

export function findNonCanceledTransaction(transactions) {
  return transactions.find(isTransactionNotCanceled);
}

export function findCompletedTransaction(transactions) {
  return transactions.find(isTransactionComplete);
}

export function findPaymentInitiatedTransaction(transactions) {
  return transactions.find(isTransactionPaymentInitiated);
}

export function hasCanceledTransaction(transactions) {
  return !!findCanceledTransaction(transactions);
}

export function hasNonCanceledTransaction(transactions) {
  return !!findNonCanceledTransaction(transactions);
}

export function hasCompletedTransaction(transactions) {
  return !!findCompletedTransaction(transactions);
}

export function hasTransactionInProgress(transactions) {
  return hasNonCanceledTransaction(transactions) && !hasCompletedTransaction(transactions);
}

export function hasNoNonCanceledTransaction(transactions) {
  return !hasNonCanceledTransaction(transactions);
}

export function hasPaymentInitiatedTransaction(transactions) {
  return !!findPaymentInitiatedTransaction(transactions);
}

export function hasPaymentNotInitiatedTransaction(transactions) {
  return hasNonCanceledTransaction(transactions) && !findPaymentInitiatedTransaction(transactions);
}

export function isListingTransactionComplete(listing) {
  return !!listing.transactionCompletedAt;
}

// eslint-disable-next-line no-unused-vars
export function isListingTransactionCanceled(listing) {
  // TODO: [FEATURE] `return !!listing.transactionCanceledAt` (NOW-968)
  return false;
}

export function doesListingHaveTransactionInProgress(listing) {
  return (
    listing.status.title === 'sold'
    && !isListingTransactionComplete(listing)
    && !isListingTransactionCanceled(listing)
  );
}
