export default {
  wrapper: {
    position: 'relative',
    maxWidth: '530px',

    '@media screen and (max-width: 600px)': {
      alignItems: 'center',
      width: '90%',
    },
  },
  container: {
    padding: '35px',
    background: '#fff',
    borderRadius: '4px',

    '@media screen and (max-width: 600px)': {
      padding: '15px',
    },
  },
  text: {
    padding: '20px 10px',
    fontSize: '32px',
    lineHeight: '38px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#219653',

    '@media screen and (max-width: 600px)': {
      fontSize: '24px',
      lineHeight: '28px',
    },
  },
};
