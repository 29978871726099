import React, { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import ExternalLink from '@material-ui/core/Link';

// styles
import styles from './styles';

const LinkButton = ({
  classes,
  label,
  buttonColor,
  fullWidth,
  path,
  external,
  openInNewTab,
  disabled,
  customStyles,
}) => {
  const location = useLocation();

  return (
    <>
      {disabled ? (
        <div
          style={{ width: fullWidth && '100%', ...customStyles }}
          className={cx(classes.button, classes[buttonColor], classes.disabled)}
        >
          {label}
        </div>
      ) : (
        external ? (
          <ExternalLink
            href={path}
            {...openInNewTab && {
              target: '_blank',
            }}
            underline="none"
            style={{ width: fullWidth && '100%', ...customStyles }}
            className={cx(classes.button, classes[buttonColor])}
          >
            {label}
          </ExternalLink>
        ) : (
          <Link
            to={{ pathname: path, state: { prevPath: location } }}
            style={{ width: fullWidth && '100%', ...customStyles }}
            className={cx(classes.button, classes[buttonColor])}
          >
            {label}
          </Link>
        )
      )}
    </>
  );
};

LinkButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  buttonColor: PropTypes.oneOf(['red', 'green', 'white', 'blue', 'redFilled', 'clearGreen', 'yellow', 'inherit', 'primaryMain', 'secondaryMain']),
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  external: PropTypes.bool,
  openInNewTab: PropTypes.bool,
  disabled: PropTypes.bool,
  customStyles: PropTypes.object,
};

export default compose(withStyles(styles), memo)(LinkButton);
