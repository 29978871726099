import React, {
  memo,
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';
import { DateTime, Interval } from 'luxon';

// components
import Header from 'components/Header';
import Footer from 'components/Footer';
import GoogleMap from 'now-frontend-shared/components/GoogleMap';
import LinkButton from 'now-frontend-shared/components/LinkButton';
import Countdown from 'now-frontend-shared/components/Countdown';
import StatusBadge from 'now-frontend-shared/components/StatusBadge';
import Spinner from 'now-frontend-shared/components/Spinner';
import ImpliedClosingFees from 'components/ImpliedClosingFees';
import TotalListingCost from 'components/TotalListingCost';
import Checkbox from 'now-frontend-shared/components/Checkbox';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DocumentsMenu from './components/DocumentsMenu';
import DocumentsTable from './components/DocumentsTable';

// back arrow
import { ReactComponent as BackArrow } from 'now-frontend-shared/assets/icons/blue_back_arrow.svg';

// helpers
import { isAdminOrCompliancePerson } from 'now-shared/validation/admin-upsert-user';
import {
  listingIsActive, listingNotYetActive, listingIsArchived,
} from 'now-shared/validation/listing-validation';
import { formatNumberToCurrency } from 'now-frontend-shared/utils/helpers';
import { computeCommission, computeTotalListingCost } from 'now-shared/helpers/bid-helpers';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import {
  nonOpWellsDateTime,
  toAuctionEventTime,
} from 'now-shared/helpers/time-helpers';
import { getCompanyName } from 'now-shared/helpers/company-helpers';
import {
  isListingTransactionCanceled,
  isListingTransactionComplete,
} from 'now-shared/helpers/escrow-transaction-helpers';
import {
  ClosingMethod,
} from 'now-shared/enums/closing-method';
import { ListingDocumentType } from 'now-shared/enums/listing-document-type';
import { computeWellsGrossAfe } from 'now-shared/helpers/listing-helpers';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import LaunchIcon from '@material-ui/icons/Launch';
import DollarIcon from '@material-ui/icons/AttachMoneyRounded';
import LockIcon from '@material-ui/icons/LockRounded';
import BankIcon from '@material-ui/icons/AccountBalanceRounded';
import SendIcon from '@material-ui/icons/SendRounded';
import MailUnreadIcon from '@material-ui/icons/MarkunreadRounded';
import ThumbsUpIcon from '@material-ui/icons/ThumbUpAltRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircleRounded';
import StepConnector from '@material-ui/core/StepConnector';

// styles
import { getCurrentProperty } from 'store/actions/propertyViewActions';
import styles from './styles';

// store
import { addListingDocumentDownload, addListingPageView } from 'store/actions/listingAnalyticsActions';

export function getMyBidStatusText(myBid) {
  if (myBid?.status.title === 'winner') {
    return 'You won!';
  }
  if (myBid) {
    return 'You have already bid on this listing';
  }
  return 'You haven’t bid on this listing';
}

function getBidUsername({ user }) {
  return getUserFullName(user);
}

function getBidCompanyName({ user: { company } }) {
  return getCompanyName(company);
}

const TRANSACTION_CREATED = 'transactionCreated';
const PAYMENT_SENT = 'paymentSent';
const PAYMENT_SECURED = 'paymentSecured';
const DOCUMENTS_SENT = 'documentsSent';
const DOCUMENTS_RECEIVED = 'documentsReceived';
const DOCUMENTS_ACCEPTED = 'documentsAccepted';
const FUNDS_DISBURSED = 'fundsDisbursed';
const TRANSACTION_CLOSED = 'transactionClosed';

const steps = [
  {
    id: TRANSACTION_CREATED,
    label: 'Transaction created',
    icon: <LaunchIcon />,
    closingMethods: Object.values(ClosingMethod).filter(method => method !== ClosingMethod.EscrowDotCom),
  },
  {
    id: PAYMENT_SENT,
    label: 'Payment sent',
    icon: <DollarIcon />,
    closingMethods: [ClosingMethod.EscrowDotCom],
  },
  {
    id: PAYMENT_SECURED,
    label: 'Payment secured',
    icon: <LockIcon />,
    closingMethods: [ClosingMethod.EscrowDotCom],
  },
  {
    id: DOCUMENTS_SENT,
    label: 'Documents sent',
    icon: <SendIcon />,
    closingMethods: [ClosingMethod.EscrowDotCom],
  },
  {
    id: DOCUMENTS_RECEIVED,
    label: 'Documents received',
    icon: <MailUnreadIcon />,
    closingMethods: [ClosingMethod.EscrowDotCom],
  },
  {
    id: DOCUMENTS_ACCEPTED,
    label: 'Documents accepted',
    icon: <ThumbsUpIcon />,
    closingMethods: [ClosingMethod.EscrowDotCom],
  },
  {
    id: FUNDS_DISBURSED,
    label: 'Funds disbursed',
    icon: <BankIcon />,
    closingMethods: [ClosingMethod.EscrowDotCom],
  },
  {
    id: TRANSACTION_CLOSED,
    label: 'Transaction closed',
    icon: <CheckCircleIcon />,
    closingMethods: Object.values(ClosingMethod),
  },
];

const stepIds = steps.map(step => step.id);
const stepIdStepMap = new Map(steps.map(({ id, ...step }) => [id, step]));

function getStepIndex(stepId, visibleStepIds = stepIds) {
  return visibleStepIds.indexOf(stepId);
}

function stepIdFromNumber(stepNumber, visibleStepIds = stepIds) {
  return visibleStepIds[stepNumber - 1];
}

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});
function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const {
    active,
    completed,
    icon: stepNumberString,
    visibleStepIds,
  } = props;

  const stepId = useMemo(() => (
    stepIdFromNumber(+stepNumberString, visibleStepIds)
  ), [stepNumberString, visibleStepIds]);

  return (
    <div
      className={cx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {stepIdStepMap.get(stepId).icon || stepNumberString}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node.isRequired,
  visibleStepIds: PropTypes.arrayOf(PropTypes.string.isRequired),
};

ColorlibStepIcon.defaultProps = {
  active: undefined,
  completed: undefined,
  visibleStepIds: stepIds,
};

const PropertyView = ({
  classes,
  match,
  property,
  user,
  isAdminOrComplianceUser,
  location,
}) => {
  const dispatch = useDispatch();
  const [payTimeRemaining, setPayTimeRemaining] = useState('');

  const listingId = match.params.id;

  useEffect(() => {
    dispatch(getCurrentProperty(listingId));
  }, [dispatch, listingId]);

  const getCoordinates = useCallback(() => {
    if (property?.wells) {
      const polylinesCoordinates = [];
      const markersCoordinates = [];

      property.wells.forEach(({
        surfaceLatitude, surfaceLongitude, bottomLatitude, bottomLongitude,
      }) => {
        if (surfaceLatitude && surfaceLongitude && bottomLatitude && bottomLongitude) {
          polylinesCoordinates.push([
            { lat: +surfaceLatitude, lng: +surfaceLongitude },
            { lat: +bottomLatitude, lng: +bottomLongitude },
          ]);

          markersCoordinates.push(
            { lat: +surfaceLatitude, lng: +surfaceLongitude },
          );
        }
      });

      return { polylinesCoordinates, markersCoordinates };
    }
  }, [property]);

  const isOwner = property?.user.id === user.id;
  const isOwnerCompany = property?.user?.company?.id === user.companyId;
  const isBuyer = !isOwnerCompany && !isAdminOrComplianceUser;
  const existingBid = (isOwnerCompany || isAdminOrComplianceUser) ? undefined : property?.bids[0];
  const winningBid = property?.bids[0]?.status.title === 'winner' ? property?.bids[0] : undefined;
  const bidAmount = existingBid?.amount !== undefined ? Number(existingBid.amount) : undefined;
  const bidCarryPercentage = existingBid?.carryPercentage !== undefined ? Number(existingBid.carryPercentage) : undefined;
  const totalWellGrossAfe = computeWellsGrossAfe(property?.wells);
  const netAfe = property?.netAfe !== undefined ? Number(property?.netAfe) : undefined;
  const coordinates = useMemo(() => getCoordinates(), [getCoordinates]);
  const listingStatusTitle = property?.status.title;
  const minimumBidCarry = Number(property?.minimumBidCarry) ? `${property.minimumBidCarry}%` : '-';

  useEffect(() => {
    if (isBuyer && listingStatusTitle === 'active') {
      dispatch(addListingPageView({ listingId }));
    }
  }, [dispatch, listingId, listingStatusTitle, isBuyer]);

  const handleDocumentDownload = ({ documentId }) => {
    if (isBuyer && listingStatusTitle === 'active') {
      dispatch(addListingDocumentDownload({ listingId, documentId }));
    }
  };

  const minimumBidFormatted = useMemo(() => (Number(property?.minimumBid) ? formatNumberToCurrency(property?.minimumBid) : '-'), [property]);
  const netAfeFormatted = useMemo(() => formatNumberToCurrency(netAfe), [netAfe]);
  const totalWellGrossAfeFormatted = useMemo(() => formatNumberToCurrency(totalWellGrossAfe), [totalWellGrossAfe]);
  const bidAmountFormatted = useMemo(() => formatNumberToCurrency(bidAmount), [bidAmount]);
  const bidCarryPercentageFormatted = useMemo(() => `${bidCarryPercentage || 0}%`, [bidCarryPercentage]);
  const transactionType = property?.transactionType ? `${property.transactionType[0].toUpperCase()}${property.transactionType.substring(1)}` : '-';

  const transaction = useMemo(() => {
    let result;
    if (property?.transaction) {
      result = {
        ...property.transaction,
        createdAt: property.escrow?.createdDate || property.soldAt,
        isComplete: isListingTransactionComplete(property),
        isCanceled: isListingTransactionCanceled(property),
      };
    }
    return result;
  }, [property]);

  const closingFees = useMemo(() => (
    // TODO: [FEATURE] return undefined if user has not entered a bid nor a bid carry
    computeCommission({
      bidAmount,
      netAfe: netAfe || 0,
    })
  ), [bidAmount, netAfe]);

  const totalCost = useMemo(() => (
    // TODO: [FEATURE] return undefined if user has not entered a bid nor a bid carry
    computeTotalListingCost({
      bidAmount,
      closingFees: closingFees || 0,
    })
  ), [bidAmount, closingFees]);

  const previousPagePath = useMemo(() => {
    const prevPath = location.state?.prevPath;
    if (prevPath) {
      return `${prevPath.pathname}${prevPath.search}`;
    }
    return '/listings';
  }, [location]);

  const visibleStepIds = useMemo(() => steps.filter(step => {
    let result;
    if (transaction?.escrow) {
      result = step.closingMethods.includes(ClosingMethod.EscrowDotCom);
    } else {
      result = step.closingMethods.includes(ClosingMethod.AlternativeEscrowAgent);
    }
    return result;
  }).map(step => step.id), [transaction]);

  let activeStepId;
  if (transaction) {
    if (transaction.isComplete) {
      activeStepId = TRANSACTION_CLOSED;
    } else if (transaction.escrow?.payment.isDisbursed) {
      activeStepId = FUNDS_DISBURSED;
    } else if (transaction.escrow?.item.status.accepted) {
      activeStepId = DOCUMENTS_ACCEPTED;
    } else if (transaction.escrow?.item.status.received) {
      activeStepId = DOCUMENTS_RECEIVED;
    } else if (transaction.escrow?.item.status.shipped) {
      activeStepId = DOCUMENTS_SENT;
    } else if (transaction.escrow?.payment.isSecured) {
      activeStepId = PAYMENT_SECURED;
    } else if (transaction.escrow?.payment.isInitiated) {
      activeStepId = PAYMENT_SENT;
    } else if (!transaction.escrow) {
      activeStepId = TRANSACTION_CREATED;
    }
  }

  const activeStepIndex = getStepIndex(activeStepId, visibleStepIds);

  const lastTimeFiredRef = useRef(0);

  useEffect(() => {
    let result;
    if (transaction?.escrow && !transaction.isComplete && !transaction.isCanceled) {
      const debounceThresholdMillis = 1000;
      const handleVisibilityChange = () => {
        const now = Date.now();
        if (!document.hidden && (now > (lastTimeFiredRef.current + debounceThresholdMillis))) {
          lastTimeFiredRef.current = now;
          dispatch(getCurrentProperty(listingId));
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);
      window.addEventListener('focus', handleVisibilityChange);
      result = () => {
        window.removeEventListener('focus', handleVisibilityChange);
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }
    return result;
  }, [dispatch, transaction, listingId, lastTimeFiredRef]);

  useEffect(() => {
    let result;
    if (
      transaction
      && !isOwnerCompany
      && !isAdminOrComplianceUser
      && !transaction.isComplete
      && !transaction.isCanceled
      && (
        !transaction.escrow?.payment.isInitiated
        || !transaction.escrow
      )
    ) {
      const updateTimeRemaining = () => {
        const dueDate = DateTime.fromISO(transaction.payment.dueDate);
        const nowDate = DateTime.local();
        let remaining = '';
        if (dueDate > nowDate) {
          remaining = Interval.fromDateTimes(nowDate, dueDate).toDuration().toFormat("d'D' hh'H' mm'M'");
        } else {
          remaining = 'no';
        }
        setPayTimeRemaining(remaining);
      };

      updateTimeRemaining();
      const interval = setInterval(updateTimeRemaining, 1000);
      result = () => clearInterval(interval);
    } else {
      setPayTimeRemaining('');
    }
    return result;
  }, [transaction, isOwnerCompany, isAdminOrComplianceUser]);

  if (!property) return <Spinner />;

  return (
    <>
      <Header />
      <Grid container className={classes.wrapper}>
        <Grid container justify="center" className={classes.container}>
          <Grid container justify="center" className={classes.topSection}>
            <Grid container className={classes.topSectionContent}>
              <Grid item className={classes.arrow}>
                <Link to={previousPagePath}>
                  <BackArrow className={classes.icon} />
                </Link>
              </Grid>

              <Grid container item xs>
                <Grid container alignItems="center" className={classes.topInfoContainer}>
                  <Grid item className={classes.heading}>
                    <span>
                      {`${property.projectName}, `}
                      <span className={classes.wellsCount}>
                        {`${property.wells.length} ${
                          property.wells.length > 1 ? 'wells' : 'well'
                        }`}
                      </span>
                    </span>
                  </Grid>

                  <Grid item className={classes.listingStatus}>
                    <span className={classes.listingStatusHeading}>Listing status</span>
                    <StatusBadge status={existingBid?.status.title === 'winner' ? 'winner' : property.status.title} small />
                    {property.archivedAt && (
                      <StatusBadge status="archived" small />
                    )}
                  </Grid>

                  <Grid container justify="flex-end" item md={2} lg={2} xl={2} className={classes.idContainer}>
                    <span className={classes.id}>
                      <span className={classes.idNumber}>{`LISTING-${property.id}`}</span>
                    </span>
                  </Grid>
                </Grid>

                <Grid container alignItems="center" className={classes.topSectionContainer}>
                  <Grid
                    container
                    direction="column"
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    xl={2}
                    className={classes.topSectionHeading}
                  >
                    <span className={classes.topHeading}>Seller:</span>
                    <span className={classes.topSectionDescription}>
                      {(
                        property.isAnonymous
                          ? 'Anonymous'
                          : getCompanyName(property.user.company) || '<missing company>'
                      )}
                    </span>
                  </Grid>

                  <Grid
                    container
                    direction="column"
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    xl={2}
                    className={classes.topSectionHeading}
                  >
                    <span className={classes.topHeading}>Operator:</span>
                    <span className={classes.topSectionDescription}>{property.operatorName}</span>
                  </Grid>

                  {(isOwnerCompany || isAdminOrComplianceUser) ? (
                    <>
                      <Grid
                        container
                        direction="column"
                        item
                        xs={12}
                        sm={12}
                        md={2}
                        lg={2}
                        xl={2}
                        className={cx(classes.topSectionHeading, classes.separation)}
                      >
                        <span className={classes.topHeading}>Your bid status:</span>
                        <span className={classes.sellerBidStatus}>
                          {isOwnerCompany && 'This is your property'}
                          {isAdminOrComplianceUser && 'Admins cannot bid'}
                        </span>
                      </Grid>
                      {winningBid && (
                        <Grid
                          container
                          direction="column"
                          item
                          xs={12}
                          sm={12}
                          md={2}
                          lg={2}
                          xl={2}
                          className={cx(classes.topSectionHeading, classes.separation)}
                        >
                          <span className={classes.topHeading}>Winner:</span>
                          <span className={classes.topSectionDescription}>
                            {`${getBidUsername(winningBid)} (${getBidCompanyName(winningBid)})`}
                          </span>
                        </Grid>
                      )}
                    </>
                  ) : (
                    <Grid
                      container
                      direction="column"
                      alignItems="flex-start"
                      item
                      xs={12}
                      sm={12}
                      md={2}
                      lg={2}
                      xl={2}
                      className={cx(classes.topSectionHeading, classes.separation)}
                    >
                      <span className={classes.topHeading}>Your bid status:</span>
                      <span className={classes.topSectionDescription}>
                        {getMyBidStatusText(existingBid)}
                      </span>
                    </Grid>
                  )}

                  {((isOwnerCompany && (user.isAuthorizedSigner || user.isViewOnlyListingEditor))
                  || isOwner
                  || isAdminOrComplianceUser)
                  && listingIsActive(property)
                  && (
                    <Grid
                      container
                      justify="flex-end"
                      item
                      xs={12}
                      sm={12}
                      md={2}
                      lg={2}
                      xl={2}
                      className={classes.buttonContainer}
                    >
                      <Box
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        <LinkButton
                          buttonColor="secondaryMain"
                          label="Close Auction"
                          path="/contact-us"
                        />
                        <LinkButton
                          path={`/listings/${property.id}/edit`}
                          buttonColor="secondaryMain"
                          label="Edit"
                        />
                      </Box>
                    </Grid>
                  )}
                  <Grid
                    container
                    justify="flex-end"
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    xl={2}
                    className={classes.buttonContainer}
                  >
                    <Box
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      {(() => {
                        if ((isOwnerCompany && (user.isAuthorizedSigner || user.isViewOnlyListingEditor))
                        || isOwner
                        || isAdminOrComplianceUser) {
                          if (listingNotYetActive(property)) {
                            return (
                              <LinkButton
                                path={`/listings/${property.id}/edit`}
                                buttonColor="secondaryMain"
                                label="Edit"
                              />
                            );
                          }
                          if (transaction) {
                            let label = '';
                            let path = '';
                            let disabled = listingIsArchived(property) || !user.isAccountManager;
                            let customStyles = {};
                            let openInNewTab = false;
                            switch (activeStepId) {
                              case TRANSACTION_CREATED:
                                // fall through
                              case undefined:
                                // fall through
                              case PAYMENT_SENT:
                                // fall through
                              case PAYMENT_SECURED:
                                label = 'Send Documents';
                                customStyles = {
                                  width: '100px',
                                  height: '70px',
                                };
                                break;
                              case DOCUMENTS_SENT:
                                // fall through
                              case DOCUMENTS_RECEIVED:
                                // fall through
                              case DOCUMENTS_ACCEPTED:
                                // fall through
                              case FUNDS_DISBURSED:
                                label = 'Escrow In Progress';
                                customStyles = {
                                  width: '100px',
                                  height: '75px',
                                };
                                disabled = true;
                                break;
                              case TRANSACTION_CLOSED:
                                label = 'Escrow Complete';
                                customStyles = {
                                  width: '100px',
                                  height: '85px',
                                };
                                disabled = true;
                                break;
                              default:
                                break;
                            }

                            if (
                              transaction.escrow
                              && !transaction.escrow?.seller.isDisbursementMethodSelected
                            ) {
                              label = 'Select Disbursement Method';
                              customStyles = {
                                height: '85px',
                              };
                            }

                            if (transaction.escrow?.nextStep?.webUrl) {
                              path = transaction.escrow.nextStep.webUrl;
                              openInNewTab = true;
                              disabled = false;
                            }

                            if (transaction.escrow?.verificationWebUrl) {
                              label = 'Get Verified';
                              path = transaction.escrow.verificationWebUrl;
                              customStyles = {};
                              disabled = listingIsArchived(property) || !user.isAccountManager;
                              openInNewTab = false;
                            }

                            if (!path) {
                              disabled = true;
                            }

                            if (isAdminOrComplianceUser) {
                              disabled = true;
                            }

                            return (
                              <LinkButton
                                path={path}
                                external
                                openInNewTab={openInNewTab}
                                buttonColor="clearGreen"
                                label={label}
                                disabled={disabled}
                                customStyles={customStyles}
                              />
                            );
                          }
                          return (
                            <>
                              <LinkButton
                                path={`/listings/${property.id}/bids`}
                                buttonColor="secondaryMain"
                                label={`Bids (${property.bidCount})`}
                              />
                              <LinkButton
                                path={`/listings/${property.id}/analytics`}
                                buttonColor="secondaryMain"
                                label="Analytics"
                              />
                            </>
                          );
                        }
                        if (transaction) {
                          let label = '';
                          let path = '';
                          let disabled = listingIsArchived(property) || !user.isAccountManager;
                          let doEscrowPay = false;
                          let customStyles = {};
                          let openInNewTab = false;
                          switch (activeStepId) {
                            case TRANSACTION_CREATED:
                              // fall through
                            case undefined:
                              label = 'Pay Now';
                              doEscrowPay = true;
                              break;
                            case PAYMENT_SENT:
                              label = 'Review Pay';
                              doEscrowPay = true;
                              openInNewTab = true;
                              break;
                            case PAYMENT_SECURED:
                              disabled = true;
                              // fall through
                            case DOCUMENTS_SENT:
                              label = 'Receive Documents';
                              customStyles = {
                                width: '100px',
                                height: '70px',
                              };
                              break;
                            case DOCUMENTS_RECEIVED:
                              label = 'Accept/Reject Documents';
                              customStyles = {
                                width: '130px',
                                height: '85px',
                              };
                              break;
                            case DOCUMENTS_ACCEPTED:
                              // fall through
                            case FUNDS_DISBURSED:
                              label = 'Escrow In Progress';
                              customStyles = {
                                width: '100px',
                                height: '75px',
                              };
                              disabled = true;
                              break;
                            case TRANSACTION_CLOSED:
                              label = 'Escrow Complete';
                              customStyles = {
                                width: '100px',
                                height: '85px',
                              };
                              disabled = true;
                              break;
                            default:
                              break;
                          }

                          if (doEscrowPay && transaction.escrow) {
                            path = transaction.escrow.payment.webUrl;
                          } else if (transaction.escrow?.nextStep?.webUrl) {
                            path = transaction.escrow.nextStep.webUrl;
                            openInNewTab = true;
                            disabled = listingIsArchived(property) || !user.isAccountManager;
                          }

                          if (!path) {
                            disabled = true;
                          }
                          return (
                            <LinkButton
                              path={path}
                              external
                              openInNewTab={openInNewTab}
                              buttonColor="clearGreen"
                              label={label}
                              disabled={disabled}
                              customStyles={customStyles}
                            />
                          );
                        }
                        if (user.isAuthorizedSigner) {
                          return (
                            <LinkButton
                              path={`/listings/${property.id}/make-a-bid`}
                              buttonColor="secondaryMain"
                              label="Bid"
                              disabled={!!existingBid || !listingIsActive(property)}
                            />
                          );
                        }
                        return null;
                      })()}
                      {payTimeRemaining && (
                        payTimeRemaining === 'no' ? (
                          (
                            !!transaction?.escrow && (
                              <span style={{ color: 'red' }}>
                                Payment overdue!
                              </span>
                            )
                          )
                          || 'Payment past due'
                        ) : (
                          <>
                            {payTimeRemaining}
                            {' '}
                            left
                          </>
                        )
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.bottomSectionContent}>
            <Grid container alignItems="center" justify="space-between" className={classes.documentsContainer}>

              <DocumentsMenu
                documents={property.documents}
                listingId={property.id}
                onDownload={handleDocumentDownload}
              />
            </Grid>
            {transaction && (
              <>
                <Grid container className={classes.detailsContainer}>
                  <Grid container alignItems="center">
                    <span className={classes.contentHeading}>
                      {transaction.escrow ? 'Escrow' : 'Transaction (Alternative Closing Method)'}
                    </span>
                  </Grid>

                  <Grid container className={classes.bottomContentContainer}>
                    {/* TODO: [UX][MOBILE] make the stepper look good on mobile */}
                    <Stepper
                      alternativeLabel
                      activeStep={activeStepIndex}
                      connector={<ColorlibConnector />}
                      classes={{
                        horizontal: classes.stepperHorizontal,
                      }}
                    >
                      {visibleStepIds.map(stepId => (
                        <Step key={stepId}>
                          <StepLabel
                            StepIconComponent={ColorlibStepIcon}
                            StepIconProps={{
                              visibleStepIds,
                            }}
                          >
                            {stepIdStepMap.get(stepId).label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                    {transaction?.escrow && (
                    <ul>
                      <li>
                        <Checkbox
                          name="verified"
                          label="Escrow ID verified"
                          checked={!!transaction.escrow.isVerified}
                          disabled
                          onChange={() => undefined}
                        />
                      </li>
                      {(isOwnerCompany || isAdminOrComplianceUser) && (
                        <li>
                          <Checkbox
                            name="disbursementMethodSelected"
                            label="Disbursement method selected"
                            checked={!!transaction.escrow.seller.isDisbursementMethodSelected}
                            disabled
                            onChange={() => undefined}
                          />
                        </li>
                      )}
                    </ul>
                    )}
                  </Grid>
                </Grid>
              </>
            )}

            <Grid container className={classes.detailsContainer}>
              <Grid container alignItems="center" justify="space-between" className={classes.descriptionContainer}>
                <span className={classes.contentHeading}>Description</span>
              </Grid>

              <Grid container className={classes.bottomContentContainer}>
                <Grid
                  container
                  direction="column"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  className={classes.bottomSectionHeading}
                >
                  <span>Location:</span>
                  <span className={classes.bottomSectionDescription}>
                    {`${property.basin.title}, ${property.state.title}, ${property.county.title}`}
                  </span>
                </Grid>

                <Grid
                  container
                  direction="column"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={1}
                  xl={1}
                  className={classes.bottomSectionHeading}
                >
                  <span>Wells:</span>
                  <span className={classes.bottomSectionDescription}>
                    {`${property.wells.length} ${
                      property.wells.length > 1 ? 'wells' : 'well'
                    }`}
                  </span>
                </Grid>
              </Grid>

              <Grid container className={cx(classes.bottomContentContainer, classes.wellsDesktop)}>
                {property.wells.map((item, index) => (
                  <Grid
                    item
                    container
                    direction="column"
                    key={index}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={3}
                    xl={3}
                    className={classes.bottomSectionHeading}
                  >
                    <Grid item className={classes.bottomSectionHeading} container direction="column">
                      <div>
                        Landing Zone
                        {' '}
                        {index + 1}
                      </div>
                    </Grid>
                    <Grid item className={classes.bottomSectionHeading} container direction="column">
                      <div>Title</div>
                      <div className={classes.bottomSectionDescription}>{item.landingZone.title}</div>
                    </Grid>
                    <Grid item className={classes.bottomSectionHeading} container direction="column">
                      <div>Well Name</div>
                      <div className={classes.bottomSectionDescription}>{item.wellName}</div>
                    </Grid>
                    <Grid item className={classes.bottomSectionHeading} container direction="column">
                      <div>Net Revenue Interest</div>
                      <div className={classes.bottomSectionDescription}>
                        {item.netRevenueInterestNumber || 0}
                        %
                      </div>
                    </Grid>
                    <Grid item className={classes.bottomSectionHeading} container direction="column">
                      <div>Working Interest</div>
                      <div className={classes.bottomSectionDescription}>
                        {item.workingInterestPercentage || 0}
                        %
                      </div>
                    </Grid>
                    <Grid item className={classes.bottomSectionHeading} container direction="column">
                      <div>Well Gross AFE Amount</div>
                      <div className={classes.bottomSectionDescription}>
                        {formatNumberToCurrency(item.wellGrossAfe)}
                      </div>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <div className={classes.wellsContainer}>
                <Box
                  component={Grid}
                  className={classes.bottomSectionHeading}
                >
                  <div>Well Number</div>
                </Box>
                <Grid className={classes.bottomSectionHeading}>
                  <div>Well Name</div>
                </Grid>
                <Grid className={classes.bottomSectionHeading}>
                  <div>Landing Zone</div>
                </Grid>
                <Grid className={classes.bottomSectionHeading}>
                  <div>Net Revenue Interest</div>
                </Grid>
                <Grid className={classes.bottomSectionHeading}>
                  <div>Working Interest</div>
                </Grid>
                <Grid className={classes.bottomSectionHeading}>
                  <div>Well Gross AFE</div>
                </Grid>
                <Grid className={classes.bottomSectionHeading}>
                  <div>Well Net AFE</div>
                </Grid>
                {property.wells.map((item, index) => (
                  <React.Fragment key={index}>
                    <Box
                      component={Grid}
                      className={classes.bottomSectionHeading}
                      display={{ xs: 'none', sm: 'block' }}
                    >
                      <div className={classes.bottomSectionDescription}>{index + 1}</div>
                    </Box>
                    <Grid item className={classes.bottomSectionHeading}>
                      <div className={classes.bottomSectionDescription}>{item.wellName}</div>
                    </Grid>
                    <Grid item className={classes.bottomSectionHeading}>
                      <div className={classes.bottomSectionDescription}>{item.landingZone.title}</div>
                    </Grid>
                    <Grid item className={classes.bottomSectionHeading}>
                      <div className={classes.bottomSectionDescription}>
                        {item.netRevenueInterestNumber || 0}
                        %
                      </div>
                    </Grid>
                    <Grid item className={classes.bottomSectionHeading}>
                      <div className={classes.bottomSectionDescription}>
                        {item.workingInterestPercentage || 0}
                        %
                      </div>
                    </Grid>
                    <Grid item className={classes.bottomSectionHeading}>
                      <div className={classes.bottomSectionDescription}>
                        {formatNumberToCurrency(item.wellGrossAfe)}
                      </div>
                    </Grid>
                    <Grid item className={classes.bottomSectionHeading}>
                      <div className={classes.bottomSectionDescription}>
                        {formatNumberToCurrency(item.wellNetAFE)}
                      </div>
                    </Grid>
                  </React.Fragment>
                ))}
              </div>
            </Grid>
            <Grid container className={classes.detailsContainer}>
              <Grid container alignItems="center">
                <span className={classes.contentHeading}>Bidding Details</span>
              </Grid>

              <Grid container className={classes.bottomContentContainer}>
                {listingIsActive(property) && (
                  <Grid
                    container
                    direction="column"
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={2}
                    xl={2}
                    className={cx(classes.bottomSectionHeading, classes.redText)}
                  >
                    <span>Time Remaining:</span>
                    <Countdown
                      endTime={
                        toAuctionEventTime(new Date(property.endTime), 'end')
                          .toJSDate()
                          .toISOString()
                      }
                      withoutLabel
                      withoutAdaptive
                    />
                  </Grid>
                )}

                <Grid
                  container
                  direction="column"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={3}
                  xl={3}
                  className={classes.bottomSectionHeading}
                >
                  <span>End Time:</span>
                  <span className={classes.bottomSectionDescription}>
                    {`${
                      nonOpWellsDateTime(new Date(property.endTime))
                        .toFormat('MM / dd / yyyy / t ZZZZ')
                    }`}
                  </span>
                </Grid>

                <Grid
                  container
                  direction="column"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={2}
                  xl={2}
                  className={classes.bottomSectionHeading}
                >
                  <span>Transaction Type:</span>
                  <span className={classes.bottomSectionDescription}>{transactionType}</span>
                </Grid>

                <Grid
                  container
                  direction="column"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={2}
                  xl={2}
                  className={classes.bottomSectionHeading}
                >
                  <span>Total Well Gross AFE:</span>
                  <span className={classes.bottomSectionDescription}>{totalWellGrossAfeFormatted}</span>
                </Grid>

                <Grid
                  container
                  direction="column"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={3}
                  xl={3}
                  className={classes.bottomSectionHeading}
                >
                  <span>Total Well Net AFE:</span>
                  <span className={classes.bottomSectionDescription}>{netAfeFormatted}</span>
                </Grid>

                <Grid
                  container
                  direction="column"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={3}
                  xl={3}
                  className={classes.bottomSectionHeading}
                >
                  <span>Minimum Bid, USD:</span>
                  <span className={classes.bottomSectionDescription}>{minimumBidFormatted}</span>
                </Grid>

                <Grid
                  container
                  direction="column"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={3}
                  xl={3}
                  className={classes.bottomSectionHeading}
                >
                  <span>Minimum Bid, Carry:</span>
                  <span className={classes.bottomSectionDescription}>
                    {minimumBidCarry}
                  </span>
                </Grid>

                {!isOwnerCompany && !isAdminOrComplianceUser && (
                  <>
                    <Grid
                      container
                      direction="column"
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                      className={classes.bottomSectionHeading}
                    >
                      <span>Your Bid:</span>
                      <span className={classes.bottomSectionDescription}>
                        {existingBid ? bidAmountFormatted : '-'}
                      </span>
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                      className={classes.bottomSectionHeading}
                    >
                      <span>Your Bid Carry:</span>
                      <span className={classes.bottomSectionDescription}>
                        {existingBid ? bidCarryPercentageFormatted : '-'}
                      </span>
                    </Grid>
                    {existingBid && (
                      <>
                        <Grid
                          container
                          direction="column"
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={6}
                          xl={6}
                          className={classes.bottomSectionHeading}
                        >
                          <ImpliedClosingFees closingFees={closingFees} />
                        </Grid>
                        <Grid
                          container
                          direction="column"
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={6}
                          xl={6}
                          className={classes.bottomSectionHeading}
                        >
                          <TotalListingCost totalCost={totalCost} />
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Grid>

            <Grid container className={classes.detailsContainer}>
              <Grid container direction="column" className={classes.bottomSectionHeading}>
                <Box className={classes.contentHeading}>
                  Recently Added Documents
                  <CheckCircleOutlineIcon className={classes.checkIcon} />
                </Box>
                <Box className={classes.contentSubHeading}>
                  Below are the recently added documents that have been submitted
                  by the seller after the listing was approved for bidding.
                </Box>
                <DocumentsTable
                  listingId={property.id}
                  documents={property.documents.filter(document => [
                    ListingDocumentType.ADDITIONAL_LISTING_CLOSING_DOCUMENT,
                    ListingDocumentType.ADDITIONAL_LISTING_OTHER_DOCUMENT,
                  ].includes(document.type))}
                  onDownload={handleDocumentDownload}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.detailsContainer}>
              <Grid container direction="column" className={classes.bottomSectionHeading}>
                <Box className={classes.contentHeading}>
                  Original Documents
                </Box>
                <Box className={classes.contentSubHeading}>
                  These are documents that have been submitted by the seller prior to the listing being approved for bidding.
                </Box>
                <DocumentsTable
                  listingId={property.id}
                  documents={property.documents.filter(document => [
                    ListingDocumentType.NEW_LISTING_CLOSING_DOCUMENT,
                    ListingDocumentType.NEW_LISTING_OTHER_DOCUMENT,
                  ].includes(document.type))}
                  onDownload={handleDocumentDownload}
                />
              </Grid>
            </Grid>

            <Grid container className={classes.mapContainer}>
              <GoogleMap wells={coordinates} />
            </Grid>

          </Grid>
        </Grid>
      </Grid>

      <Footer />
    </>
  );
};

PropertyView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  property: PropTypes.object,
  user: PropTypes.shape({
    companyId: PropTypes.number,
    isAuthorizedSigner: PropTypes.bool,
    isViewOnlyListingEditor: PropTypes.bool,
    isAccountManager: PropTypes.bool,
    // TODO: [TYPE] fill out remaining fields
  }).isRequired,
  isAdminOrComplianceUser: PropTypes.bool,
};

PropertyView.defaultProps = {
  isAdminOrComplianceUser: false,
};

export default compose(
  connect(({ propertyView, auth }) => ({
    property: propertyView.currentProperty,
    user: auth.user,
    isAdminOrComplianceUser: isAdminOrCompliancePerson(auth.user),
  })),
  withStyles(styles),
  memo,
)(PropertyView);
