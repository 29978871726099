import { primaryMain, secondaryMain } from '../../themes/colors';

export default {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100vw',
    height: '100vh',
  },
  spinner: {
    display: 'block',
    position: 'absolute',
    left: '50%',
    top: '50%',
    margin: '-75px 0 0 -75px',
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    border: '5px solid transparent',
    borderBottomColor: primaryMain,
    borderRightColor: primaryMain,
    animation: '$spin_reverse 1.2s linear infinite',

    '&:before': {
      content: "''",
      position: 'absolute',
      top: '5px',
      left: '5px',
      bottom: '5px',
      right: '5px',
      borderRadius: '50%',
      border: '6px solid transparent',
      borderRightColor: secondaryMain,
      borderTopColor: secondaryMain,
      animation: '$spin 0.6s linear infinite',
    },

    '&:after': {
      content: "''",
      position: 'absolute',
      top: '15px',
      left: '15px',
      bottom: '15px',
      right: '15px',
      borderRadius: '50%',
      border: '5px solid transparent',
      borderRightColor: primaryMain,
      borderTopColor: primaryMain,
      animation: '$spin 0.8s linear infinite',
    },
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(-180deg)',
    },

    to: {
      transform: 'rotate(180deg)',
    },
  },

  '@keyframes spin_reverse': {
    from: {
      transform: 'rotate(180deg)',
    },

    to: {
      transform: 'rotate(-180deg)',
    },
  },
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 2,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
};
