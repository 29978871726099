import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';

// components
import SingleArrowButton from './components/SingleArrowButton';

// styles and components from material-ui
import { usePagination } from '@material-ui/lab/Pagination';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

const Pagination = ({ classes, count }) => {
  const [currentPage, setCurrentPage] = useState();
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);

  useEffect(() => {
    const currentPage = searchParams.get('page');
    setCurrentPage(currentPage || 1);
    // eslint-disable-next-line
  }, [history.location.search]);

  const { items } = usePagination({
    count,
    defaultPage: 1,
    hidePrevButton: true,
    hideNextButton: true,
  });

  const setActualPage = actualPage => {
    searchParams.set('page', actualPage);
    const search = `?${searchParams.toString()}`;
    history.push(search);

    setCurrentPage(actualPage);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleChangePage = ({ target: { value } }) => {
    setActualPage(value);
  };

  const handleGoBack = () => {
    const actualPage = currentPage - 1;
    setActualPage(actualPage);
  };

  const handleMoveOn = () => {
    const actualPage = +currentPage + 1;
    setActualPage(actualPage);
  };

  return (
    <Grid container alignItems="center" justifyContent="center" className={classes.wrapper}>
      <Grid item>
        <SingleArrowButton disabled={currentPage <= 1} onClick={handleGoBack} />
      </Grid>

      <Grid item className={classes.container}>
        {items.map(({ page, type }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = (
              <button
                value={page}
                type="button"
                className={cx(classes.button, { [classes.active]: page === +currentPage })}
                onClick={handleChangePage}
              >
                {page}
              </button>
            );
          }

          if (children) {
            return (
              <Grid item key={index} className={classes.item}>
                {children}
              </Grid>
            );
          }

          return null;
        })}
      </Grid>

      <Grid item>
        <SingleArrowButton isReversed disabled={+currentPage === +count} onClick={handleMoveOn} />
      </Grid>
    </Grid>
  );
};

Pagination.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  count: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default compose(withStyles(styles))(Pagination);
