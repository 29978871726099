import {
  put, call, takeLatest, take,
} from 'redux-saga/effects';

// storage helpers
import * as storage from 'now-frontend-shared/utils/storage';

// store
import {
  removeUserData,
  setUserDataRemoved,
} from '../actions/authActions';
import { clearState as clearBidedPropertiesState } from '../actions/bidedPropertiesActions';
import { reset as clearBusinessFilesState } from '../actions/businessFilesActions';
import { reset as clearOfficerFilesState } from '../actions/officerFilesActions';
import { clearState as clearCompaniesState } from 'store/actions/companiesActions';
import { clearState as clearCompanyState } from 'store/actions/companyActions';
import { clearState as clearCreatePropertyState } from 'store/actions/createPropertyActions';
import { clearState as clearEditPropertyState } from 'store/actions/editPropertyActions';
import { clearState as clearMapState } from 'store/actions/mapActions';
import { clearState as clearMyBidAllowanceState } from '../actions/myBidAllowanceActions';
import { clearState as clearMyPropertiesState } from '../actions/myPropertiesActions';
import { clearState as clearPropertiesState } from '../actions/propertiesActions';
import { clearState as clearPropertyBidsListState } from '../actions/propertyBidsListActions';
import { clearState as clearPropertyViewState } from '../actions/propertyViewActions';
import { removeSettingsData as clearSettingsState } from '../actions/settingsActions';
import { clearState as clearStatesState } from '../actions/statesActions';
import { clearState as clearUserState } from '../actions/userActions';
import { clearListingAnalyticsState } from 'store/actions/listingAnalyticsActions';

function* logOut() {
  yield put({ type: clearBidedPropertiesState.type });
  yield put({ type: clearBusinessFilesState.type });
  yield put({ type: clearOfficerFilesState.type });
  yield put({ type: clearCompaniesState.type });
  yield put({ type: clearCompanyState.type });
  yield put({ type: clearCreatePropertyState.type });
  yield put({ type: clearEditPropertyState.type });
  yield put({ type: clearMapState.type });
  yield put({ type: clearMyBidAllowanceState.type });
  yield put({ type: clearMyPropertiesState.type });
  yield put({ type: clearPropertiesState.type });
  yield put({ type: clearPropertyBidsListState.type });
  yield put({ type: clearPropertyViewState.type });
  yield put({ type: clearSettingsState.type });
  yield put({ type: clearStatesState.type });
  yield put({ type: clearUserState.type });
  yield put({ type: clearListingAnalyticsState.type });
  yield call(storage.clearStorage);
  yield put(setUserDataRemoved());
}

export function* watchLogOut() {
  yield takeLatest(removeUserData.type, logOut);
  yield take(setUserDataRemoved.type);
}
