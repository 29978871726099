import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useRef,
} from 'react';

// components
import ModalLayout from '../layouts/ModalLayout';

const ModalContext = createContext(() => null);

export const useModalSetter = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modal, setState] = useState(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const closeHandler = useRef(undefined);

  const setModal = useCallback(
    (component, onClose) => {
      if (component) {
        setState(component);
        closeHandler.current = onClose;
        setModalVisible(true);
      } else {
        setModalVisible(false);
        if (closeHandler.current) {
          closeHandler.current();
        }
        closeHandler.current = undefined;
      }
    },
    [setState],
  );

  return (
    <ModalContext.Provider value={{ setModal }}>
      {children}
      <ModalLayout slideDirection="down" isModalOpen={isModalVisible} handleModalClick={() => setModal(null)}>
        {modal}
      </ModalLayout>
    </ModalContext.Provider>
  );
};
