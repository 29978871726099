export default {
  relativeContainer: {
    position: 'relative',
    flex: 1,
  },
  wrapper: {
    height: '100%',
    padding: '40px 75px 70px',

    '@media screen and (max-width: 0px)': {
      padding: '40px 20px',
    },
  },
  container: {
    position: 'relative',
    padding: '10px 46px',

    '@media screen and (max-width: 600px)': {
      padding: '0',
    },
  },
  topPanel: {
    padding: '36px 46px',

    '@media screen and (max-width: 600px)': {
      padding: '30px 0',
    },
  },
  message: {
    fontSize: '22px',
    lineHeight: '28px',
    fontWeight: 'normal',
    padding: '50px',
  },
};
