import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from './styles';

const RequiredDescription = ({ classes }) => <p className={classes.description}>* is a required field</p>;

RequiredDescription.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(
  withStyles(styles),
)(RequiredDescription);
