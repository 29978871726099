export const zipFileMimeTypes = [
  'application/zip',
  'application/zip-compressed',
  'application/x-zip',
  'application/x-zip-compressed',
];

/**
 * @param {string} filename
 * @returns {string}
 */
export function getContentDisposition(filename) {
  return `inline; filename="${filename}"; filename*=UTF-8''${encodeURIComponent(filename)}`;
}

/**
 * 1GB for most files
 */
export const uploadNonZipMaxFileSize = {
  bytes: 1e9,
  label: '1GB',
};

/**
 * 5GiB for ZIP files
 *
 * NOTE: a single aws s3 presigned url PUT request can upload up to 5GiB of content.
 * A file bigger than that needs to be split into multiple parts and presigned urls and
 * uploaded in a 4 step process called "multipart upload" (https://dev.to/traindex/multipart-upload-for-large-files-using-pre-signed-urls-aws-4hg4).
 */
export const uploadZipMaxFileSize = {
  bytes: 5 * 1024 * 1024 * 1024,
  label: '5GiB',
};

/**
 * Max upload file size for any kind of file
 */
export const uploadMaxFileSize = uploadZipMaxFileSize;

export function checkFileSize({
  fileMime,
  fileSize,
}) {
  let error;

  if (zipFileMimeTypes.includes(fileMime)) {
    if (fileSize > uploadZipMaxFileSize.bytes) {
      error = {
        message: `ZIP files cannot be greater than ${uploadZipMaxFileSize.label}`,
        ...uploadZipMaxFileSize,
      };
    }
  } else if (fileSize > uploadNonZipMaxFileSize.bytes) {
    error = {
      message: `Non-ZIP files cannot be greater than ${uploadNonZipMaxFileSize.label}`,
      ...uploadNonZipMaxFileSize,
    };
  }

  return error;
}

/**
 * TODO: [BUG][FEATURE] presigning Content-Length is not allowed and doesn't currently work.
 * (https://github.com/aws/aws-sdk-go/issues/1414)
 * Change this to `true` if/when it becomes supported by the AWS JavaScript SDK.
 */
export const awsUploadFileSizeRestrictionIsSupported = false;
