import React, {
  memo, useEffect, useMemo,
} from 'react';
import { Field, formValues, reduxForm } from 'redux-form';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';

import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';

// components
import Header from 'components/Header';
import Footer from 'components/Footer';
import Countdown from 'now-frontend-shared/components/Countdown';
import StatusBadge from 'now-frontend-shared/components/StatusBadge';
import Spinner from 'now-frontend-shared/components/Spinner';
import AmountInput from './components/AmountInput';
import PlaceBidButton from './components/PlaceBidButton';
import StyledLink from 'pages/Profile/components/StyledLink';
import SubmitConfirmationModal from 'now-frontend-shared/components/modals/SubmitConfirmationModal';

// back arrow
import { ReactComponent as BackArrow } from 'now-frontend-shared/assets/icons/blue_back_arrow.svg';

// helpers
import { computeAcquiredRetainedInterest, computeCommission, computeTotalListingCost } from 'now-shared/helpers/bid-helpers';
import {
  listingIsActive as isListingActive,
  listingIsArchived as isListingArchived,
} from 'now-shared/validation/listing-validation';
import { formatNumberToCurrency, maskedAmountToNumber } from 'now-frontend-shared/utils/helpers';
import { getMyBidStatusText } from 'pages/PropertyView';
import {
  computeWellsAverageWorkingInterestPercentage,
  computeWellsGrossAfe,
} from 'now-shared/helpers/listing-helpers';
import {
  nonOpWellsDateTime,
  toAuctionEventTime,
} from 'now-shared/helpers/time-helpers';
import { getCompanyName } from 'now-shared/helpers/company-helpers';

// custom hooks
import useIsTablet from 'now-frontend-shared/hooks/useIsTablet';

// validation

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ImpliedClosingFees from 'components/ImpliedClosingFees';
import TotalListingCost from 'components/TotalListingCost';

// styles
import styles from './styles';

// store
import { getCurrentProperty, makeBet } from 'store/actions/propertyViewActions';

const PropertyBid = ({
  classes,
  match,
  property,
  user,
  companyIsApprovedAndActive,
  hasBankInformation,
  hasBuyerAgreement,
  handleSubmit,
  amount: amountFormatted,
  carryPercentage: carryPercentageFormatted,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isTablet = useIsTablet();
  const { setModal } = useModalSetter();

  useEffect(() => {
    dispatch(getCurrentProperty(match.params.id));
  }, [dispatch, match.params.id]);

  const amount = useMemo(() => {
    if (amountFormatted) {
      return maskedAmountToNumber(amountFormatted);
    }
  }, [amountFormatted]);

  const carryPercentage = useMemo(() => {
    if (carryPercentageFormatted) {
      return maskedAmountToNumber(carryPercentageFormatted);
    }
  }, [carryPercentageFormatted]);

  const listingId = match.params.id;

  const onHandleSubmit = ({ amount, carryPercentage }) => {
    const submitBid = () => {
      dispatch(makeBet({
        amount: amount ? maskedAmountToNumber(amount) : amount,
        carryPercentage: carryPercentage ? maskedAmountToNumber(carryPercentage) : carryPercentage,
        id: listingId,
      }));
    };

    setModal(
      <SubmitConfirmationModal
        heading="As a reminder, all bids are binding. Are you sure you want to submit this bid?"
        confirmLabel="Submit"
        handleSubmit={submitBid}
      />,
    );
  };

  const isOwner = property?.user?.company?.id === user.companyId;
  const hideBidInputSection = false;
  const hidePlaceBidButton = false;
  const existingBid = !isOwner && property?.bids[0];
  const totalWellGrossAfe = computeWellsGrossAfe(property?.wells);
  const netAfe = Number(property?.netAfe);
  const listingIsActive = !!property && isListingActive(property);
  const listingIsArchived = !!property && isListingArchived(property);
  const showStartTime = false;
  const bidAmount = existingBid ? Number(existingBid.amount) : amount;
  const bidCarryPercentage = existingBid ? Number(existingBid.carryPercentage) : carryPercentage;
  const transactionType = property?.transactionType;
  // TODO: NOW-1143 move to the shared code folder to determine the enum string of the transaction type
  const cashTransactionType = transactionType === 'cash';
  const carryTransactionType = transactionType === 'carry';
  const eitherTransactionType = transactionType === 'either';

  const minimumBidFormatted = useMemo(() => (carryTransactionType ? '-' : formatNumberToCurrency(property?.minimumBid)), [property]);
  const minimumBidCarryFormatted = useMemo(() => (cashTransactionType ? '-' : `${property?.minimumBidCarry}%`), [property]);
  const totalWellGrossAfeFormatted = useMemo(() => formatNumberToCurrency(totalWellGrossAfe), [totalWellGrossAfe]);
  const netAfeFormatted = useMemo(() => formatNumberToCurrency(netAfe), [netAfe]);

  const wells = property?.wells;
  const wellsAverageWorkingInterestPercentage = useMemo(
    () => computeWellsAverageWorkingInterestPercentage(
      (wells || []).map(well => ({
        workingInterestPercentage: maskedAmountToNumber(well.workingInterestPercentage),
      })),
    ),
    [wells],
  );

  const bidWorkingInterest = useMemo(
    () => computeAcquiredRetainedInterest({
      bidCarryPercentage,
      sellerOriginalOwnershipPercentage: wellsAverageWorkingInterestPercentage,
    }),
    [bidCarryPercentage, wellsAverageWorkingInterestPercentage],
  );

  const closingFees = useMemo(() => (
    bidAmount === undefined && bidCarryPercentage === undefined
      ? undefined
      : computeCommission({
        bidAmount,
        netAfe: netAfe || 0,
      })
  ), [bidAmount, bidCarryPercentage, netAfe]);

  const totalCost = useMemo(() => (
    bidAmount === undefined && bidCarryPercentage === undefined
      ? undefined
      : computeTotalListingCost({
        bidAmount,
        closingFees: closingFees || 0,
      })
  ), [bidAmount, bidCarryPercentage, closingFees]);

  const inputDisabled = useMemo(
    () => (
      !companyIsApprovedAndActive
      || !hasBankInformation
      || !hasBuyerAgreement
      || existingBid
      || isOwner
      || !listingIsActive
    ),
    [
      existingBid,
      companyIsApprovedAndActive,
      hasBankInformation,
      hasBuyerAgreement,
      isOwner,
      listingIsActive,
    ],
  );

  if (!property) return <Spinner />;

  return (
    <>
      <Header />

      <Grid
        component="form"
        onSubmit={handleSubmit(onHandleSubmit)}
        container
        className={classes.wrapper}
      >
        <Grid
          container
          justify="center"
          className={classes.container}
        >
          <Grid
            container
            justify="center"
            className={classes.topSection}
          >
            <Grid
              container
              className={classes.topSectionContent}
            >
              <Grid item className={classes.arrow}>
                <Link to={`/listings/${match.params.id}`}>
                  <BackArrow className={classes.icon} />
                </Link>
              </Grid>

              <Grid container item xs>
                <Grid container alignItems="center" className={classes.topInfoContainer}>
                  <Grid item className={classes.heading}>
                    <span>
                      {`${property.projectName}, `}
                      <span className={classes.wellsCount}>
                        {`${property.wells.length} ${
                          property.wells.length > 1 ? 'wells' : 'well'
                        }`}
                      </span>
                    </span>
                  </Grid>

                  <Grid container justify="flex-end" item md={2} lg={2} xl={2} className={classes.idContainer}>
                    <span className={classes.id}>
                      ID
                      {' '}
                      <span className={classes.idNumber}>{`LISTING-${property.id}`}</span>
                    </span>
                  </Grid>
                </Grid>

                <Grid container alignItems="center" className={classes.topSectionContainer}>
                  <Grid
                    container
                    direction="column"
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                    className={classes.topSectionHeading}
                  >
                    <span>Seller:</span>
                    <span className={classes.topSectionDescription}>
                      {(
                        property.isAnonymous
                          ? 'Anonymous'
                          : getCompanyName(property.user.company) || '<missing company>'
                      )}
                    </span>
                  </Grid>

                  <Grid
                    container
                    direction="column"
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                    className={cx(classes.topSectionHeading, classes.separation)}
                  >
                    <span>Operator:</span>
                    <span className={classes.topSectionDescription}>{property.operatorName}</span>
                  </Grid>
                  {isOwner ? (
                    <Grid
                      container
                      direction="column"
                      alignItems="flex-end"
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                      className={classes.topSectionHeading}
                    >
                      <span>Your bid status:</span>
                      <span className={classes.sellerBidStatus}>This is your property</span>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      direction="column"
                      alignItems="flex-end"
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                      className={classes.topSectionHeading}
                    >
                      <span>Your bid status:</span>
                      <span className={classes.topSectionDescription}>
                        {getMyBidStatusText(existingBid)}
                      </span>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {(
            !companyIsApprovedAndActive
            || !hasBankInformation
            || !hasBuyerAgreement
          ) && (
            <Grid container justify="center" className={classes.agreementsMessageWrapper}>
              <Grid container className={classes.agreementsMessageContent}>
                {!companyIsApprovedAndActive && (
                  <span className={classes.agreementsMessage}>
                    Your company needs to be approved before you can bid
                  </span>
                )}
                {!hasBuyerAgreement && (
                  <span className={classes.agreementsMessage}>
                    You need to digitally sign or re-sign the
                    {' '}
                    <Link
                      to={{ pathname: '/buyer-agreement', state: { prevPath: location } }}
                      className={classes.agreementsLink}
                    >
                      Buyer's Agreement
                    </Link>
                    {' '}
                    in order to bid.
                  </span>
                )}

                {!hasBankInformation && (
                  <span className={classes.agreementsMessage}>
                    You need to
                    {' '}
                    <Link
                      to={{ pathname: '/bank-information', state: { prevPath: location } }}
                      className={classes.agreementsLink}
                    >
                      submit your bank information and request a bid allowance
                    </Link>
                    {' '}
                    in order to bid.
                  </span>
                )}
              </Grid>
            </Grid>
          )}

          <Grid container className={classes.bottomSectionContent}>
            <Grid container alignItems="center">
              <span className={classes.contentHeading}>Bidding details</span>
            </Grid>
            <Grid container className={classes.bottomContentContainer}>
              <Grid
                container
                {...!isTablet && {
                  item: true,
                  xs: 9,
                }}
              >
                {!listingIsActive && (
                  <Grid
                    container
                    direction="row"
                    {...!isTablet && {
                      item: true,
                      xs: 6,
                    }}
                    className={classes.bottomSectionHeading}
                  >
                    <span>Listing status</span>
                    <Grid
                      container
                      alignItems="center"
                      item
                      className={classes.status}
                    >
                      {!listingIsActive && (
                        <StatusBadge
                          status={existingBid?.status.title === 'winner' ? 'winner' : property.status.title}
                          small
                        />
                      )}
                      {listingIsArchived && (
                        <StatusBadge status="archived" small />
                      )}
                    </Grid>
                  </Grid>
                )}

                {showStartTime && (
                  <Grid
                    container
                    direction="column"
                    {...!isTablet && {
                      item: true,
                      xs: 6,
                    }}
                    className={classes.bottomSectionHeading}
                  >
                    <span>Start time:</span>
                    <span className={classes.bottomSectionDescription}>
                      {`${
                        nonOpWellsDateTime(new Date(property.startTime))
                          .toFormat('MM / dd / yyyy / t ZZZZ')
                      }`}
                    </span>
                  </Grid>
                )}

                {listingIsActive && (
                  <Grid
                    container
                    direction="column"
                    {...!isTablet && {
                      item: true,
                      xs: 6,
                    }}
                    className={cx(classes.bottomSectionHeading, classes.redText)}
                  >
                    <span>
                      Time remaining:
                    </span>
                    <Countdown
                      endTime={
                        toAuctionEventTime(new Date(property.endTime), 'end')
                          .toJSDate()
                          .toISOString()
                      }
                      withoutLabel
                      withoutAdaptive={isTablet}
                    />
                    <br />
                    <br />
                    <Grid
                      container
                      direction="column"
                      {...!isTablet && {
                        item: true,
                        xs: 8,
                      }}
                      className={classes.bottomSectionHeading}
                    >
                      <span>Total Well Gross AFE:</span>
                      <span className={classes.bottomSectionDescription}>{totalWellGrossAfeFormatted}</span>
                      <br />
                      <br />
                      <span>Total Well Net AFE:</span>
                      <span className={classes.bottomSectionDescription}>{netAfeFormatted}</span>
                      <br />
                      <br />
                    </Grid>
                  </Grid>
                )}

                <Grid
                  container
                  direction="column"

                  {...!isTablet && {
                    item: true,
                    xs: 6,
                  }}
                  className={classes.bottomSectionHeading}
                >
                  <span>End time:</span>
                  <span className={classes.bottomSectionDescription}>
                    {`${
                      nonOpWellsDateTime(new Date(property.endTime))
                        .toFormat('MM / dd / yyyy / t ZZZZ')
                    }`}
                  </span>
                  <br />
                  <br />
                  <Grid
                    container
                    direction="column"
                    {...!isTablet && {
                      item: true,
                      xs: 6,
                    }}
                    className={classes.bottomSectionHeading}
                  >
                    <span>Minimum bid, USD:</span>
                    <span className={classes.bottomSectionDescription}>{minimumBidFormatted}</span>
                    <br />
                    <br />
                    <span>Minimum bid, Carry:</span>
                    <span className={classes.bottomSectionDescription}>{minimumBidCarryFormatted}</span>
                    <br />
                    <br />
                    <br />
                    <br />
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="column"
                  {...!isTablet && {
                    item: true,
                    xs: 2,
                  }}
                  className={cx(classes.bottomSectionHeading, classes.bottomWellContainer)}
                >
                  <br />
                  <br />
                  <span className={classes.contentHeading}>Property details</span>
                  <br />
                  <span>Location:</span>
                  <span className={classes.bottomSectionDescription}>
                    {`${property.basin.title}, ${property.state.title}, ${property.county.title}`}
                  </span>
                </Grid>

                <Grid
                  container
                  direction="column"
                  {...!isTablet && {
                    item: true,
                    xs: 2,
                  }}
                  className={cx(classes.bottomSectionHeading, classes.bottomWellContainer)}
                >
                  <br />
                  <br />
                  <span className={classes.blueHeadingClone}>Wells:</span>
                  <br />
                  <span>
                    {`${property.wells.length} ${
                      property.wells.length > 1 ? 'wells' : 'well'
                    }`}
                  </span>
                </Grid>

                <Grid
                  container
                  direction="column"
                  {...!isTablet && {
                    item: true,
                    xs: 2,
                  }}
                  className={cx(classes.bottomSectionHeading, classes.bottomWellContainer)}
                >
                  <br />
                  <br />
                  <span className={classes.blueHeadingClone}>Well Name:</span>
                  <br />
                  {property.wells.map(({ wellName }, index) => (
                    <>
                      <span className={classes.wellAttrTitle}>{`Well Name ${index + 1}:`}</span>
                      <span className={classes.wellAttrValue}>{wellName}</span>
                      <br />
                    </>
                  ))}
                </Grid>

                <Grid
                  container
                  direction="column"
                  {...!isTablet && {
                    item: true,
                    xs: 2,
                  }}
                  className={cx(classes.bottomSectionHeading, classes.bottomWellContainer)}
                >
                  <br />
                  <br />
                  <span className={classes.blueHeadingClone}>Well Net AFE:</span>
                  <br />
                  {property.wells.map(({ wellNetAFE }, index) => (
                    <>
                      <span className={classes.wellAttrTitle}>{`Well Net AFE ${index + 1}:`}</span>
                      <span className={classes.wellAttrValue}>{formatNumberToCurrency(wellNetAFE || 0)}</span>
                      <br />
                    </>
                  ))}
                </Grid>

                <Grid
                  container
                  direction="column"
                  {...!isTablet && {
                    item: true,
                    xs: 2,
                  }}
                  className={cx(classes.bottomSectionHeading, classes.bottomWellContainer)}
                >
                  <br />
                  <br />
                  <span className={classes.blueHeadingClone}>Well Gross AFE:</span>
                  <br />
                  {property.wells.map(({ wellGrossAfe }, index) => (
                    <>
                      <span className={classes.wellAttrTitle}>{`Well Gross AFE ${index + 1}:`}</span>
                      <span className={classes.wellAttrValue}>{formatNumberToCurrency(wellGrossAfe)}</span>
                      <br />
                    </>
                  ))}
                </Grid>

                <Grid
                  container
                  direction="column"
                  {...!isTablet && {
                    item: true,
                    xs: 2,
                  }}
                  className={cx(classes.bottomSectionHeading, classes.bottomWellContainer)}
                >
                  <br />
                  <br />
                  <span className={classes.blueHeadingClone}>Landing Zone:</span>
                  <br />
                  {property.wells.map(({ landingZone }, index) => (
                    <>
                      <span className={classes.wellAttrTitle}>{`Landing Zone ${index + 1}:`}</span>
                      <span className={classes.wellAttrValue}>{landingZone.title}</span>
                      <br />
                    </>
                  ))}
                </Grid>
              </Grid>

              {!hideBidInputSection && (
                <Grid
                  container
                  item
                  {...isTablet ? {
                    xs: 12,
                    sm: 7,
                    md: 5,
                  } : {
                    xs: 3,
                  }}
                  direction="column"
                  justify="center"
                  className={classes.bidContainer}
                >
                  <span className={classes.bidHeading}>{existingBid ? 'Your bid' : 'Enter your bid'}</span>

                  <Grid
                    container
                    spacing={1}
                  >
                    {carryTransactionType ? null : (
                      <Grid container item xs={12}>
                        {existingBid ? (
                          <AmountInput
                            disabled
                            input={{
                              value: bidAmount,
                            }}
                            meta={{}}
                          />
                        ) : (
                          <Field
                            name="amount"
                            component={AmountInput}
                            props={{
                              disabled: inputDisabled,
                              placeholder: '$ Enter your cash bid amount',
                            }}
                          />
                        )}
                      </Grid>
                    )}
                    {eitherTransactionType && (
                      <Grid container item xs={12}>
                        <span className={classes.bottomSectionHeading}>Or</span>
                      </Grid>
                    )}
                    {cashTransactionType ? null : (
                      <Grid container item xs={12}>
                        {existingBid ? (
                          <AmountInput
                            disabled
                            maskType="percent"
                            input={{
                              value: bidCarryPercentage,
                            }}
                            meta={{}}
                          />
                        ) : (
                          <Field
                            name="carryPercentage"
                            component={AmountInput}
                            props={{
                              disabled: inputDisabled,
                              maskType: 'percent',
                              placeholder: '% Enter your carry bid percentage',
                            }}
                          />
                        )}
                      </Grid>
                    )}

                    <Grid container direction="column" className={classes.bottomSectionHeading}>
                      <>
                        <span>
                          Buyer cost bearing working interest:
                        </span>
                        <span
                          className={classes.bidStatValue}
                        >
                          {wellsAverageWorkingInterestPercentage}
                          %
                        </span>
                      </>
                    </Grid>

                    <Grid container direction="column" className={classes.bottomSectionHeading}>
                      <>
                        <span>
                          Buyer acquired/assigned working interest:
                        </span>
                        <span
                          className={classes.bidStatValue}
                        >
                          {bidWorkingInterest.buyerAcquiredPercentage}
                          %
                        </span>
                      </>
                    </Grid>

                    <Grid container direction="column" className={classes.bottomSectionHeading}>
                      <>
                        <span>
                          Seller retained (carried) working interest:
                        </span>
                        <span
                          className={classes.bidStatValue}
                        >
                          {bidWorkingInterest.sellerRetainedPercentage}
                          %
                        </span>
                      </>
                    </Grid>

                    <Grid container direction="column" className={classes.bottomSectionHeading}>
                      <ImpliedClosingFees closingFees={closingFees} />
                    </Grid>

                    <Grid container direction="column" className={classes.bottomSectionHeading}>
                      <TotalListingCost totalCost={totalCost} />
                    </Grid>

                    {!hidePlaceBidButton && (
                      <>
                        <Grid
                          container
                          item
                          {...isTablet ? {
                            xs: 12,
                            sm: 4,
                            md: 4,
                          } : {
                            xs: 4,
                          }}
                        >
                          <PlaceBidButton disabled={inputDisabled} />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <StyledLink path="/increase-bid-allowance">Increase Bid Allowance</StyledLink>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>

          </Grid>
        </Grid>
      </Grid>

      <Footer />
    </>
  );
};

PropertyBid.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  property: PropTypes.object,
  user: PropTypes.shape({
    companyId: PropTypes.number,
    // TODO: [TYPE] fill out remaining fields
  }).isRequired,
  companyIsApprovedAndActive: PropTypes.bool.isRequired,
  hasBankInformation: PropTypes.bool,
  hasBuyerAgreement: PropTypes.bool,
};

export default compose(
  reduxForm({
    form: 'makeBet',
  }),
  // don't want/need warnAboutUnsavedForm for this form
  connect(({ propertyView, auth }) => ({
    property: propertyView.currentProperty,
    hasBankInformation: auth.user.company?.hasBankInformation,
    hasBuyerAgreement: auth.user.company?.hasBuyerAgreement,
    companyIsApprovedAndActive: !!auth.user.company?.approved && !!auth.user.company?.active,
    user: auth.user,
  })),
  withStyles(styles),
  formValues('amount', 'carryPercentage'),
  memo,
)(PropertyBid);
