import { TextField } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { primaryDark } from '../../../themes/colors';

/**
 * NOTE: this file is duplicated in `frontend-main` for now, so any changes made to this file also need to be
 * made in that file as well. Please reference this file instead of that one in new code, as the other one should
 * eventually be removed.
 *
 * TODO: [DRY][REFACTOR] delete the other duplicate file in `frontend-main` and refactor code that references
 * it to reference this file instead (NOW-938)
 */
export const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: primaryDark,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: primaryDark,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.2)',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: primaryDark,
    },
  },
});
