export default {
  heading: {
    padding: '40px',
    fontSize: '32px',
    lineHeight: '37px',
    fontWeight: 'bold',
    color: '#282424',

    '@media screen and (max-width: 600px)': {
      justifyContent: 'left',
      padding: '20px 0',
      fontSize: '24px',
      lineHeight: '28px',
    },
  },
  container: {
    marginBottom: '40px',
    textAlign: 'center',

    '@media screen and (max-width: 960px)': {
      marginBottom: '20px',
    },

    '@media screen and (max-width: 600px)': {
      textAlign: 'left',
    },
  },
  text: {
    width: '100%',
    margin: '6px 0',
    fontSize: '20px',
    lineHeight: '23px',
    fontWeight: 'normal',
    color: '#333333',

    '@media screen and (max-width: 600px)': {
      fontSize: '16px',
      lineHeight: '19px',
    },
  },
  buttonContainer: {
    '@media screen and (max-width: 960px)': {
      justifyContent: 'center',
      padding: '10px',
    },
  },
};
