import React, { useState, useCallback, useEffect } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// components
import PlusButton from '../PlusButton';
import NumberInput from 'now-frontend-shared/components/inputs/NumberInput';
import SelectForIdValue from 'now-frontend-shared/components/Select/SelectForIdValue';
import DeleteConfirmationModal from 'now-frontend-shared/components/modals/DeleteConfirmationModal';
import AmountInput from 'now-frontend-shared/components/inputs/AmountInput';
import BaseInput from 'now-frontend-shared/components/inputs/BaseInput';
import LabelLayout from 'now-frontend-shared/components/inputs/layouts/LabelLayout';
import CheckBox from 'now-frontend-shared/components/Checkbox';

// custom hooks
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';

// constants
import { defaultLandingZoneValue } from '../../constants';

// helper functions
import { checkWellsFields, decimalCount } from 'now-frontend-shared/utils/helpers';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

const LandingZone = ({
  classes, fields, landingZones, formValues,
}) => {
  const [zoneFieldsError, setZoneFieldsError] = useState(false);
  const { setModal } = useModalSetter();

  useEffect(() => {
    if (fields.length === 0) {
      fields.push(defaultLandingZoneValue);
    }
  }, [fields]);

  useEffect(() => {
    if (formValues?.wells) {
      let allZoneFieldsNotEmpty = true;

      formValues.wells.forEach(zone => {
        const allFieldsFilled = checkWellsFields(zone);
        if (!allFieldsFilled) {
          allZoneFieldsNotEmpty = false;
        }
      });

      if (allZoneFieldsNotEmpty && zoneFieldsError) {
        setZoneFieldsError(false);
      }
    }
    // eslint-disable-next-line
  }, [formValues?.wells, zoneFieldsError]);

  const eightsCalculations = useCallback(index => {
    const well = formValues.wells?.[index];
    if (!well) {
      return '';
    }

    const workingInterestPercentage = parseFloat(well?.workingInterestPercentage);
    const netRevenueInterestNumber = parseFloat(well?.netRevenueInterestNumber);

    if (!workingInterestPercentage || !netRevenueInterestNumber) {
      return '';
    }

    const decimals = Math.min(Math.max(
      decimalCount(workingInterestPercentage),
      decimalCount(netRevenueInterestNumber),
    ), 10);

    return ((netRevenueInterestNumber / workingInterestPercentage) * 100).toFixed(decimals || 1);
  }, [formValues.wells]);

  const handleOneMoreWell = useCallback(() => {
    let allZoneFieldsNotEmpty = true;

    const properties = fields.getAll();

    properties.forEach(zone => {
      const allFieldsFilled = checkWellsFields(zone);
      if (!allFieldsFilled) {
        allZoneFieldsNotEmpty = false;
      }
    });

    if (allZoneFieldsNotEmpty) {
      setZoneFieldsError(false);
      fields.push(defaultLandingZoneValue);
    } else {
      setZoneFieldsError(true);
    }
  }, [fields]);

  const handleRemoveWell = useCallback(index => fields.remove(index), [fields]);

  const openConfirmationModal = index => {
    setModal(
      <DeleteConfirmationModal
        heading="You want to delete Landing Zone?"
        handleRemoveItem={handleRemoveWell}
        index={index}
      />,
    );
  };

  return (
    <Grid container>
      {fields.map((zone, index) => (
        <Grid container key={index}>
          <Grid container className={classes.container}>
            <Grid container item xs={12} sm={10} md={4} lg={4} xl={4} justify="space-between">
              {fields.length > 1 && (
                <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                  <button
                    type="button"
                    onClick={() => openConfirmationModal(index)}
                    className={classes.removeZoneButton}
                  >
                    &#10005;
                  </button>
                </Grid>
              )}

              <Grid
                item
                xs={10}
                sm={11}
                md={10}
                lg={fields.length > 1 ? 10 : 12}
                xl={fields.length > 1 ? 10 : 12}
                direction="column"
              >
                <label htmlFor={index} className={classes.label}>
                  Landing zone *
                </label>

                <label htmlFor={index} className={classes.description}>
                  {`(${index + 1})`}
                </label>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
              <Field
                name={`${zone}.landingZone`}
                component={SelectForIdValue}
                props={{ options: landingZones || [], disabled: !landingZones }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={10}
              md={4}
              direction="column"
            >
              <label htmlFor={index} className={classes.label}>
                Well Name *
              </label>
            </Grid>

            <Grid item xs={12} sm={10} md={8}>
              <Field
                name={`${zone}.wellName`}
                component={BaseInput}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={10}
              md={4}
              direction="column"
            >
              <label htmlFor={index} className={classes.label}>
                Well Gross AFE *
              </label>
            </Grid>

            <Grid item xs={12} sm={10} md={8}>
              <Field
                name={`${zone}.wellGrossAfe`}
                component={AmountInput}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={10}
              md={4}
              direction="column"
            >
              <label htmlFor={index} className={classes.label}>
                Well Net AFE *
              </label>
            </Grid>

            <Grid item xs={12} sm={10} md={8}>
              <Field
                name={`${zone}.wellNetAFE`}
                component={AmountInput}
                props={{ maskType: 'money' }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={10}
              md={4}
              direction="column"
            >
              <label htmlFor={index} className={classes.label}>
                Well Total Vertical Depth *
              </label>
            </Grid>

            <Grid item xs={12} sm={10} md={8}>
              <Field
                name={`${zone}.wellTotalVerticalDepth`}
                component={AmountInput}
                props={{ maskType: 'feet' }}
              />
            </Grid>
          </Grid>

          <Grid container className={classes.zonesWrapper}>
            <Grid container direction="column">
              <Grid container direction="row" justify="space-between" className={classes.container}>

                <Grid item xs={12} sm={10} md={4} lg={4}>
                  <span className={classes.coordinatesHeading}>Surface hole location *</span>
                </Grid>

                <Grid container item xs={12} sm={10} md={4} lg={4} className={classes.bottomCoordinates}>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={5}
                    lg={5}
                    xl={5}
                    direction="column"
                    justify="flex-start"
                    className={classes.labelContainer}
                  >
                    <label htmlFor={index} className={classes.label}>
                      Latitude
                    </label>
                  </Grid>

                  <Grid item xs={12} sm={8} md={7} lg={7} xl={7}>
                    <Field
                      name={`${zone}.surfaceLatitude`}
                      component={NumberInput}
                      props={{ placeholder: '67.531422' }}
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12} sm={10} md={4} lg={4} className={classes.surfaceCoordinates}>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={5}
                    lg={5}
                    xl={5}
                    direction="column"
                    justify="flex-start"
                    className={classes.labelContainer}
                  >
                    <label htmlFor={index} className={classes.label}>
                      Longitude
                    </label>
                  </Grid>

                  <Grid item xs={12} sm={8} md={7} lg={7} xl={7}>
                    <Field
                      name={`${zone}.surfaceLongitude`}
                      component={AmountInput}
                      props={{ placeholder: '-23.324231', maskType: 'negative' }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container direction="row" justify="space-between" className={classes.container}>
                <Grid item xs={12} sm={10} md={4} lg={4}>
                  <span className={classes.coordinatesHeading}>Bottom hole location *</span>
                </Grid>

                <Grid container item xs={12} sm={10} md={4} lg={4} className={classes.bottomCoordinates}>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={5}
                    lg={5}
                    xl={5}
                    direction="column"
                    justify="flex-start"
                    className={classes.labelContainer}
                  >
                    <label htmlFor={index} className={classes.label}>
                      Latitude
                    </label>
                  </Grid>

                  <Grid item xs={12} sm={8} md={7} lg={7} xl={7}>
                    <Field
                      name={`${zone}.bottomLatitude`}
                      component={NumberInput}
                      props={{ placeholder: '67.531422' }}
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12} sm={10} md={4} lg={4} className={classes.surfaceCoordinates}>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={5}
                    lg={5}
                    xl={5}
                    direction="column"
                    justify="flex-start"
                    className={classes.labelContainer}
                  >
                    <label htmlFor={index} className={classes.label}>
                      Longitude
                    </label>
                  </Grid>

                  <Grid item xs={12} sm={8} md={7} lg={7} xl={7}>
                    <Field
                      name={`${zone}.bottomLongitude`}
                      component={AmountInput}
                      props={{ placeholder: '-23.324231', maskType: 'negative' }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid>
                <LabelLayout label="Working interest" space="small" multiple isRequired>
                  <Field name={`${zone}.workingInterestPercentage`} component={AmountInput} props={{ maskType: 'percent' }} />
                </LabelLayout>

                <LabelLayout label="Net revenue interest" space="small" multiple isRequired>
                  <Field name={`${zone}.netRevenueInterestNumber`} component={AmountInput} props={{ maskType: 'percent' }} />
                </LabelLayout>

                <LabelLayout label="8/8ths Calculation" space="small">
                  <AmountInput
                    disabled
                    input={{
                      value: eightsCalculations(index),
                      disabled: true,
                    }}
                    maskType="percent"
                  />
                </LabelLayout>
              </Grid>

              <Grid container alignItems="flex-start" className={classes.wrapper}>
                <Grid item xs={false} sm={false} md={false} lg={4} xl={4} />
                <Grid item xs={12} sm={10} md={10} lg={8} xl={8}>
                  <CheckBox
                    name={`${zone}.depthConfirmedWithOperator`}
                    label="Landing Zone and Total Vertical Depth confirmed with operator?"
                  />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      ))}

      {fields.length < 20 && (
        <Grid container>
          <Grid item xs={false} sm={false} md={4} lg={4} xl={4} />

          <Grid item xs={12} sm={12} md={8} lg={8} xl={8} className={classes.buttonContainer}>
            <PlusButton error={zoneFieldsError} onClick={handleOneMoreWell} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

LandingZone.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  fields: PropTypes.object,
  formValues: PropTypes.object.isRequired,
  landingZones: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, title: PropTypes.string })),
};

export default compose(
  withStyles(styles),
)(LandingZone);
