import React, { useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import styles from './styles';
import { updateUser, updateUserInfo } from 'store/actions/userActions';
import { StyledTextField } from 'components/inputs/styledTextField';
import PhoneInput from 'now-frontend-shared/components/inputs/PhoneInput';
import {
  formValidationHasErrors,
} from 'now-shared/validation/validation-rules';
import {
  personalDetailsFieldValidators,
  validatePersonalDetails,
} from 'now-shared/validation/personal-details';
import { hasPersonalInfoSaved } from 'layouts/RegistrationRoute';
import { navigateToNextPage, navigateToPreviousPage } from 'constants/registrationFlow';
import { isUserRegistrationComplete } from 'now-shared/helpers/auth-helpers';
import { isAdminOrCompliancePerson } from 'now-shared/validation/admin-upsert-user';
import { getRedirectPathAfterSignIn } from 'store/sagas/mainPageSagas';

function Registration({
  authUser,
  classes,
  user,
}) {
  const [touched, setTouched] = React.useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const authUserRef = useRef();
  authUserRef.current = authUser;

  const errors = validatePersonalDetails(user);
  const arePersonalFieldsValid = !formValidationHasErrors(errors);

  useEffect(() => {
    if (isUserRegistrationComplete(authUserRef.current) || isAdminOrCompliancePerson(authUserRef.current)) {
      const path = getRedirectPathAfterSignIn(authUser);
      history.replace(path);
    }
  }, [dispatch, history]);

  useEffect(() => {
    if (hasPersonalInfoSaved(authUser, 'some')) {
      [...Object.keys(personalDetailsFieldValidators), 'companyId'].forEach(field => {
        dispatch(updateUser({ key: field, value: authUser[field] }));
      });
    }
  }, [dispatch, authUser]);

  const setTouchedDelay = 0;
  const setTouchedDelayed = (fieldName, newValue) => {
    setTimeout(() => setTouched(prev => ({
      ...prev,
      [fieldName]: newValue,
    })), setTouchedDelay);
  };

  const personalFields = [
    { label: 'First Name', value: 'firstName' },
    { label: 'Last Name', value: 'lastName' },
    { label: 'Department', value: 'department' },
    { label: 'Job Title', value: 'jobTitle' },
  ];
  const createFields = () => (
    <>
      {personalFields.map(({ label, value }, i) => {
        const error = touched[value] && errors[value];
        return (
          <StyledTextField
            value={user[value] || ''}
            key={value}
            variant="outlined"
            onChange={e => {
              dispatch(updateUser({ value: e.target.value || null, key: value }));
            }}
            onFocus={() => setTouchedDelayed(value, false)}
            onBlur={() => setTouchedDelayed(value, true)}
            label={label}
            error={!!error}
            helperText={error || '\u00a0'}
            placeholder={label}
            fullWidth
            margin="normal"
            autoFocus={i === 0}
          />
        );
      })}
    </>
  );

  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ margin: 'auto', maxWidth: '629px' }}
      >
        <Typography variant="h1" component="div" className={classes.title}>
          Personal Detail
        </Typography>
        <Typography variant="body1" component="div" className={classes.text}>
          Enter your details to proceed further
        </Typography>
        {createFields()}
        <PhoneInput
          classes={{ maskStyles: classes.phoneInput }}
          input={{
            value: user.phoneNumber || '',
            name: 'phoneNumber',
            alwaysShowMask: false,
            onChange: e => {
              dispatch(
                updateUser({ value: e.target.value || null, key: 'phoneNumber' }),
              );
            },
            onFocus: () => setTouchedDelayed('phoneNumber', false),
            onBlur: () => setTouchedDelayed('phoneNumber', true),
          }}
          renderInput={() => {
            const error = touched.phoneNumber && errors.phoneNumber;
            return (
              <StyledTextField
                label="Phone"
                variant="outlined"
                margin="normal"
                fullWidth
                error={!!error}
                helperText={error || '\u00a0'}
              />
            );
          }}
        />
        <div className={classes.buttonContainer}>
          <Button
            onClick={() => history.push(navigateToPreviousPage(history.location.pathname))}
            className={classes.backButton}
            variant="text"
          >
            BACK
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            disabled={!arePersonalFieldsValid}
            onClick={async () => {
              let nothingChanged = false;
              if (hasPersonalInfoSaved(authUser, 'some')) {
                nothingChanged = Object.entries(user).every(([field, value]) => {
                  let isSame;
                  let originalValue = authUser[field];
                  if (
                    originalValue === undefined
                  ) {
                    originalValue = false;
                  }
                  if (value !== originalValue) {
                    isSame = false;
                  } else {
                    isSame = true;
                  }
                  return isSame;
                });
              }
              if (!nothingChanged) {
                await new Promise((resolve, reject) => dispatch(updateUserInfo({ resolve, reject })));
              }
              history.push(navigateToNextPage(history.location.pathname));
            }}
          >
            NEXT
          </Button>
        </div>
      </Grid>
    </div>
  );
}

Registration.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  companies: PropTypes.objectOf(PropTypes.any).isRequired,
  authUser: PropTypes.objectOf(PropTypes.any),
};

Registration.defaultProps = {
  authUser: undefined,
};

export default compose(
  connect(({
    auth, user, companies,
  }) => ({
    user,
    companies,
    authUser: auth.user,
  })),
  withStyles(styles),
)(Registration);
