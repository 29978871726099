export default {
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '32px',
    width: '32px',
    margin: '0 24px',
    cursor: 'pointer',
    backgroundColor: '#F0F0F0',
    borderRadius: '50%',

    '& svg': {
      width: '10px',
      height: '16px',
    },

    '@media screen and (max-width: 600px)': {
      margin: '0 10px',
    },
  },
  reverse: {
    transform: 'rotate(180deg)',
  },
  disabled: {
    transition: 'filter 0.25s ease-in-out',
    filter: 'opacity(0.2)',
  },
};
