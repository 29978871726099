// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Select } from '@material-ui/core';

const SelectWrapper = withStyles({
  icon: {
    top: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
    transition: 'filter 0.25s ease-in-out',

    '&$disabled': {
      filter: 'opacity(0.1)',
    },
  },
  disabled: {},
})(Select);

export default SelectWrapper;
