import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Button as MUIButton } from '@material-ui/core';

// styles
import styles from './styles';

const Button = ({
  classes, label, buttonColor, fullWidth, ...rest
}) => (
  <MUIButton
    variant="contained"
    style={{ width: fullWidth && '100%' }}
    {...rest}
    className={cx(classes.button, classes[buttonColor])}
  >
    {label}
  </MUIButton>
);

Button.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  buttonColor: PropTypes.oneOf(['red', 'green', 'white', 'blue', 'redFilled', 'clearGreen', 'inherit', 'primaryMain', 'secondaryMain']),
  disabled: PropTypes.bool,
  type: PropTypes.string,
};

export default compose(withStyles(styles), memo)(Button);
