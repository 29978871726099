export default {
  countdown: {
    marginTop: '8px',
    color: '#EB5757',
    fontSize: '20px',
    lineHeight: '23px',
    fontWeight: 'bold',

    '@media screen and (max-width: 960px)': {
      fontSize: '18px',
      lineHeight: '24px',
    },

    '@media screen and (max-width: 600px)': {
      fontSize: '16px',
      lineHeight: '22px',
    },
  },
  countdownWithoutAdaptive: {
    marginTop: '8px',
    color: '#EB5757',
    fontSize: '20px',
    lineHeight: '23px',
    fontWeight: 'bold',
  },
};
