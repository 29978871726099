import { red } from '../../../../themes/colors';

export default {
  label: {
    padding: '0 5px 5px 0',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 'bold',
    color: '#333333',
    userSelect: 'none',
  },
  description: {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 'normal',
    color: '#333333',

    '@media screen and (max-width: 960px)': {
      paddingBottom: '10px',
    },
  },
  container: {
    margin: '10px 0 0',

    '@media screen and (max-width: 960px)': {
      justifyContent: 'center',
    },
  },
  wrapper: {
    margin: '12px 0',

    '@media screen and (max-width: 960px)': {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  zonesWrapper: {
    marginBottom: '20px',

    '@media screen and (max-width: 960px)': {
      marginBottom: 'unset',
    },
  },
  labelContainer: {
    padding: '10px 0',
  },
  coordinatesHeading: {
    padding: '10px 0',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 'bold',
    color: '#333333',
  },
  surfaceCoordinates: {
    paddingLeft: '6px',

    '@media screen and (max-width: 960px)': {
      paddingLeft: 'unset',
      margin: '12px 0',
    },
  },
  bottomCoordinates: {
    paddingRight: '6px',

    '@media screen and (max-width: 960px)': {
      paddingRight: 'unset',
    },
  },
  removeZoneButton: {
    height: '24px',
    width: '24px',
    marginRight: '16px',
    fontSize: '17px',
    color: red,
    border: `1px solid ${red}`,
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',

    '&:hover': {
      backgroundColor: red,
      color: '#FFFFFF',
    },
  },
  buttonContainer: {
    '@media screen and (max-width: 960px)': {
      alignItems: 'center',
    },
  },
};
