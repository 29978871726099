import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

const SignInLabelLayout = ({
  classes, children, label, name,
}) => (
  <Grid container className={classes.wrapper}>
    <Grid container justify="center" className={classes.labelContainer}>
      <label htmlFor={name}>{label}</label>
    </Grid>

    {children}
  </Grid>
);

SignInLabelLayout.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
};

export default compose(withStyles(styles), memo)(SignInLabelLayout);
