import React, { memo } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// components
import ForgotPasswordForm from './components/ForgotPasswordForm';
import CloseModalButton from 'now-frontend-shared/components/CloseModalButton';

// custom hooks
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';

// validations
import { forgotPasswordValidate } from 'utils/validation/validations';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from 'now-frontend-shared/components/modals/styles';

// store
import { sendResetPasswordLink } from 'store/actions/authActions';

const ForgotPasswordModal = ({ classes, handleSubmit }) => {
  const { setModal } = useModalSetter();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setModal(null);
  };

  const onHandleSubmit = data => {
    dispatch(sendResetPasswordLink(data));
  };

  return (
    <Grid container direction="column" className={classes.wrapper} tabIndex={-1}>
      <Grid container direction="row" alignItems="center" className={classes.container}>
        <Grid container justify="center">
          <h1 className={classes.formHeading}>Forgot your password?</h1>
        </Grid>

        <Grid
          component="form"
          container
          alignItems="center"
          className={classes.form}
          onSubmit={handleSubmit(onHandleSubmit)}
        >
          <Field
            name="email"
            component={ForgotPasswordForm}
            type="email"
            props={{
              placeholder: 'Enter your E-mail',
              label: 'Send email with instructions to',
            }}
          />
        </Grid>
      </Grid>

      <CloseModalButton handleCloseModal={handleCloseModal} />
    </Grid>
  );
};

ForgotPasswordModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func,
};

export default compose(
  reduxForm({
    form: 'forgot',
    validate: forgotPasswordValidate,
  }),
  // don't want/need warnAboutUnsavedForm for this form
  withStyles(styles),
  memo,
)(ForgotPasswordModal);
