/**
 * @typedef {Object} GetUserFullNameUser
 * @property {string|null=} firstName
 * @property {string|null=} lastName
 */

/**
 * @param {GetUserFullNameUser|null|undefined} user
 * @returns {string} empty if no name exists
 */
export function getUserFullName(user) {
  let userFullName;
  if (user?.firstName && user?.lastName) {
    userFullName = `${user.firstName} ${user.lastName}`;
  } else if (user?.firstName) {
    userFullName = user.firstName;
  } else if (user?.lastName) {
    userFullName = user.lastName;
  }
  return userFullName || '';
}
