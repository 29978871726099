import React, { memo, useEffect } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';
import { Link } from 'react-router-dom';

const CookieBanner = ({ classes }) => {
  const [acceptedCookie, setAcceptedCookie] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);
  const acceptedCookiesString = 'COOKIES_ACCEPTED';
  useEffect(() => {
    const cookieConsent = localStorage.getItem(acceptedCookiesString);
    if (cookieConsent) {
      setAcceptedCookie(true);
    }
  }, []);

  const updateAcceptCookie = () => {
    setClicked(true);
    localStorage.setItem(acceptedCookiesString, true);
  };

  return acceptedCookie ? null : (
    <div data-cy="cookie-banner" className={`${classes.container} ${clicked ? classes.fadeAway : ''}`}>
      <p>
        Non-Op Wells uses cookies to personalize our services and provide customized information to enhance user experience.
        By continuing to use the website, we understand that you accept their use. To learn more, click
        {' '}
        <Link to="/privacy-policy" target="_blank" className={classes.link}>here</Link>
        .
      </p>
      <button className={classes.close} onClick={updateAcceptCookie}>I Agree</button>
    </div>
  );
};

CookieBanner.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), memo)(CookieBanner);
