export default {
  container: {
    position: 'relative',
    padding: '0 45px 0 0',

    '@media screen and (max-width: 960px)': {
      padding: 'unset',
    },
  },
  buttonContainer: {
    padding: '40px 0',

    '@media screen and (max-width: 960px)': {
      justifyContent: 'center',
    },

    '@media screen and (max-width: 600px)': {
      padding: '20px 0',
    },
  },
  wrapper: {
    margin: '12px 0',

    '@media screen and (max-width: 960px)': {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  labelContainer: {
    '@media screen and (max-width: 1280px)': {
      paddingBottom: '10px',
    },

    '@media screen and (max-width: 960px)': {
      paddingBottom: '14px',
    },

    '@media screen and (max-width: 600px)': {
      paddingBottom: '8px',
    },
  },
  label: {
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 'bold',
    color: '#333333',
    userSelect: 'none',
    padding: '10px 5px 10px 0',

    '@media screen and (max-width: 960px)': {
      padding: 'unset',
    },
  },
  description: {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 'normal',
    color: '#333333',

    '@media screen and (max-width: 1280px)': {
      marginBottom: '4px',
    },
  },
  map: {
    position: 'sticky',
    top: 0,
    bottom: 0,
    right: 0,
    height: '100%',
    maxHeight: '580px',
    minHeight: '350px',
    width: '100%',
    margin: '10px 10px 18px',
    padding: '4px 0',

    '@media screen and (max-width: 1280px)': {
      margin: 'unset',
    },
  },
  checkbox: {
    '@media screen and (max-width: 1280px)': {
      padding: '0 6px',
    },
  },
};
