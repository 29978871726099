import React, { memo } from 'react';
import ReactInputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styled error field

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import ErrorField from '../ErrorField';

// styles
import styles from '../styles';

const PhoneInput = ({
  classes,
  input,
  renderInput,
  meta,
  placeholder,
  disabled,
}) => {
  const isError = meta.touched && meta.error;

  return (
    <>
      <ReactInputMask
        className={classes.maskStyles}
        style={disabled ? { opacity: '0.42' } : {}}
        mask="+1 (999) 999-9999"
        {...placeholder && {
          placeholder,
        }}
        alwaysShowMask={!!input.value}
        id={input.name}
        maskChar="X"
        type="input"
        disabled={disabled}
        {...input}
      >
        {renderInput}
      </ReactInputMask>

      {!renderInput && (
        <ErrorField error={isError ? meta.error : null} />
      )}
    </>
  );
};

PhoneInput.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  renderInput: PropTypes.func,
  disabled: PropTypes.bool,
};

PhoneInput.defaultProps = {
  meta: {},
  placeholder: undefined,
  renderInput: undefined,
  disabled: false,
};

export default compose(withStyles(styles), memo)(PhoneInput);
