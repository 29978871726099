export default {
  badge: {
    minWidth: '150px',
    padding: '6px 28px',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    fontWeight: 'bold',
    letterSpacing: '0.01em',
    color: '#fff',
    background: '#27AE60',
    border: '1px solid',
    borderRadius: '20px',
  },
  small: {
    minWidth: '90px',
    padding: '3px 11px',
    fontSize: '10px',
    lineHeight: '12px',
    fontWeight: 'normal',
  },
};
