import React, { memo } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// layouts
import SignInLabelLayout from 'now-frontend-shared/components/inputs/layouts/SignInLabelLayout';

// components
import ForgotPasswordModal from 'components/Modals/ForgotPasswordModal';
import PasswordInput from 'now-frontend-shared/components/inputs/PasswordInput';
import CloseModalButton from 'now-frontend-shared/components/CloseModalButton';
import BaseInput from 'now-frontend-shared/components/inputs/BaseInput';

// custom hooks
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';

// validations
import { signInValidate } from 'utils/validation/validations';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';

// styles
import styles from './styles';

// store
import { signIn } from 'store/actions/authActions';

const SignInModal = ({
  classes,
  handleSubmit,
  location,
  statusMessage,
  email,
}) => {
  const { setModal } = useModalSetter();
  const dispatch = useDispatch();

  const onHandleSubmit = data => {
    dispatch(signIn(data));
    setModal(null);
  };

  const handleForgotModalOpen = () => {
    setModal(<ForgotPasswordModal />);
  };

  const handleCloseModal = () => {
    setModal(null);
  };

  return (
    <Grid container direction="column" className={classes.signInWrapper} tabIndex={-1}>
      <Grid container direction="column" alignItems="center" className={classes.container}>
        <Grid container justify="center">
          <h1 className={classes.heading}>Login</h1>
          {statusMessage && <strong>{statusMessage}</strong>}
        </Grid>

        <Grid
          component="form"
          container
          direction="column"
          className={classes.form}
          onSubmit={handleSubmit(onHandleSubmit)}
        >
          <SignInLabelLayout label="Email">
            <Field
              name="email"
              component={BaseInput}
              type="email"
              props={{
                placeholder: 'billfergusson@sellcompany.com',
                autoFocus: !email,
              }}
            />
          </SignInLabelLayout>

          <SignInLabelLayout label="Password">
            <Field
              name="password"
              component={PasswordInput}
              props={{
                placeholder: '••••••••',
                autoFocus: !!email,
              }}
            />
          </SignInLabelLayout>

          <Grid container justify="center">
            <Button type="submit" variant="contained" color="primary" className={classes.loginButton}>
              Login
            </Button>
          </Grid>
        </Grid>

        <span className={classes.forgotButton} onClick={handleForgotModalOpen}>
          I forgot my password
        </span>
      </Grid>

      {location.pathname !== '/sign-up' && (
        <Grid container direction="column" alignItems="center" className={classes.registerContainer}>
          <span>Don't have an account?</span>

          <Link to="sign-up" className={classes.registerLink} onClick={handleCloseModal}>
            Register now
          </Link>
        </Grid>
      )}

      <CloseModalButton handleCloseModal={handleCloseModal} />
    </Grid>
  );
};

SignInModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func,
  statusMessage: PropTypes.string,
  email: PropTypes.string,
};

SignInModal.defaultProps = {
  statusMessage: undefined,
  handleSubmit: undefined,
  email: undefined,
};

export default compose(
  connect((_state, { email }) => ({
    initialValues: {
      email,
    },
  }), null, null, { forwardRef: true }),
  reduxForm({
    form: 'signIn',
    validate: signInValidate,
    enableReinitialize: true,
  }),
  // don't want/need warnAboutUnsavedForm for this form
  withStyles(styles),
  withRouter,
  memo,
)(SignInModal);
