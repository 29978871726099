export default {
  termsCheckbox: {
    padding: '2px 16px 0 0',
  },
  button: {
    margin: '0 40px 40px 0',

    '@media screen and (max-width: 600px)': {
      margin: 'unset',
    },
  },
  buttonContainer: {
    '@media screen and (max-width: 600px)': {
      justifyContent: 'space-between',
      marginTop: '15px',
    },
  },
};
