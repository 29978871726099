import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, {
  memo,
} from 'react';

// components
import SellerActionNeeded from './components/SellerActionNeeded';
import {
  firstMainRowMarginSides,
} from 'pages/Dashboard';
import ListingsSold from './components/ListingsSold';

// styles and components from material-ui
import Box from '@material-ui/core/Box';
import AuctionsActive from './components/AuctionsActive';
import AuctionsEnded from './components/AuctionsEnded';

const sellerDashboardSectionsGap = 23;

const SellerDashboard = ({
  sellerAgreement,
  companyIsApprovedAndActive,
}) => {
  const isApplicable = sellerAgreement?.status.title === 'approved' && companyIsApprovedAndActive;
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        width: '100%',
        marginTop: 0,
        marginBottom: firstMainRowMarginSides,
        marginLeft: firstMainRowMarginSides,
        marginRight: firstMainRowMarginSides,
        gap: sellerDashboardSectionsGap,
      }}
    >
      <SellerActionNeeded
        isApplicable={isApplicable}
      />
      <AuctionsActive
        isApplicable={isApplicable}
      />
      <AuctionsEnded
        isApplicable={isApplicable}
      />
      <ListingsSold
        isApplicable={isApplicable}
      />
    </Box>
  );
};

SellerDashboard.propTypes = {
  sellerAgreement: PropTypes.shape({
    status: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }),
  companyIsApprovedAndActive: PropTypes.bool.isRequired,
};

SellerDashboard.defaultProps = {
  sellerAgreement: undefined,
};

export default compose(
  connect(({ auth }) => ({
    sellerAgreement: auth.user.company?.sellerAgreement,
    companyIsApprovedAndActive: !!auth.user.company?.approved && !!auth.user.company?.active,
  })),
  memo,
)(SellerDashboard);
