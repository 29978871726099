import { textSecondary, primaryMain, secondaryMain } from '../../../../themes/colors';

export default {
  wrapper: {
    margin: '12px 0',
  },
  fieldStyles: {
    width: '100%',
    '& input': {
      padding: '12px 16px',
      lineHeight: '16px',
      fontWeight: '500',
      fontSize: '14px',
    },
  },
  labelContainer: {
    padding: '10px 0',

    lineHeight: '19px',
    fontWeight: 'bold',
    fontSize: '16px',
    color: textSecondary,
  },
  iconButton: {
    padding: 0,
  },
  sendButton: {
    height: '40px',
    borderRadius: '4px',
    background: primaryMain,
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'normal',
    textTransform: 'none',
    boxShadow: 'unset',

    '@media screen and (max-width: 600px)': {
      marginTop: '20px',
    },

    '&:hover': {
      backgroundColor: secondaryMain,
      color: '#fff',
    },
  },
};
