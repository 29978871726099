/**
 * @typedef {Object} GetCompanyNameCompany
 * @property {string} fullLegalCompanyName
 * @property {string|null=} operatingName
 */

/**
 * @param {GetCompanyNameCompany|null|undefined} company
 * @returns {string}
 */
export function getCompanyName(company) {
  return company?.operatingName
    || company?.fullLegalCompanyName
    || '';
}

export const sellerAgreementName = "Seller's Agreement";
export const buyerAgreementName = "Buyer's Agreement";

export function getAgreementName({
  baseName,
  /**
   * Version of the document
   */
  version,
  /**
   * If the document is customized by the customer
   */
  isCustom = false,
  /**
   * If the document contains all values pre-filled for the customer
   */
  isPreFilled = false,
  /**
   * Include the document extension in the name
   */
  withExtension = false,
}) {
  let name = isCustom ? 'Custom' : 'Default';
  if (isPreFilled) {
    name += ' Pre-filled';
  }
  name += ` ${baseName}`;
  if (version) {
    name += ` (${version})`;
  }
  if (withExtension) {
    name += '.pdf';
  }
  return name;
}
