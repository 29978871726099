import React, { memo, forwardRef } from 'react';
import { compose } from 'redux';

// icons
import { ReactComponent as SuccessIcon } from 'now-frontend-shared/assets/icons/success.svg';

// components
import CloseModalButton from 'now-frontend-shared/components/CloseModalButton';

// custom hooks
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

const SuccessModal = ({ classes, label }, ref) => {
  const { setModal } = useModalSetter();

  const handleCloseModal = () => {
    setModal(null);
  };

  return (
    <Grid container direction="column" tabIndex={-1} className={classes.wrapper} ref={ref}>
      <Grid container direction="column" alignItems="center" className={classes.container}>
        <Grid container justify="center" alignItems="center">
          <SuccessIcon />
        </Grid>

        <Grid container justify="center" className={classes.text}>
          <span>{label}</span>
        </Grid>

        <CloseModalButton handleCloseModal={handleCloseModal} />
      </Grid>
    </Grid>
  );
};

export default compose(withStyles(styles), memo, forwardRef)(SuccessModal);
