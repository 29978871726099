import { asyncAction, action } from 'now-frontend-shared/utils/actions';

// async actions
export const createNewCompany = asyncAction('company/CREATE_NEW_COMPANY');
export const createFinancialInfo = asyncAction('company/CREATE_FINANCIAL_INFO');
export const signSellerAgreement = asyncAction('company/SIGN_SELLER_AGREEMENT');
export const signBuyerAgreement = asyncAction('company/SIGN_BUYER_AGREEMENT');
export const persistCompanyUpdates = asyncAction('company/UPDATE_COMPANY');
export const saveFileUpload = asyncAction('companies/SAVE_FILE_UPLOAD');

// sync action

export const updateCompany = action('company/SET_COMPANY_VALUE');
export const clearState = action('company/CLEAR_STATE');
export const updateOfficer = action('company/UPDATE_OFFICER');
export const addOfficer = action('company/ADD_OFFICER');
export const deleteOfficer = action('company/DELETE_OFFICER');
