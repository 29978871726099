import React, {
  memo, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// components
import HelpTooltip from 'now-frontend-shared/components/HelpTooltip';

// helpers
import { formatNumberToCurrency } from 'now-frontend-shared/utils/helpers';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';

const TotalListingCost = ({
  classes,
  totalCost,
}) => {
  const totalCostFormatted = useMemo(() => (
    totalCost === undefined ? '-' : formatNumberToCurrency(totalCost)
  ), [totalCost]);

  return (
    <>
      <span>
        Total:
        <HelpTooltip
          title="Total"
          iconSize="inherit"
          content={(
            'Total paid to seller plus closing fees. For a carry transaction, $10 is paid to the seller as a "grant consideration"'
          )}
        />
      </span>
      <span
        className={classes.description}
      >
        {totalCostFormatted}
      </span>
    </>
  );
};

TotalListingCost.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  totalCost: PropTypes.number,
};

TotalListingCost.defaultProps = {
  totalCost: undefined,
};

export default compose(
  memo,
  withStyles(styles),
)(TotalListingCost);
