// actions
import * as A from '../actions/myPropertiesActions';

const initialState = {
  statuses: null,
  propertiesList: null,
  pending: false,
  listingsForSellerActionNeeded: null,
  listingsForSellerActionNeededIsLoading: false,
  listingsForAuctionsActive: null,
  listingsForAuctionsActiveIsLoading: false,
  listingsForAuctionsEnded: null,
  listingsForAuctionsEndedIsLoading: false,
  listingsSold: null,
  listingsSoldIsLoading: false,
  states: null,
  basins: null,
  basinId: null,
  counties: null,
};

export const reducer = {
  myProperties(state = initialState, action) {
    switch (action.type) {
      case A.getStates.success:
        return {
          ...state,
          states: action.payload.sort((a, b) => {
            if (a.title.toLowerCase() < b.title.toLowerCase()) {
              return -1;
            }
            if (a.title.toLowerCase() > b.title.toLowerCase()) {
              return 1;
            }
            return 0;
          }),
        };

      case A.getBasins.type:
        return { ...state, stateId: action.payload };

      case A.getBasins.success:
        return { ...state, basins: action.payload };

      case A.getCounties.type:
        return { ...state, basinId: action.payload };

      case A.getCounties.success:
        return { ...state, counties: action.payload };

      case A.getProperties.type:
        return { ...state, pending: true };

      case A.getProperties.success:
        return { ...state, propertiesList: action.payload, pending: false };

      case A.getProperties.failure:
        return { ...state, pending: true };

      case A.getSellerStatuses.success:
        return { ...state, statuses: action.payload };

      case A.getListingsForSellerActionNeeded.type:
        return { ...state, listingsForSellerActionNeededIsLoading: true };

      case A.getListingsForSellerActionNeeded.success:
        return { ...state, listingsForSellerActionNeeded: action.payload, listingsForSellerActionNeededIsLoading: false };

      case A.getListingsForSellerActionNeeded.failure:
        return { ...state, listingsForSellerActionNeededIsLoading: true };

      case A.getListingsSold.type:
        return { ...state, listingsSoldIsLoading: true };

      case A.getListingsSold.success:
        return { ...state, listingsSold: action.payload, listingsSoldIsLoading: false };

      case A.getListingsSold.failure:
        return { ...state, listingsSoldIsLoading: true };

      case A.getListingsForAuctionsActive.type:
        return { ...state, listingsForAuctionsActiveIsLoading: true };

      case A.getListingsForAuctionsActive.success:
        return { ...state, listingsForAuctionsActive: action.payload, listingsForAuctionsActiveIsLoading: false };

      case A.getListingsForAuctionsActive.failure:
        return { ...state, listingsForAuctionsActiveIsLoading: true };

      case A.getListingsForAuctionsEnded.type:
        return { ...state, listingsForAuctionsEndedIsLoading: true };

      case A.getListingsForAuctionsEnded.success:
        return { ...state, listingsForAuctionsEnded: action.payload, listingsForAuctionsEndedIsLoading: false };

      case A.getListingsForAuctionsEnded.failure:
        return { ...state, listingsForAuctionsEndedIsLoading: true };

      case A.clearState.type:
        return initialState;

      default:
        return state;
    }
  },
};
