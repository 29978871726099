import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import { compose } from 'redux';

// icons
import arrowIcon from 'now-frontend-shared/assets/icons/menu_arrow_white.svg';

// styles and components from material-ui
import { withStyles, styled } from '@material-ui/core/styles';
import {
  Grid, Menu, MenuItem, Button,
} from '@material-ui/core';

// styles
import styles from './styles';

// store
import { removeUserData } from 'store/actions/authActions';

import { getUserDisplayName } from 'utils/user-helpers';
import { isAdminOrCompliancePerson } from 'now-shared/validation/admin-upsert-user';
import { getRedirectPathAfterSignIn } from 'store/sagas/mainPageSagas';

const HeaderMenu = ({
  classes,
  hasSellerAgreement,
  hasBuyerAgreement,
  isAdminOrComplianceUser,
  user,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    history.push('/');
    dispatch(removeUserData());
    setAnchorEl(null);
  };

  const userIsApproved = user.approvalStatus?.title === 'approved';
  const companyIsApprovedAndActive = !!user.company?.approved && !!user.company?.active;
  const userDisplayName = getUserDisplayName(user);

  return (
    <Grid item className={classes.menuContainer}>
      <StyledMenuButton
        className={classes.headerButton}
        color="inherit"
        aria-controls="menu"
        aria-haspopup="true"
        toggleRotate={Boolean(anchorEl)}
        endIcon={<img alt="arrow-icon" width={12} height={8} src={arrowIcon} />}
        onClick={handleClick}
      >
        {userDisplayName}
      </StyledMenuButton>

      <StyledMenu id="menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {(() => {
          const items = [];
          if (userIsApproved || isAdminOrComplianceUser) {
            items.push(
              <StyledMenuItem component={Link} to="/dashboard" onClick={handleClose}>
                Dashboard
              </StyledMenuItem>,
            );

            if (
              isAdminOrComplianceUser
              || (
                companyIsApprovedAndActive
                && (
                  hasBuyerAgreement
                  || hasSellerAgreement
                )
              )
            ) {
              items.push(
                <StyledMenuItem component={Link} to="/listings" onClick={handleClose}>
                  Listings
                </StyledMenuItem>,
              );
            }

            if (hasSellerAgreement && companyIsApprovedAndActive) {
              items.push(
                <StyledMenuItem component={Link} to="/my-listings" onClick={handleClose}>
                  My Listings
                </StyledMenuItem>,
              );
            }

            if (hasBuyerAgreement && companyIsApprovedAndActive) {
              items.push(
                <StyledMenuItem component={Link} to="/my-bids" onClick={handleClose}>
                  My Bids
                </StyledMenuItem>,
              );
            }

            if (
              isAdminOrComplianceUser
              || (
                companyIsApprovedAndActive
                && (
                  hasBuyerAgreement
                  || hasSellerAgreement
                )
              )
            ) {
              items.push(
                <StyledMenuItem component={Link} to="/map" onClick={handleClose}>
                  Map
                </StyledMenuItem>,
              );
            }
          } else {
            items.push(
              <StyledMenuItem
                component={Link}
                key="registration"
                to={getRedirectPathAfterSignIn(user)}
                onClick={handleClose}
              >
                Registration
              </StyledMenuItem>,
            );
          }
          items.push(
            <StyledMenuItem onClick={handleLogOut}>Log Out</StyledMenuItem>,
          );
          return items;
        })()}
      </StyledMenu>
    </Grid>
  );
};

const StyledMenu = withStyles({
  list: {
    padding: 0,
  },

  paper: {
    border: 0,
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles({
  root: {
    width: '150px',
    justifyContent: 'center',
    marginBottom: '2px',
    background: '#F2F2F2',
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 'normal',
    color: '#282424',
  },
})(MenuItem);

const StyledMenuButton = styled(({ toggleRotate, children, ...other }) => <Button {...other}>{children}</Button>)({
  '& img': {
    transform: props => props.toggleRotate && 'rotateZ(-180deg)',
    transition: 'transform 0.25s ease-in-out',
  },
});

HeaderMenu.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  hasSellerAgreement: PropTypes.bool,
  hasBuyerAgreement: PropTypes.bool,
  isAdminOrComplianceUser: PropTypes.bool,
  user: PropTypes.shape({
    // TODO: [TYPE] specify missing types
  }),
};

HeaderMenu.defaultProps = {
  user: undefined,
};

export default compose(
  connect(({ auth }) => ({
    hasSellerAgreement: auth.user.company?.hasSellerAgreement,
    hasBuyerAgreement: auth.user.company?.hasBuyerAgreement,
    isAdminOrComplianceUser: isAdminOrCompliancePerson(auth.user),
    user: auth.user,
  })),
  withStyles(styles),
)(HeaderMenu);
