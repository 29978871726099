import { asyncAction, action } from 'now-frontend-shared/utils/actions';

// async actions
export const cancelMyBidAllowanceRequest = asyncAction('myBidAllowance/CANCEL_ALLOWANCE_REQUEST');
export const getMyBidAllowanceDetails = asyncAction('myBidAllowance/GET_ALLOWANCE_DETAILS');
export const getMyBidAllowanceRequest = asyncAction('myBidAllowance/GET_ALLOWANCE_REQUEST');
export const setMyBidAllowanceRequest = asyncAction('myBidAllowance/SET_ALLOWANCE_REQUEST');

// sync actions
export const clearState = action('myBidAllowance/CLEAR_STATE');
