import { makeAxiosRequest } from 'utils/apiMethods';

export default {
  getAllStates: makeAxiosRequest(
    `${process.env.REACT_APP_API_URL}/all-states`,
    {
      method: 'get',
    },
    true,
  ),
};
