import { desktopContentMaxWidthOld } from 'layouts/styles';
import { primaryDark, red, white } from '../../themes/colors';

export default {
  wrapper: {
    flex: 1,
  },
  container: {
    height: '100%',
  },
  wellsContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gridRowGap: '.5em',
    gridColumnGap: '1em',
  },
  topSection: {
    backgroundColor: primaryDark,
    color: '#fff',
    position: 'sticky',
    top: 0,
    zIndex: '3',
  },
  topSectionContent: {
    maxWidth: desktopContentMaxWidthOld,
    padding: '24px 40px 24px 16px',

    '@media screen and (max-width: 960px)': {
      padding: '20px',
    },
  },
  wellsDesktop: {
    '@media screen and (min-width: 601px)': {
      display: 'none',
    },
  },
  wellsMobile: {
    '@media screen and (max-width: 600px)': {
      display: 'none',
    },
  },
  heading: {
    flex: 1,
    fontSize: '32px',
    lineHeight: '37px',
    fontWeight: 'bold',

    '@media screen and (max-width: 960px)': {
      padding: '12px 0',
      fontSize: '28px',
      lineHeight: '32px',
    },
  },
  wellsCount: {
    color: '#b4b4b4',
  },
  id: {
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  idNumber: {
    fontWeight: 'normal',
  },
  icon: {
    width: '32px',
    height: '32px',
    margin: '2px 28px 0 16px',
    cursor: 'pointer',
    userSelect: 'none',

    '@media screen and (max-width: 960px)': {
      margin: 'unset',
    },
  },
  topSectionContainer: {
    padding: '16px 0',

    '@media screen and (max-width: 960px)': {
      padding: 'unset',
    },
  },
  topSectionHeading: {
    fontSize: '20px',
    lineHeight: '23px',
    fontWeight: 'normal',

    '@media screen and (max-width: 1280px)': {
      marginRight: '16px',
    },

    '@media screen and (max-width: 960px)': {
      flexDirection: 'row',
      alignItems: 'center',
      padding: '12px 0',
    },
  },
  topHeading: {
    '@media screen and (max-width: 960px)': {
      marginRight: '8px',
    },
  },
  topSectionDescription: {
    marginTop: '8px',
    fontWeight: 'bold',

    '@media screen and (max-width: 960px)': {
      marginTop: 'unset',
    },
  },
  mapContainer: {
    position: 'relative',
    height: '562px',
  },
  bottomSectionContent: {
    maxWidth: desktopContentMaxWidthOld,
    padding: '42px 40px',

    '@media screen and (max-width: 960px)': {
      padding: '20px',
    },
  },
  bottomContentContainer: {
    padding: '22px 0',

    '@media screen and (max-width: 960px)': {
      padding: '10px 0',
    },
  },
  bottomSectionHeading: {
    padding: '10px 10px 10px 0',
    fontSize: '20px',
    lineHeight: '23px',
    fontWeight: 'bold',
    color: '#615656',
    textAlign: 'center',

    '@media screen and (max-width: 960px)': {
      padding: '12px 0',
    },
  },
  bottomSectionDescription: {
    marginTop: '8px',
    fontWeight: 'normal',
    textAlign: 'center',
  },
  contentHeading: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 'bold',
    color: '#171721',
    display: 'flex',
    alignItems: 'center',
  },
  contentSubHeading: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#171721',
    marginTop: 8,
  },
  checkIcon: {
    color: '#9DC284',
    marginLeft: 5,
  },
  redText: {
    color: red,
  },
  sellerBidStatus: {
    maxWidth: '130px',
    marginTop: '10px',
    padding: '5px 8px',
    border: '0.5px solid #FFFFFF',
    borderRadius: '32px',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 'normal',
    color: '#FFFFFF',
    textAlign: 'center',

    '@media screen and (max-width: 960px)': {
      marginTop: 'unset',
      marginLeft: '8px',
    },
  },
  listingStatus: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'normal',
    color: '#FFFFFF',

    '@media screen and (max-width: 1280px)': {
      marginRight: '16px',
    },

    '@media screen and (max-width: 960px)': {
      display: 'flex',
      alignItems: 'center',
      padding: '12px 0',
    },
  },
  listingStatusHeading: {
    marginRight: '15px',
  },
  separation: {
    marginRight: 'auto',
  },
  arrow: {
    '@media screen and (max-width: 960px)': {
      width: '100%',
    },
  },
  topInfoContainer: {
    '@media screen and (max-width: 960px)': {
      flexDirection: 'column',
      alignItems: 'unset',
    },
  },
  idContainer: {
    '@media screen and (max-width: 960px)': {
      justifyContent: 'unset',
      padding: '12px 0',
    },
  },
  buttonContainer: {
    '@media screen and (max-width: 960px)': {
      justifyContent: 'unset',
    },
  },
  descriptionContainer: {
    '@media screen and (max-width: 960px)': {
      flexDirection: 'column',
      alignItems: 'unset',
    },
  },
  documentsContainer: {
    margin: '0px 0px 14px 0px',
    flexDirection: 'row-reverse',
  },
  stepperHorizontal: {
    width: '100%',
  },
  detailsContainer: {
    backgroundColor: white,
    borderRadius: '4px 4px 4px 4px',
    margin: '0px 0px 14px 0px',
    padding: '14px 14px 14px 14px',
    boxShadow: `0px 2px 4px -1px rgba(0, 0, 0, 0.2), 
    0px 4px 5px rgba(0, 0, 0, 0.14), 
    0px 1px 10px rgba(0, 0, 0, 0.12)`,
    transition: 'all 0.05s linear',
    '&:hover': {
      borderRadius: '4px 4px 4px 4px',
      boxShadow: `0px 4px 4px 4px rgba(0, 0, 0, 0.1), 
      0px 8px 5px 4px rgba(0, 0, 0, 0.07), 
      0px 2px 10px 4px rgba(0, 0, 0, 0.06)`,
      // margin: '10px 0px 10px 0px',
      transition: 'all 0.05s linear',
      transform: 'scale(1.007, 1.007)',
    },
  },
};
