export default {
  container: {
    position: 'fixed',
    bottom: '40px',
    left: '10%',
    right: '10%',
    width: '80%',
    padding: '20px 14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '5px',
    boxShadow: '0 0 2px 1px rgba(0, 0, 0, 0.2)',
    backgroundColor: 'rgba(46, 49, 49, 1)',
    color: '#fff',
    opacity: '0.9',
    fontSize: '16px',
    zIndex: '999',
  },
  close: {
    backgroundColor: '#777',
    border: 'solid 1px',
    color: '#fff',
    borderRadius: '2px',
    cursor: 'pointer',
    padding: '5px 10px',
    fontSize: '16px',
  },
  link: {
    color: '#fff',
  },
  fadeAway: {
    opacity: 0,
    transition: 'all 250ms linear',
  },
  hide: {
    display: 'none',
  },
};
