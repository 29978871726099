const makeActionDefault = payload => ({ payload });

export const action = (type, makeAction = makeActionDefault) => {
  const actionCreator = (...args) => {
    const action = makeAction(...args);
    if (typeof action === 'object') {
      action.type = type;
    }
    return action;
  };
  actionCreator.type = type;
  return actionCreator;
};

export const asyncAction = (base, makeAction = makeActionDefault) => {
  const actionCreator = (...args) => {
    const action = makeAction(...args);
    if (typeof action === 'object') {
      action.type = base;
    }
    return action;
  };

  actionCreator.type = base;
  actionCreator.request = `${base}/REQUEST`;
  actionCreator.success = `${base}/SUCCESS`;
  actionCreator.failure = `${base}/FAILURE`;

  return actionCreator;
};
