// actions
import * as A from '../actions/listingAnalyticsActions';

const initialState = {
  analytics: undefined,
  analyticsIsLoading: false,
};

export const reducer = {
  listingAnalytics(state = initialState, action) {
    switch (action.type) {
      case A.clearListingAnalyticsState.type:
        return initialState;

      case A.getListingAnalytics.type:
        return { ...state, analyticsIsLoading: true };

      case A.getListingAnalytics.failure:
        return { ...state, analyticsIsLoading: false };

      case A.getListingAnalytics.success:
        return {
          ...state,
          analytics: {
            companies: action.payload,
            totalViews: action.payload.map(company => company.views).reduce((previous, current) => previous + current, 0),
            totalDownloads: action.payload.map(company => company.downloads)
              .reduce((previous, current) => previous + current, 0),
            totalBids: action.payload.map(company => (company.hasBid ? 1 : 0))
              .reduce((previous, current) => previous + current, 0),
          },
          analyticsIsLoading: false,
        };

      default:
        return state;
    }
  },
};
