import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';
import axios from 'axios';
import parsePath from 'path-parse';

// icons
import FileIcon from 'now-frontend-shared/assets/icons/file_upload.png';
import closeFileIcon from 'now-frontend-shared/assets/icons/close_file.png';
import editIcon from 'now-frontend-shared/assets/icons/edit.png';
import pauseIcon from 'now-frontend-shared/assets/icons/pause.svg';
import pauseDarkIcon from 'now-frontend-shared/assets/icons/pause-dark.svg';
import playIcon from 'now-frontend-shared/assets/icons/play1.svg';
import playDarkIcon from 'now-frontend-shared/assets/icons/play-dark.svg';

// components
import TitleEditDialog from '../TitleEditDialog';

// styles and components from material-ui
import { withStyles } from '@material-ui/core';
import { Grid, CircularProgress, Box } from '@material-ui/core';

// styles
import styles from './styles';
import { toast } from 'react-toastify';

import {
  getContentDisposition,
  uploadMaxFileSize,
} from 'now-shared/helpers/upload-helpers';

export function isDocumentUploaded(document) {
  return !!document.key && !document.file;
}

const DropZoneFile = props => {
  const {
    classes,
    document,
    startUpload,
    watchAllFilesUploadProgress,
    handleStopUploadFiles,
    handleRemoveFile,
    handleEditTitle,
    finishUpload,
    canRemoveFile,
    isSimpleView,
    hideSaved,
  } = props;
  const [loadingIsComplete, setLoadingIsComplete] = useState(false);
  const [uploading, setUploading] = useState(false);
  // TODO: [UX][CLARIFY] I think this is more accurately named `canceled` and `setCanceled` and the
  // UI should reflect that with text/icons related to this state.
  const [paused, setPaused] = useState(false);
  const [progress, setProgress] = useState(undefined);
  const [request, setRequest] = useState();
  const [openEditDialog, setEditDialogOpened] = useState(false);

  const {
    file,
    md5Base64,
    filename,
    key,
    url,
  } = document;

  // NOTE: the file extension is required, unless rules change as to whether DropZone will allow
  // upload of files without an extension.
  const {
    name: filenameWithoutExtension,
    ext: fileExtension,
  } = useMemo(() => (
    parsePath(filename)
  ), [filename]);

  const fileIsUploaded = isDocumentUploaded(document);
  const documentIsSaved = !!document.id;

  useEffect(() => {
    if (fileIsUploaded) {
      setLoadingIsComplete(true);
      setProgress(100);
      finishUpload({
        key,
      });
      watchAllFilesUploadProgress(100, key);
    }
    // TODO: [CONVENTIONS] get this to work with the correct dependency list (probably by modifying
    // some of the dependent variables to be refs instead of state/prop variables)
    // eslint-disable-next-line
  }, []);

  const uploadFiles = useCallback(async () => {
    const source = axios.CancelToken.source();
    setRequest(source);

    const contentDisposition = getContentDisposition(filename);

    const config = {
      onUploadProgress: ({ loaded, total }) => {
        setUploading(true);
        const percentCompleted = total ? Math.round((loaded * 100) / total) : 0;
        setProgress(percentCompleted);
        watchAllFilesUploadProgress(percentCompleted, key);
      },
      cancelToken: source.token,
      headers: {
        /**
         * TOOD: fix this so it uses the actual file MIME type
         *
         * This header has an impact
         * on how the browser displays the file by default when the user clicks on a
         * link to the uploaded file to download/view it. For example, if the type
         * if application/pdf, the browser may default to show it as a PDF inside the
         * browser window. By using multipart/form-data here, the browser can't do that
         * because it doesn't know the file is a PDF.
         *
         * I remember before we had tried using `file.type` here as the Content-Type, which
         * makes sense. However, it seemed we had issues either uploading or downloading
         * certain types of binary files this way, and using 'multipart/form-data' seemed to fix
         * that issue.
         * We should retest using `file.type` and falling back on `multipart/form-data` for file
         * specific file types that don't work with upload/download.
         */
        'Content-Type': 'multipart/form-data',

        /**
         * Pre signed headers
         *
         * These are required here if they have been signed in the pre signed URL. Otherwise, they are optional.
         *
         * If they are required as signed headers, they are included in the query params of the signed URL as:
         *   X-Amz-SignedHeaders: content-disposition%3Bhost%3Bx-amz-acl%3Bx-amz-object-lock-mode
         *
         * Here are some of the typical headers that may be signed:
         * * host
         * * content-disposition
         * * x-amz-acl
         * * x-amz-object-lock-mode
         * * x-amz-object-lock-retain-until-date
         *
         * The above query params change dependending on which optional fields are supplied to
         * s3.getSignedUrl() request configuration on the server. "host" is always present by default
         * and does not need to be specifically included here because it is generated by axios.
         *
         * The other headers are present or omitted in the signed URL depending on whether they are present
         * or omitted in the options to the call to s3.getSignedUrl() on the server.
         */

        /**
         * TODO: [CLEANUP] remove if not needed. Not sure if it's required or not, since the bucket itself already
         * has object lock mode set to COMPLIANCE, by default any object put into the bucket should have
         * the same policy. So, I don't see why we would need to specify it again here. But, I also don't
         * see why it would hurt to specify it here, either.
         */
        // 'x-amz-object-lock-mode': 'COMPLIANCE',

        /**
         * TODO: [CLEANUP] remove or fix if needed. The bucket already has a policy of retaining the object lock for
         * a specific amount of time from when the object is created, so I don't think we should try to
         * set a retain-until-date here, which 1) likely conflicts with the bucket policy and 2) seems
         * unnecessary because the bucket should already manage this retain-until-date automatically.
         */
        // 'x-amz-object-lock-retain-until-date': '2024-10-01T20:30:00.000Z',

        /**
         * specifies the file name metadata for when this file is downloaded later
         */
        'Content-Disposition': contentDisposition,

        /**
         * This header is needed for upload to a bucket with ObjectLock retention period configured
         * See: https://docs.aws.amazon.com/AmazonS3/latest/API/API_PutObject.html#API_PutObject_RequestSyntax
         * Before I addded this header, uploading was giving 400 Bad Request error. After adding this,
         * it give 403 Forbidden error. I guess that's an improvement?
         */
        'Content-MD5': md5Base64,

        /**
         * This isn't required but is an optional signed header
         */
        // 'X-Amz-Date': '20211223T153052Z',

        /**
         * This isn't required but is an optional signed header
         */
        // 'X-Amz-Content-SHA256': 'UNSIGNED-PAYLOAD',
      },
      /**
       * Allow file sizes greater than default 10MB limit
       */
      maxContentLength: uploadMaxFileSize.bytes,
    };

    setPaused(false);
    setUploading(true);
    try {
      await axios.put(url, file, config);
      setLoadingIsComplete(true);
      setProgress(100);
      watchAllFilesUploadProgress(100, key);
      finishUpload({
        key,
        createdAt: (new Date()).toISOString(),
      });
    } catch (err) {
      setLoadingIsComplete(false);
      setProgress(undefined);
      watchAllFilesUploadProgress(undefined, key);
      toast.error(`Something went wrong while uploading the file: ${filename}`);
      console.error(err);
    } finally {
      setUploading(false);
    }
  }, [
    file,
    md5Base64,
    filename,
    key,
    url,
    finishUpload,
    watchAllFilesUploadProgress,
  ]);

  useEffect(() => {
    if (startUpload && !loadingIsComplete) {
      uploadFiles();
      handleStopUploadFiles();
    }
  }, [startUpload, loadingIsComplete, uploadFiles, handleStopUploadFiles]);

  const handleCancelRequest = () => {
    setUploading(false);
    setPaused(true);
    request.cancel();
  };

  const removeFile = () => {
    handleRemoveFile(key);
  };

  const handleSetNewTitle = ({ newTitle }) => {
    handleEditTitle({
      editedFile: {
        key,
        filename: `${newTitle}${fileExtension}`,
      },
    });
  };

  const handleOpenEditDialog = () => {
    setEditDialogOpened(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpened(false);
  };

  if (documentIsSaved && hideSaved) {
    return null;
  }

  return (
    <Grid
      item
      xs={isSimpleView ? 12 : 5}
      container
      direction="column"
      alignItems="center"
      className={cx(classes.wrapper, isSimpleView && classes.simpleWrapper)}
    >
      {!isSimpleView && (
        <Grid container alignItems="center" className={classes.imageContainer}>
          <img src={FileIcon} alt="file icon" className={classes.fileUpload} />

          {progress !== undefined && !loadingIsComplete && (
            <Grid container justifyContent="center" alignItems="center" className={classes.progressContainer}>
              <Box position="relative" display="inline-flex">
                <Progress variant="determinate" value={progress} />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {paused ? (
                    <img src={playIcon} alt="stop" onClick={uploadFiles} className={classes.icon} />
                  ) : (
                    <img src={pauseIcon} alt="stop" onClick={handleCancelRequest} className={classes.icon} />
                  )}
                </Box>
              </Box>
            </Grid>
          )}

          {canRemoveFile && !uploading && (
            <img
              src={closeFileIcon}
              alt="close button"
              onClick={removeFile}
              className={cx(classes.cornerCloseButton)}
            />
          )}
        </Grid>
      )}

      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={cx(classes.titleContainer, isSimpleView && classes.simpleTitleContainer)}
        spacing={2}
      >
        <Grid container item xs>
          <span
            title={filename}
            className={cx(classes.title, isSimpleView && classes.simpleTitle)}
          >
            {filename}
          </span>
        </Grid>

        {/* TODO: [FEATURE] support editing filename after file is uploaded in DropZone component */}
        {!loadingIsComplete && !uploading && (progress === undefined || paused) && (
          <Grid item className={classes.iconContainer}>
            <img
              src={editIcon}
              alt="edit"
              onClick={handleOpenEditDialog}
              className={classes.inlineIconButton}
            />
          </Grid>
        )}
        {isSimpleView && progress !== undefined && !loadingIsComplete && (
          <Grid item className={classes.iconContainer}>
            {paused ? (
              <img
                src={playDarkIcon}
                alt="upload"
                onClick={uploadFiles}
                className={classes.inlineIconButton}
              />
            ) : (
              <img
                src={pauseDarkIcon}
                alt="stop"
                onClick={handleCancelRequest}
                className={classes.inlineIconButton}
              />
            )}
          </Grid>
        )}
        {isSimpleView && canRemoveFile && !uploading && (
          <Grid item className={classes.iconContainer}>
            <img
              src={closeFileIcon}
              alt="close button"
              onClick={removeFile}
              className={cx(classes.inlineIconButton)}
            />
          </Grid>
        )}
      </Grid>

      <TitleEditDialog
        title={filenameWithoutExtension}
        open={openEditDialog}
        handleClose={handleCloseEditDialog}
        handleSetNewTitle={handleSetNewTitle}
      />
    </Grid>
  );
};

const Progress = withStyles({
  circleStatic: {
    color: '#FFF',
  },
})(CircularProgress);

DropZoneFile.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  document: PropTypes.shape({
    id: PropTypes.number,
    /**
     * We only show files that have an associated S3 key
     */
    key: PropTypes.string.isRequired,
    /**
     * Includes file extension
     */
    filename: PropTypes.string.isRequired,
    file: PropTypes.instanceOf(File),
    md5Base64: PropTypes.string,
    /**
     * Upload URL
     *
     * TODO: [CLARIFY][REFACTOR] rename to `uploadUrl`
     */
    url: PropTypes.string,
  }).isRequired,
  startUpload: PropTypes.bool.isRequired,
  watchAllFilesUploadProgress: PropTypes.func.isRequired,
  handleStopUploadFiles: PropTypes.func.isRequired,
  handleRemoveFile: PropTypes.func.isRequired,
  handleEditTitle: PropTypes.func.isRequired,
  finishUpload: PropTypes.func.isRequired,
  canRemoveFile: PropTypes.bool.isRequired,
  isSimpleView: PropTypes.bool.isRequired,
  hideSaved: PropTypes.bool.isRequired,
};

export default compose(withStyles(styles))(DropZoneFile);
