import { asyncAction, action } from 'now-frontend-shared/utils/actions';

// async actions
export const getStates = asyncAction('myProperties/GET_STATES');
export const getBasins = asyncAction('myProperties/GET_BASINS');
export const getCounties = asyncAction('myProperties/GET_COUNTIES');
export const getListingsSold = asyncAction('myProperties/GET_LISTINGS_SOLD');
export const getProperties = asyncAction('myProperties/GET_PROPERTIES');
export const getListingsForSellerActionNeeded = asyncAction('myProperties/GET_PROPERTIES_FOR_SELLER_ACTIONS_NEEDED');
export const getListingsForAuctionsActive = asyncAction('myProperties/GET_AUCTIONS_ACTIVE');
export const getListingsForAuctionsEnded = asyncAction('myProperties/GET_AUCTIONS_ENDED');
export const getSellerStatuses = asyncAction('myProperties/GET_SELLER_STATUSES');

// sync actions
export const clearState = action('myProperties/CLEAR_STATE');
