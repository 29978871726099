import PhoneInputStyles from 'now-frontend-shared/components/inputs/PhoneInput/styles';
import ssnOrTinInputStyles from 'now-frontend-shared/components/inputs/SsnOrTinInput/styles';

export default {
  root: {
    padding: '86px 0',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '34px',
    textAlign: 'center',
    letterSpacing: '0.25px',
    color: '#171721',
  },
  text: {
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: '#4B6377',
    margin: '11px 0 25px 0',
  },
  link: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: 0.15,
    color: 'rgba(0, 0, 0, 0.6)',
  },
  linkContainer: {
    marginTop: '20px',
    textAlign: 'left',
    width: '100%',
  },
  phoneInput: PhoneInputStyles.phoneInput,
  ssnOrTinInput: ssnOrTinInputStyles.input,
  buttonContainer: {
    marginTop: '20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonContainerSingle: {
    marginTop: '20px',
    width: '100%',
    textAlign: 'right',
  },
  button: {
    backgroundColor: '#7890A6',
    color: '#ffffff',
  },
  backButton: {
    color: '#7890A6',
  },
  homeButton: {
    border: '1px solid rgba(178, 157, 121, 0.5)',
    color: '#B29D79',
    marginTop: '24px',
  },
  emailButton: {
    background: 'rgba(120, 144, 166, 0.08)',
    border: '1px solid rgba(120, 144, 166, 0.5)',
    boxSizing: 'border-box',
    borderRadius: '4px',
    padding: '8px 22px',
    color: '#7890A6',
    fontWeight: '500',
    fontSize: '15px',
    textTransform: 'uppercase',
    minWidth: '200px',
    height: '56px',
    marginTop: '52px',
  },
  separator: {
    textAlign: 'center',
    width: '100%',
    margin: '5px 0',
  },
};
