import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';

const CloseInfoWindowButton = ({ classes, handleClick }) => (
  <div className={classes.wrapper}>
    <button className={classes.closeButton} onClick={handleClick}>
      <span />
      <span />
    </button>
  </div>
);

CloseInfoWindowButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), memo)(CloseInfoWindowButton);
