// actions
import * as A from '../actions/bidedPropertiesActions';

const initialState = {
  statuses: null,
  bidsList: null,
  pending: false,
};

export const reducer = {
  bidedProperties(state = initialState, action) {
    switch (action.type) {
      case A.getBidedProperties.type:
        return { ...state, pending: true };

      case A.getBidedProperties.success:
        return { ...state, bidsList: action.payload, pending: false };

      case A.getBidedProperties.failure:
        return { ...state, pending: false };

      case A.getBidsStatuses.success:
        return { ...state, statuses: action.payload };

      case A.clearState.type:
        return initialState;

      default:
        return state;
    }
  },
};
