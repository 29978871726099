export default {
  root: {
    padding: '86px 0',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '34px',
    textAlign: 'center',
    letterSpacing: '0.25px',
    color: '#171721',
  },
  subTitle: {
    fontSize: '20px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: '500',
    letterSpacing: '0.15px',
    marginTop: '20px',
  },
  text: {
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: '#4B6377',
    margin: '11px 0 25px 0',
  },
  backButton: {
    color: '#7890A6',
  },
  subText: {
    fontSize: '24px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  optionContainer: {
    display: 'flex',
    marginTop: '23px',
    justifyContent: 'space-between',
    width: '100%',
  },
  button: {
    border: '1px solid rgba(120, 144, 166, 0.5)',
    boxSizing: 'border-box',
    borderRadius: '4px',
    padding: '8px 22px',
    color: '#7890A6',
    fontWeight: '500',
    fontSize: '15px',
    textTransform: 'uppercase',
    minWidth: '200px',
    height: '56px',
  },
  email: {
    color: '#7890A6',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'flex-start',
    marginTop: '50px',
  },
};
