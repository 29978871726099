import { primaryMain, secondaryMain, white } from 'themes/colors';

export default {
  message: {
    fontSize: '22px',
    lineHeight: '28px',
    fontWeight: 'normal',
    padding: '50px 50px 50px 150px',
  },
  listings: {
    padding: '0px 10px',
  },
  map: {
    gridColumnStart: 3,
    gridColumnEnd: 4,
    margin: '0px 0px 0px 0px',
  },
  sidenav: {
    gridColumnStart: 1,
    gridColumnEnd: 2,
    width: '0px',
    overflowX: 'hidden',
    transition: 'width 0.5s linear',
  },
  sideNavContent: {
    display: 'grid',
    gridColumnStart: 1,
    gridColumnEnd: 2,
    gridRowStart: 1,
    gridRowEnd: 2,
    width: '400px',
    height: '1215px',
    opacity: 0,
    visibility: 'hidden',
    alignContent: 'start',
    gridTemplateRows: 'fit-content(0px)',
    padding: '30px 0px 0px 15px',
    fontSize: '16px',
    backgroundColor: 'white',
    transition: 'opacity 0.5s linear',
    boxShadow: `0 1px 2px rgba(0,0,0,0.07), 
                0 2px 4px rgba(0,0,0,0.07), 
                0 4px 8px rgba(0,0,0,0.07), 
                0 8px 16px rgba(0,0,0,0.07),
                0 16px 32px rgba(0,0,0,0.07), 
                0 32px 64px rgba(0,0,0,0.07)`,
  },
  statusFilter: {
    display: 'grid',
    gridAutoFlow: 'row',
    padding: '0px 0px 0px 25px',
    width: '100%',
  },
  filters: {
    display: 'grid',
    gridAutoFlow: 'row',
    padding: '0px 0px 0px 25px',
    width: '100%',
    maxHeight: '300px',
    overflowY: 'scroll',
  },
  labelAndChips: {
    marginTop: '30px',
    gridColumnStart: 1,
    gridColumnEnd: 2,
    gridRowStart: 1,
    gridRowEnd: 2,
    justifySelf: 'start',
    padding: '0px 0px 0px 10px',
    transition: 'margin 0.5s linear',
    zIndex: 4,
    maxHeight: '50px',
  },
  mapToggle: {
    marginTop: '30px',
    marginRight: '10px',
    gridColumnStart: 1,
    gridColumnEnd: 2,
    gridRowStart: 1,
    gridRowEnd: 2,
    justifySelf: 'end',
  },
  listingsAndMap: {
    display: 'grid',
    gridColumnStart: 1,
    gridColumnEnd: 2,
    gridRowStart: 1,
    gridRowEnd: 2,
    gridTemplateColumns: 'fit-content(0px) 1fr 1fr',
    margin: '105px 0px 0px 10px',
    zIndex: 2,
  },
  filterButton: {
    transition: 'opacity 0.5s linear',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'end',
  },
  createListingButton: {
    color: white,
    backgroundColor: primaryMain,
    '&:hover': {
      backgroundColor: secondaryMain,
      cursor: 'hand',
    },
    marginRight: 16,
    marginBottom: 16,
  },
  filterButtonStyle: {
    minWidth: '125px',
    color: white,
    backgroundColor: primaryMain,
    '&:hover': {
      backgroundColor: secondaryMain,
      cursor: 'hand',
    },
  },
  checkbox: {
    color: primaryMain,
    '&.Mui-checked': {
      color: primaryMain,
    },
  },
};
