import {
  all, fork, takeLatest, take, call, put,
} from 'redux-saga/effects';

// actions
import { sendContactUs, changeAuthInfo } from 'store/actions/authActions';

// api methods
import Api from 'api/auth';

function* ensureContactUs({ payload: { data, resolve, reject } }) {
  try {
    yield call(Api.contactUs, { data: JSON.stringify(data) });
    yield put({ type: sendContactUs.success });
    if (resolve) {
      resolve();
    }
  } catch (err) {
    yield put({
      type: sendContactUs.failure,
      err,
    });
    if (reject) {
      reject(err);
    }
  }
}

function* watchSendingContactUs() {
  yield takeLatest(sendContactUs.type, ensureContactUs);
  yield take(sendContactUs.success);
  yield put(changeAuthInfo({ contactUsSended: false }));
}

export default function* contactUsSagas() {
  yield all([fork(watchSendingContactUs)]);
}
