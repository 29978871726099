import {
  all, fork, take, takeLatest, call, put,
} from 'redux-saga/effects';

// actions
import { getAllStates } from '../actions/statesActions';

// api methods
import Api from 'api/states';

function* ensureGetAllStates() {
  try {
    const { data } = yield call(Api.getAllStates);
    yield put({ type: getAllStates.success, payload: data });
  } catch (err) {
    yield put({ type: getAllStates.failure, err });
  }
}

function* watchGetAllState() {
  yield takeLatest(getAllStates.type, ensureGetAllStates);
  yield take(getAllStates.success);
}

export default function* statesSagas() {
  yield all([
    fork(watchGetAllState),
  ]);
}
