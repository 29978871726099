import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Slide } from '@material-ui/core';

// styles
import styles from './styles';

const ModalLayout = ({
  children, classes, isModalOpen, handleModalClick, slideDirection,
}) => (
  <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    className={classes.modal}
    open={isModalOpen}
    onClose={handleModalClick}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Slide unmountOnExit in={isModalOpen} direction={slideDirection || 'up'}>
      {children}
    </Slide>
  </Modal>
);

ModalLayout.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleModalClick: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  slideDirection: PropTypes.string,
};

export default compose(withStyles(styles), memo)(ModalLayout);
