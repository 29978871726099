import React from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

// layouts

// components
import PdfViewer from '../../components/PdfViewer';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';

// styles
import styles from './styles';

const DocumentLayoutContainerInternal = ({
  classes,
  documentName,
  children,
}) => (
  <>
    <Grid container justify="center">
      <div className={classes.root}>
        <Grid
          container
          justify="center"
          justifyContent="center"
          alignItems="center"
          direction="column"
            // TODO: [DRY][REFACTOR] define this `maxWidth` as a shared style constant
            // so it can be maintained in one place rather than in various places that should use
            // this same style property.
          style={{ margin: 'auto', maxWidth: '883px' }}
        >
          <Typography variant="h1" component="div" className={classes.title}>
            {documentName}
          </Typography>
          <div className={classes.bodyContainer}>
            {children}
          </div>
        </Grid>
      </div>
    </Grid>
  </>
);

DocumentLayoutContainerInternal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node,
  documentName: PropTypes.string.isRequired,
};

DocumentLayoutContainerInternal.defaultProps = {
  children: null,
};

export const DocumentLayoutContainer = compose(
  withStyles(styles),
)(DocumentLayoutContainerInternal);

const DocumentViewLayout = ({
  classes,
  document,
  documentName,
  attachments,
  children,
}) => (
  <>
    <DocumentLayoutContainer
      documentName={documentName}
    >
      <Typography variant="body1" component="div" className={classes.body}>
        <PdfViewer file={document} attachments={attachments} />
      </Typography>
    </DocumentLayoutContainer>
    {children}
  </>
);

DocumentViewLayout.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  // TODO: [TYPE] specify types
  children: PropTypes.node,
  documentName: PropTypes.string.isRequired,
};

DocumentViewLayout.defaultProps = {
  children: null,
};

export default compose(
  withStyles(styles),
)(DocumentViewLayout);
