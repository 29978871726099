import { put, call, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { parseServerError } from 'now-shared/helpers/server-errors';

// actions
import { removeUserData } from 'store/actions/authActions';

export default function* ensureNotifications() {
  yield takeEvery(
    e => e.type.includes('/FAILURE'),
    function* logger(action) {
      const data = parseServerError(action.err.response?.data);
      if (data.refreshTokenError) {
        yield put(removeUserData({ tokensHasExpired: true }));
      } else {
        console.error('saga error:', action);
        yield call(toast.error, data.friendlyMessage, { position: 'top-center' });
      }
    },
  );
}
