import {
  addError,
  generalRules,
} from 'now-shared/validation/validation-rules';

import { compose } from 'redux';
import validateFormattedCurrency from './validateFormattedCurrency';

const createPropertyValidator = values => (errors, fieldName) => {
  const value = values[fieldName];
  const { transactionType } = values;

  switch (fieldName) {
    case 'projectName':
      return compose(
        addError(fieldName, generalRules.shortFieldMaxLenght(value)),
        addError(fieldName, generalRules.minLength(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);

    case 'operatorName':
      return compose(
        addError(fieldName, generalRules.shortFieldMaxLenght(value)),
        addError(fieldName, generalRules.minLength(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);

    case 'state':
      return compose(
        addError(fieldName, generalRules.required(value)),
      )(errors);

    case 'basin':
      return compose(
        addError(fieldName, generalRules.required(value)),
      )(errors);

    case 'county':
      return compose(
        addError(fieldName, generalRules.required(value)),
      )(errors);

    case 'wells': {
      if (!value) return errors;
      const wellsArrayErrors = value.map(well => wellsValidate(well));
      return compose(addError(fieldName, wellsArrayErrors))(errors);
    }

    case 'minimumBid': {
      if (transactionType === 'carry') {
        return errors;
      }
      return compose(
        addError(fieldName, validateFormattedCurrency(value, {
          required: true,
          positive: true,
        })),
      )(errors);
    }

    case 'minimumBidCarry': {
      if (transactionType === 'cash') {
        return errors;
      }
      let parsedValue = parseFloat(value);
      if (Number.isNaN(parsedValue)) {
        parsedValue = undefined;
      }
      return compose(
        addError(fieldName, generalRules.isPositiveNumber(parsedValue)),
        addError(fieldName, generalRules.required(parsedValue)),
      )(errors);
    }

    case 'startTime':
      return compose(addError(fieldName, generalRules.required(value)))(errors);

    case 'endTime':
      return compose(addError(fieldName, generalRules.required(value)))(errors);

    case 'isAnonymous':
      return compose(addError(fieldName, generalRules.required(value)))(errors);

    default:
      return errors;
  }
};

const wellsValidator = values => (errors, fieldName) => {
  const value = values[fieldName];

  switch (fieldName) {
    case 'landingZone':
      return compose(
        addError(fieldName, generalRules.required(value)),
      )(errors);
    case 'workingInterestPercentage':
    case 'netRevenueInterestNumber': {
      // parse is needed before since this value comes with a maskType: percentage
      // ex. "3 %"
      let parsedValue = parseFloat(value);
      if (Number.isNaN(parsedValue)) {
        parsedValue = undefined;
      }
      return compose(
        addError(fieldName, generalRules.isPositiveNumber(parsedValue)),
        addError(fieldName, generalRules.required(parsedValue)),
      )(errors);
    }
    case 'surfaceLatitude':
    case 'bottomLatitude':
      return compose(
        addError(fieldName, generalRules.isPositiveNumber(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);
    case 'surfaceLongitude':
    case 'bottomLongitude':
      return compose(
        addError(fieldName, generalRules.isNegativeNumber(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);
    case 'wellTotalVerticalDepth': {
      // parse is needed before since this value comes with a maskType: feet
      // ex. "12345 ft"
      let parsedValue = parseFloat(value);
      if (Number.isNaN(parsedValue)) {
        parsedValue = undefined;
      }
      return compose(
        addError(fieldName, generalRules.isInteger(parsedValue)),
        addError(fieldName, generalRules.isPositiveNumber(parsedValue)),
        addError(fieldName, generalRules.required(parsedValue)),
      )(errors);
    }
    case 'wellNetAFE': {
      return compose(
        addError(fieldName, validateFormattedCurrency(value, {
          required: true,
          atLeast: 0,
        })),
      )(errors);
    }
    case 'wellGrossAfe': {
      return compose(
        addError(fieldName, validateFormattedCurrency(value, {
          required: true,
          atLeast: 0,
        })),
      )(errors);
    }
    case 'wellName': {
      return compose(
        addError(fieldName, generalRules.shortFieldMaxLenght(value)),
        addError(fieldName, generalRules.minLength(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);
    }
    default:
      return errors;
  }
};

const wellsValidate = values => [
  'landingZone',
  'workingInterestPercentage',
  'netRevenueInterestNumber',
  'surfaceLatitude',
  'bottomLatitude',
  'surfaceLongitude',
  'bottomLongitude',
  'wellTotalVerticalDepth',
  'wellNetAFE',
  'wellGrossAfe',
  'wellName',
].reduce(wellsValidator(values), {});

export const createPropertyValidate = values => [
  'projectName',
  'operatorName',
  'state',
  'basin',
  'county',
  'wells',
  'minimumBid',
  'minimumBidCarry',
  'startTime',
  'endTime',
  'isAnonymous',
].reduce(createPropertyValidator(values), {});
