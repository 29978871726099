import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from 'now-frontend-shared/layouts/styles';

const BottomSection = ({
  children, classes, heading, description,
}) => (
  <>
    <Grid container justify="center">
      {heading && (
      <Grid container>
        <Grid container direction="column" className={classes.headingContainer}>
          <span className={classes.heading}>{heading}</span>

          {description && <span className={classes.description}>{description}</span>}
        </Grid>
      </Grid>
      )}

      <Grid item xs={12} sm={12} md={2} lg={2} />

      <Grid item xs={12} sm={9} md={9} lg={9}>
        <Grid container className={classes.container}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  </>
);

BottomSection.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  heading: PropTypes.string,
  description: PropTypes.string,
};

export default compose(withStyles(styles), memo)(BottomSection);
