import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';

// components
import ListingForm from 'now-frontend-shared/components/ListingForm';

// layouts
import HeadSection from 'now-frontend-shared/layouts/AuthSections/HeadSection';

// store
import {
  clearAWSData,
  editProperty,
  getBasins,
  getCounties,
  getCurrentProperty,
  getLandingZones,
  getPreSignedUrls,
  removeAWSDataFile,
  setAWSData,
  setBasinId,
  setStateId,
  setUnloadedFilesExist,
} from 'store/actions/editPropertyActions';
import { listingHasBeenApproved } from 'now-shared/validation/listing-validation';
import { getAllCompanies } from 'store/actions/companiesActions';
import { refreshTokens } from 'utils/apiMethods';

const formName = 'editListing';

const EditProperty = ({ match, currentProperty, ...props }) => {
  const dispatch = useDispatch();
  const listingId = match.params.id;

  useEffect(() => {
    dispatch(getCurrentProperty(listingId));
    dispatch(getAllCompanies());
  }, [listingId, dispatch]);

  const onSubmit = useCallback(formData => {
    dispatch(editProperty({
      id: listingId,
      data: formData,
      addDocuments: listingHasBeenApproved(currentProperty),
      formName,
    }));
  }, [listingId, currentProperty, dispatch]);

  return (
    <ListingForm
      formName={formName}
      isBasedOnExisting
      onSubmit={onSubmit}
      keepSessionAlive={refreshTokens}
      currentProperty={currentProperty}
      { ...props }
    >
      <HeadSection heading="Edit Listing" path="/my-listings" />
    </ListingForm>
  );
};

EditProperty.propTypes = {
  // TODO: [TYPE] add `.isRequired` to this field
  match: PropTypes.objectOf(
    PropTypes.shape({
      params: PropTypes.objectOf(PropTypes.shape({ id: PropTypes.string })),
    }),
  ),
  currentProperty: PropTypes.object.isRequired,
  // TODO: [TYPE] specify missing types
};

const mapStateToProps = ({ companies, editProperty, auth }) => ({
  listingOwner: auth.user,
  AWSData: editProperty.AWSData,
  basins: editProperty.basins,
  clearAWSData,
  companies: companies.companies,
  counties: editProperty.counties,
  currentProperty: editProperty.currentProperty,
  getLandingZones,
  getBasins,
  getCounties,
  getPreSignedUrls,
  isLoadingCompanies: companies.companiesLoading,
  landingZones: editProperty.landingZones,
  preSignedUrls: editProperty.preSignedUrls,
  removeAWSDataFile,
  setAWSData,
  setBasinId,
  setStateId,
  setUnloadedFilesExist,
  states: editProperty.states,
  unloadedFilesExist: editProperty.unloadedFilesExist,
});

export default compose(
  connect(mapStateToProps),
)(EditProperty);
