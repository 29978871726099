import { asyncAction, action } from 'now-frontend-shared/utils/actions';

// async actions
export const getPreSignedUrls = asyncAction(
  'officerFiles/GET_PRE_SIGNED_URLS',
);

// sync action
export const reset = action('officerFiles/RESET');
export const setAWSData = action('officerFiles/SET_AWS_DATA');
export const removeAWSDataFile = action('officerFiles/REMOVE_AWS_DATA_FILE');
export const clearAWSData = action('officerFiles/CLEAR_AWS_DATA');
export const setUnloadedFilesExist = action('officerFiles/SET_UNLOADED_FILES_EXIST');
