import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { StyledTextField } from 'now-frontend-shared/components/inputs/StyledTextField/styledTextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { compose } from 'redux';
import { useDispatch, connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Delete from '@material-ui/icons/Delete';
import LockIcon from '@material-ui/icons/LockRounded';
import { getAllStates } from 'store/actions/statesActions';

import SsnOrTinInput from 'now-frontend-shared/components/inputs/SsnOrTinInput';
import DatePicker from 'now-frontend-shared/components/DatePicker';

import styles from './styles';
import { deleteOfficer, updateOfficer } from 'store/actions/companyActions';
import moment from 'moment';
import { dateOnlyDtoFormat, hasValue } from 'now-shared/validation/validation-rules';

function Officer({
  autoFocus,
  classes,
  errors,
  index,
  officerInformation,
  states,
  officerAgreement,
}) {
  const dispatch = useDispatch();

  const [touched, setTouched] = React.useState({});
  const [isSecretVisible, setIsSecretVisible] = React.useState({});

  const setTouchedDelay = 0;
  const setTouchedDelayed = (fieldName, newValue) => {
    setTimeout(() => setTouched(prev => ({
      ...prev,
      [fieldName]: newValue,
    })), setTouchedDelay);
  };

  const setIsSecretVisibleDelayed = (fieldName, newValue) => {
    setTimeout(() => setIsSecretVisible(prev => ({
      ...prev,
      [fieldName]: newValue,
    })), setTouchedDelay);
  };

  const data = officerInformation;

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '10px 0',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'flex-start',
          gap: '5px',
        }}
      >
        {(() => {
          const field = 'name';
          const label = 'Name';
          const isSecret = false;
          const value = data[field];
          const error = touched[field] && errors[field];
          return (
            <StyledTextField
              label={label}
              value={value || ''}
              type={(!isSecret || isSecretVisible[field]) ? 'text' : 'password'}
              variant="outlined"
              disabled={officerAgreement}
              onChange={e => {
                dispatch(updateOfficer({
                  key: field,
                  value: e.target.value || null,
                  index,
                }));
              }}
              onFocus={() => {
                setTouchedDelayed(field, false);
                if (isSecret) {
                  setIsSecretVisibleDelayed(field, true);
                }
              }}
              onBlur={() => {
                setTouchedDelayed(field, true);
                if (isSecret) {
                  setIsSecretVisibleDelayed(field, false);
                }
              }}
              error={!!error}
              helperText={error || '\u00a0'}
              placeholder={label}
              className={classes.item}
              autoFocus={autoFocus}
              {...isSecret && {
                InputProps: {
                  endAdornment: (
                    <LockIcon />
                  ),
                },
              }}
              required
            />
          );
        })()}
        {(() => {
          const field = 'title';
          const label = 'Title';
          const isSecret = false;
          const value = data[field];
          const error = touched[field] && errors[field];
          return (
            <StyledTextField
              label={label}
              value={value || ''}
              disabled={officerAgreement}
              type={(!isSecret || isSecretVisible[field]) ? 'text' : 'password'}
              variant="outlined"
              onChange={e => {
                dispatch(updateOfficer({
                  key: field,
                  value: e.target.value || null,
                  index,
                }));
              }}
              onFocus={() => {
                setTouchedDelayed(field, false);
                if (isSecret) {
                  setIsSecretVisibleDelayed(field, true);
                }
              }}
              onBlur={() => {
                setTouchedDelayed(field, true);
                if (isSecret) {
                  setIsSecretVisibleDelayed(field, false);
                }
              }}
              error={!!error}
              helperText={error || '\u00a0'}
              placeholder={label}
              className={classes.item}
              {...isSecret && {
                InputProps: {
                  endAdornment: (
                    <LockIcon />
                  ),
                },
              }}
              required
            />
          );
        })()}
        {(() => {
          const field = 'dateOfBirth';
          const label = 'Date of Birth';
          const value = data[field];
          return (
            <Grid item xs={6} className={classes.datePickerContainer}>
              <Typography
                variant="body1"
                component="div"
                className={classes.datePickerLabel}
              >
                {label}
                {' '}
                *
              </Typography>
              <DatePicker
                meta={{
                  touched: touched[field],
                  error: errors[field],
                }}
                maxDate={new Date()}
                classes={{ input: classes.datePicker }}
                disabled={officerAgreement}
                fullWidth
                input={{
                  name: field,
                  value: value ? moment(value, dateOnlyDtoFormat).toDate() : null,
                  onChange: e => dispatch(updateOfficer({
                    key: field,
                    value: e ? moment(e).format(dateOnlyDtoFormat) : null,
                    index,
                  })),
                  onFocus: () => setTouchedDelayed(field, false),
                  onBlur: () => setTouchedDelayed(field, true),
                }}
                required
              />
            </Grid>
          );
        })()}
        {(() => {
          const field = 'socialSecurityNumber';
          const label = 'Social Security';
          const isSecret = true;
          const error = touched[field] && errors[field];
          const value = data[field];
          return (
            <SsnOrTinInput
              classes={{ maskStyles: classes.ssnOrTinInput }}
              autoComplete="off"
              input={{
                name: field,
                value: value || '',
                onChange: e => {
                  if (!officerAgreement) {
                    dispatch(updateOfficer({
                      key: field,
                      value: e.target.value || null,
                      index,
                    }));
                  }
                },
                onFocus: () => {
                  setTouchedDelayed(field, false);
                  if (isSecret) {
                    setIsSecretVisibleDelayed(field, true);
                    if (document.getElementById('socialSecurityNumber').getAttribute('type') === 'password') {
                      document.getElementById('socialSecurityNumber').setAttribute('type', 'text');
                    }
                  }
                },
                onBlur: () => {
                  setTouchedDelayed(field, true);
                  if (isSecret) {
                    document.getElementById('socialSecurityNumber').setAttribute('type', 'password');
                    setIsSecretVisibleDelayed(field, false);
                  }
                },
              }}
              renderInput={() => (
                <StyledTextField
                  label={label}
                  id={field}
                  type="text"
                  error={!!error}
                  helperText={error || '\u00a0'}
                  variant="outlined"
                  fullWidth
                  className={classes.item}
                  InputProps={{
                    endAdornment: (
                      <LockIcon />
                    ),
                  }}
                />
              )}
            />
          );
        })()}
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginBottom: 20,
        }}
      >
        <Typography variant="body" component="div">
          If the user does not enter a Social Security Number during registration,
          it must be provided prior to closing any transaction on the Platform.
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        (Optional)
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '5px',
          margin: '7px 0',
          position: 'relative',
        }}
      >
        {(() => {
          const field = 'governmentIssuedId';
          const label = 'Drivers License #';
          const isSecret = true;
          const value = data[field];
          const error = touched[field] && errors[field];
          return (
            <StyledTextField
              label={label}
              value={value || ''}
              id={field}
              type="text"
              variant="outlined"
              disabled={officerAgreement}
              onChange={e => {
                dispatch(updateOfficer({
                  key: field,
                  value: e.target.value || null,
                  index,
                }));
              }}
              onFocus={() => {
                setTouchedDelayed(field, false);
                if (isSecret) {
                  setIsSecretVisibleDelayed(field, true);
                  if (document.getElementById('governmentIssuedId').getAttribute('type') === 'password') {
                    document.getElementById('governmentIssuedId').setAttribute('type', 'text');
                  }
                }
              }}
              onBlur={() => {
                setTouchedDelayed(field, true);
                if (isSecret) {
                  document.getElementById('governmentIssuedId').setAttribute('type', 'password');
                  setIsSecretVisibleDelayed(field, false);
                }
              }}
              error={!!error}
              helperText={error || '\u00a0'}
              placeholder={label}
              className={classes.item}
              {...isSecret && {
                InputProps: {
                  endAdornment: (
                    <LockIcon />
                  ),
                },
              }}
            />
          );
        })()}
        {(() => {
          const field = 'placeOfIssuance';
          const value = data[field];
          const error = touched[field] && errors[field];
          return (
            <Autocomplete
              margin="normal"
              value={states.find(opt => opt.value === value)}
              className={classes.item}
              disabled={officerAgreement}
              onChange={(_, newOption) => {
                const newValue = newOption?.title || null;
                dispatch(updateOfficer({
                  key: field,
                  value: newValue,
                  index,
                }));
              }}
              onFocus={() => setTouchedDelayed(field, false)}
              onBlur={() => setTouchedDelayed(field, true)}
              id="state-select"
              options={states}
              getOptionLabel={option => option.title || ''}
              renderInput={params => (
                <StyledTextField
                  {...params}
                  label="Place of Issuance"
                  variant="outlined"
                  error={!!error}
                  value={value || ''}
                  helperText={error || '\u00a0'}
                  required={hasValue(data.governmentIssuedId)}
                />
              )}
            />
          );
        })()}
        {index !== 0 && (
          <Delete
            className={classes.deleteIcon}
            onClick={() => dispatch(deleteOfficer({ index }))}
          />
        )}
      </Box>
    </Box>
  );
}

Officer.propTypes = {
  autoFocus: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  index: PropTypes.number.isRequired,
  officerInformation: PropTypes.objectOf(PropTypes.string).isRequired,
};

Officer.defaultProps = {
  autoFocus: false,
};

function Officers({
  autoFocus,
  classes,
  officersInformation,
  officersErrors,
  states,
  officerAgreement,
}) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getAllStates());
  }, [dispatch]);

  return officersInformation.map((data, index) => {
    const errors = officersErrors[index];
    return (
      <Officer
        autoFocus={autoFocus && index === 0}
        classes={classes}
        errors={errors}
        index={index}
        key={index}
        officerAgreement={officerAgreement}
        officerInformation={data}
        states={states}
      />
    );
  });
}

Officers.propTypes = {
  autoFocus: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  officersInformation: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  officersErrors: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  states: PropTypes.objectOf(PropTypes.any).isRequired,

};

Officers.defaultProps = {
  autoFocus: false,
};

export default compose(connect(({
  states,
}) => ({
  states: states.states,
})), withStyles(styles))(Officers);
