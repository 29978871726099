import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import styles from './styles';
import { updateCompany } from 'store/actions/companyActions';
import { navigateToNextPage, navigateToPreviousPage } from 'constants/registrationFlow';

function BeneficialOwnerView({ classes, company }) {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ margin: 'auto', maxWidth: '627px' }}
      >
        <Typography variant="h1" component="div" className={classes.title}>
          Ultimate Beneficial Owner Detail
        </Typography>
        <Typography variant="h5" component="div" className={classes.subTitle}>
          Do you have an ultimate beneficial owner that owns 25% of the company or more?
        </Typography>
        <div className={classes.optionContainer}>
          <Button
            className={classes.button}
            variant="outlined"
            onClick={() => {
              dispatch(
                updateCompany({
                  value: true,
                  key: 'BO25PercentOfCompanyOrMore',
                }),
              );
              history.push(navigateToNextPage(history.location.pathname, true));
            }}
          >
            Yes
          </Button>
          <Button
            className={classes.button}
            variant="outlined"
            onClick={() => {
              dispatch(
                updateCompany({
                  value: false,
                  key: 'BO25PercentOfCompanyOrMore',
                }),
              );
              history.push(navigateToNextPage(history.location.pathname, false));
            }}
          >
            No
          </Button>
        </div>
        <div className={classes.optionContainer} style={{ marginTop: '50px' }}>
          <Button
            className={classes.backButton}
            variant="text"
            onClick={() => history.push(navigateToPreviousPage(history.location.pathname))}
          >
            Back
          </Button>
        </div>
      </Grid>
    </div>
  );
}

BeneficialOwnerView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  company: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(
  connect(({ company }) => ({
    company,
  })),
  withStyles(styles),
)(BeneficialOwnerView);
