import { HttpStatus } from '../helpers/https-status';
import {
  hasValue,
  maxCharCount,
  minCharCount,
  validateNotEmpty,
} from './validation-rules';

export const ApprovalStatus = {
  Pending: 'pending',
  Approved: 'approved',
  Rejected: 'rejected',
};

let allApprovalStatuses;

export const toStatusName = statusObjectOrName => statusObjectOrName?.title ?? statusObjectOrName;

export const getAllApprovalStatuses = () => {
  if (allApprovalStatuses === undefined) {
    allApprovalStatuses = Object.values(ApprovalStatus);
  }
  return allApprovalStatuses;
};

export const noApprovalStatusId = 'none';

export const getApprovalStatusNotAllowedError = approvalStatus => ({
  message: `Not an allowed approval status: ${toStatusName(approvalStatus)}`,
  code: HttpStatus.UnprocessableEntity,
});

/**
 * @type {import('./validation-rules').ValueValidator}
 */
export const validateIsApprovalStatus = value => {
  let result;
  const statusName = toStatusName(value);
  if (!getAllApprovalStatuses().includes(statusName)) {
    result = getApprovalStatusNotAllowedError(value);
  }
  return result;
};

/**
 * Roughly 1 page of text
 *
 * NOTE: updates to this must be accompanied by a database migration
 */
export const reasonForRejectionMaximumChars = 3000;
export const reasonForRejectionMinimumChars = 2;

export const getRejectionReasonValidators = () => [
  validateNotEmpty,
  minCharCount(reasonForRejectionMinimumChars),
  maxCharCount(reasonForRejectionMaximumChars),
];

/**
 * @param {string} approvalStatusField
 * @returns {import('./validation-rules').ValueValidator}
 */
export const validateHasRejectionReason = approvalStatusField => (value, values) => {
  let result;
  if (hasValue(value)) {
    const status = values[approvalStatusField];
    const statusName = toStatusName(status);
    const allowedApprovalStatuses = [
      ApprovalStatus.Rejected,
    ];
    if (!allowedApprovalStatuses.includes(statusName)) {
      result = `Must only exist if approval status is one of: ${allowedApprovalStatuses.map(
        allowedStatus => `'${allowedStatus}'`,
      ).join(', ')}`;
    }
  }
  return result;
};

/**
 * @param {object} values
 * @param {string} approvalStatusField
 * @returns {boolean}
 */
export const isRejectionReasonProhibited = (
  (values, approvalStatusField) => (
    validateHasRejectionReason(approvalStatusField)('somevalue', values) !== undefined
  )
);

/**
 * @param {string} approvalStatusField
 * @returns {import('./validation-rules').ValueValidator}
 */
export const validateHasNoRejectionReason = approvalStatusField => (value, values) => {
  let result;
  if (!hasValue(value)) {
    const status = values[approvalStatusField];
    const statusName = toStatusName(status);
    if (statusName === ApprovalStatus.Rejected) {
      result = `Required when approval status is '${ApprovalStatus.Rejected}'`;
    }
  }
  return result;
};

/**
 * @param {object} values
 * @param {string} approvalStatusField
 * @returns {boolean}
 */
export const isRejectionReasonRequired = (
  (values, approvalStatusField) => (
    validateHasNoRejectionReason(approvalStatusField)(undefined, values) !== undefined
  )
);

/**
 * @param {string} approvalStatusField
 * @returns {import('./validation-rules').ValueValidatorArrayGetter}
 */
export const getRejectionReasonExistsValidators = approvalStatusField => (_values, value) => [
  ...hasValue(value) ? [
    validateHasRejectionReason(approvalStatusField),
  ] : [
    validateHasNoRejectionReason(approvalStatusField),
  ],
];

/**
 * @param {string} approvalStatusField
 * @returns {import('./validation-rules').ValueValidatorArrayGetter}
 */
export const getRejectionReasonValidatorsWithExistsCheck = approvalStatusField => (values, value) => [
  ...getRejectionReasonExistsValidators(approvalStatusField)(values, value),
  ...hasValue(value) || isRejectionReasonRequired(values, approvalStatusField) ? [
    ...getRejectionReasonValidators(),
  ] : [],
];
