import React, { memo, useCallback, forwardRef } from 'react';
import { compose } from 'redux';

// components
import Button from 'now-frontend-shared/components/Button';

// custom hooks
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

const DeleteConfirmationModal = ({
  classes, heading, handleRemoveItem, index,
}, ref) => {
  const { setModal } = useModalSetter();

  const handleCloseModal = useCallback(() => {
    setModal(null);
  }, [setModal]);

  const onHandleRemoveItem = useCallback(() => {
    handleRemoveItem(index);
    setModal(null);
  }, [setModal, handleRemoveItem, index]);

  return (
    <Grid container direction="column" tabIndex={-1} className={classes.wrapper} ref={ref}>
      <Grid container direction="column" alignItems="center" className={classes.container}>
        <Grid container justify="center" className={classes.heading}>
          <span>{heading}</span>
        </Grid>

        <Grid container justify="space-between">
          <Button label="No" buttonColor="red" onClick={handleCloseModal} />
          <Button label="Yes" buttonColor="green" onClick={onHandleRemoveItem} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default compose(withStyles(styles), memo, forwardRef)(DeleteConfirmationModal);
