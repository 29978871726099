import { LOCATION_CHANGE } from 'connected-react-router';

// actions
import * as A from '../actions/editPropertyActions';

const initialState = {
  currentProperty: null,
  states: null,
  stateId: null,
  basins: null,
  basinId: null,
  counties: null,
  countyId: null,
  landingZones: null,
  preSignedUrls: undefined,
  preSignedUrlsIsLoading: false,
  AWSData: [],
  unloadedFilesExist: false,
};

export const reducer = {
  editProperty(state = initialState, action) {
    switch (action.type) {
      case LOCATION_CHANGE:
      case A.clearState.type:
        return initialState;

      case A.getCurrentProperty.success:
        return {
          ...state,
          currentProperty: action.payload.data,
          states: action.payload.states,
          basins: action.payload.basins,
          counties: action.payload.counties,
          stateId: action.payload.stateId,
          basinId: action.payload.basinId,
          countyId: action.payload.countyId,
          landingZones: action.payload.landingZones,
        };

      case A.getStates.type:
        return {
          ...state,
          states: null,
          stateId: null,
          basins: null,
          basinId: null,
          counties: null,
          countyId: null,
          landingZones: null,
        };

      case A.getStates.success:
        return {
          ...state,
          states: action.payload.sort((a, b) => {
            if (a.title.toLowerCase() < b.title.toLowerCase()) {
              return -1;
            }
            if (a.title.toLowerCase() > b.title.toLowerCase()) {
              return 1;
            }
            return 0;
          }),
        };

      case A.getBasins.type:
        return {
          ...state, stateId: action.payload, counties: null, countyId: null, landingZones: null,
        };

      case A.getBasins.success:
        return { ...state, basins: action.payload };

      case A.getCounties.type:
        return { ...state, basinId: action.payload, landingZones: null };

      case A.setBasinId.type:
        return { ...state, basinId: action.payload };

      case A.setStateId.type:
        return { ...state, stateId: action.payload };

      case A.getCounties.success:
        return { ...state, counties: action.payload };

      case A.getLandingZones.type:
        return { ...state, countyId: action.payload };

      case A.getLandingZones.success:
        return { ...state, landingZones: action.payload };

      case A.getPreSignedUrls.type:
        return { ...state, preSignedUrlsIsLoading: true };

      case A.getPreSignedUrls.success:
        return {
          ...state,
          preSignedUrls: action.payload,
          preSignedUrlsIsLoading: false,
        };

      case A.getPreSignedUrls.failure:
        return {
          ...state,
          preSignedUrls: initialState.preSignedUrls,
          preSignedUrlsIsLoading: false,
        };

      case A.setAWSData.type:
        return {
          ...state,
          AWSData: [
            ...state.AWSData.filter(doc => doc.key !== action.payload.key),
            action.payload,
          ],
        };

      case A.removeAWSDataFile.type:
        return { ...state, AWSData: [...state.AWSData.filter(file => file.key !== action.payload)] };

      case A.clearAWSData.type:
        return { ...state, AWSData: initialState.AWSData };

      case A.setUnloadedFilesExist.type:
        return { ...state, unloadedFilesExist: action.payload };

      default:
        return state;
    }
  },
};
