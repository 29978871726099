import React, { useRef } from 'react';
import { Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { documentTypes } from 'now-shared/helpers/document-types';
import {
  formValidationHasErrors,
  hasValue,
} from 'now-shared/validation/validation-rules';
import {
  personalDetailsFieldValidators,
  validatePersonalDetails,
} from 'now-shared/validation/personal-details';

// components
import Header from 'components/Header';
import Footer from 'components/Footer';

// styled component from material-ui
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';
import {
  desktopContentMinWidthExclusive,
  mainPageContentBackgroundColor,
} from 'layouts/styles';
import {
  registrationFlow,
  PERMISSION_ROUTE,
  BUSINESS_ADDRESS_ROUTE,
  UPLOAD_COMPANY_FILES_ROUTE,
  LIST_OFFICERS_ROUTE,
  BENEFICIAL_OWNER_UPLOADS,
  FINANCING_INFORMATION_ROUTE,
  REGISTRATION_ROUTE,
} from 'constants/registrationFlow';

export function hasCompletedPersonalInfoSection(user) {
  const errors = validatePersonalDetails(user);
  return !formValidationHasErrors(errors);
}

export function hasPersonalInfoSaved(authUser, howMuch = 'all') {
  if (howMuch === 'all') {
    const errors = validatePersonalDetails({
      ...authUser,
      // required in form; treat undefined as a `false`
      isAccountManager: !!authUser.isAccountManager,
    });
    return !formValidationHasErrors(errors);
  }
  return Object.keys(personalDetailsFieldValidators).some(field => hasValue(authUser[field]));
}

export function hasCompanyInfoToSubmit(company) {
  return !!company?.businessStreetAddress;
}

export function hasCompanyInfoSaved(authUser) {
  return !!authUser.company;
}

const WrappedRoute = ({
  classes,
  component: Component,
  path: url,
  company,
  authUser,
  user,
}) => {
  const companyRef = useRef();
  const history = useHistory();

  companyRef.current = company;

  const userRef = useRef();
  userRef.current = user;

  const authUserRef = useRef();
  authUserRef.current = authUser;

  React.useEffect(() => {
    const documents = authUserRef.current?.company?.documents;

    const incorporationFile = documents?.find(
      v => v.type === documentTypes.CERTIFIED_ARTICLES_OF_INCORPORATION,
    );
    const formationFile = documents?.find(
      v => v.type === documentTypes.FORM_W9,
    );

    const hasRequiredUploadsDocuments = incorporationFile && formationFile;

    const officerAgreement = documents?.find(
      v => v.type === documentTypes.OFFICER_CORPORATE_AGREEMENT,
    );
    const beneficialOwnerAgreement = documents?.find(
      v => v.type === documentTypes.BENEFICIAL_OWNER_FILES,
    );

    const validateAgainstRegistrationRequirements = pageIndex => {
      if ((pageIndex === 0 && !user.isAccountManager)) {
        return PERMISSION_ROUTE;
      } if (pageIndex === 1 && !hasCompletedPersonalInfoSection(authUserRef.current)) {
        return REGISTRATION_ROUTE;
      }
      if ([2, 3, 4, 5, 6, 7].includes(pageIndex) && !hasCompanyInfoToSubmit(companyRef.current) && !hasCompanyInfoSaved(authUserRef.current)) {
        return BUSINESS_ADDRESS_ROUTE;
      } if (pageIndex === 8 && !hasRequiredUploadsDocuments) {
        return UPLOAD_COMPANY_FILES_ROUTE;
      } if (pageIndex === 9 && !officerAgreement) {
        return LIST_OFFICERS_ROUTE;
      } if (pageIndex === 10 && companyRef.current.BO25PercentOfCompanyOrMore && !beneficialOwnerAgreement) {
        return BENEFICIAL_OWNER_UPLOADS;
      } if (pageIndex === 12 && authUserRef.current.company?.buyerAgreement && !authUserRef.current.company?.bankInformation) {
        return FINANCING_INFORMATION_ROUTE;
      }
      return null;
    };

    let targetPage = history.location.pathname;

    const registrationTargetStepIndex = registrationFlow.findIndex(page => page.route === targetPage);
    if (registrationTargetStepIndex) {
      for (let i = 0; i < registrationTargetStepIndex; i++) {
        const updatedRoute = validateAgainstRegistrationRequirements(i);
        if (updatedRoute) {
          targetPage = updatedRoute;
          break;
        }
      }
    }

    history.replace(targetPage);
  }, [history]);

  return (
    <Route
      exact
      path={url || null}
      render={props => (
        <>
          <Header />
          <Box
            display="flex"
            flexDirection="column"
            style={{
              width: '100%',
              minWidth: `${desktopContentMinWidthExclusive}`,
              backgroundColor: mainPageContentBackgroundColor,
            }}
            flexGrow={1}
          >
            <Grid
              component="main"
              container
              direction="column"
              className={classes.wrapperNew}
            >
              <Component {...props} />
            </Grid>
          </Box>
          <Footer />
        </>
      )}
    />
  );
};

WrappedRoute.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  path: PropTypes.string,
  company: PropTypes.objectOf(PropTypes.any).isRequired,
  authUser: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default compose(
  connect(({ company, auth, user }) => ({
    authUser: auth.user,
    company,
    user,
  })),
  withStyles(styles),
)(WrappedRoute);
