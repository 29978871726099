// actions
import * as A from '../actions/propertiesActions';

const initialState = {
  propertiesList: null,
  statuses: null,
  states: null,
  basins: null,
  basinId: null,
  counties: null,
  countyId: null,
  landingZones: null,
  landingZoneId: null,
  pending: false,
};

export const reducer = {
  properties(state = initialState, action) {
    switch (action.type) {
      case A.getStates.success:
        return {
          ...state,
          states: action.payload.sort((a, b) => {
            if (a.title.toLowerCase() < b.title.toLowerCase()) {
              return -1;
            }
            if (a.title.toLowerCase() > b.title.toLowerCase()) {
              return 1;
            }
            return 0;
          }),
        };

      case A.getBasins.type:
        return { ...state, stateId: action.payload };

      case A.getBasins.success:
        return { ...state, basins: action.payload };

      case A.getCounties.type:
        return { ...state, basinId: action.payload };

      case A.getCounties.success:
        return { ...state, counties: action.payload };

      case A.getLandingZones.type:
        return { ...state, countyId: action.payload };

      case A.getLandingZones.success:
        return { ...state, landingZones: action.payload };

      case A.getProperties.type:
        return { ...state, pending: true };

      case A.getProperties.success:
        return { ...state, propertiesList: action.payload, pending: false };

      case A.getProperties.failure:
        return { ...state, pending: false };

      case A.getStatuses.success:
        return { ...state, statuses: action.payload };

      case A.clearState.type:
        return initialState;

      default:
        return state;
    }
  },
};
