import React from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid, TextField } from '@material-ui/core';
import { StyledTextField } from 'components/inputs/styledTextField';

// styles
import styles from './styles';

const SignatureFormLayout = ({
  classes,
  children,
  onSignatureChange,
  newSignature,
  printedName,
  signatureDate,
  errors,
  hideFields,
  alreadySigned,
}) => {
  const [touched, setTouched] = React.useState({});
  const setTouchedField = (fieldName, newValue) => {
    setTouched(prev => ({
      ...prev,
      [fieldName]: newValue,
    }));
  };

  const signatureError = touched.signature && errors.signature;
  return (
    <div className={classes.inputContainer}>
      {!hideFields && (
        <Grid container spacing={9}>
          <Grid item xs={9}>
            <StyledTextField
              fullWidth
              key="signature"
              InputProps={{ style: styles.signature }}
              id="standard-basic"
              label="Signature"
              disabled={alreadySigned}
              variant="standard"
              value={alreadySigned ? printedName : newSignature}
              onChange={onSignatureChange}
              autoFocus
              onFocus={() => {
                setTouchedField('signature', false);
              }}
              onBlur={() => {
                setTouchedField('signature', true);
              }}
              error={!!signatureError}
              helperText={signatureError || '\u00a0'}
            />
          </Grid>
          <Grid item xs={3}>
            <div className={classes.dateContainer}>
              <TextField
                fullWidth
                id="standard-basic"
                type="date"
                disabled
                variant="standard"
                value={signatureDate}
              />
            </div>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={9}>
        {!hideFields && (
          <Grid item xs={9}>
            <TextField
              fullWidth
              disabled
              id="standard-basic"
              label="Printed Name & Title"
              variant="standard"
              value={printedName}
              onChange={onSignatureChange}
            />
          </Grid>
        )}
        {children}
      </Grid>
    </div>
  );
};

SignatureFormLayout.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.objectOf(PropTypes.any),
  onSignatureChange: PropTypes.func.isRequired,
  // TODO: [CONVENTIONS][REFACTOR] rename this field to just `signature` instead of `newSignature`
  newSignature: PropTypes.string.isRequired,
  printedName: PropTypes.string.isRequired,
  signatureDate: PropTypes.string.isRequired,
  hideFields: PropTypes.bool,
  alreadySigned: PropTypes.bool,
};

SignatureFormLayout.defaultProps = {
  children: undefined,
  hideFields: false,
  alreadySigned: false,
};

export default compose(connect(({ auth }) => ({
  initialValues: auth.user.company?.buyerAgreement,
})), withStyles(styles))(SignatureFormLayout);
