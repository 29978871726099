import {
  createFormValidator,
  minCharCount,
  PERSON_FULL_NAME_MIN_LENGTH,
  required,
  trimmed,
} from './validation-rules';

export const signatureDoesNotMatchPersonNameError = 'Signature does not match person\'s name';
export const signatureDoesNotMatch = printedName => signature => {
  let result;
  const toLowerCasePrintedName = printedName?.replace(/\b\w/g, l => l.toLowerCase());
  const toLowerCaseSignature = signature?.replace(/\b\w/g, l => l.toLowerCase());
  const signatureWords = toLowerCaseSignature?.trim().split(/\s+/);
  const printedNameWords = toLowerCasePrintedName?.trim().split(/\s+/);
  const notMatching = (
    signatureWords.length !== printedNameWords.length
    || printedNameWords.some((printedNameWord, i) => signatureWords[i] !== printedNameWord)
  );
  if (notMatching) {
    result = signatureDoesNotMatchPersonNameError;
  }
  return result;
};

/**
 * @type {import('./validation-rules').FieldValidators}
 */
export const getSignatureFormValidators = {
  signature: values => [
    required,
    trimmed,
    minCharCount(PERSON_FULL_NAME_MIN_LENGTH),
    signatureDoesNotMatch(values.printedName),
  ],
};

export const validateSignatureForm = createFormValidator(getSignatureFormValidators);
