export const ServerError = {
  Generic: 'Something went wrong',
  RefreshTokenNotFound: 'Refresh token not found',
  RefreshTokenExpired: 'Refresh token has expired',
  PasswordResetTokenExpired: 'Password reset token has expired',
  PasswordResetTokenNotExpired: 'Another password reset request is pending. Please check your email',
  PasswordResetTokenNotFound: 'Password reset token not found',
  NothingChanged: 'Nothing changed',
  NothingToChange: 'Nothing to change',
  UnexpectedMatchedEntitiesCount: 'The number of matched entities was different than expected',
};

export const parseServerError = data => {
  const result = {};
  let friendlyMessage;
  if (typeof data === 'object' && data !== null && !Array.isArray(data)) {
    const { message } = data;
    if (typeof message === 'string') {
      friendlyMessage = message;
      if (
        [
          ServerError.RefreshTokenNotFound,
          ServerError.RefreshTokenExpired,
        ].includes(message)
      ) {
        result.refreshTokenError = message;
      } else if (message === ServerError.PasswordResetTokenNotFound) {
        friendlyMessage = "It looks like this password reset link can't be used anymore, use the newer reset link.";
      }
    } else if (Array.isArray(message)) {
      friendlyMessage = 'Validation error';
      const errors = message;
      result.errors = errors;
      const firstError = errors[0];
      if (firstError) {
        if (typeof firstError === 'object' && firstError !== null && !Array.isArray(firstError)) {
          let field;
          let fieldFormatted;
          if (typeof firstError.field === 'string') {
            field = firstError.field;
            fieldFormatted = `\`${field}\``;
            friendlyMessage = `Error validating ${fieldFormatted}`;
          }
          if (typeof firstError.messages === 'object' && firstError.messages !== null) {
            const validationErrorsObject = firstError.messages;
            result.validationErrorsObject = validationErrorsObject;
            const firstErrorName = Object.keys(validationErrorsObject)[0];
            if (firstErrorName) {
              const fieldError = validationErrorsObject[firstErrorName];
              if (typeof fieldError === 'string' && fieldError) {
                if (
                  !fieldFormatted
                  || (
                    field === 'email'
                    && [
                      'isEmail',
                      'isEmailAlreadyExistConstraint',
                    ].includes(firstErrorName)
                  )
                ) {
                  friendlyMessage = fieldError;
                } else {
                  friendlyMessage = `${fieldFormatted}: ${fieldError}`;
                }
              }
            }
          } else if (Array.isArray(firstError.messages)) {
            const firstMessage = firstError.messages[0];
            if (typeof firstMessage === 'string' && firstMessage) {
              if (fieldFormatted) {
                friendlyMessage = `${fieldFormatted}: ${firstMessage}`;
              } else {
                friendlyMessage = firstMessage;
              }
            }
          }
        } else if (typeof firstError === 'string') {
          friendlyMessage = firstError;
        }
      }
    }
  } else if (typeof data === 'string') {
    friendlyMessage = data;
  }

  if (friendlyMessage === undefined) {
    friendlyMessage = ServerError.Generic;
    if (typeof data === 'object' && data !== null && data.error) {
      friendlyMessage = `${friendlyMessage}: ${data.error}`;
    } else if (typeof data === 'string') {
      friendlyMessage = `${friendlyMessage}: ${data}`;
    }
  }
  result.friendlyMessage = friendlyMessage;
  return result;
};
