export default {
  table: {
    marginLeft: -16,
    marginRight: -16,
  },
  tableCell: {
    padding: '16px',
  },
  button: {
    padding: 0,
  },
};
