import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import useSupercluster from 'use-supercluster';
import GoogleMapReact from 'google-map-react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import * as shapefile from '@mickeyjohn/shapefile';

import shape from 'assets/shape/shape.shp';

// components
import Header from 'components/Header';
import Footer from 'components/Footer';

// custom hooks
import useIsMobile from 'now-frontend-shared/hooks/useIsMobile';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import { getPropertiesMarkers } from 'store/actions/mapActions';
import styles from './styles';

// store
import InfoWindow from './components/InfoWindow';
import Marker from './components/Marker';
import { primaryDark } from 'themes/colors';

const Map = ({ classes, pins }) => {
  const [mapRef, setMapRef] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [infoWindowData, setInfoWindowData] = useState(null);
  const [mapScrollwheel, setMapScrollwheel] = useState(true);
  const [draggable, setDraggable] = useState(true);

  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  useEffect(() => {
    dispatch(getPropertiesMarkers());
    // eslint-disable-next-line
  }, []);

  const data = pins || [];
  const points = data.map(({ propertyId, longitude, latitude }) => ({
    type: 'Feature',
    properties: { cluster: false, propertyId },
    geometry: {
      type: 'Point',
      coordinates: [longitude, latitude],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 100 },
  });

  const getClusterProperties = useCallback(
    cluster => {
      const [longitude, latitude] = cluster.geometry.coordinates;

      if (cluster.id) {
        const clusterData = supercluster.getLeaves(cluster.id);
        const propertiesIdArray = clusterData.map(({ properties }) => properties.propertyId);
        setInfoWindowData({ id: cluster.id, properties: propertiesIdArray });
      } else {
        setInfoWindowData({ propertyId: cluster.properties.propertyId, properties: [cluster.properties.propertyId] });
      }

      mapRef.panTo({ lat: latitude, lng: longitude });
    },
    [supercluster, mapRef],
  );

  const handleToggleScrollwheel = useCallback(() => setMapScrollwheel(prevScrollwheel => !prevScrollwheel), [
    setMapScrollwheel,
  ]);

  const setMapDragging = useCallback(draggable => setDraggable(draggable), [setDraggable]);

  const handleCloseInfoWindow = useCallback(() => {
    setInfoWindowData(null);
    setMapScrollwheel(true);
    setMapDragging(true);
  }, [setInfoWindowData, setMapScrollwheel, setMapDragging]);

  const renderInfoWindow = ({ markerId, isCluster, pointCount }) => {
    if (isCluster && markerId === infoWindowData?.id) {
      return (
        <InfoWindow
          isCluster={isCluster}
          pointCount={pointCount}
          infoWindowData={infoWindowData}
          handleToggleScrollwheel={handleToggleScrollwheel}
          handleCloseInfoWindow={handleCloseInfoWindow}
        />
      );
    }

    if (!isCluster && markerId === infoWindowData?.propertyId) {
      return (
        <InfoWindow
          isCluster={isCluster}
          pointCount={pointCount}
          infoWindowData={infoWindowData}
          handleToggleScrollwheel={handleToggleScrollwheel}
          handleCloseInfoWindow={handleCloseInfoWindow}
        />
      );
    }
  };

  const handleApiLoaded = (map, maps) => {
    shapefile
      .openShp(shape)
      .then(source => source.read().then(function log(result) {
        if (result.done) return;
        const coords = [];
        result.value.coordinates.forEach(coordinates => coordinates.forEach(coordinate => {
          if (Array.isArray(coordinate[0])) {
            coordinate.forEach(coord => coords.push({ lng: coord[0], lat: coord[1] }));
          } else {
            coords.push({ lng: coordinate[0], lat: coordinate[1] });
          }
        }));

        const polygons = new maps.Polygon({
          paths: coords,
          strokeColor: primaryDark,
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: '#bfbfbf',
          fillOpacity: 0.5,
        });

        polygons.setMap(map);

        return source.read().then(log);
      }))
      .catch(error => console.error(error.stack));
  };

  const defaultZoomValue = useMemo(() => (isMobile ? 2 : 6), [isMobile]);

  return (
    <>
      <Header />

      <Grid container className={classes.wrapper}>
        {clusters && (
          <GoogleMapReact
            options={{
              scrollwheel: mapScrollwheel,
              disableDefaultUI: !isMobile,
              gestureHandling: !draggable && 'none',
            }}
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
            defaultCenter={{ lat: 39.77001, lng: -101.331774 }}
            zoom={defaultZoomValue}
            yesIWantToUseGoogleMapApiInternals
            onZoomAnimationStart={() => setInfoWindowData(null)}
            onChange={({ zoom, bounds }) => {
              setZoom(zoom);
              setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
            }}
            onGoogleApiLoaded={({ map, maps }) => {
              setMapRef(map);
              handleApiLoaded(map, maps);
            }}
          >
            {clusters.map((cluster, index) => {
              const [longitude, latitude] = cluster.geometry.coordinates;
              const { cluster: isCluster, point_count: pointCount } = cluster.properties;
              const handleClick = () => {
                getClusterProperties(cluster);
                if (isMobile) setMapDragging(false);
              };
              return (
                <Marker
                  key={index}
                  lat={latitude}
                  lng={longitude}
                  isCluster={isCluster}
                  pointCount={pointCount}
                  infoWindowData={infoWindowData}
                  handleClick={handleClick}
                >
                  {renderInfoWindow({
                    markerId: isCluster ? cluster.id : cluster.properties.propertyId,
                    isCluster,
                    pointCount,
                  })}
                </Marker>
              );
            })}
          </GoogleMapReact>
        )}
      </Grid>

      <Footer />
    </>
  );
};

Map.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  pins: PropTypes.arrayOf(
    PropTypes.shape({
      propertyId: PropTypes.number.isRequired,
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
    }),
  ),
};

export default compose(
  connect(({ map }) => ({
    pins: map.pins,
  })),
  withStyles(styles),
)(Map);
