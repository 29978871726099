import { getUserFullName } from 'now-shared/helpers/user-helpers';

/**
 * @typedef {Object} GetUserDisplayNameUser
 * @property {string=} firstName
 * @property {string=} lastName
 * @property {string=} email
 */

/**
 * @param {GetUserDisplayNameUser|null|undefined} user
 * @returns {string}
 */
export function getUserDisplayName(user) {
  return getUserFullName(user) || user.email || 'User';
}
