export default {
  wrapper: {
    height: '48px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  iconsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 18px',
  },
  topIcon: {
    transform: 'rotateZ(90deg)',
    transition: 'opacity 0.25s ease-in-out',
  },
  bottomIcon: {
    transform: 'rotateZ(-90deg)',
    transition: 'opacity 0.25s ease-in-out',
  },
  placeholder: {
    flex: 1,
    padding: '0 8px',
    color: '#828282',
  },
  disabled: {
    filter: 'grayscale(100)',
    opacity: 0.3,
    transition: 'opacity 0.25s ease-in-out',
  },
};
