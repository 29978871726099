export default {
  wrapper: {
    padding: '36px 0',
  },
  headingContainer: {
    marginBottom: '24px',
  },
  heading: {
    marginRight: '16px',
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 'bold',

    '@media screen and (max-width: 600px)': {
      fontSize: '20px',
      lineHeight: '23px',
    },
  },
  submitted: {
    padding: '3px 11px',
    fontSize: '10px',
    lineHeight: '12px',
    textAlign: 'center',
    fontWeight: 'normal',
    letterSpacing: '0.01em',
    textTransform: 'uppercase',
    color: '#fff',
    background: '#27AE60',
    borderRadius: '20px',
  },
};
