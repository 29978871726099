import {
  createFormValidator,
  maxCharCount,
  minCharCount,
  PERSON_FIRST_NAME_MAX_LENGTH,
  PERSON_LAST_NAME_MAX_LENGTH,
  phoneNumber,
  required,
  trimmed,
} from './validation-rules';

/**
 * @type {import('./validation-rules').FieldValidators}
 */
export const personalDetailsFieldValidators = {
  firstName: () => [
    required,
    trimmed,
    maxCharCount(PERSON_FIRST_NAME_MAX_LENGTH),
  ],
  lastName: () => [
    required,
    trimmed,
    maxCharCount(PERSON_LAST_NAME_MAX_LENGTH),
  ],
  department: () => [
    required,
    trimmed,
    // TODO: [REFACTOR][DRY] define this as a constant and use it to determine db column size
    minCharCount(2),
    // TODO: [REFACTOR][DRY][MIGRATION][CONSISTENCY] define this as a constant and use it to determine db column size
    // Currently the column size is 200 but validation limits the size to 50 characters.
    maxCharCount(50),
  ],
  jobTitle: () => [
    required,
    trimmed,
    // TODO: [REFACTOR][DRY] define this as a constant and use it to determine db column size
    minCharCount(2),
    // TODO: [REFACTOR][DRY] define this as a constant and use it to determine db column size
    maxCharCount(200),
  ],
  phoneNumber: () => [
    required,
    phoneNumber,
  ],
  isAccountManager: () => [
    required,
  ],
};

export const validatePersonalDetails = createFormValidator(personalDetailsFieldValidators);
