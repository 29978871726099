import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';

// helper functions
import { formatStringToTitleCase } from 'now-frontend-shared/utils/helpers';

// colors
import {
  green, grey, white, cyan, red, lightGreen, yellow,
} from 'now-frontend-shared/themes/colors';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';

const propsFromStatus = {
  submitted: {
    color: white,
    borderColor: green,
    backgroundColor: green,
  },
  pending: {
    color: white,
    borderColor: cyan,
    backgroundColor: cyan,
  },
  approved: {
    color: white,
    borderColor: lightGreen,
    backgroundColor: lightGreen,
  },
  sold: {
    color: white,
    borderColor: red,
    backgroundColor: red,
  },
  rejected: {
    color: red,
    borderColor: red,
    backgroundColor: white,
  },
  active: {
    color: white,
    borderColor: lightGreen,
    backgroundColor: lightGreen,
  },
  closed: {
    color: red,
    borderColor: red,
    backgroundColor: white,
  },
  'winner selected': {
    color: red,
    borderColor: red,
    backgroundColor: white,
  },
  archived: {
    color: white,
    borderColor: grey,
    backgroundColor: grey,
  },
  deleted: {
    color: white,
    borderColor: grey,
    backgroundColor: grey,
  },
  canceled: {
    color: red,
    borderColor: red,
    backgroundColor: white,
  },
  'bid placed': {
    color: white,
    borderColor: yellow,
    backgroundColor: yellow,
  },
};

const StatusBadge = ({ classes, small, status }) => {
  const formattedStatus = useMemo(() => formatStringToTitleCase(status), [status]);
  return (
    <span style={propsFromStatus[status]} className={cx(classes.badge, small && classes.small)}>
      {formattedStatus}
    </span>
  );
};

StatusBadge.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  small: PropTypes.bool,
  status: PropTypes.oneOf([
    'submitted',
    'pending',
    'approved',
    'rejected',
    'active',
    'closed',
    'winner selected',
    'winner',
    'sold',
    'deleted',
    'canceled',
    'archived',
    'bid placed',
  ]).isRequired,
};

export default compose(withStyles(styles), memo)(StatusBadge);
