import {
  all, call, fork, put, take, takeLatest,
} from 'redux-saga/effects';

// actions
import { getPreSignedUrls } from '../actions/businessFilesActions';

// api methods
import Api from 'api/businessFiles';

import { getAuthorizationHeader } from '../../auth/auth-helpers';
import queryString from 'query-string';

function* ensureGetPreSignedUrls({ payload }) {
  const {
    filesName,
    filesMd5,
    filesSize,
    resolve,
    reject,
  } = payload;
  try {
    const params = queryString.stringify({
      filesName,
      filesMd5,
      filesSize,
    }, { arrayFormat: 'bracket' });
    const { data } = yield call(Api.getPreSignedUrls, {
      url: `/get-s3-pre-signed-urls-for-company-uploads?${params}`,
      headers: { Authorization: getAuthorizationHeader() },
    });
    yield put({ type: getPreSignedUrls.success, payload: data });
    if (resolve) {
      resolve(data);
    }
  } catch (err) {
    yield put({ type: getPreSignedUrls.failure, err });
    if (reject) {
      reject(err);
    }
  }
}

function* watchGetPreSignedUrls() {
  yield takeLatest(getPreSignedUrls.type, ensureGetPreSignedUrls);
  yield take(getPreSignedUrls.success);
}

export default function* editListingSagas() {
  yield all([fork(watchGetPreSignedUrls)]);
}
