// actions
import * as A from '../actions/statesActions';

const initialState = {
  states: [],
  statesLoading: false,
  statesLoaded: false,
};

export const reducer = {
  states(state = initialState, action) {
    switch (action.type) {
      case A.getAllStates.type:
        return {
          ...state,
          statesLoading: true,
        };

      case A.getAllStates.success:
        return {
          ...state,
          states: action.payload.sort((a, b) => {
            if (a.title.toLowerCase() < b.title.toLowerCase()) {
              return -1;
            }
            if (a.title.toLowerCase() > b.title.toLowerCase()) {
              return 1;
            }
            return 0;
          }),
          statesLoading: false,
          statesLoaded: true,
        };

      case A.getAllStates.failure:
        return { ...state, statesLoading: false };

      case A.clearState.type:
        return initialState;

      default:
        return state;
    }
  },
};
