// actions
import * as A from '../actions/companiesActions';

const initialState = {
  companies: [],
  companiesLoading: false,
  companiesLoaded: false,
  allUsersInCompany: [],
  isLoadingAllUsersInCompany: false,
  isLoadedAllUsersInCompany: false,
  nonOpWellsSignatureDetails: {},
};

export const reducer = {
  companies(state = initialState, action) {
    switch (action.type) {
      case A.getAllCompanies.type:
        return {
          ...state,
          companiesLoading: true,
        };

      case A.getAllCompanies.success:
        return {
          ...state, companies: action.payload, companiesLoading: false, companiesLoaded: true,
        };

      case A.getAllCompanies.failure:
        return { ...state, companiesLoading: false };

      case A.findUsersByCompany.type:
        return {
          ...state,
          isLoadingAllUsersInCompany: true,
        };

      case A.findUsersByCompany.success:
        return {
          ...state,
          allUsersInCompany: action.payload,
          isLoadingAllUsersInCompany: false,
          isLoadedAllUsersInCompany: true,
        };

      case A.findUsersByCompany.failure:
        return {
          ...state,
          isLoadingAllUsersInCompany: false,
        };

      case A.getNonOpWellsSignatureDetails.type:
        return {
          ...state,
        };

      case A.getNonOpWellsSignatureDetails.success:
        return {
          ...state,
          nonOpWellsSignatureDetails: action.payload,
        };

      case A.getNonOpWellsSignatureDetails.failure:
        return {
          ...state,
        };

      case A.clearUsersInCompany.type:
        return { ...state, allUsersInCompany: [] };

      case A.clearState.type:
        return initialState;

      default:
        return state;
    }
  },
};
