import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import styles from './styles';
import { signSellerAgreement } from 'store/actions/companyActions';
import defaultNonPreFilledAgreementPdf from 'now-shared/assets/docs/seller-agreement.pdf';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import BottomSection from 'layouts/AuthSections/BottomSection';
import FormLayout from 'now-frontend-shared/layouts/FormLayout';
import DocumentViewLayout from 'now-frontend-shared/layouts/DocumentViewLayout';
import SignatureFormLayout from 'now-frontend-shared/layouts/SignatureFormLayout';
import { getAgreementSignatureAttachments } from 'now-frontend-shared/helpers/pdfAttachment';
import { getNonOpWellsSignatureDetails } from 'store/actions/companiesActions';
import IconSaveAlt from '@material-ui/icons/SaveAlt';

import {
  validateSignatureForm,
} from 'now-shared/validation/signature-validation';
import { formValidationHasErrors } from 'now-shared/validation/validation-rules';
import {
  getAgreementName,
  sellerAgreementName,
} from 'now-shared/helpers/company-helpers';
import { downloadFileAsFilename } from 'now-frontend-shared/utils/download-helpers';
import { navigateToNextPage, navigateToPreviousPage } from 'constants/registrationFlow';
import { getSignatureDateString, toDateString } from '../BuyersCertificateView';
import {
  doesAgreementHaveDocument,
  isCustomAgreement,
  isOldNonPreFilledAgreement,
} from 'now-shared/helpers/agreement-helpers';
import Spinner from 'now-frontend-shared/components/Spinner';
import { getSellerAgreementAttachmentOffsets } from 'now-frontend-shared/helpers/seller-agreement-pdf';

const agreementName = sellerAgreementName;

function SellerCertificateView({
  classes,
  authUserFullName,
  signer2Name,
  signer2Title,
  company,
  authUser,
  isSubmitting,
}) {
  const sellerAgreement = authUser.company?.sellerAgreement;

  const hasSavedAgreement = !!sellerAgreement;

  const savedAgreementIsMissingDocument = !!sellerAgreement && !doesAgreementHaveDocument(sellerAgreement);

  const hasAgreementFileStored = !!sellerAgreement?.document;

  const hasOldNonPreFilledAgreement = !!sellerAgreement && isOldNonPreFilledAgreement(sellerAgreement);

  const hasCustomAgreement = !!sellerAgreement && isCustomAgreement(sellerAgreement);

  const [signature, setSignature] = useState({
    // TODO: [BUG][INTEGRITY][UX] if anyone besides the original signer is viewing this page, this name and
    // signature below will not be correct.
    name: authUserFullName,
    date: getSignatureDateString(),
    signature: '',
  });

  useEffect(() => {
    setSignature(data => ({
      // TODO: [BUG][INTEGRITY][UX] if anyone besides the original signer is viewing this page, this name and
      // signature below will not be correct.
      ...data,
      date: toDateString(sellerAgreement?.wasSignedOnCreation ? sellerAgreement.createdAt : new Date()),
      // TODO: [BUG] This value should be initialized with the signature value that user did in the registration step,
      // not necessarily the same as the user's current full name.
      signature: sellerAgreement?.wasSignedOnCreation ? authUserFullName : '',
    }));
  }, [sellerAgreement, authUserFullName]);

  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => { dispatch(getNonOpWellsSignatureDetails()); }, []);

  const signatureChange = e => setSignature({ ...signature, signature: e.target.value });

  const newSignature = signature.signature;

  const printedName = signature.name;

  const signatureDate = signature.date;

  const errors = validateSignatureForm({ signature: newSignature, printedName });

  let documentDownloadUrl;
  let documentDisplayedUrl;
  if (hasAgreementFileStored) {
    documentDownloadUrl = sellerAgreement.document.downloadUrl;
  } else if (hasOldNonPreFilledAgreement || !savedAgreementIsMissingDocument) {
    documentDownloadUrl = defaultNonPreFilledAgreementPdf;
  }

  if (!documentDisplayedUrl) {
    documentDisplayedUrl = documentDownloadUrl;
  }

  const attachments = hasAgreementFileStored
    ? []
    : getAgreementSignatureAttachments({
      pageNumber: getSellerAgreementAttachmentOffsets.pageNumber,
      offsetLeft: getSellerAgreementAttachmentOffsets.offsetLeft,
      offsetTop: getSellerAgreementAttachmentOffsets.offsetTop,
      signer1Name: signature.signature,
      signer1Title: printedName,
      signer2Name,
      signer2Title,
    });

  return (
    <>
      <FormLayout>
        {(
          isSubmitting
        ) && (
          <Spinner backdrop />
        )}
        <DocumentViewLayout
          document={documentDownloadUrl}
          documentName={agreementName}
          attachments={attachments}
        >
          {documentDownloadUrl && (
            <div className={classes.downloadContainer}>
              <a
                onClick={e => {
                  e.preventDefault();
                  downloadFileAsFilename({
                    downloadUrl: documentDownloadUrl,
                    filename: getAgreementName({
                      baseName: agreementName,
                      isCustom: hasCustomAgreement,
                      withExtension: true,
                    }),
                  });
                }}
                href={documentDisplayedUrl}
                className={classes.download}
                download
              >
                {agreementName}
                <IconSaveAlt />
              </a>
            </div>
          )}
          <BottomSection>
            <SignatureFormLayout
              onSignatureChange={signatureChange}
              newSignature={newSignature}
              printedName={printedName}
              signatureDate={signatureDate}
              errors={errors}
              alreadySigned={sellerAgreement?.wasSignedOnCreation}
              hideFields={hasCustomAgreement || savedAgreementIsMissingDocument}
            >
              <Box
                display="flex"
                width="100%"
                justifyContent="center"
              >
                <Box
                  display="flex"
                  textAlign="center"
                  width="85%"
                  marginBottom={3}
                  fontWeight="bold"
                >
                  If you have any questions regarding the above agreement, click “SKIP” to temporarily
                  bypass and a NOW representative will reach out to discuss
                </Box>
              </Box>
              <div className={classes.buttonContainer}>
                <Button
                  variant="text"
                  className={classes.backButton}
                  onClick={() => history.push(navigateToPreviousPage(history.location.pathname, company))}
                >
                  BACK
                </Button>
                <div style={{ display: 'flex', gap: '20px' }}>
                  <Button
                    className={classes.button}
                    variant="outlined"
                    onClick={() => history.push(navigateToNextPage(history.location.pathname))}
                  >
                    Skip
                  </Button>
                  <Button
                    className={classes.button}
                    variant="outlined"
                    disabled={
                      !sellerAgreement
                      && (
                        formValidationHasErrors(errors)
                        || !signature.date
                        || !signer2Name
                        || !signer2Title
                        || isSubmitting
                        || savedAgreementIsMissingDocument
                      )
                  }
                    onClick={async () => {
                      if (!hasSavedAgreement) {
                        await new Promise((resolve, reject) => dispatch(signSellerAgreement({
                          signature: signature.signature,
                          resolve,
                          reject,
                        })));
                      }
                      history.push(navigateToNextPage(history.location.pathname));
                    }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </SignatureFormLayout>
          </BottomSection>
        </DocumentViewLayout>
      </FormLayout>
    </>
  );
}

SellerCertificateView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  company: PropTypes.objectOf(PropTypes.string).isRequired,
  authUserFullName: PropTypes.string.isRequired,
  signer2Name: PropTypes.string.isRequired,
  signer2Title: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

SellerCertificateView.defaultProps = {
};

export default compose(
  connect(({ auth, companies, company }) => ({
    company,
    fullLegalCompanyName: auth.user.company?.fullLegalCompanyName,
    authUserFullName: getUserFullName(auth.user),
    signer2Name: companies.nonOpWellsSignatureDetails?.name || '',
    signer2Title: companies.nonOpWellsSignatureDetails?.title || '',
    authUser: auth.user,
    isSubmitting: auth.isSubmittingAgreement,
  })),
  withStyles(styles),
)(SellerCertificateView);
