/**
 * WARNING: changing these existing values will likely require a database migration, as these types
 * are stored in the database and application logic depends on these exact value strings. Changing
 * these values means the existing documents stored with the old version of the value will no longer
 * be considered to have the same type.
 *
 * TODO: [REFACTOR] rename to CompanyDocumentType and move to `shared/enums`
 * TODO: [TYPE] add jsdoc enum info so this can be truly dealt with as an enum in TypeScript
 */
export const documentTypes = {
  CERTIFIED_ARTICLES_OF_INCORPORATION: 'certifiedArticlesOfIncorporation',
  AUTHORIZATION_FOR_ACCOUNT_MANAGER_ON_ENTITY:
    'authorizationForAccountManagerOnEntity',
  FORM_W9: 'formW9',
  OFFICER_CORPORATE_AGREEMENT: 'officerCorporateAgreement',
  BENEFICIAL_OWNER_FILES: 'beneficialOwnerFiles',
  ACCOUNT_MANAGER_VERIFICATION: 'accountManagerVerification',
};

export const documentTypeTitles = {
  [documentTypes.CERTIFIED_ARTICLES_OF_INCORPORATION]:
    'Certified Articles Of Incorporation',
  [documentTypes.AUTHORIZATION_FOR_ACCOUNT_MANAGER_ON_ENTITY]:
    'Authorization For Account Manager On Entity',
  [documentTypes.FORM_W9]: 'Form W9',
  [documentTypes.OFFICER_CORPORATE_AGREEMENT]: 'Officer Corporate Agreement',
  [documentTypes.BENEFICIAL_OWNER_FILES]: 'Beneficial Owner File',
  [documentTypes.ACCOUNT_MANAGER_VERIFICATION]: 'Account Manager Verification',
};
