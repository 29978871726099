import { desktopContentMaxWidthOld } from 'layouts/styles';

export default {
  wrapper: {
    position: 'relative',
    height: '100vh',
    overflow: 'hidden',
  },
  videoContainer: {
    position: 'absolute',
    overflow: 'hidden',
  },
  video: {
    minHeight: '100vh',
    minWidth: '100vw',
    'object-fit': 'cover',

    '@media screen and (max-width: 1100px)': {
      transform: 'translateX(20%)',
      minWidth: 'unset',
    },

    '@media screen and (max-width: 600px)': {
      minHeight: 'unset',
      height: '100vh',
      transform: 'translateX(18%)',
    },

    '@media (aspect-ratio: 256/135)': {
      width: '100vw',
    },
  },
  descriptionContainer: {
    maxWidth: desktopContentMaxWidthOld,
    width: '100%',
    padding: '50px 32px 170px 175px',
    marginTop: 'auto',

    fontWeight: 600,
    fontSize: '48px',
    lineHeight: '58px',
    color: 'black',
    textShadow:
      '-1px -1px 7px rgba(255, 255, 255, 0.7), 1px 1px 7px rgba(255, 255, 255, 0.7)',
    textAlign: 'right',

    zIndex: 2,

    '@media screen and (max-width: 1280px)': {
      fontSize: '44px',
      lineHeight: '54px',
      padding: '50px 32px 140px 175px',
    },

    '@media screen and (max-width: 1024px)': {
      fontSize: '40px',
      lineHeight: '50px',
      padding: '50px 32px 60px 175px',
    },

    '@media screen and (max-width: 960px)': {
      fontSize: '36px',
      lineHeight: '42px',
    },

    '@media screen and (max-width: 600px)': {
      padding: '0 15px 120px',
      fontSize: '28px',
      lineHeight: '36px',
      fontWeight: 400,
    },

    '@media screen and (max-width: 360px)': {
      padding: '0 15px 20px',
      fontSize: '26px',
      lineHeight: '30px',
    },

    '@media screen and (max-width: 320px)': {
      padding: '0 15px 20px',
      fontSize: '23px',
      lineHeight: '30px',
    },
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
};
