import { asyncAction, action } from 'now-frontend-shared/utils/actions';

// async actions
export const getStates = asyncAction('properties/GET_STATES');
export const getBasins = asyncAction('properties/GET_BASINS');
export const getCounties = asyncAction('properties/GET_COUNTIES');
export const getLandingZones = asyncAction('properties/GET_LANDING_ZONES');
export const getProperties = asyncAction('properties/GET_PROPERTIES');
export const getStatuses = asyncAction('properties/GET_STATUSES');

// sync actions
export const clearState = action('properties/CLEAR_STATE');
