import { primaryDark } from 'themes/colors';

export default {
  buttonContainer: {
    '@media screen and (max-width: 960px)': {
      justifyContent: 'space-around',
    },
  },
  button: {
    padding: '60px 0',
    marginRight: '40px',

    '@media screen and (max-width: 960px)': {
      marginRight: 0,
    },

    '@media screen and (max-width: 600px)': {
      padding: 0,
    },
  },
  infoContainer: {
    padding: '40px 45px 0',

    '@media screen and (max-width: 600px)': {
      padding: '25px 0 0',
    },
  },
  heading: {
    marginBottom: '10px',
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 'bold',
    color: primaryDark,

    '@media screen and (max-width: 600px)': {
      fontSize: '20px',
      lineHeight: '23px',
    },
  },
  description: {
    padding: '10px 0',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'normal',
    letterSpacing: '0.003em',
    color: '#615656',
  },
};
