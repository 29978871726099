import { desktopContentMaxWidth } from 'layouts/styles';

export default {
  wrapperNew: {
    maxWidth: desktopContentMaxWidth,
    margin: '0 auto',
    flex: 1,
  },
  wrapperNewFullWidth: {
    flex: 1,
  },
};
