import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styled component from material-ui
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';

const FormLayout = ({
  classes,
  children,
  onSubmit,
  ...rest
}) => (
  <Grid
    component="form"
    container
    className={classes.wrapper}
    onSubmit={onSubmit}
    {...rest}
  >
    {children}
  </Grid>
);

FormLayout.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), memo)(FormLayout);
