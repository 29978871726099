import { primaryDark } from 'themes/colors';

export default {
  termsCheckbox: {
    padding: '2px 16px 0 0',
  },
  buttonContainer: {
    '@media screen and (max-width: 600px)': {
      justifyContent: 'center',
    },
  },
  button: {
    marginBottom: '40px',

    '@media screen and (max-width: 600px)': {
      marginBottom: '25px',
    },
  },
  switchContainer: {
    padding: '20px 20px 0',

    '@media screen and (max-width: 600px)': {
      padding: '20px 0',
    },
  },
  checkboxContainer: {
    maxWidth: '476px',
    width: '100%',
    margin: '20px 65px 15px 65px',
    padding: '15px 15px 15px 25px',
    border: '1px  solid #E9E9E9',
    borderRadius: '4px',

    '@media screen and (max-width: 600px)': {
      maxWidth: '100%',
      margin: 'unset',
    },
  },
  selectAll: {
    marginLeft: '-10px',
  },
  checkboxes: {
    height: '120px',
    width: '100%',
    overflow: 'auto',

    /* webkit browsers scrollbar */
    '&::-webkit-scrollbar': {
      width: '3px',
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: primaryDark,
      borderRadius: '4px',
    },

    /* firefox scrollbar */
    scrollbarWidth: 'thin',
    scrollbarColor: '#8F8F8F #fff',

    '@media screen and (max-width: 600px)': {
      height: '135px',
    },
  },
  text: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 'normal',
    color: '#333333',
  },
};
