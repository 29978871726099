import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// background video
import mp4video from 'assets/videos/video.mp4';
import ogvvideo from 'assets/videos/video.ogv';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

const TopSection = ({ classes }) => (
  <>
    <Grid container direction="column" justify="center" alignItems="center" className={classes.wrapper}>
      <Grid container justify="center" className={classes.videoContainer}>
        <video preload="auto" autoPlay muted loop playsInline className={classes.video}>
          <source src={mp4video} type="video/mp4" />
          <source src={ogvvideo} type="video/ogv" />
        </video>
      </Grid>

      <Grid container direction="column" className={classes.descriptionContainer}>
        <span>
          <span className={classes.noWrap}>A marketplace for</span>
          <br />
          {' '}
          <span className={classes.noWrap}>wellbore interests</span>
        </span>
      </Grid>

    </Grid>
  </>
);

TopSection.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), memo)(TopSection);
