export default {
  root: {
    padding: '86px 0',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '34px',
    textAlign: 'center',
    letterSpacing: '0.25px',
    color: '#171721',
    marginBottom: '10px',
  },
  buttonContainer: {
    marginTop: '20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    backgroundColor: '#7890A6',
    color: '#ffffff',
  },
  backButton: {
    color: '#7890A6',
  },
  subTitle: {
    fontWeight: 'normal',
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: '#4B6377',
  },
  dropZoneContainer: {
    marginTop: '35px',
    width: '100%',
  },
  complete: {
    color: '#008000',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '40px 0',
  },
};
