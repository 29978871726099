import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

export const minSpaceBetweenStatCards = 100;
export const minSpaceBetweenStatCardsHalf = minSpaceBetweenStatCards / 2;

export const HighLevelStatCard = props => {
  const {
    title,
    value,
  } = props;

  const theme = useTheme();

  const fontColor = theme.palette.primary.dark;

  return (
    <Card
      elevation={1}
      style={{
        backgroundColor: theme.palette.primary.contrastText,
        color: fontColor,
        width: 300,
        height: 100,
        borderRadius: 30,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: minSpaceBetweenStatCardsHalf,
        marginRight: minSpaceBetweenStatCardsHalf,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        style={{
          margin: 0,
          textAlign: 'center',
          padding: 8,
        }}
      >
        <Typography
          variant="h6"
        >
          {title}
        </Typography>
        <Typography
          variant="h6"
        >
          {value}
        </Typography>
      </Box>
    </Card>
  );
};

HighLevelStatCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
