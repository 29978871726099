// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { InputBase } from '@material-ui/core';
import { primaryDark } from '../../../themes/colors';

const SelectInput = withStyles({
  root: {
    width: '100%',
    '& .MuiSelect-select:focus': {
      background: 'none',
    },
  },
  input: {
    lineHeight: '20px',
    fontWeight: '500',
    fontSize: '14px',

    padding: '9px 16px',
    margin: 0,

    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',

    transition: 'all 0.25s ease-in-out',

    '&:focus': {
      padding: '8px 16px',
      border: `2px solid ${primaryDark}`,
      borderRadius: '4px',
    },
    disabled: {
      transition: 'border 0.25s ease-in-out',
      border: '1px solid rgba(0,0,0,0.1)',

      '&:hover': {
        border: '1px solid rgba(0, 0, 0, 1)',
      },
    },
  },
})(InputBase);

export default SelectInput;
