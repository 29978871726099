import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';

import styles from './styles';
import { updateCompany } from 'store/actions/companyActions';
import {
  OptionBestSuitsEntity1, OptionBestSuitsEntity2, OptionBestSuitsEntity1Label, OptionBestSuitsEntity2Label,
} from 'now-shared/enums/company-entities';
import { navigateToNextPage, navigateToPreviousPage } from 'constants/registrationFlow';

function DescriptionView({ classes, company }) {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ margin: 'auto', maxWidth: '890px' }}
      >
        <Typography variant="h1" component="div" className={classes.title}>
          Entity Description
        </Typography>
        <div className={classes.questionContainer}>
          <Typography
            variant="body1"
            component="div"
            className={classes.textTitle}
          >
            Select option that best suits your entity
          </Typography>
          <RadioGroup
            aria-label="businessType"
            name="row-radio-buttons-group"
            value={company.optionBestSuitsEntity1 || company.optionBestSuitsEntity2}
            onChange={event => {
              const isOption1 = event.target.value === OptionBestSuitsEntity1.CorporateOrLLCOrLessThan50Mil;
              dispatch(
                updateCompany({
                  value: null,
                  key: isOption1 ? 'optionBestSuitsEntity2' : 'optionBestSuitsEntity1',
                }),
              );
              dispatch(
                updateCompany({
                  value: event.target.value,
                  key: isOption1 ? 'optionBestSuitsEntity1' : 'optionBestSuitsEntity2',
                }),
              );
            }}
            required
          >
            <FormControlLabel
              className={classes.radio}
              value={OptionBestSuitsEntity2.OtherPerson}
              control={<Radio color="default" />}
              label={OptionBestSuitsEntity2Label[OptionBestSuitsEntity2.OtherPerson]}
            />
            <FormControlLabel
              className={classes.radio}
              value={OptionBestSuitsEntity1.CorporateOrLLCOrLessThan50Mil}
              control={<Radio color="default" />}
              label={OptionBestSuitsEntity1Label[OptionBestSuitsEntity1.CorporateOrLLCOrLessThan50Mil]}
            />
            <FormControlLabel
              className={classes.radio}
              value={OptionBestSuitsEntity2.BankAssociationInvestmentCompany}
              control={<Radio color="default" />}
              label={OptionBestSuitsEntity2Label[OptionBestSuitsEntity2.BankAssociationInvestmentCompany]}
            />
            <FormControlLabel
              className={classes.radio}
              value={OptionBestSuitsEntity2.InvestmentAdvisor}
              control={<Radio color="default" />}
              label={OptionBestSuitsEntity2Label[OptionBestSuitsEntity2.InvestmentAdvisor]}
            />
          </RadioGroup>
        </div>
        <div className={classes.optionContainer}>
          <Button
            onClick={() => history.push(navigateToPreviousPage(history.location.pathname))}
            className={classes.backButton}
            variant="text"
          >
            BACK
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            disabled={!company.optionBestSuitsEntity1 && !company.optionBestSuitsEntity2}
            onClick={() => history.push(navigateToNextPage(history.location.pathname))}
          >
            Next
          </Button>
        </div>
      </Grid>
    </div>
  );
}

DescriptionView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  company: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(
  connect(({ company }) => ({
    company,
  })),
  withStyles(styles),
)(DescriptionView);
