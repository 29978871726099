import { desktopContentMaxWidthOld } from 'layouts/styles';
import { primaryDark, white, red } from '../../themes/colors';

export default {
  wrapper: {
    flex: 1,
  },
  bottomWellContainer: {
    maxWidth: '33%',
    flexBasis: '33%',
  },
  container: {
    height: '100%',
  },
  topSection: {
    backgroundColor: primaryDark,
    color: white,
  },
  topSectionContent: {
    maxWidth: desktopContentMaxWidthOld,
    padding: '24px 40px 24px 16px',

    '@media screen and (max-width: 960px)': {
      padding: '20px',
    },
  },
  heading: {
    flex: 1,
    fontSize: '32px',
    lineHeight: '37px',
    fontWeight: 'bold',

    '@media screen and (max-width: 960px)': {
      padding: '12px 0',
      fontSize: '28px',
      lineHeight: '32px',
    },
  },
  wellsCount: {
    color: '#b4b4b4',
  },
  id: {
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  idNumber: {
    fontWeight: 'normal',
  },
  icon: {
    width: '32px',
    height: '32px',
    margin: '2px 28px 0 16px',
    cursor: 'pointer',
    userSelect: 'none',

    '@media screen and (max-width: 960px)': {
      margin: 'unset',
    },
  },
  topSectionContainer: {
    padding: '16px 0',

    '@media screen and (max-width: 960px)': {
      padding: 'unset',
    },
  },
  topSectionHeading: {
    fontSize: '20px',
    lineHeight: '23px',
    fontWeight: 'normal',

    '@media screen and (max-width: 1280px)': {
      marginRight: '16px',
    },

    '@media screen and (max-width: 960px)': {
      flexDirection: 'row',
      alignItems: 'center',
      padding: '12px 0',
    },
  },
  topHeading: {
    '@media screen and (max-width: 960px)': {
      marginRight: '8px',
    },
  },
  topSectionDescription: {
    marginTop: '8px',
    fontWeight: 'bold',

    '@media screen and (max-width: 960px)': {
      marginTop: 'unset',
    },
  },
  bottomSectionContent: {
    maxWidth: desktopContentMaxWidthOld,
    padding: '42px 92px',

    '@media screen and (max-width: 960px)': {
      padding: '20px',
    },
  },
  bottomContentContainer: {
    padding: '0px 0px',
  },
  bottomSectionHeading: {
    padding: '20px 8px 20px 0',
    fontSize: '20px',
    lineHeight: '20px',
    fontWeight: 'normal',
    color: '#615656',
    '@media screen and (max-width: 960px)': {
      padding: '12px 0',
    },
  },
  bottomSectionDescription: {
    marginTop: '8px',
    fontWeight: 'bold',
    lineHeight: '20px',
  },
  contentHeading: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 'bold',
    color: '#171721',
  },
  blueHeadingClone: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 'bold',
  },
  redText: {
    color: red,
  },
  wellAttrTitle: {
    lineHeight: '20px',
    marginBottom: '8px',
  },
  wellAttrValue: {
    lineHeight: '20px',
  },
  sellerBidStatus: {
    maxWidth: '130px',
    marginTop: '10px',
    padding: '5px 8px',
    border: `0.5px solid ${white}`,
    borderRadius: '32px',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 'normal',
    color: white,
    textAlign: 'center',
  },
  status: {
    marginTop: '8px',
  },
  bidContainer: {
    padding: '32px 24px',
    border: '1px solid #EFEFEF',
    borderRadius: '8px',
    backgroundColor: '#F8F8F8',

    '@media screen and (max-width: 1280px)': {
      margin: '20px 0',
    },

    '@media screen and (max-width: 960px)': {
      margin: '12px 0',
    },

    '@media screen and (max-width: 600px)': {
      alignItems: 'center',
      padding: '32px 20px',
    },
  },
  bidHeading: {
    marginBottom: '16px',
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 'normal',
    color: '#000000',

    '@media screen and (max-width: 600px)': {
      fontSize: '22px',
    },
  },
  agreementsMessageWrapper: {
    backgroundColor: '#EFEFEF',
  },
  agreementsMessageContent: {
    maxWidth: desktopContentMaxWidthOld,
    padding: '48px 92px',

    '@media screen and (max-width: 960px)': {
      padding: '48px 20px',
    },
  },

  // TODO: [CLEANUP] Delete these classes once the code that uses them is deleted
  agreementsMessage: {
    padding: '8px 0',
    fontSize: '20px',
    lineHeight: '23px',
    fontWeight: 'normal',
    color: primaryDark,

    '@media screen and (max-width: 960px)': {
      fontSize: '19px',
    },
  },
  agreementsLink: {
    textTransform: 'none',
    fontWeight: 'bold',
    color: primaryDark,
  },
  separation: {
    marginRight: 'auto',
  },
  arrow: {
    '@media screen and (max-width: 960px)': {
      width: '100%',
    },
  },
  topInfoContainer: {
    '@media screen and (max-width: 960px)': {
      flexDirection: 'column',
      alignItems: 'unset',
    },
  },
  idContainer: {
    '@media screen and (max-width: 960px)': {
      justifyContent: 'unset',
      padding: '12px 0',
    },
  },
  buttonContainer: {
    '@media screen and (max-width: 960px)': {
      justifyContent: 'unset',
    },
  },
  descriptionContainer: {
    '@media screen and (max-width: 960px)': {
      flexDirection: 'column',
      alignItems: 'unset',
    },
  },
  bidStatValue: {
    marginTop: '8px',
    fontWeight: 'bold',
  },
};
