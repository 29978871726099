import {
  createFormValidator,
  hasValue,
  socialSecurityNumber,
} from './validation-rules';

/**
 * @type {import('./validation-rules').FieldValidators}
 */
export const beneficialOwnerDetailsFieldValidators = {
  BOSSNorTin: values => [
    ...hasValue(values.BOSSNorTin) ? [
      socialSecurityNumber,
    ] : [],
  ],
};

export const validateBeneficialOwnerDetails = createFormValidator(beneficialOwnerDetailsFieldValidators);
