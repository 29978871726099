import { primaryMain } from '../../themes/colors';

export default {
  wrapper: {
    padding: '120px 0 30px 0',

    '@media screen and (max-width: 1280px)': {
      padding: '30px 0',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '32px',
    padding: '0 10px',
    backgroundColor: '#F0F0F0',
    borderRadius: '100px',

    '@media screen and (max-width: 600px)': {
      maxWidth: '280px',
    },

    '@media screen and (max-width: 380px)': {
      maxWidth: '200px',
    },
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '42px',
    height: '100%',
    color: '#BDBDBD',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 'normal',
    border: 'unset',
    color: '#BDBDBD',
    cursor: 'pointer',
    transition: 'backgroundColor 0.7s ease-in-out',
  },
  active: {
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: primaryMain,
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(59, 117, 245, 0.2)',
    height: '40px',
    transition: 'backgroundColor 0.7s ease-in-out',
  },
};
