import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

const BusinessContinuityDisclosure = ({ classes }) => (
  <Grid container justify="center">
    <Grid container alignItems="center" justify="center" className={classes.heading}>
      <span>Business Continuity Disclosure</span>
    </Grid>

    <Grid container item xs={12} sm={12} md={8} lg={8} justify="left" className={classes.textContainer}>
      <span className={classes.text}>
        NonOpWells is committed to providing continuous service and support; however, we recognize that there are potential risks that could disrupt our ability to serve you. We are confident that we have taken the necessary steps that will allow us to reduce or eliminate the impact of a business disruption.
      </span>

      <span className={classes.text}>
        NonOpWells recognizes the responsibility we have to our users. We have implemented a business continuity management program with a strong governance model and commitment from senior management. Our continuity program's primary objectives are to meet the needs of our users, maintain the wellbeing and safety of our employees and meet our regulatory obligations. The planning process is risk based and involves the understanding and prioritization of critical operations across the firm and platform, the anticipation of probable threats, and the proactive development of strategies to mitigate the impact of those events.
      </span>

      <span className={classes.text}>
        NonOpWells has created this continuity plan to respond to disasters and disruptions which could affect either the business or technological operations of the company. Detailed response plans have been developed and cross-discipline teams have been trained to address both day-to-day disruptions as well as these specific events.
      </span>

      <span className={classes.text}>
        NonOpWells has developed the capabilities to recover both operations and systems. All continuity plans are designed to account for disruptions of various lengths and scopes, and to ensure that critical functions are recovered to meet their business objectives. Critical business groups operate from multiple sites. Dedicated teams within our technology organization ensure that critical applications and data have sufficient redundancy and availability to minimize the impact of an event. Key components of NonOpWells' continuity and technology recovery planning include:
      </span>

      <span className={classes.text}>
        <li className={classes.list}>Alternate physical locations and preparedness</li>
        <li className={classes.list}>Alternative means to communicate with our users</li>
        <li className={classes.list}>Back-up telecommunications and systems</li>
        <li className={classes.list}>Employee safety programs</li>
      </span>

      <span className={classes.text}>
        Plans are tested regularly to ensure they are effective should an actual event occur. NonOpWells’ Business Continuity Plan is reviewed no less than annually to ensure the appropriate updates are made to account for operations, technology and regulatory changes. To obtain a copy of this notice at any time,
        {' '}
        <a href="/contact-us">contact us </a>
        {' '}
        or call 832-900-3055.
      </span>
    </Grid>
  </Grid>
);

BusinessContinuityDisclosure.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), memo)(BusinessContinuityDisclosure);
