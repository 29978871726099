import {
  put,
  call,
  select,
  take,
} from 'redux-saga/effects';
import queryString from 'query-string';

import Api from 'api/auth';
import * as storage from 'now-frontend-shared/utils/storage';
import * as A from '../actions/authActions';
import { setUserNotificationsData } from '../actions/settingsActions';
import { apiAuthTokenQueryParamName } from 'now-shared/helpers/auth-helpers';
import { persistToken } from './mainPageSagas';
import { replace } from 'connected-react-router';

export function* getUserDataFromAccessToken({ accessToken }) {
  const {
    data: {
      confirmingBidsEmails,
      outBidsEmails,
      closesAuctionEmails,
      newListingsEmails,
      basins,
      ...userData
    },
  } = yield call(Api.getUserData, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  yield put(A.setUserData(userData));
  yield put(
    setUserNotificationsData({
      notificationsSwitches: {
        confirmingBidsEmails,
        outBidsEmails,
        closesAuctionEmails,
        newListingsEmails,
      },
      basins,
    }),
  );
  yield put(A.setIsAuthorized(true));
}

export function* checkAuth() {
  const location = yield select(({ router }) => router.location);
  const { query, search, ...locationRest } = location;
  const queryAccessToken = query[apiAuthTokenQueryParamName];
  let accessToken;
  if (queryAccessToken) {
    accessToken = queryAccessToken;
    yield put(A.removeUserData({ reset: true }));
    yield take(A.setUserDataRemoved.type);
    yield call(persistToken, { accessToken });
  } else {
    accessToken = JSON.parse(storage.getStorageItem('accessToken', '""'));
  }
  const refreshToken = JSON.parse(storage.getStorageItem('refreshToken', '""'));
  if (accessToken || refreshToken) {
    try {
      yield call(getUserDataFromAccessToken, { accessToken });
    } catch {
      yield put(A.removeUserData({ tokensHasExpired: true }));
    }
  } else {
    yield put(A.setIsAuthorized(false));
  }
  if (queryAccessToken) {
    // remove query access token from address bar
    const newQuery = queryString.parse(search);
    delete newQuery[apiAuthTokenQueryParamName];
    const newSearch = queryString.stringify(newQuery);
    const newLocation = {
      ...locationRest,
      search: newSearch ? `?${newSearch}` : newSearch,
    };
    yield put(replace(newLocation));
  }
}
