import { asyncAction, action } from 'now-frontend-shared/utils/actions';

// async actions
export const getStates = asyncAction('createProperty/GET_STATES');
export const getBasins = asyncAction('createProperty/GET_BASINS');
export const getCounties = asyncAction('createProperty/GET_COUNTIES');
export const getLandingZones = asyncAction('createProperty/GET_LANDING_ZONES');
export const createProperty = asyncAction('createProperty/CREATE_PROPERTY');
export const getPreSignedUrls = asyncAction('createProperty/GET_PRE_SIGNED_URLS');

// sync action

export const setBasinId = action('createProperty/SET_BASIN_ID');
export const setStateId = action('createProperty/SET_STATE_ID');
export const setAWSData = action('createProperty/SET_AWS_DATA');
export const removeAWSDataFile = action('createProperty/REMOVE_AWS_DATA_FILE');
export const clearAWSData = action('createProperty/CLEAR_AWS_DATA');
export const setUnloadedFilesExist = action('createProperty/SET_UNLOADED_FILES_EXIST');
export const clearState = action('createProperty/CLEAR_STATE');
