import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';
// TODO: [CLARIFY] rename to `PdfSignatureNameOrTitleField` and move to `components/pdf/signature`
const PdfNameAndTitleWrapper = ({
  children, classes, left, style, top,
}) => (
  <div
    className={classes.container}
    style={{
      top,
      left,
      ...style,
    }}
  >
    {children}
  </div>
);

PdfNameAndTitleWrapper.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  left: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  top: PropTypes.number.isRequired,
};

export default compose(withStyles(styles), memo)(PdfNameAndTitleWrapper);
