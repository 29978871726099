import { useMemo } from 'react';

import useWindowWidth from './useWindowWidth';

function useIsWindowGreaterThanWidth(maxWidth) {
  const width = useWindowWidth();

  return useMemo(() => width > maxWidth, [width, maxWidth]);
}

export default useIsWindowGreaterThanWidth;
