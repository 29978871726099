export default {
  text: {
    padding: '5px 0',
    fontSize: '20px',
    lineHeight: '23px',
    fontWeight: 'normal',
    color: '#615656',

    '@media screen and (max-width: 600px)': {
      fontSize: '18px',
      lineHeight: '22px',
    },
  },
  link: {
    textDecoration: 'underline',
  },
};
