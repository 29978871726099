/**
 * @readonly
 * @enum {'true'|'false'}
 */
export const BooleanString = {
  /**
   * @readonly
   * @type {'true'}
   */
  True: 'true',
  /**
   * @readonly
   * @type {'false'}
   */
  False: 'false',
};

/**
 * @readonly
 */
export const BooleanStringTitle = {
  /**
   * @readonly
   */
  [BooleanString.True]: 'Yes',
  /**
   * @readonly
   */
  [BooleanString.False]: 'No',
};
