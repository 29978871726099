export default {
  wrapper: {
    margin: '5px 0',

    '@media screen and (max-width: 600px)': {
      alignItems: 'flex-start',
    },
  },
  label: {
    paddingLeft: '10px',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'normal',
    color: '#333333',
    userSelect: 'none',

    '@media screen and (max-width: 600px)': {
      maxWidth: '80%',
    },
  },
};
