import React, { memo, useEffect } from 'react';
import { Field, reduxForm, reset } from 'redux-form';
import { useDispatch, connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import PropTypes from 'prop-types';
import { compose } from 'redux';

// layouts
import LabelLayout from 'now-frontend-shared/components/inputs/layouts/LabelLayout';
import FormLayout from 'now-frontend-shared/layouts/FormLayout';

// modals
import SuccessModal from 'now-frontend-shared/components/modals/SuccessModal';

// components
import Button from 'now-frontend-shared/components/Button';
import RequiredDescription from 'now-frontend-shared/components/inputs/RequiredDescription';

// constants
import { contactUsFields } from './constants';

// validations
import { contactUsValidate } from 'utils/validation/validations';

// custom hooks
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';
import { warnAboutUnsavedForm } from 'now-frontend-shared/hoc/warnAboutUnsavedForm';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

// store
import { clearModalFlags, sendContactUs } from 'store/actions/authActions';

import { returnToPreviousPage } from 'now-frontend-shared/layouts/AuthSections/HeadSection';

// helpers
import { getUserFullName } from 'now-shared/helpers/user-helpers';

const formName = 'contactUs';
const recaptchaSiteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

const ContactUs = ({ classes, handleSubmit, contactUsSended }) => {
  const { setModal } = useModalSetter();
  const [captcha, setCaptcha] = React.useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (contactUsSended) {
      setModal(
        <SuccessModal label="Your request has been sent successfully!" />,
        () => returnToPreviousPage(location, history),
      );
      dispatch(clearModalFlags());
    }
  }, [dispatch, contactUsSended, setModal, history, location]);

  const onHandleSubmit = async data => {
    await new Promise((resolve, reject) => dispatch(sendContactUs({ data, resolve, reject })));
    dispatch(reset('contactUs'));
  };

  return (
    <FormLayout onSubmit={handleSubmit(onHandleSubmit)}>
      <Grid container justify="center">
        <Grid container alignItems="center" justify="center" className={classes.heading}>
          <span>Contact Us</span>
        </Grid>

        <Grid container item xs={12} sm={12} md={8} lg={8} justify="center">
          <Grid container justify="center" className={classes.container}>
            <span className={classes.text}>We're here to help and answer any question you might have.</span>
            <span className={classes.text}>We look forward to hearing from you.</span>
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={12} md={7} lg={5} direction="column">
          <Grid container>
            {contactUsFields.map(({ name, layoutProps, inputProps }) => (
              <LabelLayout key={name} name={name} {...layoutProps}>
                <Field name={name} {...inputProps} />
              </LabelLayout>
            ))}
          </Grid>

          <Grid container justifyContent="center" className={classes.wrapper}>
            <RequiredDescription />
          </Grid>

          <Grid container className={classes.buttonContainer}>
            <Grid item xs={12} sm={12} md={5} lg={5} />
            <Grid item>
              <ReCAPTCHA
                style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
                sitekey={recaptchaSiteKey}
                onChange={value => setCaptcha(value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} />
            <Grid item>
              <Button type="submit" label="Submit" buttonColor="green" disabled={!captcha} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormLayout>
  );
};

ContactUs.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func,
  contactUsSended: PropTypes.bool,
};

export default compose(
  connect(({ auth }) => ({
    contactUsSended: auth.authInfo.contactUsSended,
    initialValues: {
      name: getUserFullName(auth.user),
      email: auth.user.email,
      phoneNumber: auth.user.phoneNumber,
    },
  })),
  reduxForm({ form: formName, validate: contactUsValidate, enableReinitialize: true }),
  warnAboutUnsavedForm,
  withStyles(styles),
  memo,
)(ContactUs);
