// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { InputBase } from '@material-ui/core';
import { primaryDark } from '../../../themes/colors';

const ControlledSelectInput = withStyles({
  root: {
    width: '100%',
  },
  input: {
    display: 'flex',
    alignItems: 'center',

    height: '46px',

    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 'normal',

    padding: '0 16px',
    margin: 0,

    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',

    '&:focus': {
      height: '44px',
      border: `2px solid ${primaryDark}`,
      borderRadius: '4px',
    },
    disabled: {
      transition: 'border 0.25s ease-in-out',
      border: '1px solid rgba(0,0,0,0.1)',

      '&:hover': {
        border: '1px solid rgba(0, 0, 0, 1)',
      },
    },
  },
})(InputBase);

export default ControlledSelectInput;
