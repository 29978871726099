import React, { useState } from 'react';
import { reduxForm } from 'redux-form';
import { useDispatch, connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import PropTypes from 'prop-types';
import { compose } from 'redux';

// layouts
import HeadSection from 'now-frontend-shared/layouts/AuthSections/HeadSection';

// components
import AmountInput from 'now-frontend-shared/components/inputs/AmountInput';
import PhoneInput from 'now-frontend-shared/components/inputs/PhoneInput';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { warnAboutUnsavedForm } from 'now-frontend-shared/hoc/warnAboutUnsavedForm';
import { StyledTextField } from 'now-frontend-shared/components/inputs/StyledTextField/styledTextField';

// validations
import { bankInformationValidate } from 'utils/validation/validations';
import { generalRules } from 'now-shared/validation/validation-rules';
import validateBidAllowance from 'now-frontend-shared/validation/validateBidAllowance';

// helpers
import { formattedCurrencyToNumberString, formattedPhoneToNumberString } from 'now-frontend-shared/utils/helpers';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles

// store
import { sendBankInformation, updateBankInformation } from 'store/actions/authActions';
import styles from './styles';

const formName = 'bankInformation';

const BankInformation = ({
  classes, handleSubmit, bankInformation,
}) => {
  const dispatch = useDispatch();
  const isEditable = !bankInformation;
  const isPending = ['pending'].includes(bankInformation?.status.title);
  const isRejected = ['rejected'].includes(bankInformation?.status.title);

  const [financial, setFinancial] = useState({
    bankName: '',
    bankerName: '',
    bankerEmail: '',
    bankerPhoneNumber: '',
    requestedBidAllowance: '',
    notes: '',
  });
  const [touchedEmail, setTouchedEmail] = useState(false);
  const [touchedPhone, setTouchedPhone] = useState(false);
  const [touchedAllowance, setTouchedAllowance] = useState(false);
  const [terms, setTerms] = useState(false);

  const onHandleSubmit = () => {
    const formatData = {
      bankName: financial.bankName,
      bankerName: financial.bankerName,
      bankerEmail: financial.bankerEmail,
      bankerPhoneNumber: formattedPhoneToNumberString(financial.bankerPhoneNumber),
      notes: financial.notes,
      requestedBidAllowance: formattedCurrencyToNumberString(financial.requestedBidAllowance),
    };
    dispatch(
      bankInformation
        ? updateBankInformation(formatData)
        : sendBankInformation(formatData),
    );
  };

  const financialFields = [
    { label: 'Name of Bank or Financial Institution', value: 'bankName' },
    { label: 'Name of Banker or Private Equity Contact', value: 'bankerName' },
  ];
  const financialFields2 = [{ label: 'Notes/Instructions', value: 'notes' }];
  const createFields = (list, func, state, autoFocus = false) => (
    <>
      {list.map(({ label, value }, i) => (
        <StyledTextField
          value={bankInformation ? financial.value : state[value]}
          key={label}
          multiline={value === 'notes'}
          rows={4}
          variant="outlined"
          onChange={e => {
            func({ ...state, [value]: e.target.value });
          }}
          id="standard-full-width"
          label={label}
          placeholder={label}
          fullWidth
          margin="normal"
          autoFocus={autoFocus && i === 0}
        />
      ))}
    </>
  );

  const { notes, ...restFinancial } = financial;
  const presentFieldsValidationError = Object.values(restFinancial).some(v => !v);
  const emailValidationError = generalRules.required(financial.bankerEmail) || generalRules.email(financial.bankerEmail);
  const phoneValidationError = generalRules.phoneNumber(financial.bankerPhoneNumber);
  const bidAllowanceValidationError = validateBidAllowance(financial.requestedBidAllowance);
  const areFinancialFieldsValid = !presentFieldsValidationError
    && !emailValidationError
    && !phoneValidationError
    && !bidAllowanceValidationError;

  if (isEditable) {
    return (
      <Grid component="form" onSubmit={handleSubmit(onHandleSubmit)}>
        <div className={classes.root}>
          <HeadSection path="/company-dashboard" />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            style={{ margin: 'auto', maxWidth: '642px' }}
          >
            <Typography variant="h1" component="div" className={classes.title}>
              Financing Information
            </Typography>
            {createFields(financialFields, setFinancial, financial, true)}
            <StyledTextField
              type="email"
              value={financial.bankerEmail}
              onChange={e => {
                if (!touchedEmail) {
                  setTouchedEmail(true);
                }
                setFinancial({ ...financial, bankerEmail: e.target.value });
              }}
              onBlur={() => {
                if (!touchedEmail) {
                  setTouchedEmail(true);
                }
              }}
              error={touchedEmail && !!emailValidationError}
              helperText={touchedEmail && emailValidationError}
              label="Email of Banker or Private Equity Contact"
              placeholder="Email of Banker or Private Equity Contact"
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <PhoneInput
              classes={{ maskStyles: classes.phoneInput }}
              meta={{
                touched: touchedPhone,
                error: generalRules.phoneNumber(financial.bankerPhoneNumber),
              }}
              placeholder="Telephone Number for Banker or Private Equity Contact"
              input={{
                value: financial.bankerPhoneNumber,
                name: 'bankerPhoneNumber',
                onChange: e => {
                  if (e.type === 'focus') {
                    return;
                  }
                  if (!touchedPhone) {
                    setTouchedPhone(true);
                  }
                  setFinancial({ ...financial, bankerPhoneNumber: e.target.value });
                },
                onBlur: () => {
                  if (!touchedPhone) {
                    setTouchedPhone(true);
                  }
                },
              }}
            />
            <AmountInput
              classes={{ fieldStyles: classes.amountInput }}
              placeholder="Requested Bid Allowance"
              meta={{
                touched: touchedAllowance,
                error: bidAllowanceValidationError,
              }}
              input={{
                value: financial.requestedBidAllowance,
                onChange: e => {
                  if (!touchedAllowance) {
                    setTouchedAllowance(true);
                  }
                  setFinancial({
                    ...financial,
                    requestedBidAllowance: e.target.value,
                  });
                },
                onBlur: () => {
                  if (!touchedAllowance) {
                    setTouchedAllowance(true);
                  }
                },
                name: 'requestedBidAllowance',
              }}
            />
            {createFields(financialFields2, setFinancial, financial, false)}
            <FormControlLabel
              className={classes.label}
              control={(
                <Checkbox
                  color="default"
                  checked={terms}
                  onChange={() => setTerms(!terms)}
                />
              )}
              label="By checking this box, I agree to contact my entity's financial provider listed above, in order to allow representatives from NOW to speak to my financial provider to verify my entity's access to funds"
            />
            <div className={classes.buttonContainerSingle}>
              <Button
                className={classes.button}
                variant="contained"
                disabled={!areFinancialFieldsValid || !terms || !isEditable}
                type="submit"
              >
                NEXT
              </Button>
            </div>
          </Grid>
        </div>
      </Grid>
    );
  }
  if (isPending) {
    return (
      <Grid>
        <div className={classes.root}>
          <HeadSection path="/company-dashboard" />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            style={{ margin: 'auto', maxWidth: '642px' }}
          >
            <Typography variant="h1" component="div" className={classes.title}>
              Financing Information
            </Typography>
            <Typography variant="body1" className={classes.text}>
              Thank you for submitting your bank information! You will be notified if it is approved.
              If you have any questions or would like to make updates to this information, please contact us.
            </Typography>
          </Grid>
        </div>
      </Grid>
    );
  }
  if (isRejected) {
    return (
      <Grid>
        <div className={classes.root}>
          <HeadSection path="/company-dashboard" />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            style={{ margin: 'auto', maxWidth: '642px' }}
          >
            <Typography variant="h1" component="div" className={classes.title}>
              Financing Information
            </Typography>
            <Typography variant="body1" className={classes.text}>
              Your bank information has been rejected by an Admin. Please contact us if you have any questions or concerns.
            </Typography>
          </Grid>
        </div>
      </Grid>
    );
  }

  return (
    <Grid>
      <div className={classes.root}>
        <HeadSection path="/company-dashboard" />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          style={{ margin: 'auto', maxWidth: '642px' }}
        >
          <Typography variant="h1" component="div" className={classes.title}>
            Financing Information
          </Typography>
          <Typography variant="body1" className={classes.text}>
            Your bank information has already been approved.
            If you would like to update this information, please contact us.
          </Typography>
        </Grid>
      </div>
    </Grid>
  );
};

BankInformation.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func,
  bankInformation: PropTypes.object,
};

export default compose(
  connect(({ auth }) => ({
    initialValues: auth.user.company?.bankInformation,
    bankInformation: auth.user.company?.bankInformation,
  })),
  reduxForm({
    form: formName,
    validate: bankInformationValidate,
  }),
  warnAboutUnsavedForm,
  withStyles(styles),
)(BankInformation);
