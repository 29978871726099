import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// marker icon
import markerIconRed from 'now-frontend-shared/assets/icons/marker_red.svg';
import markerIcon from 'now-frontend-shared/assets/icons/marker.svg';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';

const Marker = ({
  classes, children, isCluster, pointCount, handleClick,
}) => {
  const markerWidth = useMemo(() => 15 + pointCount * 2, [pointCount]);
  const markerHeight = useMemo(() => 20 + pointCount * 2, [pointCount]);

  return (
    <>
      <img
        className={classes.marker}
        src={isCluster ? markerIconRed : markerIcon}
        alt="solo_marker"
        style={{
          width: isCluster && `${markerWidth}px`,
          height: isCluster && `${markerHeight}px`,
        }}
        onClick={handleClick}
      />
      {children}
    </>
  );
};

Marker.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isCluster: PropTypes.bool.isRequired,
  pointCount: PropTypes.number,
  handleClick: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), memo)(Marker);
