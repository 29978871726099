import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// components
import EmptyIcon from './components/EmptyIcon';
import SquareIcon from './components/SquareIcon';
import CheckIcon from './components/CheckIcon';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Checkbox as MUICheckbox, Grid } from '@material-ui/core';

// styles
import styles from './styles';

const Checkbox = ({
  classes, label, name, withSquareIcon, ...rest
}) => (
  <Grid container alignItems="baseline" className={classes.container}>
    <MUICheckbox
      className={classes.root}
      color="primary"
      id={name}
      icon={<EmptyIcon />}
      checkedIcon={withSquareIcon ? <SquareIcon /> : <CheckIcon />}
      {...rest}
    />

    <label htmlFor={name} className={classes.label}>
      {label}
    </label>
  </Grid>
);

Checkbox.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
};

export default compose(withStyles(styles), memo)(Checkbox);
