import moment from 'moment';
import { nonOpWellsDateTime, toAuctionEventTime } from '../helpers/time-helpers';
import { zipFileMimeTypes } from '../helpers/upload-helpers';

export const acceptFileFormats = [
  // pdf
  'application/pdf',
  '.pdf',
  // excel
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.xlsx',
  'application/vnd.ms-excel',
  '.xls',
  // text and msword files
  'application/msword',
  '.doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.docx',
  'text/plain',
  '.txt',
  // images
  'image/tiff',
  'image/jpeg',
  ...zipFileMimeTypes,
  '.zip',
];

export function minStartTimeForListing() {
  return toAuctionEventTime(new Date(), 'start')
    .toJSDate();
}

export function defaultStartTimeForListing() {
  return toAuctionEventTime(
    (
      nonOpWellsDateTime()
        .plus({ days: 1 })
        .toJSDate()
    ),
    'start',
  ).toJSDate();
}

export function minEndTimeForListing(startTime) {
  return toAuctionEventTime(
    (
      nonOpWellsDateTime(startTime ? new Date(startTime) : minStartTimeForListing())
        .toJSDate()
    ),
    'end',
  ).toJSDate();
}

export function defaultEndTimeForListing(startTime) {
  // 2 days long by default, so if it starts Monday morning it will end Tuesday night
  return toAuctionEventTime(
    (
      nonOpWellsDateTime(startTime ? new Date(startTime) : minStartTimeForListing())
        .plus({ days: 1 })
        .toJSDate()
    ),
    'end',
  ).toJSDate();
}

export const listingErrors = {
  beforeMinStartTime: 'Cannot be before today',
  beforeMinEndTime: 'Cannot be before the start time',
  beforeMinEndTimeDefault: 'Cannot be before today',
};

export function listingIsPending({ status: { title } }) {
  return title === 'pending';
}
export function listingIsApproved({ status: { title } }) {
  return title === 'approved';
}

export function listingIsArchived({ archivedAt }) {
  return !!archivedAt;
}

export function listingNotYetApproved({ status: { title } }) {
  return ['pending', 'rejected'].includes(title);
}

export function listingNotYetActive({ status: { title } }) {
  return ['pending', 'approved', 'rejected'].includes(title);
}

export const listingHasBeenActive = listing => !listingNotYetActive(listing);

export const listingHasBeenApproved = listing => !listingNotYetApproved(listing);

export function listingNotYetClosed({ status: { title } }) {
  return [
    'pending',
    'approved',
    'rejected',
    'active',
  ].includes(title);
}

export const listingHasBeenClosed = listing => !listingNotYetClosed(listing);

export function listingNotYetClosedNorArchived({ status: { title }, archivedAt }) {
  return !listingIsArchived({ archivedAt }) && listingNotYetClosed({ status: { title } });
}

export const listingHasBeenClosedOrArchived = listing => !listingNotYetClosedNorArchived(listing);

export function listingIsActive({ status: { title }, archivedAt }) {
  return !listingIsArchived({ archivedAt }) && title === 'active';
}

export function canRejectListing({ status: { title }, archivedAt }) {
  return !listingIsArchived({ archivedAt }) && ['pending', 'approved'].includes(title);
}

export function canApproveListing({
  status: {
    title,
  },
  endTime,
  closedAt,
  archivedAt,
}) {
  const listingEndDate = toAuctionEventTime(new Date(endTime), 'end')
    .toJSDate();

  const now = moment();
  const canApprove = !listingIsArchived({ archivedAt })
      && ['pending', 'rejected'].includes(title)
      && now.isBefore(listingEndDate)
      && (!closedAt || now.isBefore(closedAt));
  return canApprove;
}

export function canCloseListing({ status: { title }, archivedAt }) {
  return listingIsActive({ status: { title }, archivedAt });
}

export const ALLOWED_LISTING_STATUS_TITLES_FOR_DELETE = [
  'pending',
  'approved',
  'rejected',
  'closed',
  'winner selected',
  'deleted',
];

export function canDeleteListing({ status: { title } }) {
  return ALLOWED_LISTING_STATUS_TITLES_FOR_DELETE.includes(title);
}

export function canArchiveListing({ status: { title }, archivedAt }) {
  return !listingIsArchived({ archivedAt }) && [
    'pending',
    'approved',
    'rejected',
    'closed',
    'winner selected',
    'sold',
    'deleted',
  ].includes(title);
}

export function listingIsClosedIsh({ status: { title } }) {
  return ['closed', 'winner selected'].includes(title);
}

export function canReopenListing({ status: { title }, archivedAt }) {
  return !listingIsArchived({ archivedAt }) && listingIsClosedIsh({ status: { title } });
}
