import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';
import SubmitConfirmationModal from 'now-frontend-shared/components/modals/SubmitConfirmationModal';
import { Prompt } from 'react-router-dom';

export const warnAboutUnsavedForm = WrappedComponent => {
  const WarnAboutUnsavedChanges = props => {
    const {
      leaveMessage,
      submittingMessage,
      ...passThroughProps
    } = props;

    const {
      dirty,
      submitSucceeded,
      submitting,
    } = passThroughProps;

    const [show, setShow] = useState(false);
    const [hasSubmitSucceeded, setHasSubmitSucceeded] = useState(false);
    const [lastLocation, setLastLocation] = useState(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const { setModal } = useModalSetter();
    const history = useHistory();

    useEffect(() => {
      if (submitSucceeded) {
        setHasSubmitSucceeded(true);
      }
    }, [submitSucceeded]);

    useEffect(() => {
      // TODO: [REFACTOR][SIMPLIFY] in all forms, manually reset the form upon submission success.
      // Once that change has been made, remove the code here that checks for submitSucceeded, and
      // instead just check for dirty.
      //
      // NOTE: in form, make sure to reset the form during onSubmit if you want to intentionally
      // navigate to another location within onSubmit (before the form is marked as successfully submitted).      //
      //
      // NOTE: some forms appear to always be dirty, even after they are reset. May be related to this issue:
      // https://github.com/redux-form/redux-form/issues/2502
      // If this is the case in a form, and that form needs to redirect as part of the onSubmit, the redirect
      // needs to happen _after_ the onSubmit returns (using setTimeout() or similar mechanism to ensure
      // onSubmit returns _before_ the redirect occurs).
      //
      // NOTE: the component using this HOC needs to be re-mounted after successful submission if the form
      // is going to be reused on the same page after submission. This can be done by incrementing a `key`
      // prop on the form component every time it completes submission successfully.
      setShow(dirty && !hasSubmitSucceeded);
    }, [setModal, dirty, hasSubmitSucceeded]);

    useEffect(() => {
      if (confirmedNavigation && lastLocation) {
        const path = lastLocation.nextLocation.pathname;
        // Navigate to the previous blocked location with your navigate function
        switch (lastLocation.action) {
          // TODO: [FEATURE] NOW-245 Handle POP state by going back in browser history
          // case 'POP':
          case 'REPLACE':
            history.replace(path);
            break;
          default:
            history.push(path);
        }
      }
    }, [confirmedNavigation, lastLocation, history]);

    const handleOpenConfirmModal = () => {
      setModal(
        <SubmitConfirmationModal
          heading={submitting ? submittingMessage : leaveMessage}
          handleSubmit={() => {
            setShow(false);
            setConfirmedNavigation(true);
          }}
        />,
      );
    };

    const handleNavigation = (nextLocation, action) => {
      if (show) {
        setLastLocation({ nextLocation, action });
        handleOpenConfirmModal();
        return false;
      }

      return true;
    };

    const alertUser = useCallback(e => {
      if (show) {
        e.preventDefault();
        e.returnValue = '';
      }
    }, [show]);

    useEffect(() => {
      window.addEventListener('beforeunload', alertUser);
      return () => {
        window.removeEventListener('beforeunload', alertUser);
      };
    }, [alertUser]);

    return (
      <>
        <WrappedComponent {...passThroughProps} />
        <Prompt
          when={show}
          message={handleNavigation}
        />
      </>
    );
  };

  WarnAboutUnsavedChanges.propTypes = {
    dirty: PropTypes.bool.isRequired,
    submitSucceeded: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    leaveMessage: PropTypes.string,
    submittingMessage: PropTypes.string,
  };

  WarnAboutUnsavedChanges.defaultProps = {
    leaveMessage: 'Leave with unsaved change?',
    submittingMessage: 'Leave while submission is in progress?',
  };

  return WarnAboutUnsavedChanges;
};
