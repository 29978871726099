import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// components
import BaseInput from '../BaseInput';

// eye icons to view/hide password
import { ReactComponent as EyeIsOpen } from 'now-frontend-shared/assets/icons/eye_is_open.svg';
import { ReactComponent as EyeIsClose } from 'now-frontend-shared/assets/icons/eye_is_close.svg';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';

// styles
import styles from '../styles';

const PasswordInput = ({
  classes,
  input,
  meta,
  placeholder,
  description,
  autoFocus,
}) => {
  const [isPasswordVisible, setPasswordIsVisible] = useState(false);

  const handleButtonMouseDown = event => {
    event.preventDefault();
  };

  const toggleIcon = () => {
    setPasswordIsVisible(prevState => !prevState);
  };

  const isError = meta.touched && meta.error;

  return (
    <BaseInput
      type={isPasswordVisible ? 'input' : 'password'}
      placeholder={placeholder}
      input={input}
      meta={meta}
      autoFocus={autoFocus}
      inputToggleButton={(
        <IconButton
          className={classes.iconButton}
          aria-label="toggle password visibility"
          onMouseDown={handleButtonMouseDown}
          onClick={toggleIcon}
        >
          {isPasswordVisible ? <EyeIsClose /> : <EyeIsOpen />}
        </IconButton>
      )}
      description={description && !isError && <span className={classes.passwordDescription}>{description}</span>}
    />
  );
};

PasswordInput.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string.isRequired,
  description: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  autoFocus: PropTypes.bool,
};

PasswordInput.defaultProps = {
  autoFocus: false,
};

export default compose(withStyles(styles), memo)(PasswordInput);
