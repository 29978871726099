import React, { useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import { useDispatch, connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import {
  doesAgreementHaveDocument,
  isCustomAgreement,
  isOldNonPreFilledAgreement,
} from 'now-shared/helpers/agreement-helpers';
import Spinner from 'now-frontend-shared/components/Spinner';

// layouts
import BottomSection from 'layouts/AuthSections/BottomSection';
import HeadSection, { returnToPreviousPage } from 'now-frontend-shared/layouts/AuthSections/HeadSection';
import FormLayout from 'now-frontend-shared/layouts/FormLayout';
import DocumentViewLayout from 'now-frontend-shared/layouts/DocumentViewLayout';
import SignatureFormLayout from 'now-frontend-shared/layouts/SignatureFormLayout';

// components
import Button from '@material-ui/core/Button';
import { warnAboutUnsavedForm } from 'now-frontend-shared/hoc/warnAboutUnsavedForm';
import { getAgreementSignatureAttachments } from 'now-frontend-shared/helpers/pdfAttachment';
import { getSellerAgreementAttachmentOffsets } from 'now-frontend-shared/helpers/seller-agreement-pdf';
import IconSaveAlt from '@material-ui/icons/SaveAlt';

// constants
import defaultNonPreFilledAgreementPdf from 'now-shared/assets/docs/seller-agreement.pdf';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';

// styles
import { sendSellerAgreement, updateSellerAgreement } from 'store/actions/authActions';
import styles from '../styles';

// store
import { getNonOpWellsSignatureDetails } from 'store/actions/companiesActions';

// helpers
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import {
  getAgreementName,
  sellerAgreementName,
} from 'now-shared/helpers/company-helpers';
import { downloadFileAsFilename } from 'now-frontend-shared/utils/download-helpers';

// validations
import { validateSignatureForm } from 'now-shared/validation/signature-validation';
import { formValidationHasErrors } from 'now-shared/validation/validation-rules';
import { getSignatureDateString, toDateString } from 'pages/Business/components/BuyersCertificateView';

const formName = 'sellerAgreement';
const agreementName = sellerAgreementName;

const SellerAgreement = ({
  classes,
  handleSubmit,
  history,
  sellerAgreement,
  authUserFullName,
  signer2Name,
  signer2Title,
  isSubmitting,
}) => {
  const hasSavedAgreement = !!sellerAgreement;

  const savedAgreementIsMissingDocument = !!sellerAgreement && !doesAgreementHaveDocument(sellerAgreement);

  const hasAgreementFileStored = !!sellerAgreement?.document;

  const hasOldNonPreFilledAgreement = !!sellerAgreement && isOldNonPreFilledAgreement(sellerAgreement);

  const hasCustomAgreement = !!sellerAgreement && isCustomAgreement(sellerAgreement);

  const [signature, setSignature] = useState({
    // TODO: [BUG][INTEGRITY][UX] if anyone besides the original signer is viewing this page, this name and
    // signature below will not be correct.
    name: authUserFullName,
    date: getSignatureDateString(),
    signature: '',
  });

  useEffect(() => {
    setSignature(data => ({
      // TODO: [BUG][INTEGRITY][UX] if anyone besides the original signer is viewing this page, this name and
      // signature below will not be correct.
      ...data,
      date: toDateString(sellerAgreement?.wasSignedOnCreation ? sellerAgreement.createdAt : new Date()),
      // TODO: [BUG] This value should be initialized with the signature value that user did in the registration step,
      // not necessarily the same as the user's current full name.
      signature: sellerAgreement?.wasSignedOnCreation ? authUserFullName : '',
    }));
  }, [sellerAgreement, authUserFullName]);

  const isEditable = !hasSavedAgreement;
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => { dispatch(getNonOpWellsSignatureDetails()); }, []);

  const onHandleClose = () => {
    returnToPreviousPage(location, history, '/company-dashboard');
  };

  const onHandleSubmit = async () => {
    await new Promise((resolve, reject) => {
      dispatch(
        sellerAgreement
          ? updateSellerAgreement({
            signature: signature.signature,
            resolve,
            reject,
          })
          : sendSellerAgreement({
            signature: signature.signature,
            resolve,
            reject,
          }),
      );
    });
    onHandleClose();
  };

  const signatureChange = e => setSignature({ ...signature, signature: e.target.value });

  const newSignature = signature.signature;

  const printedName = signature.name;

  const signatureDate = signature.date;

  const errors = validateSignatureForm({ signature: newSignature, printedName });

  let documentDownloadUrl;
  let documentDisplayedUrl;
  if (hasAgreementFileStored) {
    documentDownloadUrl = sellerAgreement.document.downloadUrl;
  } else if (hasOldNonPreFilledAgreement || !savedAgreementIsMissingDocument) {
    documentDownloadUrl = defaultNonPreFilledAgreementPdf;
  }

  if (!documentDisplayedUrl) {
    documentDisplayedUrl = documentDownloadUrl;
  }

  const attachments = hasAgreementFileStored
    ? []
    : getAgreementSignatureAttachments({
      pageNumber: getSellerAgreementAttachmentOffsets.pageNumber,
      offsetLeft: getSellerAgreementAttachmentOffsets.offsetLeft,
      offsetTop: getSellerAgreementAttachmentOffsets.offsetTop,
      signer1Name: signature.signature,
      signer1Title: printedName,
      signer2Name,
      signer2Title,
    });

  return (
    <FormLayout
      onSubmit={handleSubmit(onHandleSubmit)}
    >
      {(
        isSubmitting
      ) && (
        <Spinner backdrop />
      )}
      <HeadSection path="/company-dashboard" />
      <DocumentViewLayout
        document={documentDownloadUrl}
        documentName={agreementName}
        attachments={attachments}
      >
        {documentDownloadUrl && (
          <div className={classes.downloadContainer}>
            <a
              onClick={e => {
                e.preventDefault();
                downloadFileAsFilename({
                  downloadUrl: documentDownloadUrl,
                  filename: getAgreementName({
                    baseName: agreementName,
                    isCustom: hasCustomAgreement,
                    withExtension: true,
                  }),
                });
              }}
              href={documentDisplayedUrl}
              className={classes.download}
              download
            >
              {agreementName}
              <IconSaveAlt />
            </a>
          </div>
        )}
        <BottomSection>
          <SignatureFormLayout
            onSignatureChange={signatureChange}
            newSignature={newSignature}
            printedName={printedName}
            signatureDate={signatureDate}
            errors={errors}
            alreadySigned={sellerAgreement?.wasSignedOnCreation}
            hideFields={hasCustomAgreement || savedAgreementIsMissingDocument}
          >
            {isEditable && (
              <div className={classes.buttonContainer}>
                <Button
                  className={classes.button}
                  variant="outlined"
                  onClick={() => {
                    onHandleClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className={classes.button}
                  variant="outlined"
                  type="submit"
                  disabled={
                    formValidationHasErrors(errors)
                    || !signature.date
                    || !signer2Name
                    || !signer2Title
                    || isSubmitting
                    || savedAgreementIsMissingDocument
                  }
                >
                  I agree
                </Button>
              </div>
            )}
          </SignatureFormLayout>
        </BottomSection>
      </DocumentViewLayout>
    </FormLayout>
  );
};

SellerAgreement.defaultProps = {
};

SellerAgreement.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  sellerAgreement: PropTypes.object,
  authUserFullName: PropTypes.string.isRequired,
  signer2Name: PropTypes.string.isRequired,
  signer2Title: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default compose(
  connect(({ auth, companies }) => ({
    initialValues: auth.user.company?.sellerAgreement,
    sellerAgreement: auth.user.company?.sellerAgreement,
    authUserFullName: getUserFullName(auth.user),
    signer2Name: companies.nonOpWellsSignatureDetails?.name || '',
    signer2Title: companies.nonOpWellsSignatureDetails?.title || '',
    isSubmitting: auth.isSubmittingAgreement,
  })),
  reduxForm({ form: formName }),
  warnAboutUnsavedForm,
  withStyles(styles),
)(SellerAgreement);
