import { action, asyncAction } from 'now-frontend-shared/utils/actions';

// async actions
export const getAllCompanies = asyncAction('companies/GET_ALL_COMPANIES');
export const findUsersByCompany = asyncAction('companies/FIND_USERS_BY_COMPANY');
export const getNonOpWellsSignatureDetails = asyncAction('companies/GET_NONOPWELLS_SIGNATURE_DETAILS');

// sync actions
export const clearState = action('companies/CLEAR_STATE');
export const clearUsersInCompany = action('auth/CLEAR_USERS_IN_COMPANY');
