import { primaryMain, secondaryMain, white } from 'themes/colors';

export default {
  message: {
    fontSize: '22px',
    lineHeight: '28px',
    fontWeight: 'normal',
    margin: '50px 0px 0px 200px',
  },
  listings: {
    padding: '0px 10px',
  },
  sidenav: {
    gridColumnStart: 1,
    gridColumnEnd: 2,
    width: '0px',
    overflowX: 'hidden',
    transition: 'width 0.5s linear',
  },
  sideNavContent: {
    display: 'grid',
    gridColumnStart: 1,
    gridColumnEnd: 2,
    gridRowStart: 1,
    gridRowEnd: 2,
    width: '400px',
    height: '1215px',
    opacity: 0,
    visibility: 'hidden',
    alignContent: 'start',
    gridTemplateRows: 'fit-content(0px)',
    padding: '30px 0px 0px 15px',
    fontSize: '16px',
    // TODO: [DRY] use color palette constant instead of literal color value
    // See `frontend-shared/theme/colors.js`
    backgroundColor: 'white',
    transition: 'opacity 0.5s linear',
    // TODO: [DRY] use a color palette constant instead of string literal here.
    // See `frontend-shared/theme/colors.js`
    boxShadow: `0 1px 2px rgba(0,0,0,0.07),
    0 2px 4px rgba(0,0,0,0.07),
    0 4px 8px rgba(0,0,0,0.07),
    0 8px 16px rgba(0,0,0,0.07),
    0 16px 32px rgba(0,0,0,0.07),
    0 32px 64px rgba(0,0,0,0.07)`,
  },
  statusFilter: {
    display: 'grid',
    gridAutoFlow: 'row',
    padding: '0px 0px 0px 25px',
    width: '100%',
  },
  labelAndChips: {
    gridColumnStart: 1,
    gridColumnEnd: 2,
    gridRowStart: 1,
    gridRowEnd: 2,
    transition: 'margin 0.5s linear',
    maxHeight: '100px',
    padding: '40px 10px 40px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listingsAndMap: {
    display: 'grid',
    gridColumnStart: 1,
    gridColumnEnd: 2,
    gridRowStart: 1,
    gridRowEnd: 2,
    gridTemplateColumns: 'fit-content(0px) 1fr',
    margin: '105px 0px 0px 10px',
    zIndex: 2,
  },
  filterButton: {
    transition: 'opacity 0.5s linear',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'end',
  },
  filters: {
    display: 'grid',
    gridAutoFlow: 'row',
    padding: '0px 0px 0px 25px',
    width: '100%',
    maxHeight: '300px',
    overflowY: 'scroll',
  },
  createListingButton: {
    color: white,
    backgroundColor: primaryMain,
    '&:hover': {
      backgroundColor: secondaryMain,
      cursor: 'hand',
    },
  },
  filterButtonStyle: {
    minWidth: '125px',
    color: white,
    backgroundColor: primaryMain,
    '&:hover': {
      backgroundColor: secondaryMain,
      cursor: 'hand',
    },
  },
  checkbox: {
    color: primaryMain,
    '&.Mui-checked': {
      color: primaryMain,
    },
  },
};
