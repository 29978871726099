import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Field,
  reduxForm,
} from 'redux-form';
import {
  useDispatch,
  connect,
} from 'react-redux';

import PropTypes from 'prop-types';
import { compose } from 'redux';

// components
import Spinner from 'now-frontend-shared/components/Spinner';
import { warnAboutUnsavedForm } from 'now-frontend-shared/hoc/warnAboutUnsavedForm';

// validations
import { validateCertification } from 'now-shared/validation/company-certification';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

// styles
import styles from './styles';
import { signatureStyle } from '../styles';

// store
import { getSignatureDateString } from '../BuyersCertificateView';
import BaseInputNew from 'now-frontend-shared/components/inputs/BaseInputNew';
import { persistCompanyUpdates } from 'store/actions/companyActions';

import { getUserFullName } from 'now-shared/helpers/user-helpers';
import { navigateToNextPage, navigateToPreviousPage } from 'constants/registrationFlow';

const CertifyInfoView = ({
  classes,
  handleSubmit,
  user,
  submitting,
  reset,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isEditable, setIsEditable] = useState(true);

  const companyId = user?.company?.id;

  const onSubmit = useCallback(async data => {
    setIsEditable(false);
    try {
      if (!user.company?.certification) {
        await new Promise((resolve, reject) => dispatch(persistCompanyUpdates({
          companyId,
          updates: {
            certification: {
              certifierSignature: data.signature,
            },
          },
          resolve,
          reject,
        })));
      }
      // reset the form so that we can navigate away from the page without being prompted to
      // confirm navigation away from a dirty form.
      reset();
      history.push(navigateToNextPage(history.location.pathname));
    } finally {
      setIsEditable(true);
    }
  }, [companyId, dispatch, history, reset]);

  return (
    <div className={classes.root}>
      {submitting && <Spinner backdrop />}

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{
          margin: 'auto',
          maxWidth: '883px',
        }}
      >
        <Typography variant="h1" component="div" className={classes.title}>
          Certification of Complete and Correct Information
        </Typography>
        <Typography variant="body1" component="div" className={classes.subTitleNew}>
          I hereby certify, to the best of my knowledge, that the information
          provided is complete and correct.
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>

          {/* TODO [REFACTOR] consolidate these fields into SignatureFormLayout shared component */}
          <div className={classes.inputContainer}>
            <fieldset disabled={!isEditable || submitting} className={classes.fieldset}>
              <Grid container spacing={9}>
                <Grid item xs={9}>
                  <Field
                    component={BaseInputNew}
                    name="signature"
                    props={{
                      fullWidth: true,
                      InputProps: {
                        style: signatureStyle,
                        ...user.company?.certification?.certifierSignature && {
                          value: user.company.certification.certifierSignature,
                        },
                      },
                      label: 'Signature',
                      variant: 'standard',
                      autoFocus: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <div className={classes.dateContainer}>
                    <TextField
                      fullWidth
                      type="date"
                      disabled
                      variant="standard"
                      value={getSignatureDateString()}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={9}>
                <Grid item xs={9}>
                  <Field
                    component={BaseInputNew}
                    name="printedName"
                    props={{
                      fullWidth: true,
                      disabled: true,
                      label: 'Printed Name',
                      variant: 'standard',
                    }}
                  />
                </Grid>
              </Grid>
            </fieldset>
          </div>
          <div className={classes.buttonContainer}>
            <Button variant="text" className={classes.backButton} onClick={() => history.push(navigateToPreviousPage(history.location.pathname))}>
              BACK
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              type="submit"
              disabled={submitting}
            >
              Next
            </Button>
          </div>
        </form>
      </Grid>
    </div>
  );
};

CertifyInfoView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func,
  // TODO: fill in shape details
  user: PropTypes.shape({}).isRequired,
  submitting: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
};

CertifyInfoView.defaultProps = {
  handleSubmit: undefined,
};

export default compose(
  connect(state => ({
    initialValues: {
      printedName: getUserFullName(state.auth.user),
    },
    user: state.auth.user,
  })),
  reduxForm({
    form: 'certifyRegistrationInfo',
    validate: validateCertification,
  }),
  warnAboutUnsavedForm,
  withStyles(styles),
)(CertifyInfoView);
