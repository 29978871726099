import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
// import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import queryString from 'query-string';

import styles from './styles';
import { signBuyerAgreement } from 'store/actions/companyActions';
import defaultNonPreFilledAgreementPdf from 'now-shared/assets/docs/buyers-agreement.pdf';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import BottomSection from 'layouts/AuthSections/BottomSection';
import FormLayout from 'now-frontend-shared/layouts/FormLayout';
import DocumentViewLayout, { DocumentLayoutContainer } from 'now-frontend-shared/layouts/DocumentViewLayout';
import SignatureFormLayout from 'now-frontend-shared/layouts/SignatureFormLayout';
import { getAgreementSignatureAttachments } from 'now-frontend-shared/helpers/pdfAttachment';
import { getNonOpWellsSignatureDetails } from 'store/actions/companiesActions';
import IconSaveAlt from '@material-ui/icons/SaveAlt';

import {
  validateSignatureForm,
} from 'now-shared/validation/signature-validation';
import { formValidationHasErrors } from 'now-shared/validation/validation-rules';
import {
  getAgreementName,
  buyerAgreementName,
} from 'now-shared/helpers/company-helpers';
import { downloadFileAsFilename } from 'now-frontend-shared/utils/download-helpers';
import { navigateToNextPage, navigateToPreviousPage } from 'constants/registrationFlow';
import TipTap, { ExtensionName } from 'now-frontend-shared/components/TipTap';
import { apiAuthTokenQueryParamName } from 'now-shared/helpers/auth-helpers';
import { getAccessToken } from 'auth/auth-helpers';
import Spinner from 'now-frontend-shared/components/Spinner';
import { getBuyerAgreementTemplate } from 'store/actions/authActions';
import {
  agreementPageSettings,
  doesAgreementHaveDocument,
  isCustomAgreement,
  isOldNonPreFilledAgreement,
  oldNonPreFilledBuyerAgreementVersion,
} from 'now-shared/helpers/agreement-helpers';
import { getBuyerAgreementAttachmentOffsets } from 'now-frontend-shared/helpers/buyer-agreement-pdf';

const agreementName = buyerAgreementName;

export function toDateString(date) {
  return new Date(date).toISOString().slice(0, 10);
}

export function getSignatureDateString() {
  return toDateString(new Date());
}

function BuyersCertificateView({
  classes,
  authUserFullName,
  buyerAgreement,
  buyerAgreementTemplate,
  buyerAgreementTemplateIsLoading,
  isSubmitting,
  signer2Name,
  signer2Title,
  company,
  authUserCompany,
}) {
  const hasSavedAgreement = !!buyerAgreement;

  const savedAgreementIsMissingDocument = !!buyerAgreement && !doesAgreementHaveDocument(buyerAgreement);

  const [signature, setSignature] = useState({
    // TODO: [BUG][INTEGRITY][UX] if anyone besides the original signer is viewing this page, this name and
    // signature below will not be correct.
    name: authUserFullName,
    date: getSignatureDateString(),
    signature: '',
  });

  useEffect(() => {
    setSignature(data => ({
      // TODO: [BUG][INTEGRITY][UX] if anyone besides the original signer is viewing this page, this name and
      // signature below will not be correct.
      ...data,
      date: toDateString(buyerAgreement?.wasSignedOnCreation ? buyerAgreement.createdAt : new Date()),
      // TODO: [BUG] This value should be initialized with the signature value that user did in the registration step,
      // not necessarily the same as the user's current full name.
      signature: buyerAgreement?.wasSignedOnCreation ? authUserFullName : '',
    }));
  }, [buyerAgreement, authUserFullName]);

  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => { dispatch(getNonOpWellsSignatureDetails()); }, [dispatch]);

  const signatureChange = e => setSignature({ ...signature, signature: e.target.value });

  const newSignature = signature.signature;

  const printedName = signature.name;

  const signatureDate = signature.date;

  const errors = validateSignatureForm({ signature: newSignature, printedName });

  const [tipTapContent, setTipTapContent] = useState(undefined);

  const tipTapData = useMemo(() => ({
    [ExtensionName.Company]: {
      company: authUserCompany ?? company,
    },
  }), [
    authUserCompany,
    company,
  ]);

  const hasAgreementFileStored = !!buyerAgreement?.document;

  const hasCustomAgreement = !!buyerAgreement && isCustomAgreement(buyerAgreement);

  const hasOldNonPreFilledAgreement = !!buyerAgreement && isOldNonPreFilledAgreement(buyerAgreement);

  const isRenderingWithTipTap = !hasSavedAgreement;

  let documentDownloadUrl;
  let documentDisplayedUrl;
  if (hasAgreementFileStored) {
    documentDownloadUrl = buyerAgreement.document.downloadUrl;
  } else if (hasOldNonPreFilledAgreement) {
    documentDownloadUrl = defaultNonPreFilledAgreementPdf;
  } else if (!savedAgreementIsMissingDocument) {
    documentDisplayedUrl = `${process.env.REACT_APP_API_URL}/buyer-agreement-pdf`;
    documentDownloadUrl = queryString.stringifyUrl({
      url: documentDisplayedUrl,
      query: {
        // TODO: [SECURITY][FEATURE] find a way to download the file
        // without having to send the auth token in the URL?
        [apiAuthTokenQueryParamName]: getAccessToken(),
      },
    });
  }

  if (!documentDisplayedUrl) {
    documentDisplayedUrl = documentDownloadUrl;
  }

  useEffect(() => {
    if (isRenderingWithTipTap) {
      dispatch(getBuyerAgreementTemplate());
    }
  }, [dispatch, isRenderingWithTipTap]);

  useEffect(() => {
    if (buyerAgreementTemplate) {
      setTipTapContent(buyerAgreementTemplate.document);
    }
  }, [buyerAgreementTemplate]);

  const attachments = (hasAgreementFileStored || isRenderingWithTipTap)
    ? []
    : getAgreementSignatureAttachments({
      pageNumber: getBuyerAgreementAttachmentOffsets.pageNumber,
      offsetLeft: getBuyerAgreementAttachmentOffsets.offsetLeft,
      offsetTop: getBuyerAgreementAttachmentOffsets.offsetTop,
      signer1Name: signature.signature,
      signer1Title: printedName,
      signer2Name,
      signer2Title,
    });

  const tipTapView = isRenderingWithTipTap
    ? (
      <>
        <Box
          display="flex"
          flexDirection="column"
          width={1}
          style={{
            width: agreementPageSettings.width,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            width={1}
            style={{
              paddingTop: agreementPageSettings.margin.top,
              paddingBottom: agreementPageSettings.margin.bottom,
              paddingLeft: agreementPageSettings.margin.left,
              paddingRight: agreementPageSettings.margin.right,
              boxSizing: 'border-box',
            }}
          >
            <TipTap
              extensionData={tipTapData}
              content={tipTapContent}
            />
          </Box>
        </Box>
      </>
    )
    : null;

  const renderNavigationButtons = () => {
    const SkipAndNextButtons = () => (
      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          variant="outlined"
          onClick={() => history.push(navigateToNextPage(history.location.pathname, true))}
        >
          Skip
        </Button>
        <Button
          className={classes.button}
          variant="outlined"
          disabled={
            !hasSavedAgreement
            && (
              formValidationHasErrors(errors)
              || !signature.date
              || !signer2Name
              || !signer2Title
              || isSubmitting
              || (isRenderingWithTipTap && buyerAgreementTemplateIsLoading)
              || savedAgreementIsMissingDocument
            )
          }
          onClick={async () => {
            // TODO [BUG] Make the backend accept a new signature
            if (!hasSavedAgreement) {
              try {
                await new Promise((resolve, reject) => dispatch(signBuyerAgreement({
                  signature: signature.signature,
                  docVersion: buyerAgreementTemplate.version,
                  resolve,
                  reject,
                })));
              } catch (err) {
                // eslint-disable-next-line no-console
                console.log(err);
              } finally {
                history.push(navigateToNextPage(history.location.pathname));
              }
            } else {
              history.push(navigateToNextPage(history.location.pathname));
            }
          }}
        >
          Next
        </Button>
      </div>
    );
    return (
      <div className={classes.buttonContainerWithBack}>
        <Button
          variant="text"
          className={classes.backButton}
          onClick={() => history.push(navigateToPreviousPage(history.location.pathname, company))}
        >
          BACK
        </Button>
        <SkipAndNextButtons />
      </div>
    );
  };

  return (
    <>
      <FormLayout>
        {(
          isSubmitting
          || (buyerAgreementTemplateIsLoading && isRenderingWithTipTap)
        ) && (
          <Spinner backdrop />
        )}
        {(
          (isRenderingWithTipTap)
            ? (
              <DocumentLayoutContainer
                documentName={agreementName}
              >
                {tipTapView}
              </DocumentLayoutContainer>
            )
            : (
              <DocumentViewLayout
                document={documentDownloadUrl}
                documentName={agreementName}
                attachments={attachments}
              />
            )
        )}
        {documentDownloadUrl && (
          <div className={classes.downloadContainer}>
            <a
              onClick={e => {
                e.preventDefault();
                downloadFileAsFilename({
                  downloadUrl: documentDownloadUrl,
                  filename: getAgreementName({
                    baseName: agreementName,
                    isCustom: hasCustomAgreement,
                    version: (buyerAgreement && isOldNonPreFilledAgreement(buyerAgreement))
                      ? oldNonPreFilledBuyerAgreementVersion
                      : undefined,
                    withExtension: true,
                  }),
                });
              }}
              href={documentDisplayedUrl}
              className={classes.download}
              download
            >
              {agreementName}
              <IconSaveAlt />
            </a>
          </div>
        )}
        <BottomSection>
          <SignatureFormLayout
            onSignatureChange={signatureChange}
            newSignature={newSignature}
            printedName={printedName}
            signatureDate={signatureDate}
            errors={errors}
            alreadySigned={buyerAgreement?.wasSignedOnCreation}
            hideFields={hasCustomAgreement || savedAgreementIsMissingDocument}
          >
            <Box
              display="flex"
              width="100%"
              justifyContent="center"
            >
              <Box
                display="flex"
                textAlign="center"
                width="85%"
                marginBottom={3}
                fontWeight="bold"
              >
                If you have any questions regarding the above agreement, click “SKIP” to temporarily
                bypass and a NOW representative will reach out to discuss
              </Box>
            </Box>
            {renderNavigationButtons()}
          </SignatureFormLayout>
        </BottomSection>
      </FormLayout>
    </>
  );
}

BuyersCertificateView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  authUserCompany: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  buyerAgreement: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  buyerAgreementTemplate: PropTypes.object,
  buyerAgreementTemplateIsLoading: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  authUserFullName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  company: PropTypes.object.isRequired,
};

BuyersCertificateView.defaultProps = {
  authUserCompany: undefined,
  buyerAgreement: undefined,
  buyerAgreementTemplate: undefined,
};

export default compose(
  connect(({ auth, companies, company }) => ({
    company,
    fullLegalCompanyName: auth.user.company?.fullLegalCompanyName,
    authUserFullName: getUserFullName(auth.user),
    buyerAgreement: auth.user.company?.buyerAgreement,
    buyerAgreementTemplate: auth.buyerAgreementTemplate,
    buyerAgreementTemplateIsLoading: auth.buyerAgreementTemplateIsLoading,
    isSubmitting: auth.isSubmittingAgreement,
    signer2Name: companies.nonOpWellsSignatureDetails?.name || '',
    signer2Title: companies.nonOpWellsSignatureDetails?.title || '',
    authUserCompany: auth.user.company,
  })),
  withStyles(styles),
)(BuyersCertificateView);
