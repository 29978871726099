import { primaryDark } from '../../../themes/colors';

export default {
  container: {
    padding: '40px 45px 0',

    '@media screen and (max-width: 600px)': {
      padding: '40px 0 0',
    },
  },
  heading: {
    marginBottom: '10px',
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 'bold',
    color: primaryDark,

    '@media screen and (max-width: 600px)': {
      fontSize: '20px',
      lineHeight: '23px',
    },
  },
};
